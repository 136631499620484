import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {environment} from 'src/environments/environment';
import {RoadmapModel, TaxonomyType} from '../models/roadmap.model';
import {Observable} from 'rxjs';
import { ObjectiveModel } from '../models/objective.model';
import { KeyResultModel } from '../models/key-result.model';
import { ProductModel } from '../models/product.model';
import { ProductGroupModel } from '../models/product-group.model';
import { RoadmapProgressModel } from '../models/roadmap-progress.model';
import { RoadmapPriorityModel } from '../models/roadmap-priority.model';
import _ from 'lodash';
import { Month, Quarter, State } from '@enums';
import { DatePipe } from '@angular/common';
import { RoadmapPriorityService } from './roadmap-priority.service';
import { ProductService } from './product.service';
import { ProductGroupService } from './productGroup.service';

@Injectable({
  providedIn: 'root'
})
export class RoadmapService {

  constructor(
    private readonly httpClient: HttpClient,
    private readonly datePipe: DatePipe,
    private readonly productService: ProductService,
    private readonly productGroupService: ProductGroupService,
    private readonly roadmapPriorityService: RoadmapPriorityService,
  ) {
  }

  formatMilestone(roadmap: RoadmapModel): string {
    let thisMonth: string;
    let thisQuarter: string;
    let thisYear: string;

    if (roadmap.month !== '0') {
      thisMonth = roadmap.month;
    }
    if (roadmap.quarter !== '0') {
      thisQuarter = roadmap.quarter;
    }
    if (roadmap.year !== '0') {
      thisYear = roadmap.year;
    }

    if (thisMonth && thisQuarter && thisYear) {
      return thisYear + ' ' + thisQuarter + ' ' + this.datePipe.transform(thisMonth + '-01-' + thisYear, 'MMMM');
    } else if (!thisMonth && thisQuarter && thisYear) {
      return thisYear + ' ' + thisQuarter;
    } else if (thisQuarter && !thisMonth && !thisYear) {
      return thisQuarter;
    } else if (!thisQuarter && !thisMonth && thisYear) {
      return thisYear;
    } else if (thisQuarter && thisMonth && !thisYear) {
      return thisQuarter + ' ' + this.datePipe.transform(thisMonth + '-01-' + thisYear, 'MMMM');
    } else {
      return ''; //not a complete date, show nothing until filled out completely
    }

  }
  create(newRoadmap: RoadmapModel): Observable<any> {
    return this.httpClient.post<RoadmapModel>(`${environment.baseURL}/roadmaps`, newRoadmap);
  }

  update(existingRoadmapItem: RoadmapModel): Observable<any> {
    return this.httpClient.put<RoadmapModel>(`${environment.baseURL}/roadmaps`, existingRoadmapItem);
  }

  getById(id: string): Observable<any> {
    return this.httpClient.get<RoadmapModel>(`${environment.baseURL}/roadmaps/${id}`);
  }

  delete(existingRoadmapItem: RoadmapModel): Observable<any> {
    return this.httpClient.delete(`${environment.baseURL}/roadmaps/${existingRoadmapItem.id}`);
  }

  filterRoadmapsByStates(roadmaps: RoadmapModel[], sts: State[]): RoadmapModel[] {
    return _(roadmaps)
      .filter((rm: RoadmapModel) => sts.length === 0 || sts.find(s => s === rm.progressState) !== undefined)
      .value();
  }

  filterRoadmapsByQuarters(roadmaps: RoadmapModel[], qts: Quarter[]): RoadmapModel[] {
    return _(roadmaps)
      .filter((rm: RoadmapModel) => qts.length === 0 || qts.find(x => x === rm.quarter) !== undefined)
      .value();
  }

  filterRoadmapsByYears(roadmaps: RoadmapModel[], yrs: number[]): RoadmapModel[] {
    return _(roadmaps)
      .filter((rm: RoadmapModel) => yrs.length === 0 || yrs.find(x => x === +rm.year) !== undefined)
      .value();
  }

  filterRoadmapsByMonths(roadmaps: RoadmapModel[], mts: Month[]): RoadmapModel[] {
    return _(roadmaps)
      // RM months are not zero based!!!!!!!!!!!!!!!!!!
      .filter((rm: RoadmapModel) => mts.length === 0 || mts.find(x => (Object.values(Month).indexOf(x) + 1) === +rm.month) !== undefined)
      .value();
  }

  collectRoadmapDeliveryMilestoneYears(roadmaps: RoadmapModel[]): number[] {
     return _(roadmaps)
     .map((rm: RoadmapModel) => rm.year)
     .filter((year: string) => year !== undefined)
     .map((year: string) => +year)
     .uniq()
     .sortBy((year: number) => year)
     .value();
  }

  async findAllRoadmapsByTaxTypeAndTaxIdSortedByPriority(objectives: ObjectiveModel[],
    taxonomyType: TaxonomyType,
    taxonomyId: string): Promise<RoadmapModel[]> {

    let allProductGroups: ProductGroupModel[];
    let allProducts: ProductModel[];

    if (taxonomyType === 'PRODUCT_GROUP' || taxonomyType === 'PRODUCT') {
      allProductGroups = await this.productGroupService.getAllProductGroups().toPromise();

    }

    if (taxonomyType === 'PRODUCT_ON_PRODUCT_GROUP') {
      allProducts = await this.productService.getAllProducts().toPromise();

    }
    const filteredObjectivesById: ObjectiveModel[] = _(objectives)
        .filter((obj: ObjectiveModel) => {
          let passFilter = false;
          if (taxonomyType === 'PORTFOLIO') {
            passFilter = (String(obj.planningPortfolioId) === String(taxonomyId));
          } else if (taxonomyType === 'PRODUCT_GROUP' || taxonomyType === 'PRODUCT_ON_PRODUCT_GROUP') {
            passFilter = (String(obj.productGroupId) === String(taxonomyId));
          }  else if (taxonomyType === 'PRODUCT') {
            passFilter = (String(obj.productId) === String(taxonomyId));
          }

          return passFilter;
        })
        .value();

    const aggregatedRoadmaps: RoadmapModel[] = this.aggregateRoadmaps(filteredObjectivesById);
    const roadmapIdMap: Map<string, boolean> = new Map();

    const roadmapsFromSearch: RoadmapModel[] = _(aggregatedRoadmaps)
      .filter((roadmap: RoadmapModel) => {
          const alreadyExist: boolean = roadmapIdMap.has(roadmap.id);
          roadmapIdMap.set(roadmap.id, true);

          if (taxonomyType === 'PRODUCT_ON_PRODUCT_GROUP') {
            this.collectAndSetProductNamesAndIds(objectives, roadmap, allProducts);
          } else if (taxonomyType === 'PRODUCT_GROUP') {
            roadmap.productGroupName = this.findProductGroupNameByGroupId(taxonomyId, allProductGroups);
          }

          return !alreadyExist;
        })
        .map((roadmap: RoadmapModel) => {
          this.setCurrentProgress(roadmap);

          if (taxonomyType === 'PRODUCT_ON_PRODUCT_GROUP') {
            roadmap.taxonomyId = String(roadmap.productId);
          } else {
            roadmap.taxonomyId = String(taxonomyId);
          }

          roadmap.taxonomyType = taxonomyType;

          return roadmap;
        })
        .value();
    return this.sortAndPrioritizeRoadmaps(roadmapsFromSearch);
  }


  private collectAndSetProductNamesAndIds(objectives: ObjectiveModel[], roadmap: RoadmapModel,
      allProducts: ProductModel[]) {
    const idsNames: [string[], string[]] = this.findProductIdsAndProductNamesByRoadmap(objectives,
      roadmap, allProducts);
    roadmap.productName = idsNames[1].join('\n');
    roadmap.productId = idsNames[0].join(';');
    roadmap.taxonomyId = roadmap.productId;
  }

  private createRoadmapPriority(rmElement: RoadmapModel, newPriority: number) {
    let newPriorityItem;
    if (rmElement.productId !== undefined && rmElement.productId.includes(';')) {
      //we have multiple product IDs, create a priority entry
      //for each using the same priority number
      //required to keep roadmap for multiple products
      //in the same priority if one or more are removed later
      const allProductIds = rmElement.productId.split(';');
      allProductIds.forEach(async (p)=> { //p is product id ; separated
        newPriorityItem = {
          roadmapId: rmElement.id,
          taxonomyType: rmElement.taxonomyType,
          taxonomyId: p,
          priority: newPriority
        };
        rmElement.priority = newPriority;
        this.roadmapPriorityService.create(newPriorityItem).subscribe();
      });
    } else {
      newPriorityItem = {
        roadmapId: rmElement.id,
        taxonomyType: rmElement.taxonomyType,
        taxonomyId: rmElement.taxonomyId,
        priority: newPriority
      };
      rmElement.priority = newPriority;
      this.roadmapPriorityService.create(newPriorityItem).subscribe();
    }
  }

  private sortAndPrioritizeRoadmaps(roadmaps: RoadmapModel[]) {
    let priority = 1;
    let updatePriorityItem: RoadmapPriorityModel;

    const activeRoadmaps: RoadmapModel[] = roadmaps.filter((rm) => rm.progressState !== 'Done');
    const doneRoadmaps: RoadmapModel[] = roadmaps.filter((rm) => rm.progressState === 'Done');

    //sort active first and update if priority doesn't match
    //then sort done after active

    const tempActiveRoadmaps: RoadmapModel[] = _(activeRoadmaps)
    .map((rm: RoadmapModel) => {
      rm.priority = this.findHighestPriorityNumber(rm, rm.taxonomyId, rm.taxonomyType);
      return rm;
    })
    .sortBy((rm: RoadmapModel) => rm.priority)
    .map((rm: RoadmapModel) => {
      // Create priority for roadmap item if it did not exist.
      const existingPriority = rm.priority;

      if (!existingPriority) {
        // If no priority exists create one
        this.createRoadmapPriority(rm, priority);
      } else if (existingPriority !== priority) {
        //update priority entry
        updatePriorityItem = {
          roadmapId: rm.id,
          taxonomyId: rm.taxonomyId,
          taxonomyType: rm.taxonomyType,
          priority
        };
        this.roadmapPriorityService.update(updatePriorityItem).subscribe();
      }

      rm.priority = priority;
      ++priority;

      return rm;
    })
    .value();

    const tempDoneRoadmaps: RoadmapModel[] = _(doneRoadmaps)
    .map((rm: RoadmapModel) => {
      rm.priority = this.findHighestPriorityNumber(rm, rm.taxonomyId, rm.taxonomyType);
      return rm;
    })
    .sortBy((rm: RoadmapModel) => rm.priority)
    .map((rm: RoadmapModel) => {
      // Create priority for roadmap item if it did not exist.
      const existingPriority = rm.priority;

      if (!existingPriority) {
        // If no priority exists create one
        this.createRoadmapPriority(rm, priority);
      } else if (existingPriority !== priority) {
        //update priority entry
        updatePriorityItem = {
          roadmapId: rm.id,
          taxonomyId: rm.taxonomyId,
          taxonomyType: rm.taxonomyType,
          priority
        };
        this.roadmapPriorityService.update(updatePriorityItem).subscribe();
      }

      rm.priority = priority;
      ++priority;

      return rm;
    })
    .value();

    const allRoadmaps: RoadmapModel[] = _.concat(tempActiveRoadmaps, tempDoneRoadmaps);
    const tempSort = _.sortBy(allRoadmaps, (rm: RoadmapModel) => rm.priority);
    return tempSort;

  }

  private aggregateRoadmaps(objectives: ObjectiveModel[]) {
    return _(objectives).filter((obj: ObjectiveModel) => obj.keyResults !== undefined)
        .map((obj: ObjectiveModel) => obj.keyResults)
        .flatten()
        .filter((kr: KeyResultModel) => kr.roadmaps !== undefined)
        .map((kr: KeyResultModel) => kr.roadmaps)
        .flatten()
        .value();
  }

  private findHighestPriorityNumber(roadmapModel: RoadmapModel, taxId: string, taxonomyType: TaxonomyType): number | undefined {
    let priority: number | undefined;
    const rmPriority: RoadmapPriorityModel[] = roadmapModel['roadmap-priority'];
    let taxIds = [];

    if (taxonomyType === 'PRODUCT_ON_PRODUCT_GROUP') {
      taxIds = taxId.split(';');
    } else {
      taxIds = [taxId];
    }

    if (rmPriority) {
      priority = _(rmPriority).filter((prior: RoadmapPriorityModel) => taxIds.includes(prior.taxonomyId)
        && prior.taxonomyType === taxonomyType)
      .map((prior: RoadmapPriorityModel) => prior.priority )
      .filter((prior: number | undefined) => prior !== undefined)
      .max();
    }

    return priority;
  }

  private findLatestRoadmapProgress(roadmap: RoadmapModel): RoadmapProgressModel | undefined {
    let latestProgress: RoadmapProgressModel | undefined;

    if (roadmap['roadmap-progress']) {
      latestProgress = roadmap['roadmap-progress'][0];
      roadmap['roadmap-progress'].forEach((progress: RoadmapProgressModel) => {
        if (latestProgress.dateOfProgress && progress.dateOfProgress && progress.dateOfProgress > latestProgress.dateOfProgress) {
          latestProgress = progress;
        }
      });
    }
    return latestProgress;
  }

  private setCurrentProgress(roadmap: RoadmapModel) {
    const latestProgress: RoadmapProgressModel = this.findLatestRoadmapProgress(roadmap);
    if (latestProgress) {
      roadmap.progressConfidenceLevel = latestProgress.confidenceLevel;
      roadmap.progressState = latestProgress.status;
      //not sure why name is truncated in array
      roadmap.progressPercentPlannedWorkCompleted = latestProgress.percentageOfPlannedWorkCom;
      roadmap.progressCurrentValueDelivered = latestProgress.currentValueDelivered;
    } else {
      roadmap.progressState = State.backlog;
    }
  }

  private findProductGroupNameByGroupId(groupId: string, productGroups: ProductGroupModel[]): string {
    let productGroupName = '';
    if (groupId !== undefined) {
      productGroupName = _(productGroups)
        .filter((productGroup: ProductGroupModel) => String(productGroup.id) === String(groupId))
        .map((productGroup: ProductGroupModel) => productGroup.name)
        .first();
    }

    return productGroupName;
  }

  private findProductIdsAndProductNamesByRoadmap(objectives: ObjectiveModel[], roadmap: RoadmapModel,
    products: ProductModel[]): [string[], string[]] {
    let productIdName: [string[], string[]] = [[], []];
    const productId: string[] = _(objectives)
    .filter((obj: ObjectiveModel) => {
      const rm: RoadmapModel | undefined = _(obj.keyResults)
      .map((kr: KeyResultModel) => kr.roadmaps)
      .flatten()
      .filter((roadM: RoadmapModel) => String(roadM.id) === String(roadmap.id))
      .first();

      return rm !== undefined;
    })
    .map((obj: ObjectiveModel) => obj.productId)
    .value();

    if (productId !== undefined) {
      const productName: string[] = _(products)
        .filter((product: ProductModel) => productId.find((id: string) => id === String(product.id)) !== undefined)
        .map((product: ProductModel) => product.name)
        .value();

      productIdName = [productId, productName];
    }

    return productIdName;
  }
}
