
import { Component,  Input, OnInit,  } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgForm } from '@angular/forms';
import { RoadmapsLogService } from 'src/app/services/roadmaps-log.service';
import { RoadmapsLogModel } from 'src/app/models/roadmaps-log.model';
import { GraphqlQueriesService, UserSearchResult } from 'src/app/services/graphql-queries.service';
import { RoadmapModel } from 'src/app/models/roadmap.model';
import { ObjectiveModel } from 'src/app/models/objective.model';

@Component({
  selector: 'app-roadmaps-log',
  templateUrl: './roadmaps-log.component.html',
  styleUrls: ['./roadmaps-log.component.scss']
})
export class RoadmapsLogComponent implements OnInit {

  static componentInstance: any;

  @Input() objectiveType: string;
  @Input() id: string;
  @Input() fromModal: string;
  @Input() roadmap: RoadmapModel;

  noRoadmapsLogs: boolean;
  roadmapsLogEntries: RoadmapsLogModel[];
  pageLoaded: Promise<boolean>;
  loading = false;
  selectedOwnerName: string;

  productObjectives: ObjectiveModel[] = [];
  roadmaps: RoadmapModel[] = [];
  productGroupObjectives: ObjectiveModel[] = [];
  portfolioObjectives: ObjectiveModel[] = [];
  enterpriseObjectives: ObjectiveModel[] = [];
  userSearchResults: UserSearchResult[];

  constructor(public activeModal: NgbActiveModal,
    private roadmapsLogService: RoadmapsLogService,
    private graphqlQueriesService: GraphqlQueriesService,

    ) {}

  ngOnInit(): void {
    this.retrieveRoadmapsLog(this.roadmap.id);
  }

  openConfirmationDialog = (sendData: string, roadmapslogForm: NgForm) => {
    this.closeModal(sendData, roadmapslogForm);
  };
  closeModal = (sendData: string, roadmapslogForm: NgForm): void => {
    if (sendData === 'cancel') {
      roadmapslogForm.resetForm();
      this.activeModal.close(sendData);
    }
  };

  retrieveRoadmapsLog = (roadmapId: string): void => {
    this.roadmapsLogService.getAllByRoadmapId(roadmapId).subscribe(
      (data) => {
        this.noRoadmapsLogs = data.length === 0 ? true : false;
        this.roadmapsLogEntries = data;
        this.pageLoaded = Promise.resolve(true);
        let logLength = this.roadmapsLogEntries.length;
        this.roadmapsLogEntries.forEach((roadmapsLogEntry) => {
          if (roadmapsLogEntry.userId) {
            this.graphqlQueriesService
                    .getOwnerName(roadmapsLogEntry.userId)
                    .subscribe((result: any) => {
                      roadmapsLogEntry.userName = result.data.userSearch.users[0]?.displayName || '';
                      roadmapsLogEntry.sort = logLength--;
                  });
          }

        });
      }
    );
};
}


