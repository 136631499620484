import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { KeyResultModel } from 'src/app/models/key-result.model';
import { KeyResultService } from 'src/app/services/key-results.service';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { KeyResultTrackingModalComponent } from '../key-result-tracking-modal/key-result-tracking-modal.component';
import { KeyResultTrackingComponent } from '../key-result-tracking/key-result-tracking.component';
import { ConfirmationDialogService } from 'src/app/services/confirmation-dialog.service';
import { EventEmitterService } from 'src/app/services/event-emitter.service';
import { UtilsService } from 'src/app/services/utils.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-key-result-modal',
  templateUrl: './key-result-modal.component.html',
  styleUrls: ['./key-result-modal.component.scss'],
  providers: [DatePipe]
})

export class KeyResultModalComponent implements OnInit {
  static componentInstance: any;
  @Input() objectiveId: string;
  @Input() editKeyResult: KeyResultModel[];
  @Input() objectiveType: string;
  @Input() numKeyResults: number;
  @ViewChild('submitBtn') private buttonSubmit: ElementRef;

  currentKeyResult = [];
  measureTypeList = [
    'Percent',
    'Count',
    'Dollar',
    'Time (Hours)',
    'Time (Minutes)',
    'Time (Seconds)',
    'Other'
  ];

  saveType: string;
  maxOtherLength = 15; //maximum length for 'Other' measurement type field
  statusList = [
    'Not Started',
    'Measuring',
    'Done',
    'Deprioritized'
  ];
  invalidStatus: string;

  keyResult: KeyResultModel = {
    name: '',
    objectiveId: null,
    goal: '',
    status: '',
    measureType: '',
    timeframe: '',
    baseline: '',
    dueDate: '',
    draft: true,
    form: null,
    confidenceLevel: '',
    otherMeasurementType: ''
  };


  constructor(private keyResultService: KeyResultService,
    public activeModal: NgbActiveModal,
    private modalService: NgbModal,
    private confirmationDialogService: ConfirmationDialogService,
    private eventEmitterService: EventEmitterService,
    private utilsService: UtilsService,
    private datePipe: DatePipe) { }

  ngOnInit(): void {
    this.initKeyResult();
  }

  onKeyDown(inputField: HTMLInputElement, $e: KeyboardEvent): void {
    this.utilsService.onKeyDownNumbers(inputField, $e);
  }

  onPaste(inputField: HTMLInputElement, $e: ClipboardEvent): void {
    this.utilsService.onPasteNumbers(inputField, $e);
  }

  onDrop(inputField: HTMLInputElement, $e: DragEvent): void {
    this.utilsService.onDropNumbers(inputField, $e);
  }

  changesDetected(): boolean {
    let foundChange = false;
    if (!this.currentKeyResult) {return foundChange;}

    this.currentKeyResult.forEach((kr) => {
      if (kr.name !== this.keyResult.name ||
        kr.baseline !== this.keyResult.baseline ||
        kr.goal !== this.keyResult.goal ||
        kr.measureType !== this.keyResult.measureType ||
        kr.dueDate !== this.keyResult.dueDate ||
        kr.timeframe !== this.keyResult.timeframe ||
        kr.status !== this.keyResult.status) {
          foundChange = true;
        }
    });
    return foundChange;
  }

  openConfirmationDialog = (isNew: boolean, keyResultForm: NgForm) => {
    if (this.changesDetected()) {
      this.confirmationDialogService.confirm('Save', 'Do you want to save your changes?')
      .then((confirmed) => {
        if (confirmed) { //selected Yes
          this.buttonSubmit.nativeElement.click();
          if (keyResultForm.form.valid) { // and passes validation
            this.closeModal('progress', keyResultForm, null, isNew, true);
          }
        } else { //selected No
          this.closeModal('progress', keyResultForm, null, isNew);
        }
      })
      .catch(() => console.error());
    } else { //no changes detected
      this.closeModal('progress', keyResultForm, null, isNew);
    }
  };

  initKeyResult = (): void => {

    if (this.editKeyResult) {
      // edit key result
      this.currentKeyResult.push(this.editKeyResult); // push into an array
      if (this.currentKeyResult[0].draft) {  // button label
        this.saveType = 'Submit';
      } else {
        this.saveType = 'Save';
      }
      this.keyResult.id = this.currentKeyResult[0].id;
      this.keyResult.name = this.currentKeyResult[0].name;
      this.keyResult.goal = this.currentKeyResult[0].goal;

      //for migrated data, if doesn't have a valid option, put value in other
      if (this.measureTypeList.includes(this.currentKeyResult[0].measureType) || this.currentKeyResult[0].measureType === '') {
        this.keyResult.measureType = this.currentKeyResult[0].measureType;
        this.keyResult.otherMeasurementType = this.currentKeyResult[0].otherMeasurementType;
      } else { //migrated data, add it to Other and truncate at 15 characters
        this.keyResult.otherMeasurementType = this.currentKeyResult[0].measureType;
        this.keyResult.measureType = 'Other';
      }

      this.keyResult.baseline = this.currentKeyResult[0].baseline;
      this.keyResult.timeframe = this.currentKeyResult[0].timeframe;
      this.keyResult.objectiveId = this.currentKeyResult[0].objectiveId;
      this.keyResult.dueDate = this.datePipe.transform(this.currentKeyResult[0].dueDate, 'yyyy-MM-dd');
      this.keyResult.draft = this.currentKeyResult[0].draft;
      this.keyResult.sort = this.currentKeyResult[0].sort;

      if (!this.statusList.includes(this.currentKeyResult[0].status)) {
        //add migrated status to invalid list for validation
        this.invalidStatus = this.currentKeyResult[0].status;
      }
      this.keyResult.status = this.currentKeyResult[0].status;
    } else {
      // new key result
      this.saveType = 'Submit';
    }
  };

  changeMeasureType(e: string) {
    if (e !== 'Other') {
      this.keyResult.otherMeasurementType = '';
    }
  }

  onSubmit(submitBtn: HTMLButtonElement, keyResultForm: NgForm): void {
    if (keyResultForm.form.valid) {
      submitBtn.disabled = true;
      this.keyResult.objectiveId = this.objectiveId;
      this.keyResult.draft = false;

      if (!this.keyResult.sort) {
        this.keyResult.sort = this.numKeyResults;
      }
      if (this.editKeyResult) {
        this.keyResultService.update(this.keyResult).subscribe(
          response => {
            keyResultForm.resetForm();
            this.activeModal.close('onSubmit-Update');
          },
          error => {
            console.error(error);
          });
      } else {
        this.keyResultService.create(this.keyResult).subscribe(
          response => {
            keyResultForm.resetForm();
            this.activeModal.close('onSubmit-Create');
          },
          error => {
            console.error(error);
          });

      }
    }
  }

  saveAsDraft(keyResultForm: NgForm): void {
    this.keyResult.objectiveId = this.objectiveId;

    this.keyResult.draft = true;
    if (!this.keyResult.sort) {
      this.keyResult.sort = this.numKeyResults;
    }
    if (this.editKeyResult) {
      this.keyResultService.update(this.keyResult).subscribe(
        response => {
          keyResultForm.resetForm();
          this.activeModal.close('saveAsDraft-Update');
        },
        error => {
          console.error(error);
        });
    } else {
      this.keyResultService.create(this.keyResult).subscribe(
        response => {
          keyResultForm.resetForm();
          this.activeModal.close('saveAsDraft-Create');
        },
        error => {
          console.error(error);
        });
    }
  }

  closeModal = (sendData: string, keyResultForm: NgForm,
    draftBtn?: HTMLButtonElement, isNew?: boolean,
    wasSaved: boolean = false): void => {
    if (sendData === 'cancel') {
      keyResultForm.resetForm();
      this.activeModal.close(sendData);
    } else if (sendData === 'saveAsDraft') {
      draftBtn.disabled = true;
      this.saveAsDraft(keyResultForm);
    } else if (sendData === 'progress') {
      //adding or viewing a progress
      //do not call reset form - will clear this.keyResult and any updates made to it
      this.activeModal.close(sendData);
      this.openKeyResultTracking(isNew, wasSaved);
    }
  };

  openKeyResultTracking = (isNew: boolean, wasSaved: boolean): void => {
    let useModal: any;
    if (isNew) {
      useModal = KeyResultTrackingModalComponent;
    } else {
      useModal = KeyResultTrackingComponent;
    }

    if (this.modalService.hasOpenModals) {
      this.modalService.dismissAll('Open-KRT');
    }
    const modalRef = this.modalService.open(useModal,
      {
        windowClass: 'customModalClass',
        centered: true,
        keyboard: false,
        backdrop: 'static'
      });

    if (wasSaved) {
      //pass a copy of this.keyResult, so if baseline/goals/name are updated,
      //they will be reflected in progress modal.  Must be a copy because
      //this.keyResult will wipe out when modal opens
      modalRef.componentInstance.tempKeyResult = {...this.keyResult};
    } else {
      //pass editKeyResult is KR was not saved, otherwise edits are still bound to this.keyResult
      //and will show values that were entered, even if not saved.
      modalRef.componentInstance.tempKeyResult = this.editKeyResult;
    }

    modalRef.result.then((result) => {
      if (isNew) {
        this.eventEmitterService.retrieveObjectives();
      }
    }, (reason) => {
    });
  };
}
