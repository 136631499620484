
export class Drag {
  private targetObjectiveId: string;

  setTargetObjectiveId(val: string) {
    this.targetObjectiveId = val;
  }

  getTargetObjectiveId(): string {
    return this.targetObjectiveId;
  }

}
