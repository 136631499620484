<form name="form" class="form-horizontal" (ngSubmit)="objectiveForm.form.valid && onSubmit(objectiveForm, submitBtn)"
  #objectiveForm="ngForm" novalidate>
  <div class="modal-content">
    <div class="modal-header">
      <h2 class="modal-title">Realign OKR</h2>
      <button type="button" class="btn-close" aria-label="Close"
        (click)="closeModal('cancel', objectiveForm, null)"></button>
    </div>
    <div class="modal-body pt-2">
      <div class="mb-3">
        <p>Realign this OKR and its roadmap items.</p>
      </div> 
      <div class="mb-3">
      <label for="enterpriseobjective" class="form-label">OKR type:</label>
      <select id="objectiveType" class="form-control" name="objective.objectiveType" #objectiveType="ngModel"
        [(ngModel)]="this.selectedType"
        [ngClass]="{'is-invalid':objectiveForm.submitted && objectiveType.invalid}" required class="form-select"
        (change)="onTypeChange()">
        <option value="PRODUCT">Product</option>
        <option value="PRODUCT_GROUP">Product Group</option>
      </select>
      <div class="invalid-feedback">
        <div>Product is required</div>
      </div>
    </div>
    <div *ngIf="this.selectedType === 'PRODUCT'" class="mb-3">
      <label for="enterpriseobjective" class="form-label">Product:</label>
      <select id="product" class="form-control" name="product" #product="ngModel" [(ngModel)]="selectedProductId"
        [ngClass]="{'is-invalid':objectiveForm.submitted && product.invalid && this.selectedType ==='PRODUCT'}"
        class="form-select" required>
        <option value=""></option>
        <ng-container *ngFor="let product of products">
          <option [value]="product.id">{{ product.name }}</option>
        </ng-container>
      </select>
      <div class="invalid-feedback">
        <div>Product is required</div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button (click)="closeModal('cancel', objectiveForm, null)" type="button" class="btn btn-secondary">Cancel</button>
    <button class="btn btn-primary" type="submit" #submitBtn>Save</button>
  </div>
  </div>
</form>