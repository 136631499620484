<ngb-accordion #acc="ngbAccordion" [activeIds]="activeIds" (panelChange)="onToggleAccordion($event)">
  <ngb-panel [id]="'heading' + index">
    <ng-template ngbPanelHeader>
      <div class="d-flex">
        <div>
          <button ngbPanelToggle class="btn btn-link container-fluid pl-0 pt-2 pb-2">
            <i class="bi" [ngClass]="acc.isExpanded('heading' + index)? 'bi-caret-down-fill':'bi-caret-right-fill'"></i>
          </button>
        </div>
        <div class="flex-grow-1">
          <button ngbPanelToggle class="btn btn-link container-fluid pl-0 pt-2 pb-2 text-start ps-0 ">
            <span>{{ objective.description }} <span *ngIf="objective.draft"> (Draft)</span></span>
          </button>
        </div>
        <div class="text-end pt-2">
          {{ objective.status }}
        </div>
        <div class="text-end pt-1" dropdown *ngIf="!readOnly">
          <a class="bi bi-three-dots-vertical fs-4" role="button" id="dropdownMoreInfoObjective"
            data-bs-toggle="dropdown" aria-expanded="false"></a>
          <ul class="dropdown-menu dropdown-menu-light text-small shadow" aria-labelledby="dropdownMoreInfoObjective">
            <li><a class="dropdown-item" role="button" data-toggle="modal" data-target="#newObjective"
                (click)="onEditObjective(objective)"><i class="pe-2 bi-pencil-square"></i>Edit</a></li>
            <li *ngIf="objective.objectiveType === 'PRODUCT' || objective.objectiveType === 'PRODUCT_GROUP'">
              <a class="dropdown-item" role="button" data-toggle="modal" data-target="#newObjective"
                (click)="onRealignObjective()"><i class="pe-2 bi-arrow-left-right"></i>Realign</a>
            </li>
            <li><a class="dropdown-item" role="button" (click)="onDeleteObjective(objective)"><i
                  class="bi bi-trash"></i>
                Delete</a></li>           
            <li><a class="dropdown-item" role="button" data-toggle="modal"
                  data-target="#newObjlog" (click)="onOpenObjectivesLog(objective)"><i
                    class="pe-2 bi-calendar-check"></i>View History</a></li>              
            
          </ul>
        </div>
        <div style="width: 20px;" *ngIf="readOnly">&nbsp;</div>
      </div>
    </ng-template>
    <ng-template ngbPanelContent>
      <ul ngbNav #nav="ngbNav" class="nav-tabs" [(activeId)]="activeTabId">
        <li [ngbNavItem]="1">
          <a ngbNavLink>Key Results ({{objective.keyResults.length}})</a>
          <ng-template ngbNavContent>
            <div class="row mx-1 mb-1 p-2 flex-nowrap" style="background-color: #d0dae0;"
              *ngIf="objective.keyResults.length > 0">
              <div class="col-6">Key Result</div>
              <div class="col-1">Baseline (From)</div>
              <div class="col-1">Goal (To)</div>
              <div class="col-1">Current</div>
              <div class="col-1">By When</div>
              <div class="col-1">Status</div>
              <div class="col-1">Confidence Level</div>
              <div class="col-1">&nbsp;</div>
            </div>
            <div class="row mx-1 mb-1 p-2 flex-nowrap" *ngIf="objective.keyResults.length === 0">
              <h4>No Key Results found</h4>
            </div>
            <div class="row mx-1 mb-1 flex-nowrap border-bottom p-2 draggable"
                *ngFor="let keyResult of objective.keyResults; let i = index"
                draggable="true"
                [class.active]="draggedOverIndex === i && dragType==='kr' && this.drag.getTargetObjectiveId() === this.objective.id"
                (dragover)="allowDrop($event, i, 'kr')"
                (dragstart)="onDragStart(i, 'kr');"
                (drop)="onDrop($event, i, 'kr')"
                (dragleave)="dragLeave($event)"
                [ngSwitch]="keyResult.measureType"
              >
              <div class="col-6 d-flex">{{keyResult.name}}
                <span *ngIf="keyResult.draft"> (Draft)</span>
              </div>
              <ng-container *ngIf="keyResult.baseline && isNum(keyResult.baseline)">
                <ng-container *ngSwitchCase="'Percent'">
                  <div class="col-1 d-flex align-items-center">{{ keyResult.baseline }}%</div>
                </ng-container>
                <ng-container *ngSwitchCase="'Count'">
                  <div class="col-1 d-flex align-items-center">{{ keyResult.baseline | number: '0.0-10' }} unit(s)</div>
                </ng-container>
                <ng-container *ngSwitchCase="'Dollar'">
                  <div class="col-1 d-flex align-items-center">${{ keyResult.baseline | number: '0.0-10' }}</div>
                </ng-container>
                <ng-container *ngSwitchCase="'Time (Hours)'">
                  <div class="col-1 d-flex align-items-center">{{ keyResult.baseline | number: '0.0-10' }} hr(s)</div>
                </ng-container>
                <ng-container *ngSwitchCase="'Time (Minutes)'">
                  <div class="col-1 d-flex align-items-center">{{ keyResult.baseline | number: '0.0-10' }} min(s)</div>
                </ng-container>
                <ng-container *ngSwitchCase="'Time (Seconds)'">
                  <div class="col-1 d-flex align-items-center">{{ keyResult.baseline | number: '0.0-10' }} sec(s)</div>
                </ng-container>
                <ng-container *ngSwitchCase="'Other'">
                  <div class="col-1 d-flex align-items-center">{{ keyResult.baseline | number: '0.0-10' }} {{ keyResult.otherMeasurementType | shorten: 15 }}</div>
                </ng-container>
                <ng-container *ngSwitchDefault>
                  <div class="col-1 d-flex align-items-center">{{ keyResult.baseline | number: '0.0-10' }}</div>
                </ng-container>
              </ng-container>
              <div class="col-1 d-flex align-items-center" *ngIf="!keyResult.baseline">{{ keyResult.baseline }}
              </div>
              <ng-container *ngIf="keyResult.goal && isNum(keyResult.goal)">
                <ng-container *ngSwitchCase="'Percent'">
                  <div class="col-1 d-flex align-items-center">{{ keyResult.goal }}%</div>
                </ng-container>
                <ng-container *ngSwitchCase="'Count'">
                  <div class="col-1 d-flex align-items-center">{{ keyResult.goal | number: '0.0-10' }} unit(s)</div>
                </ng-container>
                <ng-container *ngSwitchCase="'Dollar'">
                  <div class="col-1 d-flex align-items-center">${{ keyResult.goal | number: '0.0-10' }}</div>
                </ng-container>
                <ng-container *ngSwitchCase="'Time (Hours)'">
                  <div class="col-1 d-flex align-items-center">{{ keyResult.goal | number: '0.0-10'}} hr(s)</div>
                </ng-container>
                <ng-container *ngSwitchCase="'Time (Minutes)'">
                  <div class="col-1 d-flex align-items-center">{{ keyResult.goal | number: '0.0-10' }} min(s)</div>
                </ng-container>
                <ng-container *ngSwitchCase="'Time (Seconds)'">
                  <div class="col-1 d-flex align-items-center">{{ keyResult.goal | number: '0.0-10' }} sec(s)</div>
                </ng-container>
                <ng-container *ngSwitchCase="'Other'">
                  <div class="col-1 d-flex align-items-center">{{ keyResult.goal | number: '0.0-10' }} {{ keyResult.otherMeasurementType | shorten: 15 }}</div>
                </ng-container>
                <ng-container *ngSwitchDefault>
                  <div class="col-1 d-flex align-items-center">{{ keyResult.goal | number: '0.0-10' }}</div>
                </ng-container>
              </ng-container>
              <div class="col-1 d-flex align-items-center" *ngIf="!keyResult.goal">{{ keyResult.goal }}</div>
              <div class="col-1 d-flex align-items-center">
                <i [ngSwitch]="keyResult.measureType" class="bi bi-heptagon-fill me-2"
                  [ngStyle]="{'color':getColor(keyResult.confidenceLevel)}"></i>
                <div *ngIf="keyResult.progress">
                  <ng-container *ngSwitchCase="'Percent'">{{ keyResult.progress | number: '0.0-10' }}%</ng-container>
                  <ng-container *ngSwitchCase="'Count'">{{ keyResult.progress | number: '0.0-10' }} unit(s)</ng-container>
                  <ng-container *ngSwitchCase="'Dollar'">${{ keyResult.progress | number: '0.0-10' }}</ng-container>
                  <ng-container *ngSwitchCase="'Time (Hours)'">{{ keyResult.progress | number: '0.0-10' }} hr(s)</ng-container>
                  <ng-container *ngSwitchCase="'Time (Minutes)'">{{ keyResult.progress | number: '0.0-10' }} min(s)
                  </ng-container>
                  <ng-container *ngSwitchCase="'Time (Seconds)'">{{ keyResult.progress | number: '0.0-10' }} sec(s)
                  </ng-container>
                  <ng-container *ngSwitchCase="'Other'">{{ keyResult.progress | number: '0.0-10' }} {{ keyResult.otherMeasurementType | shorten: 15 }}</ng-container>
                  <ng-container *ngSwitchDefault>{{ keyResult.progress | number: '0.0-10' }}</ng-container>
                </div>
                <div *ngIf="!keyResult.progress"><i class="bi-dash-lg"></i></div>
              </div>
              <div class="col-1 d-flex align-items-center">{{ keyResult.dueDate | date: 'MMM yyyy' }}</div>
              <div class="col-1 d-flex align-items-center">{{ keyResult.status }}</div>
              <div class="col-1 d-flex align-items-center">{{ keyResult.confidenceLevel }}</div>
              <div class="col text-end">
                <a class="bi bi-three-dots-vertical fs-5" role="button" id="dropdownMoreInfoKR"
                  data-bs-toggle="dropdown" aria-expanded="false" *ngIf="!readOnly"></a>
                <ul class="dropdown-menu dropdown-menu-light text-small shadow" aria-labelledby="dropdownMoreInfoKR">
                  <li><a class="dropdown-item" role="button" data-toggle="modal" data-target="#newKR"
                      (click)="onEditKeyResult(keyResult,objectiveType)"><i class="pe-2 bi-pencil-square"></i>
                      <span *ngIf="!keyResult.draft">Update</span>
                      <span *ngIf="keyResult.draft">Edit</span>
                    </a></li>
                  <li *ngIf="!keyResult.draft"><a class="dropdown-item" role="button" data-toggle="modal"
                      data-target="#newKRT" (click)="onOpenKeyResultTracking(true, keyResult)"><i
                        class="pe-2 bi-calendar-plus"></i>Add Progress</a></li>
                  <li *ngIf="!keyResult.draft"><a class="dropdown-item" role="button" data-toggle="modal"
                      data-target="#newKRT" (click)="onOpenKeyResultTracking(false, keyResult)"><i
                        class="pe-2 bi-calendar-check"></i>View Progress</a></li>                  
                  <li ><a class="dropdown-item" role="button" data-toggle="modal"
                    data-target="#copyKR" (click)="onCopyKeyResultObjective(keyResult)"><i
                      class="pe-2 bi-files"></i>Duplicate Key Result</a></li>                    
                  <li><a class="dropdown-item" role="button" (click)="deleteKeyResults(keyResult)"><i
                        class="bi bi-trash"></i> Delete Key Result</a></li>
                </ul>
              </div>
            </div>
            <div class="row mt-2 flex-nowrap">
              <div class="col-6 text-start pt-1">
                <button type="button" class="btn btn-primary btn-sm" (click)="onOpenKeyResultModal(null, objectiveType, objective.id, objective.keyResults.length)"
                  *ngIf="!readOnly">
                  <i class="bi-plus"></i>Add Key Result
                </button>
              </div>
              <div class="col-6 text-end"></div>
            </div>
            <div class="row pt-1 flex-nowrap">
              <div class="col-12 text-end" style="font-size:smaller;">
                <div>
                  <div>
                    <span *ngIf="this.objective.objectiveType === 'PRODUCT'" class="fw-bold">Product OKR</span>
                    <span *ngIf="this.objective.objectiveType === 'PRODUCT_GROUP'" class="fw-bold">Product Group OKR
                      <span *ngIf="readOnly">(read only)</span></span>
                  </div>
                </div>
                <div *ngIf="this.objectiveType !== 'ENTERPRISE'">
                  <span class="fw-bold">Owner:</span> {{ objective.ownerName}} [{{objective.ownerId}}]
                </div>
              </div>
            </div>
          </ng-template>
        </li>
      
        <li [ngbNavItem]="2">
          <a ngbNavLink>{{ this.roadmapType }}<span *ngIf="this.roadmapType">s</span></a>
          <ng-template ngbNavContent>
            <ng-container *ngIf="objective.keyResults.length > 0; else cannotCreateRoadmapItems">
              <ng-container *ngIf="roadmapItems.length > 0; else noRoadmapItems">
                <br />
                <table class="table">
                  <thead>
                    <tr>
                      <th scope="col-3">Initiative</th>
                      <th scope="col-1">Owner</th>
                      <th scope="col-1">Delivery Milestone</th>
                      <th scope="col-1">State</th>
                      <th scope="col-1">% Complete</th>
                      <th scope="col-1">Confidence Level</th>
                      <th scope="col-2">Current Value Delivered</th>
                      <th scope="col-1">Next Idea</th>
                      <th scope="col-1"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <ng-container *ngFor="let roadmap of uniqueRoadmaps; let i = index">
                      <tr class="draggable" draggable="true"
                      [class.active]="draggedOverIndex === i && dragType==='roadmap' && this.drag.getTargetObjectiveId() === this.objective.id"
                      (dragover)="allowDrop($event, i, 'roadmap')"
                      (dragstart)="onDragStart(i, 'roadmap')"
                      (dragleave)="dragLeave($event)"
                      (drop)="onDrop($event, i, 'roadmap')">
                        <td>
                          {{ roadmap.description }}&nbsp;&nbsp;&nbsp;
                          <span class="bi bi-info-circle desc-hover-icon" style="font-size: 16px" title="Description &#13;{{ roadmap.impactEstimate | shorten: 256 }}" *ngIf="roadmap.impactEstimate;else noDescription"></span>
                          <ng-template #noDescription><span class="bi bi-info-circle desc-hover-icon" style="font-size: 16px" title="Description not yet updated"></span></ng-template>
                        </td>
                        <td>
                          <span *ngIf="roadmap.ownerId">{{ roadmap.ownerName }} [{{ roadmap.ownerId }}]</span>
                        </td>
                        <td *ngIf="roadmap.valueTimeframe">{{ roadmap.valueTimeframe }}</td>
                        <td *ngIf="!roadmap.valueTimeframe">{{ setMilestone(roadmap) }}</td>
                        <td>{{ (roadmap.progressState === undefined || roadmap.progressState === null || roadmap.progressState === '') ? "Backlog":roadmap.progressState }}
                        <br> {{ roadmap.doneTime | date: 'MMM d, y, h:mm a' }}</td>
                        <td>{{ roadmap.progressPercentPlannedWorkCompleted }}<span *ngIf="roadmap.progressPercentPlannedWorkCompleted">%</span></td>
                        <td>{{ roadmap.progressConfidenceLevel }}</td>
                        <td>{{ roadmap.progressCurrentValueDelivered }}</td>
                        <td>
                          <span class="bi bi-lightbulb-fill desc-hover-icon" style="font-size: 16px" title="{{ roadmap.progressNextBestIdeas | shorten: 256 }}" *ngIf="roadmap.progressNextBestIdeas;else noIdeas"></span>
                          <ng-template #noIdeas>
                            <span></span> <!--If no ideas, display no icon!-->
                          </ng-template>
                        </td>
                        <td>
                          <a class="bi bi-three-dots-vertical fs-5" role="button" id="dropdownMoreInfoRoadmap"
                          data-bs-toggle="dropdown" aria-expanded="false" *ngIf="!readOnly"></a>
                          <ul class="dropdown-menu dropdown-menu-light text-small shadow"
                          aria-labelledby="dropdownMoreInfoRoadmap">
                          <li><a class="dropdown-item" role="button" data-toggle="modal" data-target="#editRoadmap"
                            (click)="onEditRoadmap(roadmap.id)"><i class="pe-2 bi-pencil-square"></i>
                            Edit
                            </a></li>
                          <li><a class="dropdown-item" role="button" data-toggle="modal"
                              data-target="#newKRT" (click)="onRoadmapProgress(true, roadmap)"><i
                              class="pe-2 bi-calendar-plus"></i>Add Progress</a></li>
                          <li><a class="dropdown-item" role="button" data-toggle="modal"
                              data-target="#newKRT" (click)="onRoadmapProgress(false, roadmap)"><i
                              class="pe-2 bi-calendar-check"></i>View Progress</a></li>
                          <li><a class="dropdown-item" role="button" (click)="onDeleteRoadmap(roadmap)"><i
                              class="bi bi-trash"></i> Delete</a></li>
                          <li><a class="dropdown-item" role="button" data-toggle="modal"
                                data-target="#newRoadmaplog" (click)="onOpenRoadmapsLog(roadmap)"><i
                                  class="pe-2 bi-calendar-check"></i>View History</a></li> 
                          </ul>
                        </td>
                      </tr>
                    </ng-container>
                  </tbody>
                </table>
              </ng-container>
            </ng-container>
            <ng-template #noRoadmapItems>
              <div class="row mx-1 mb-1 p-2 flex-nowrap">
                <h4>No {{ this.roadmapType }}s found</h4>
              </div>
            </ng-template>
            <ng-template #cannotCreateRoadmapItems>
              <div class="row mx-1 mb-1 p-2 flex-nowrap">
                <h4>A key result must be made first before this objective can have roadmaps</h4>
              </div>
            </ng-template>
            <div class="row mx-1 mt-3 mb-1 flex-nowrap">
              <div class="col-6 text-start">
                <button type="button" [ngClass]="buttonColor()" class="btn btn-sm" (click)="onAddNewRoadmapItem()"
                  [disabled]="canAddRoadmapItem()" *ngIf="!readOnly">
                  <i class="bi-plus"></i>Add {{ this.roadmapType }}
                </button>
              </div>
              <div class="col-6 text-end"></div>
            </div>
          </ng-template>
        </li>
      
      </ul>
      <div [ngbNavOutlet]="nav" class="mt-2"></div>
    </ng-template>
  
  </ngb-panel>

</ngb-accordion>
