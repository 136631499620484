import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {environment} from 'src/environments/environment';
import {RoadmapPriorityModel} from '../models/roadmap-priority.model';
import {Observable} from 'rxjs';
import { RoadmapModel } from '../models/roadmap.model';

@Injectable({
  providedIn: 'root'
})
export class RoadmapPriorityService {

  constructor(
    private readonly httpClient: HttpClient
  ) {
  }

  renumberPriority(allRoadmaps: RoadmapModel[]) {

    let newPriority = 1;
    allRoadmaps.forEach((rmElement) => {
      rmElement.priority = newPriority++;
      let updatePriorityItem: RoadmapPriorityModel;

      if (rmElement.productId !== undefined && rmElement.productId.includes(';')) {
        //for multiple products on a single roadmap, keep all of the priority numbers
        //for each priority entry the same, will be easier to sort if one product
        //is deleted.
        const allProductIds = rmElement.productId.split(';');
        allProductIds.forEach((p)=> { //p is product id ; separated
          updatePriorityItem = {
            priority: rmElement.priority,
            roadmapId: rmElement.id,
            taxonomyId: p,
            taxonomyType: rmElement.taxonomyType
          };
          this.update(updatePriorityItem).subscribe();
        });
      } else {
        updatePriorityItem = {
          priority: rmElement.priority,
          roadmapId: rmElement.id,
          taxonomyId: rmElement.taxonomyId,
          taxonomyType: rmElement.taxonomyType
        };
        this.update(updatePriorityItem).subscribe();
      }
    });
  }

  create(newRoadmapPriority: RoadmapPriorityModel): Observable<any> {
    return this.httpClient.post<RoadmapPriorityModel>(`${environment.baseURL}/roadmap-priority`, newRoadmapPriority);
  }

  update(existingRoadmapPriorityItem: RoadmapPriorityModel): Observable<any> {
    return this.httpClient.put<RoadmapPriorityModel>(`${environment.baseURL}/roadmap-priority`, existingRoadmapPriorityItem);
  }

  getById(id: string): Observable<any> {
    //this is by roadmap id
    return this.httpClient.get<RoadmapPriorityModel>(`${environment.baseURL}/roadmap-priority/${id}`);
  }

  getHighestPriorityByTaxIdAndTaxType(taxId: string, taxType: string): Observable<any> {
    return this.httpClient.get<RoadmapPriorityModel>(`${environment.baseURL}/roadmap-priority/${taxId}/${taxType}`);
  }

  delete(roadmapItem: any): Observable<any> {
    let params = new HttpParams();
    if (roadmapItem.roadmapId) {
      params = params.set('roadmapId', roadmapItem.roadmapId);
    }
    if (roadmapItem.taxonomyId) {
      params = params.set('taxonomyId', roadmapItem.taxonomyId);
    }
    return this.httpClient.delete(`${environment.baseURL}/roadmap-priority`, { params });
  }
}
