
import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { RoadmapsLogModel } from '../models/roadmaps-log.model';

@Injectable({
  providedIn: 'root'
})
export class RoadmapsLogService {

  roadmapsLog: Observable<RoadmapsLogModel[]>;

  constructor(@Inject(HttpClient) private http: HttpClient) { }

  getAllByRoadmapId(roadmapId: string): Observable<RoadmapsLogModel[]> {
    this.roadmapsLog = this.http.get<RoadmapsLogModel[]>(`${environment.baseURL}/roadmapsLog/${roadmapId}`);
    return this.roadmapsLog;
  }

  create(data: any): Observable<any> {
    return this.http.post(`${environment.baseURL}/roadmapsLog`, data);
  }
}
