<form name="form" class="form-horizontal"  #roadmapslogForm="ngForm" novalidate>

    <div class="modal-content">
        <div class="modal-header">
            <h2 class="modal-title">View History</h2>
            <button type="button" class="btn-close" aria-label="Close" (click)="closeModal('cancel', roadmapslogForm)"></button>
        </div>
        <div class="modal-body text-start">
            <div class="row pb-2">
                <div class="col-12">
                    <label for="outcome" class="form-label fw-bold">Roadmap:</label>  
                    <div id="outcome" class=" bg-light">{{ this.roadmap.description }}</div>
                </div>
            </div>

            <div *ngIf="noRoadmapsLogs">
                <hr>
                <div class="row">
                    <div class="col-12">
                    No History Found
                    </div>
                </div>
            </div>
            <div *ngIf="!noRoadmapsLogs">
                <hr>                
                <div *ngIf="pageLoaded" class="px-2">
                    <div class="row grid-striped" *ngFor="let roadmapsLogEntry of roadmapsLogEntries;">
                        <div class="row">
                            <div class="col-12">
                                <span *ngIf="roadmapsLogEntry.operation === 'I'">
                                {{ roadmapsLogEntry.sort }}). {{ roadmapsLogEntry.updatedAt | date: 'MMM d, y, h:mm a' }}: {{ roadmapsLogEntry.userName }} - created.
                                </span>                               
                                <span *ngIf="roadmapsLogEntry.operation === 'U'">
                                    {{ roadmapsLogEntry.sort }}). {{ roadmapsLogEntry.updatedAt | date: 'MMM d, y, h:mm a' }}: {{ roadmapsLogEntry.userName }} - updated.                                    
                                </span>  
                            </div>
                        </div>                  
                    </div>
                </div>
            </div>          
            <hr>
        </div>   
  
  <div class="modal-footer">
    <button (click)="closeModal('cancel', roadmapslogForm)" type="button" class="btn btn-secondary">Cancel</button>    
  </div>
</div>
</form>