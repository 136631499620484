import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { EnterpriseObjectivesComponent } from './components/enterprise-objectives/enterprise-objectives.component';
import { ProductObjectivesComponent } from './components/product-objectives/product-objectives.component';
import { PortfolioObjectivesComponent } from './components/portfolio-objectives/portfolio-objectives.component';
import { HomeComponent } from './components/home/home.component';
import { PingAuthGuard } from '@techops-ui/ping-authentication';
import { UserProfileComponent } from './components/user-profile/user-profile.component';
import {
  ProductGroupObjectivesComponent
} from './components/product-group-objectives/product-group-objectives.component';

const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
    data: {
      title: 'OKR'
    },
     canActivate: [ PingAuthGuard ]
  },
  {
    path: 'enterprise-objectives',
    component: EnterpriseObjectivesComponent,
    data: {
      title: 'Enterprise Objectives'
    },
     canActivate: [ PingAuthGuard ]
  },
  {
    path: 'product-group-objectives',
    component: ProductGroupObjectivesComponent,
    data: {
      title: 'Product Group Objectives'
    },
    canActivate: [ PingAuthGuard ]
  },
  {
    path: 'product-group-objectives/:productGroupId',
    component: ProductGroupObjectivesComponent,
    data: {
      title: 'Product Group Objectives'
    },
    canActivate: [ PingAuthGuard ]
  },
  {
    path: 'product-objectives',
    component: ProductObjectivesComponent,
    data: {
      title: 'Product Objectives'
    },
     canActivate: [ PingAuthGuard ]
  },
  {
    path: 'product-objectives/:productId',
    component: ProductObjectivesComponent,
    data: {
      title: 'Product Objectives'
    },
     canActivate: [ PingAuthGuard ]
  },
  {
    path: 'portfolio-objectives',
    component: PortfolioObjectivesComponent,
    data: {
      title: 'Planning Portfolio Objectives'
    },
     canActivate: [ PingAuthGuard ]
  },
  {
    path: 'portfolio-objectives/:planningPortfolioId',
    component: PortfolioObjectivesComponent,
    data: {
      title: 'Planning Portfolio Objectives'
    },
     canActivate: [ PingAuthGuard ]
  },
  {
    path: 'user-profile',
    component: UserProfileComponent,
    data: {
      title: 'User Profile'
    },
     canActivate: [ PingAuthGuard ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
