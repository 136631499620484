import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { KeyResultModel } from '../models/key-result.model';

@Injectable({
  providedIn: 'root'
})
export class KeyResultService {

  constructor(@Inject(HttpClient) private http: HttpClient) { }

  getAll(): Observable<KeyResultModel[]> {
    return this.http.get<KeyResultModel[]>(`${environment.baseURL}/key-results`);
  }

  create(data: any): Observable<any> {
    return this.http.post(`${environment.baseURL}/key-results`, data);
  }

  update(data: KeyResultModel): Observable<any> {
    return this.http.put(`${environment.baseURL}/key-results`, data, {
      params: {
        id: data.id
      }
    });
  }

  duplicate(data: KeyResultModel): Observable<any> {
    return this.http.post(`${environment.baseURL}/key-results-duplicate`, data, {
      params: {
        id: data.id
      }
    });
  }

  delete(data: KeyResultModel): Observable<any> {
    return this.http.delete(`${environment.baseURL}/key-results/${data.id}`);
  }
}


