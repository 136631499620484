import { NgModule } from '@angular/core';
import { BrowserModule, Title } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { HttpClient } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { InsightsComponent } from './components/insights/insights.component';
import { ObjectiveItemsComponent } from './components/objective-items/objective-items.component';
import { ObjectivemodalComponent } from './components/objectivemodal/objectivemodal.component';
import { KeyResultModalComponent } from './components/key-result-modal/key-result-modal.component';
import { HomeComponent } from './components/home/home.component';
import { EnterpriseObjectivesComponent } from './components/enterprise-objectives/enterprise-objectives.component';
import { ProductObjectivesComponent } from './components/product-objectives/product-objectives.component';
import { PortfolioObjectivesComponent } from './components/portfolio-objectives/portfolio-objectives.component';
import { NgbActiveModal, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { KeyResultTrackingModalComponent } from './components/key-result-tracking-modal/key-result-tracking-modal.component';
import { PingAuthenticationModule} from '@techops-ui/ping-authentication';
import { SessionTimeoutDialogComponent } from './components/session-timeout-dialog/session-timeout-dialog.component';
import { KeyResultTrackingComponent } from './components/key-result-tracking/key-result-tracking.component';
import { ConfirmationDialogComponent } from './components/confirmation-dialog/confirmation-dialog.component';
import { ConfirmationDialogService } from './services/confirmation-dialog.service';
import { EventEmitterService } from './services/event-emitter.service';
import { GraphQLModule } from './graphql.module';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatTabsModule } from '@angular/material/tabs';
import { UserProfileComponent } from './components/user-profile/user-profile.component';
import { ObjectiveItemComponent } from './components/objective-item/objective-item.component';
import { NewRoadmapItemComponent } from './components/new-roadmap-item/new-roadmap-item.component';
import { ProductGroupObjectivesComponent } from './components/product-group-objectives/product-group-objectives.component';
import { VisionStatementComponent } from './components/vision-statement/vision-statement.component';
import { UniquePipe } from './pipes/unique';
import { ObjectiveRealignModalComponent } from './components/objective-realign-modal/objective-realign-modal.component';
import { Drag } from './utils/drag';
import { RoadmapPriorityComponent } from './components/roadmap-priority/roadmap-priority.component';
import { ViewObjectivesComponent } from './components/view-objectives/view-objectives.component';
import { CopyKeyresultObjectiveModalComponent } from './components/copy-keyresult-objective-modal/copy-keyresult-objective-modal.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { Shorten } from './pipes/shorten';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ExternalLinksComponent } from './components/external-links/external-links.component';
import { RoadmapProgressAddComponent } from './components/roadmap-progress-add/roadmap-progress-add.component';
import { RoadmapProgressViewComponent } from './components/roadmap-progress-view/roadmap-progress-view.component';
import { DatePipe } from '@angular/common';
import { RoadmapFilterComponent } from './components/roadmap-filter/roadmap-filter.component';
import { RefreshSignalComponent } from './components/refresh-signal/refresh-signal.component';
import { ObjectivesLogComponent } from './components/objectives-log/objectives-log.component';
import { RoadmapsLogComponent } from  './components/roadmaps-log/roadmaps-log.component';
import { TableauModule } from 'ngx-tableau';

@NgModule({
    declarations: [
        AppComponent,
        SidebarComponent,
        InsightsComponent,
        ObjectiveItemsComponent,
        ObjectivesLogComponent,
        ObjectivemodalComponent,
        ObjectiveRealignModalComponent,
        KeyResultModalComponent,
        HomeComponent,
        EnterpriseObjectivesComponent,
        ProductObjectivesComponent,
        PortfolioObjectivesComponent,
        KeyResultTrackingModalComponent,
        SessionTimeoutDialogComponent,
        KeyResultTrackingComponent,
        ConfirmationDialogComponent,
        UserProfileComponent,
        ObjectiveItemComponent,
        NewRoadmapItemComponent,
        ProductGroupObjectivesComponent,
        VisionStatementComponent,
        UniquePipe,
        RoadmapPriorityComponent,
        ViewObjectivesComponent,
        CopyKeyresultObjectiveModalComponent,
        Shorten,
        ExternalLinksComponent,
        RoadmapProgressAddComponent,
        RoadmapProgressViewComponent,
        RefreshSignalComponent,
        RoadmapFilterComponent,
        RoadmapsLogComponent
    ],
    imports: [
        NgSelectModule,
        BrowserModule,
        PingAuthenticationModule.forRoot({
            // eslint-disable-next-line @typescript-eslint/naming-convention
            post_logout_redirect_uri: window.location.origin,
            // eslint-disable-next-line @typescript-eslint/naming-convention
            idle_warning_dialog_component: SessionTimeoutDialogComponent, //any option can be overridden here
        }),
        AppRoutingModule,
        FormsModule,
        HttpClientModule,
        NgbModule,
        GraphQLModule,
        AutocompleteLibModule,
        BrowserAnimationsModule,
        MatTabsModule,
        MatProgressSpinnerModule,
        TableauModule,
    ],
    providers: [
        Title,
        NgbActiveModal,
        ConfirmationDialogService,
        EventEmitterService,
        Drag,
        HttpClient,
        DatePipe
    ],
    bootstrap: [AppComponent]
})
export class AppModule {}
