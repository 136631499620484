import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {environment} from 'src/environments/environment';
import { RoadmapProgressModel } from '../models/roadmap-progress.model';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RoadmapProgressService {

  constructor(
    private readonly httpClient: HttpClient
  ) {
  }

  create(newRoadmapProgress: RoadmapProgressModel): Observable<any> {
    return this.httpClient.post<RoadmapProgressModel>(`${environment.baseURL}/roadmap-progress`, newRoadmapProgress);
  }

  update(existingRoadmapProgressItem: RoadmapProgressModel): Observable<any> {
    return this.httpClient.put<RoadmapProgressModel>(`${environment.baseURL}/roadmap-progress`, existingRoadmapProgressItem);
  }

  getAll(id: string): Observable<RoadmapProgressModel[]> {
    return this.httpClient.get<RoadmapProgressModel[]>(`${environment.baseURL}/roadmap-progress/${id}`);
  }

  delete(existingRoadmapProgressItem: RoadmapProgressModel): Observable<any> {
    return this.httpClient.delete(`${environment.baseURL}/roadmap-progress/${existingRoadmapProgressItem.id}`);
  }
}
