import { Component, Input, OnInit } from '@angular/core';
import { ExternalLinkModel } from 'src/app/models/external-link.model';
import { ConfirmationDialogService } from 'src/app/services/confirmation-dialog.service';
import { ExternalLinkService } from 'src/app/services/external-links.service';

@Component({
  selector: 'app-external-links',
  templateUrl: './external-links.component.html',
  styleUrls: ['./external-links.component.scss']
})
export class ExternalLinksComponent implements OnInit {

@Input() parentId: string;
@Input() title: string;

allLinks: ExternalLinkModel[] = [];
isAdding = false;
isSaving = false;
restoreLinks: ExternalLinkModel[] = [];
pageLoaded: Promise<boolean>;


addLink: ExternalLinkModel = {
  editMode: false,
  parentId: '',
  displayName: '',
  url: ''
};

  constructor(private confirmationDialogService: ConfirmationDialogService,
              private externalLinkService: ExternalLinkService) { }

  ngOnInit(): void {
    this.loadData();

  }

  async loadData() {
    this.addLink.displayName = '';
    this.addLink.url = '';
    this.isSaving = false;
    this.pageLoaded = Promise.resolve(false);
    if (this.parentId) {
      this.addLink.parentId = this.parentId;
      this.allLinks = await this.externalLinkService.getByParentId(this.parentId).toPromise();
      this.allLinks.forEach((element) => {
        element.editMode = false; //initialize editmode per existing link
      });
      this.pageLoaded = Promise.resolve(true);
    }
  }

  editExternalLink(e: any, link: ExternalLinkModel) {
    e.preventDefault();
    if (link.editMode === false) {
        link.editMode = true;
         //copy values to a new array to restore in case of cancel
        const tempLink = {...link};
        this.restoreLinks.push(tempLink);
    }
  }

  cancelEdit(e: any, link: ExternalLinkModel) {
    e.preventDefault();
    link.editMode = false;
    this.restoreLinks.forEach((element,index)=>{
      if(element.id===link.id) {
          link.displayName = element.displayName;
          link.url = element.url;
          this.removePreviousValue(link);
        }
    });
  }

  async removePreviousValue(link: ExternalLinkModel) {
    this.restoreLinks.forEach((element,index)=>{
      if(element.id===link.id) {
        this.restoreLinks.splice(index, 1); //element doesn't work with splice?
      }
    });
  }

  toggleAddExternal(e: any) {
    //show/hides add button and shows inputs for new link
    this.isAdding = !this.isAdding;
  }

  saveNewExternalLink(e: any) {
    if (e !== null) {e.preventDefault();}
    this.isSaving = true;
    if (this.addLink.displayName.trim() === '' || this.addLink.url.trim() === '') {
      return;
    } else {
      this.externalLinkService.create(this.addLink).subscribe(
        async response => {
          this.ngOnInit();
        },
        error => {
          console.error(error);
        });
      this.isAdding = false;
    }
  }

  updateExternalLink(e: any, link: ExternalLinkModel) {
    if (e !== null) {e.preventDefault();}
    if (link.displayName.trim() === '' || link.url.trim() === '') {
      return;
    } else {
      this.externalLinkService.update(link).subscribe(
        async response => {
          link.editMode = false;
          await this.removePreviousValue(link);
        },
        error => {
          console.error(error);
        });
    }
  }

  delete(e: any, link: ExternalLinkModel) {
    e.preventDefault();
    this.confirmationDialogService.confirm('Delete', 'Do you want to delete the external link for ' +
    link.displayName + '?', 'Yes', 'No','')
      .then(async (confirmed) => {
        if (confirmed) { //selected Yes to delete
          this.externalLinkService.delete(link).subscribe(
            async response => {
              link.editMode = false;
              await this.removePreviousValue(link);
              this.ngOnInit();
            },
            error => {
              console.error(error);
            });
        } else { //Selected no to delete
        }
      }
      );
  }

}
