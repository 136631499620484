// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  baseURL: 'https://okrtool-appservice-dev.azurewebsites.net/api',
  ssoConfigFile: 'app_config_dev.json',
  graphQLGatewayUrl: 'https://graph-np.aa.com/graphql',
  okrtrackerClientName: 'okrtracker-dev',
  okrtrackerClientVersion: '1.0.0',
  archerId: '7349615',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
