import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PingAuthenticationService } from '@techops-ui/ping-authentication';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-session-timeout-dialog',
  templateUrl: './session-timeout-dialog.component.html',
  styleUrls: ['./session-timeout-dialog.component.scss']
})
export class SessionTimeoutDialogComponent implements OnInit {
  public isIdle$: Observable<boolean>;
  public secondsLeft$: Observable<number>;

  constructor(private authService: PingAuthenticationService, private router: Router) {}
  extendSession() {
    this.authService.renewSession();
  }
  endSession() {
    this.authService.endSession();
    this.router.navigate(['logout']);
  }
  ngOnInit() {
    this.isIdle$ = this.authService.isIdle$;
    this.secondsLeft$ = this.authService.secondsLeft$;
  }
}
