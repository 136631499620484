import { Component, Input, OnInit } from '@angular/core';
import { ObjectiveService } from 'src/app/services/objective.service';
import { ObjectiveModel } from 'src/app/models/objective.model';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { RoadmapModel } from 'src/app/models/roadmap.model';
import { unique } from 'jquery';

@Component({
  selector: 'app-view-objectives',
  templateUrl: './view-objectives.component.html',
  styleUrls: ['./view-objectives.component.scss']
})
export class ViewObjectivesComponent implements OnInit {
  @Input() objectiveType: string;
  @Input() productGroupId: string;
  @Input() productId: string;
  @Input() roadmapItem: RoadmapModel;
  @Input() planningPortfolioId: number;

  objectives: ObjectiveModel[] = [];
  roadmapObjectives: ObjectiveModel[] = [];
  activeIds: string[] = [];

  constructor(private readonly objectiveService: ObjectiveService,
              private readonly activeModal: NgbActiveModal
              ) { }

  ngOnInit(): void {
    this.loadData();
  }

  toggleAccordion(e: any) {
    //tracks accordion headers open/closed so they may be retained when modals close
    if (e.nextState === false) {
      const getIndex: number = this.activeIds.indexOf(e.panelId);
      if (getIndex > -1) {
        this.activeIds.splice(getIndex, 1);
      }
    } else {
      this.activeIds.push(e.panelId);
    }
  }

  async loadData() {
    if (this.objectiveType === 'PRODUCT' || this.objectiveType === 'PRODUCT_GROUP') {
      const objectives = await this.objectiveService.getAllByProductAndGroup(
        this.objectiveType,
        this.productId,
        this.productGroupId,
        ''
      ).toPromise();
      this.objectives = [
        ...this.objectives,
        ...objectives
      ];
      this.objectives.forEach((obj) => {
        obj.keyResults.forEach((kr) => {
          const findRoadmap = kr.roadmaps.find((x) => x.id === this.roadmapItem.id);
          if (findRoadmap) {
            this.roadmapObjectives.push(obj);
          }
        });
      });
    } else if (this.objectiveType === 'PORTFOLIO') {
      const objectives = await this.objectiveService.getAllPortfolioByPlanningPortfolio(this.planningPortfolioId).toPromise();
      this.objectives = [
        ...this.objectives,
        ...objectives
      ];
      this.objectives.forEach((obj) => {
        obj.keyResults.forEach((kr) => {
          const findRoadmap = kr.roadmaps.find((x) => x.id === this.roadmapItem.id);
          if (findRoadmap) {
            this.roadmapObjectives.push(obj);
          }
        });
      });
    }

    const uniqueObjectives = new Set(this.roadmapObjectives);
    this.roadmapObjectives = [...uniqueObjectives];
  }


  dismissModal(): void {
    this.activeModal.dismiss('View Objectives Modal Closed');
  }



}
