import {HttpClient} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from 'src/environments/environment';
import { ProductGroupOKRModel } from '../models/productGroupokr.model';
import {ProductGroupModel} from '../models/product-group.model';

@Injectable({
  providedIn: 'root'
})
export class ProductGroupService {

  constructor(@Inject(HttpClient) private http: HttpClient) {
  }



  getAllProductGroups(): Observable<ProductGroupModel[]> {
    return this.http.get<ProductGroupModel[]>(`${environment.baseURL}/productgroupokr`);
  }

  getProductGroupById(id: string): Observable<ProductGroupModel[]> {
    return this.http.get<ProductGroupModel[]>(`${environment.baseURL}/productgroupokr?id=${id}`);
  }


}
