import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ObjectiveModel } from '../models/objective.model';

@Injectable({
  providedIn: 'root'
})
export class ObjectiveService {

  objectives: Observable<ObjectiveModel[]>;

  constructor(@Inject(HttpClient) private http: HttpClient) { }

  getAll(objectiveType: string, draft: string): Observable<ObjectiveModel[]> {
    let params = new HttpParams();
    params = params.set('type', objectiveType);
    if (draft) { params = params.set('draft', draft); }
    this.objectives = this.http.get<ObjectiveModel[]>(`${environment.baseURL}/objectives`, { params });
    return this.objectives;
  }

  getAllByProduct(objectiveType: string, productid: string, draft: string): Observable<ObjectiveModel[]> {
    let params = new HttpParams();
    params = params.set('type', objectiveType);
    if (productid) { params = params.set('productid', productid); }
    if (draft) { params = params.set('draft', draft); }
    this.objectives = this.http.get<ObjectiveModel[]>(`${environment.baseURL}/objectives`, { params });
    return this.objectives;
  }

  getAllByProductAndGroup(objectiveType: string, productid: string, productgroupid: string, draft: string): Observable<ObjectiveModel[]> {
    let params = new HttpParams();
    params = params.set('type', objectiveType);
    if (productid) { params = params.set('productid', productid); }
    if (productgroupid) { params = params.set('productgroupid', productgroupid); }
    if (draft) { params = params.set('draft', draft); }
    this.objectives = this.http.get<ObjectiveModel[]>(`${environment.baseURL}/objectives`, { params });
    return this.objectives;
  }

  getAllPortfolioByPlanningPortfolio(planningPortfolioId: number): Observable<ObjectiveModel[]> {
    let params = new HttpParams();
    params = params.set('type', 'PORTFOLIO');
    params = params.set('planningportfolioid', planningPortfolioId);
    this.objectives = this.http.get<ObjectiveModel[]>(`${environment.baseURL}/objectives`, { params });
    return this.objectives;
  }

  getAllByEnterprise(): Observable<ObjectiveModel[]> {
    let params = new HttpParams();
    params = params.set('type', 'ENTERPRISE');
    this.objectives = this.http.get<ObjectiveModel[]>(`${environment.baseURL}/objectives`, { params });
    return this.objectives;
  }

  getAllByProductGroup(objectiveType: string, productgroupid: string, draft: string): Observable<ObjectiveModel[]> {
    let params = new HttpParams();
    params = params.set('type', objectiveType);
    if (productgroupid) { params = params.set('productgroupid', productgroupid); }
    if (draft) { params = params.set('draft', draft); }
    this.objectives = this.http.get<ObjectiveModel[]>(`${environment.baseURL}/objectives`, { params });
    return this.objectives;
  }

  create(data: any): Observable<any> {
    return this.http.post(`${environment.baseURL}/objectives`, data);
  }

  update(data: ObjectiveModel): Observable<any> {
    return this.http.put(`${environment.baseURL}/objectives`, data, {
      params: {
        id: data.id
      }
    });
  }

  delete(data: ObjectiveModel): Observable<any> {
    return this.http.delete(`${environment.baseURL}/objectives/${data.id}`);
  }

}
