import { Inject, Injectable } from '@angular/core';
import { HttpClient} from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import {AllinitiativesModel} from '../models/allinitiatives.model';

@Injectable({
  providedIn: 'root'
})
export class RallyAdoInitiativesService {

  allInitiatives: Observable<AllinitiativesModel[]>;

  constructor(@Inject(HttpClient) private http: HttpClient) { }

  getAllByProductId(productId: string): Observable<AllinitiativesModel[]> {
    this.allInitiatives = this.http.get<AllinitiativesModel[]>(`${environment.baseURL}/allinitiatives/${productId}`);
    return this.allInitiatives;
  }
  getAllADOByProductId(productId: string): Observable<AllinitiativesModel[]> {
    this.allInitiatives = this.http.get<AllinitiativesModel[]>(`${environment.baseURL}/allinitiatives/ado/${productId}`);
    return this.allInitiatives;
  }
  getAllRallyByProductId(productId: string): Observable<AllinitiativesModel[]> {
    this.allInitiatives = this.http.get<AllinitiativesModel[]>(`${environment.baseURL}/allinitiatives/rally/${productId}`);
    return this.allInitiatives;
  }


  getAllADOByProductGroupId(productGroupId: string): Observable<AllinitiativesModel[]> {
    this.allInitiatives = this.http.get<AllinitiativesModel[]>(`${environment.baseURL}/allinitiatives/adopg/${productGroupId}`);
    return this.allInitiatives;
  }
  getAllRallyByProductrGroupId(productGroupId: string): Observable<AllinitiativesModel[]> {
    this.allInitiatives = this.http.get<AllinitiativesModel[]>(`${environment.baseURL}/allinitiatives/rallypg/${productGroupId}`);
    return this.allInitiatives;
  }


  update(data: AllinitiativesModel): Observable<any> {
    return this.http.put(`${environment.baseURL}/allinitiatives`, data, {
      params: {
        initiativeId: data.initiativeId
      }
    });
  }


}


