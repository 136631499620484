<ng-container *ngIf="isIdle$ | async">
  <div class="modal-backdrop fade show"></div>
  <div class="modal d-block" aria-labelledby="warningLabel"  tabindex="-1" aria-modal="true" role="dialog">
    <div class="modal-dialog" >
      <div class="modal-content">
        <div class="modal-header text-white">
          <h4 class="modal-title">Session Timeout Warning</h4>
        </div>
        <div class="modal-body">
          <div class="fluid-container">
            <div class="row">
              <div class="col-sm-1">
                <i class="text-warning x-large" style="font-size: 30px; font-style: normal">&#x26A0;</i>
              </div>
              <div class="col-sm-11">
                <h4>
                  You will be logged out automatically in {{ secondsLeft$ | async }}
                  seconds.
                </h4>
                <b>Do you want to stay signed in?</b>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-success" (click)="extendSession()">Yes, Keep me signed in</button>
          <button type="button" class="btn btn-danger" (click)="endSession()">No, Sign me out</button>
        </div>
      </div>
    </div>
  </div>
</ng-container>