import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ObjectivesLogModel } from '../models/objectives-log.model';

@Injectable({
  providedIn: 'root'
})
export class ObjectivesLogService {

  objectivesLog: Observable<ObjectivesLogModel[]>;

  constructor(@Inject(HttpClient) private http: HttpClient) { }

  getAllByObjId(objectiveId: string): Observable<ObjectivesLogModel[]> {
    this.objectivesLog = this.http.get<ObjectivesLogModel[]>(`${environment.baseURL}/objectiveslog/${objectiveId}`);
    return this.objectivesLog;
  }

  create(data: any): Observable<any> {
    return this.http.post(`${environment.baseURL}/objectiveslog`, data);
  }
}
