<form name="form" class="form-horizontal" (ngSubmit)="keyResultTrackingForm.form.valid && onSubmit(keyResultTrackingForm, submitBtn)" #keyResultTrackingForm="ngForm" novalidate>
    <div class="modal-content">
      <div class="modal-header">
        <h2 class="modal-title" id="keyResultTrackingLabel">Progress</h2>
            <button type="button" class="btn-close" aria-label="Close" (click)="openConfirmationDialog('cancel', keyResultTrackingForm)"></button>
      </div>
      <div class="modal-body text-start">
        <div class="row">
            <div class="col-md-12">
                <div class="bg-light"> {{ selectedKeyResult[0].name }} </div>
            </div>
        </div>
        <div class="row pt-2">
            <div class="col-md-4">
                <div class="form-label">Quarter</div>
                    <input
                        type="radio"
                        id="quarter1"
                        name="quarter"
                        class="btn-check"
                        value="Q1"
                        #quarter = "ngModel"
                        [(ngModel)]="keyResultTracking.quarter"
                        [ngClass]="{'is-invalid':keyResultTrackingForm.submitted && quarter.invalid}"
                        required>
                    <label class="btn btn-sm btn-outline-secondary m-1" for="quarter1">Q1</label>
                    <input
                        type="radio"
                        id="quarter2"
                        name="quarter"
                        class="btn-check"
                        value="Q2"
                        #quarter = "ngModel"
                        [(ngModel)]="keyResultTracking.quarter"
                        [ngClass]="{'is-invalid':keyResultTrackingForm.submitted && quarter.invalid}"
                        required>
                    <label class="btn btn-sm btn-outline-secondary m-1" for="quarter2">Q2</label>
                    <input
                        type="radio"
                        id="quarter3"
                        name="quarter"
                        class="btn-check"
                        value="Q3"
                        #quarter = "ngModel"
                        [(ngModel)]="keyResultTracking.quarter"
                        [ngClass]="{'is-invalid':keyResultTrackingForm.submitted && quarter.invalid}"
                        required>
                    <label class="btn btn-sm btn-outline-secondary m-1" for="quarter3">Q3</label>
                    <input
                        type="radio"
                        id="quarter4"
                        name="quarter"
                        class="btn-check"
                        value="Q4"
                        #quarter = "ngModel"
                        [(ngModel)]="keyResultTracking.quarter"
                        [ngClass]="{'is-invalid':keyResultTrackingForm.submitted && quarter.invalid}"
                        required>
                    <label class="btn btn-sm btn-outline-secondary m-1" for="quarter4">Q4</label>
                    <div class="invalid-feedback">Quarter is required</div>
            </div>
            <div class="col-md-5">
                <div class="form-label">Month</div>
                <div class="text-muted pt-md-3" *ngIf="!keyResultTracking.quarter">Select Quarter</div>
                <div [ngSwitch]="keyResultTracking.quarter">
                    <ng-container *ngSwitchCase="'Q1'">
                        <input
                            type="radio"
                            id="month1"
                            name="month"
                            class="btn-check"
                            value="1"
                            #month = "ngModel"
                            [(ngModel)]="keyResultTracking.month"
                            [ngClass]="{'is-invalid':keyResultTrackingForm.submitted && month.invalid}"
                            required>
                        <label class="btn btn-sm btn-outline-secondary m-1" for="month1">January</label>
                        <input
                            type="radio"
                            id="month2"
                            name="month"
                            class="btn-check"
                            value="2"
                            #month = "ngModel"
                            [(ngModel)]="keyResultTracking.month"
                            [ngClass]="{'is-invalid':keyResultTrackingForm.submitted && month.invalid}"
                            required>
                        <label class="btn btn-sm btn-outline-secondary m-1" for="month2">February</label>
                        <input
                            type="radio"
                            id="month3"
                            name="month"
                            class="btn-check"
                            value="3"
                            #month = "ngModel"
                            [(ngModel)]="keyResultTracking.month"
                            [ngClass]="{'is-invalid':keyResultTrackingForm.submitted && month.invalid}"
                            required>
                        <label class="btn btn-sm btn-outline-secondary m-1" for="month3">March</label>
                    </ng-container>
                    <ng-container *ngSwitchCase="'Q2'">
                        <input
                            type="radio"
                            id="month4"
                            name="month"
                            class="btn-check"
                            value="4"
                            #month = "ngModel"
                            [(ngModel)]="keyResultTracking.month"
                            [ngClass]="{'is-invalid':keyResultTrackingForm.submitted && month.invalid}"
                            required>
                        <label class="btn btn-sm btn-outline-secondary m-1" for="month4">April</label>
                        <input
                            type="radio"
                            id="month5"
                            name="month"
                            class="btn-check"
                            value="5"
                            #month = "ngModel"
                            [(ngModel)]="keyResultTracking.month"
                            [ngClass]="{'is-invalid':keyResultTrackingForm.submitted && month.invalid}"
                            required>
                        <label class="btn btn-sm btn-outline-secondary m-1" for="month5">May</label>
                        <input
                            type="radio"
                            id="month6"
                            name="month"
                            class="btn-check"
                            value="6"
                            #month = "ngModel"
                            [(ngModel)]="keyResultTracking.month"
                            [ngClass]="{'is-invalid':keyResultTrackingForm.submitted && month.invalid}"
                            required>
                        <label class="btn btn-sm btn-outline-secondary m-1" for="month6">June</label>
                    </ng-container>
                    <ng-container *ngSwitchCase="'Q3'">
                        <input
                            type="radio"
                            id="month7"
                            name="month"
                            class="btn-check"
                            value="7"
                            #month = "ngModel"
                            [(ngModel)]="keyResultTracking.month"
                            [ngClass]="{'is-invalid':keyResultTrackingForm.submitted && month.invalid}"
                            required>
                        <label class="btn btn-sm btn-outline-secondary m-1" for="month7">July</label>
                        <input
                            type="radio"
                            id="month8"
                            name="month"
                            class="btn-check"
                            value="8"
                            #month = "ngModel"
                            [(ngModel)]="keyResultTracking.month"
                            [ngClass]="{'is-invalid':keyResultTrackingForm.submitted && month.invalid}"
                            required>
                        <label class="btn btn-sm btn-outline-secondary m-1" for="month8">August</label>
                        <input
                            type="radio"
                            id="month9"
                            name="month"
                            class="btn-check"
                            value="9"
                            #month = "ngModel"
                            [(ngModel)]="keyResultTracking.month"
                            [ngClass]="{'is-invalid':keyResultTrackingForm.submitted && month.invalid}"
                            required>
                        <label class="btn btn-sm btn-outline-secondary m-1" for="month9">September</label>
                    </ng-container>
                    <ng-container *ngSwitchCase="'Q4'">
                        <input
                            type="radio"
                            id="month10"
                            name="month"
                            class="btn-check"
                            value="10"
                            #month = "ngModel"
                            [(ngModel)]="keyResultTracking.month"
                            [ngClass]="{'is-invalid':keyResultTrackingForm.submitted && month.invalid}"
                            required>
                        <label class="btn btn-sm btn-outline-secondary m-1" for="month10">October</label>
                        <input
                            type="radio"
                            id="month11"
                            name="month"
                            class="btn-check"
                            value="11"
                            #month = "ngModel"
                            [(ngModel)]="keyResultTracking.month"
                            [ngClass]="{'is-invalid':keyResultTrackingForm.submitted && month.invalid}"
                            required>
                        <label class="btn btn-sm btn-outline-secondary m-1" for="month11">November</label>
                        <input
                            type="radio"
                            id="month12"
                            name="month"
                            class="btn-check"
                            value="12"
                            #month = "ngModel"
                            [(ngModel)]="keyResultTracking.month"
                            [ngClass]="{'is-invalid':keyResultTrackingForm.submitted && month.invalid}"
                            required>
                        <label class="btn btn-sm btn-outline-secondary m-1" for="month12">December</label>
                    </ng-container>
                    <div class="invalid-feedback">Month is required</div>
                </div>
            </div>
            <div class="col-md-3">
                <div class="form-label">Year</div>
                <div style="float:left" *ngFor="let year of years; let i = index">
                    <input 
                        type="radio"
                        [id]="'year'+i"
                        name="yearRadio"
                        class="btn-check"
                        value="{{ year }}"
                        #yearRadio = "ngModel"
                        [(ngModel)]="keyResultTracking.year"
                        [ngClass]="{'is-invalid':keyResultTrackingForm.submitted && yearRadio.invalid}"
                        required>
                    <label class="btn btn-sm btn-outline-secondary m-1" [attr.for]="'year'+i">{{ year }}</label>
                    <div *ngIf="i === years.length-1" class="invalid-feedback">Year is required</div>
                </div>
            </div>
        </div>
        <hr>
        <div class="row pt-2">
            <div class="col-md-4 card text-center">
                <div class="card-body">
                    <div class="fs-3" id="baseline" [ngSwitch]="selectedKeyResult[0].measureType">
                        <ng-container *ngSwitchCase="'Percent'">{{ selectedKeyResult[0].baseline }}%</ng-container>
                        <ng-container *ngSwitchCase="'Dollar'">${{ selectedKeyResult[0].baseline | number: '0.0-10' }}</ng-container>
                        <ng-container *ngSwitchCase="'Count'">{{ selectedKeyResult[0].baseline | number: '0.0-10' }} unit(s)</ng-container>
                        <ng-container *ngSwitchCase="'Time (Hours)'">{{ selectedKeyResult[0].baseline | number: '0.0-10' }} hr(s)</ng-container>
                        <ng-container *ngSwitchCase="'Time (Minutes)'">{{ selectedKeyResult[0].baseline | number: '0.0-10' }} min(s)</ng-container>
                        <ng-container *ngSwitchCase="'Time (Seconds)'">{{ selectedKeyResult[0].baseline | number: '0.0-10' }} sec(s)</ng-container>
                        <ng-container *ngSwitchCase="'Other'">{{ selectedKeyResult[0].baseline | number: '0.0-10' }} {{selectedKeyResult[0].otherMeasurementType | shorten: 15}}</ng-container>
                        <ng-container *ngSwitchDefault>{{ selectedKeyResult[0].baseline }}</ng-container>
                    </div>
                </div>
                <div class="card-footer">
                    <label for="baseline">Baseline (From)</label>
                </div>
            </div>
            <div class="col-md-4 card">
                <div class="card-body text-center">
                    <input type="text"
                        id="current"
                        class="form-control text-center fs-4"
                        name="current"
                        #current = "ngModel"
                        inputmode="numeric"
                        placeholder="Numerical value"
                        pattern="[0-9.-]*"
                        (paste)="onPaste(current, $event)"
                        (drop)="onDrop(current, $event)"
                        (keydown)="onKeyDown(current, $event)"
                        [(ngModel)]="keyResultTracking.results"
                        [ngClass]="{'is-invalid':keyResultTrackingForm.submitted && current.invalid}"
                        required>
                    <div class="invalid-feedback">
                        <div> Required</div>
                    </div>
                </div>
                <div class="card-footer text-center">
                    <label for="current">Current</label>
                </div>
            </div>
            <div class="col-md-4 card">
                <div class="card-body text-center">
                    <div id="goal" class="fs-3" [ngSwitch]="selectedKeyResult[0].measureType">
                        <ng-container *ngSwitchCase="'Percent'">{{ selectedKeyResult[0].goal }}%</ng-container>
                        <ng-container *ngSwitchCase="'Dollar'">${{ selectedKeyResult[0].goal | number: '0.0-10' }}</ng-container>
                        <ng-container *ngSwitchCase="'Count'">{{ selectedKeyResult[0].goal | number: '0.0-10' }} unit(s)</ng-container>
                        <ng-container *ngSwitchCase="'Time (Hours)'">{{ selectedKeyResult[0].goal | number: '0.0-10' }} hr(s)</ng-container>
                        <ng-container *ngSwitchCase="'Time (Minutes)'">{{ selectedKeyResult[0].goal | number: '0.0-10' }} min(s)</ng-container>
                        <ng-container *ngSwitchCase="'Time (Seconds)'">{{ selectedKeyResult[0].goal | number: '0.0-10' }} sec(s)</ng-container>
                        <ng-container *ngSwitchCase="'Other'">{{ selectedKeyResult[0].goal | number: '0.0-10' }} {{selectedKeyResult[0].otherMeasurementType | shorten: 15}}</ng-container>
                        <ng-container *ngSwitchDefault>{{ selectedKeyResult[0].goal }}</ng-container>
                    </div>
                </div>
                <div class="card-footer text-center">
                    <label for="goal">Goal (To)</label>
                </div>
            </div>
        </div>
        <hr>
        <div class="row pt-2">
            <div class="col-md-12">
            <div class="form-label">Confidence Level</div>
                <input
                    type="radio"
                    id="confidenceLevel1"
                    name="confidenceLevel"
                    class="btn-check"
                    value="On Track"
                    #confidenceLevel = "ngModel"
                    [(ngModel)]="keyResultTracking.confidenceLevel"
                    [ngClass]="{'is-invalid':keyResultTrackingForm.submitted && confidenceLevel.invalid}"
                    required>
                <label class="btn btn-sm btn-outline-secondary m-1" for="confidenceLevel1">On Track</label>
                <input
                    type="radio"
                    id="confidenceLevel2"
                    name="confidenceLevel"
                    class="btn-check"
                    value="Off Track"
                    #timeframe = "ngModel"
                    [(ngModel)]="keyResultTracking.confidenceLevel"
                    [ngClass]="{'is-invalid':keyResultTrackingForm.submitted && confidenceLevel.invalid}"
                    required>
                <label class="btn btn-sm btn-outline-secondary" for="confidenceLevel2">Off Track</label>
                <input
                    type="radio"
                    id="confidenceLevel3"
                    name="confidenceLevel"
                    class="btn-check"
                    value="At Risk"
                    #timeframe = "ngModel"
                    [(ngModel)]="keyResultTracking.confidenceLevel"
                    [ngClass]="{'is-invalid':keyResultTrackingForm.submitted && confidenceLevel.invalid}"
                    required>
                <label class="btn btn-sm btn-outline-secondary m-1" for="confidenceLevel3">At Risk</label>
                <div class="invalid-feedback">Confidence Level is required</div>
            </div>
        </div>
        <div class="row pt-1">
            <div class="col-md-12">
              <label for="comments" class="col-form-label">Comments:</label>
              <textarea
                type="text"
                id="comments"
                name="comments"
                class="form-control" 
                placeholder="optional"
                rows="2"
                [(ngModel)]="keyResultTracking.comments">
              </textarea>
            </div>
          </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-primary me-auto" (click)="openConfirmationDialog('progress', keyResultTrackingForm)">
            View Progress
        </button>
        <button (click)="closeModal('cancel', keyResultTrackingForm)" type="button" class="btn btn-secondary">Cancel</button>
        <button class="btn btn-primary" type="submit" #submitBtn>Save</button>
    </div>
</div>
</form>

