<div *ngIf="pageLoaded" class="modal-lg">
    <div class="modal-content">
        <div class="modal-header">
            <h2 class="modal-title">Progress</h2>
            <button type="button" class="btn-close" aria-label="Close" (click)="openConfirmationDialog('close')"></button>
        </div>
        <div class="modal-body text-start">
            <div class="row pb-2">
                <div class="col-12">
                    <label for="outcome" class="form-label fw-bold">Desired Outcome:</label>  
                    <div id="outcome" class=" bg-light">{{ selectedKeyResult[0].name }}</div>
                </div>
            </div>
            <div class="row pt-2">
                <div class="col-6">
                    <div class="form-label" [ngSwitch]="selectedKeyResult[0].measureType">
                        <span class="fw-bold">Baseline:</span>
                        <span class="ms-2">
                            <ng-container *ngSwitchCase="'Percent'">{{ selectedKeyResult[0].baseline }}%</ng-container>
                            <ng-container *ngSwitchCase="'Dollar'">${{ selectedKeyResult[0].baseline | number: '0.0-10' }}</ng-container>
                            <ng-container *ngSwitchCase="'Count'">{{ selectedKeyResult[0].baseline | number: '0.0-10' }} unit(s)</ng-container>
                            <ng-container *ngSwitchCase="'Time (Hours)'">{{ selectedKeyResult[0].baseline | number: '0.0-10' }} hr(s)</ng-container>
                            <ng-container *ngSwitchCase="'Time (Minutes)'">{{ selectedKeyResult[0].baseline | number: '0.0-10' }} min(s)</ng-container>
                            <ng-container *ngSwitchCase="'Time (Seconds)'">{{ selectedKeyResult[0].baseline | number: '0.0-10' }} sec(s)</ng-container>
                            <ng-container *ngSwitchCase="'Other'">{{ selectedKeyResult[0].baseline | number: '0.0-10' }} {{selectedKeyResult[0].otherMeasurementType | shorten: 15}}</ng-container>
                            <ng-container *ngSwitchDefault>{{ selectedKeyResult[0].baseline }}</ng-container>
                        </span>
                    </div>  
                </div>
                <div class="col-6">
                    <div class="form-label" [ngSwitch]="selectedKeyResult[0].measureType">
                        <span class="fw-bold">Goal:</span>
                        <span class="ms-2">
                            <ng-container *ngSwitchCase="'Percent'">{{ selectedKeyResult[0].goal }}%</ng-container>
                            <ng-container *ngSwitchCase="'Dollar'">${{ selectedKeyResult[0].goal | number: '0.0-10' }}</ng-container>
                            <ng-container *ngSwitchCase="'Count'">{{ selectedKeyResult[0].goal | number: '0.0-10' }} unit(s)</ng-container>
                            <ng-container *ngSwitchCase="'Time (Hours)'">{{ selectedKeyResult[0].goal | number: '0.0-10' }} hr(s)</ng-container>
                            <ng-container *ngSwitchCase="'Time (Minutes)'">{{ selectedKeyResult[0].goal | number: '0.0-10' }} min(s)</ng-container>
                            <ng-container *ngSwitchCase="'Time (Seconds)'">{{ selectedKeyResult[0].goal | number: '0.0-10' }} sec(s)</ng-container>
                            <ng-container *ngSwitchCase="'Other'">{{ selectedKeyResult[0].goal | number: '0.0-10' }} {{selectedKeyResult[0].otherMeasurementType | shorten: 15}}</ng-container>
                            <ng-container *ngSwitchDefault>{{ selectedKeyResult[0].goal }}</ng-container>
                        </span>
                    </div>  
                </div>
            </div>
            <div *ngIf="noKeyResultTracking">
                <hr>
                <div class="row pt-2">
                    <div class="col-12">
                    No Progress Found
                    </div>
                </div>
            </div>
            <div *ngIf="!noKeyResultTracking">
                <hr>
                <div class="row pt-2 gridHeader">
                    <div class="col-1">
                        Year
                    </div>
                    <div class="col-1">
                        Qtr
                    </div>
                    <div class="col-2">
                        Month
                    </div>
                    <div class="col-2">
                        Progress
                    </div>
                    <div class="col-2">
                        Conf. Level
                    </div>
                    <div class="col-2">
                        Comments
                    </div>
                    <div class="col-2">
                        Action
                    </div>
                </div>
                <div class="row grid-striped" *ngFor="let keyResultTracking of keyResultTrackingEntries;" [ngSwitch]="selectedKeyResult[0].measureType">
                    <div class="col-1 pt-1">
                        {{ keyResultTracking.year }}
                    </div>
                    <div class="col-1 pt-1">
                        {{ keyResultTracking.quarter }}
                    </div>
                    <div class="col-2 pt-1" (click)="editKeyResultTracking($event, keyResultTracking)" role="button">
                        <span *ngIf="!keyResultTracking.editMode" [ngSwitch]="keyResultTracking.month">
                            <ng-container *ngSwitchCase="'1'">
                                January
                            </ng-container>
                            <ng-container *ngSwitchCase="'2'">
                                February
                            </ng-container>
                            <ng-container *ngSwitchCase="'3'">
                                March
                            </ng-container>
                            <ng-container *ngSwitchCase="'4'">
                                April
                            </ng-container>
                            <ng-container *ngSwitchCase="'5'">
                                May
                            </ng-container>
                            <ng-container *ngSwitchCase="'6'">
                                June
                            </ng-container>
                            <ng-container *ngSwitchCase="'7'">
                                July
                            </ng-container>
                            <ng-container *ngSwitchCase="'8'">
                                August
                            </ng-container>
                            <ng-container *ngSwitchCase="'9'">
                                September
                            </ng-container>
                            <ng-container *ngSwitchCase="'10'">
                                October
                            </ng-container>
                            <ng-container *ngSwitchCase="'11'">
                                November
                            </ng-container>
                            <ng-container *ngSwitchCase="'12'">
                                December
                            </ng-container>
                        </span>
                        <span *ngIf="keyResultTracking.editMode" [ngSwitch]="keyResultTracking.quarter">
                            <select 
                                id="month"
                                name="month"
                                class="form-control editSelect"
                                [(ngModel)]="keyResultTracking.month">
                                <ng-container *ngSwitchCase="'Q1'">
                                    <option value="1">January</option>
                                    <option value="2">February</option>
                                    <option value="3">March</option>
                                </ng-container>
                                <ng-container *ngSwitchCase="'Q2'">
                                    <option value="4">April</option>
                                    <option value="5">May</option>
                                    <option value="6">June</option>
                                </ng-container>
                                <ng-container *ngSwitchCase="'Q3'">
                                    <option value="7">July</option>
                                    <option value="8">August</option>
                                    <option value="9">September</option>
                                </ng-container>
                                <ng-container *ngSwitchCase="'Q4'">
                                    <option value="10">October</option>
                                    <option value="11">November</option>
                                    <option value="12">December</option>
                                </ng-container>
                            </select>
                        </span>
                    </div>
                    <div class="col-2 pt-1" (click)="editKeyResultTracking($event, keyResultTracking)" role="button">
                        <span *ngIf="!keyResultTracking.editMode" [ngSwitch]="selectedKeyResult[0].measureType">
                            <ng-container *ngSwitchCase="'Percent'">{{ keyResultTracking.results }}%</ng-container>
                            <ng-container *ngSwitchCase="'Dollar'">${{ keyResultTracking.results | number: '0.0-10' }}</ng-container>
                            <ng-container *ngSwitchCase="'Count'">{{ keyResultTracking.results | number: '0.0-10' }} unit(s)</ng-container>
                            <ng-container *ngSwitchCase="'Time (Hours)'">{{ keyResultTracking.results | number: '0.0-10' }} hr(s)</ng-container>
                            <ng-container *ngSwitchCase="'Time (Minutes)'">{{ keyResultTracking.results | number: '0.0-10' }} min(s)</ng-container>
                            <ng-container *ngSwitchCase="'Time (Seconds)'">{{ keyResultTracking.results | number: '0.0-10' }} sec(s)</ng-container>
                            <ng-container *ngSwitchCase="'Other'">{{ keyResultTracking.results | number: '0.0-10' }} {{selectedKeyResult[0].otherMeasurementType | shorten:15 }}</ng-container>
                            <ng-container *ngSwitchDefault>{{ keyResultTracking.results }}</ng-container>
                        </span>
                        <span *ngIf="keyResultTracking.editMode">
                            <input
                                id="current"
                                type="text"
                                name="current"
                                #current = "ngModel"
                                class="form-control editInput"
                                inputmode="numeric"
                                pattern="[0-9.-]*"
                                #currentResults
                                (paste)="onPaste(currentResults, $event)"
                                (drop)="onDrop(currentResults, $event)"
                                (keydown)="onKeyDown(currentResults, $event)"
                                [ngClass]="{'is-invalid': current.value === ''}"
                                [(ngModel)]="keyResultTracking.results"
                                required>
                            <div class="invalid-feedback">
                                <div> Required</div>
                            </div>
                        </span>
                    </div>
                    <div class="col-2 pt-1" (click)="editKeyResultTracking($event, keyResultTracking)" role="button">
                        <span *ngIf="!keyResultTracking.editMode">{{ keyResultTracking.confidenceLevel }}</span>
                        <select *ngIf="keyResultTracking.editMode"
                        class="form-control editSelect"
                        id="confidenceLevel"
                        name="confidenceLevel"
                        #confidenceLevel = "ngModel"
                        [(ngModel)]="keyResultTracking.confidenceLevel"
                        [ngClass]="{'is-invalid': confidenceLevel.value === ''}">
                            <option value="On Track">On Track</option>
                            <option value="Off Track">Off Track</option>
                            <option value="At Risk">At Risk</option>
                        </select>
                        <div class="invalid-feedback">
                            <div> Required</div>
                        </div>
                    </div>
                    <div class="col-2 pt-1 pb-1" (click)="editKeyResultTracking($event, keyResultTracking)" role="button">
                        <span *ngIf="!keyResultTracking.editMode">{{ keyResultTracking.comments }}</span>
                        <textarea
                            type="text"
                            id="comments"
                            name="comments"
                            class="form-control editTextArea"
                            placeholder="optional"
                            *ngIf="keyResultTracking.editMode"
                            [(ngModel)]="keyResultTracking.comments"
                            rows="4">
                        </textarea>
                    </div>
                    <div class="col-2">
                        <div *ngIf="!keyResultTracking.editMode">
                                <a href="#" (click)="editKeyResultTracking($event, keyResultTracking)"><span title="Edit" class="bi-pencil-square fs-5 p-0"></span></a>
                                <a href="#" (click)="delete($event, keyResultTracking)"><span title="Delete" class="bi bi-trash fs-5 px-1"></span></a>

                        </div>
                        <div class="col-2 editButtons" *ngIf="keyResultTracking.editMode">
                            <a href="#" (click)="saveKeyResultTracking($event, keyResultTracking)"><span title="Save" class="bi-check-square-fill fs-5 p-1"></span></a>
                            <a href="#" (click)="cancelEdit($event, keyResultTracking)"><span title="Cancel" class="bi-x-square fs-5 p-0"></span></a>
                            <a href="#" (click)="delete($event, keyResultTracking)"><span title="Delete" class="bi bi-trash fs-5 px-1"></span></a>
                        </div>
                    </div>
                </div>
            </div>
            <hr>
            <div class="row mt-3 mb-1 flex-nowrap">
                <div class="col-6 text-start">
                    <button type="button" class="btn btn-primary btn-sm" (click)="openConfirmationDialog('progress')">
                        <i class="bi-plus"></i>Add Progress
                    </button>
                </div>
                <div class="col-6 text-end">
                    <button *ngIf="restoreKeyResultTrackingEntries.length > 1" type="button" class="btn btn-primary btn-sm" #saveAllBtn (click)="saveAll(saveAllBtn, $event)">
                        Save All
                    </button>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <button (click)="closeModal('close')" type="button" class="btn btn-secondary">Close</button>
        </div>
    </div>
</div>
