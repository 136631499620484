import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import {
  PingAuthenticationConfig,
  PING_CONFIG,
} from '@techops-ui/ping-authentication';

fetch(`./assets/${environment.ssoConfigFile}`)
  .then((response) => response.json())
  .then((config: PingAuthenticationConfig) => {
    if (environment.production) {
      enableProdMode();
    }
    platformBrowserDynamic([{ provide: PING_CONFIG, useValue: config }])
    .bootstrapModule(AppModule)
    .catch((err) => console.error(err));
  });
