import { Injectable } from '@angular/core';
import { ApolloQueryResult } from '@apollo/client/core';
import { Apollo, gql } from 'apollo-angular';
import { Observable } from 'rxjs';

export interface UserSearchResult {
  value: string;
  name: string;
}

const searchOwnerQuery = gql`
query graphQLAuthUserSearch($userSearchInput: UserSearchInput) { 
  
  userSearch(userSearchInput:$userSearchInput)
  {

    users {
      employeeNumber
      displayName
    }
  }
}`;

@Injectable({
  providedIn: 'root'
})
export class GraphqlQueriesService {
  constructor(private apollo: Apollo){}
  getOwnerName(search: string ): Observable<ApolloQueryResult<UserSearchResult>> {
      return this.apollo.watchQuery<UserSearchResult>({
        query: searchOwnerQuery,
        variables: { userSearchInput: { search, pageSize: 20 } }
      })
      .valueChanges;
  }
}





