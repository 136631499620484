import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-insights',
  templateUrl: './insights.component.html',
  styleUrls: ['./insights.component.scss']
})

export class InsightsComponent implements OnInit {
  @Input() numObjectives: number;
  @Input() numKeyResults: number;
  @Input() numNotStarted: number;
  @Input() numMeasuring: number;
  @Input() numDone: number;

  constructor() { }

  ngOnInit(): void {

  }

}
