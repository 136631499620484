import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { KeyResultTrackingModel } from '../models/key-result-tracking.model';

@Injectable({
  providedIn: 'root'
})
export class KeyResultTrackingService {

  constructor(@Inject(HttpClient) private http: HttpClient) { }

  getAll(id: string): Observable<KeyResultTrackingModel[]> {
    return this.http.get<KeyResultTrackingModel[]>(`${environment.baseURL}/key-results-tracking`, {
      params: {
        id
      }
    });
  }

  create(data: any): Observable<any> {
    return this.http.post(`${environment.baseURL}/key-results-tracking`, data);
  }

  update(data: KeyResultTrackingModel): Observable<any> {
    return this.http.put(`${environment.baseURL}/key-results-tracking`, data, {
      params: {
        id: data.id
      }
    });
  }

  delete(data: KeyResultTrackingModel): Observable<any> {
    return this.http.delete(`${environment.baseURL}/key-results-tracking/${data.id}`);
  }

}
