<form name="form" class="form-horizontal" (ngSubmit)="visionStatementForm.form.valid && saveVisionStatement(visionStatementForm)"
  #visionStatementForm="ngForm" novalidate>
  <div class="mt-3 mb-2 mx-5">
      <p *ngIf="!editMode" class="displayVision">{{ vision.visionStatement }}</p>
      <p *ngIf="!editMode && !vision.visionStatement">{{ visionMsg }} not found</p>
      <textarea
      id="visionStatementText"
      *ngIf="editMode"
      placeholder="Enter your {{ visionMsg }} here"
      class="form-control"
      rows="10"
      name="visionStatementText"
      #visionStatement = "ngModel"
      [(ngModel)]="vision.visionStatement"
      [ngClass]="{'is-invalid':visionStatementForm.submitted && visionStatement.invalid}"
      required>
      </textarea>
      <div class="invalid-feedback">
        <div> {{ visionMsg }} is required</div>
      </div>
  </div>
  <div class="mt-3 text-end">
    <hr>
    <span *ngIf="editMode"(click)="vision.visionStatement = this.prevStatement;editMode = false;" class="me-2"><button type="button" class="btn btn-secondary">Cancel</button></span>
    <span *ngIf="!editMode" (click)="editMode = true;" class="me-2"><button type="button" class="btn btn-primary">Edit</button></span>
    <button *ngIf="editMode" type="submit" class="btn btn-primary" #submitBtn>{{existingVisionStatement ? "Save" : "Submit"}}</button>
  </div>
</form>