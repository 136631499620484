import { EventEmitter, Injectable } from '@angular/core';
import { Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UtilsService {
  navNumberKeys = ['Backspace', 'Tab', 'Delete', 'Enter', 'ArrowRight', 'ArrowLeft', 'Home', 'End'];

  constructor() { }

  onKeyDownNumbers(inputField: HTMLInputElement, e: KeyboardEvent) {
    const foundDecimal = inputField.value.trim().includes('.'); //checks if any decimals
    const foundHyphen = inputField.value.trim().includes('-');
    let financialfields = false;
    if ((inputField.name ==='hardwareEst') || (inputField.name ==='softwareEst') || (inputField.name ==='thirdPartyEst')) {
       financialfields = true;
        }
    if (
      this.navNumberKeys.indexOf(e.key) > -1 || //allow navigation keys in number field
      (e.key === '.' && !foundDecimal && !financialfields) || //allow only one decimal
      (e.key === '-' && !foundHyphen && !financialfields) || //allow one hyphen (negative number)
      (e.key === 'a' && e.ctrlKey === true) || // Allow: Ctrl+A
      (e.key === 'c' && e.ctrlKey === true) || // Allow: Ctrl+C
      (e.key === 'v' && e.ctrlKey === true) || // Allow: Ctrl+V
      (e.key === 'x' && e.ctrlKey === true) || // Allow: Ctrl+X
      (e.key === 'a' && e.metaKey === true) || // Cmd+A (Mac)
      (e.key === 'c' && e.metaKey === true) || // Cmd+C (Mac)
      (e.key === 'v' && e.metaKey === true) || // Cmd+V (Mac)
      (e.key === 'x' && e.metaKey === true) // Cmd+X (Mac)
    ) {
      return;  // not allowed
    }
    // Ensure that it is a number and stop the keypress
    if (e.key === ' ' || isNaN(Number(e.key))) {
      e.preventDefault();
    }
  }

  onPasteNumbers(inputField: HTMLInputElement, event: ClipboardEvent) {
    event.preventDefault();
    const pastedInput: string = event.clipboardData
      .getData('text/plain')
      .replace(/[^0-9.-]/g, ''); // allow a digit-only or decimal/hyphen string
    document.execCommand('insertText', false, pastedInput);
    //execCommand showing deprecated but still widely used
  }

  onDropNumbers(inputField: HTMLInputElement, event: DragEvent) {
    event.preventDefault();
    const textData = event.dataTransfer
      .getData('text').replace(/[^0-9.-]/g, ''); // allow a digit-only or decimal/hyphen string
    inputField.focus();
    document.execCommand('insertText', false, textData);
    //execCommand showing deprecated but still widely used
  }
}
