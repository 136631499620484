import { Component, Input, OnInit } from '@angular/core';
import { RoadmapProgressModel } from 'src/app/models/roadmap-progress.model';
import { RoadmapModel } from 'src/app/models/roadmap.model';
import { RoadmapProgressService } from 'src/app/services/roadmap-progress.service';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmationDialogService } from 'src/app/services/confirmation-dialog.service';
import { RoadmapProgressAddComponent } from '../roadmap-progress-add/roadmap-progress-add.component';
import { UtilsService } from 'src/app/services/utils.service';
import { EventEmitterService } from 'src/app/services/event-emitter.service';
import { RoadmapService } from 'src/app/services/roadmap.service';

@Component({
  selector: 'app-roadmap-progress-view',
  templateUrl: './roadmap-progress-view.component.html',
  styleUrls: ['./roadmap-progress-view.component.scss']
})
export class RoadmapProgressViewComponent implements OnInit {
  @Input() roadmap: RoadmapModel;
  @Input() roadmapType: string;
  @Input() fromModal: string;
  @Input() fromPage: string;
  @Input() fromObjectiveId: string;

  pageLoaded: Promise<boolean>;
  restoreRoadmapProgressEntries = [];
  noRoadmapProgress: boolean;
  roadmapProgressEntries: RoadmapProgressModel[] = [];
  isDone = false;

  constructor(private roadmapProgressService: RoadmapProgressService,
            private modalService: NgbModal,
            public activeModal: NgbActiveModal,
            private confirmationDialogService: ConfirmationDialogService,
            private utilsService: UtilsService,
            private eventEmitterService: EventEmitterService,
            private roadmapService: RoadmapService) { }

  ngOnInit(): void {
    this.loadData();
  }

  loadData() {
    this.pageLoaded = Promise.resolve(false);

    //if roadmap already has doneTime value, it was previously marked done
    if (this.roadmap.doneTime) {
      this.isDone = true;
    }

    this.roadmapProgressService.getAll(this.roadmap.id).subscribe(
        (data) => {
          this.noRoadmapProgress = data.length === 0 ? true : false;
          this.roadmapProgressEntries = data;
          this.roadmapProgressEntries.sort(this.sortProgressDate);
          this.setDefaultEditMode();
          this.pageLoaded = Promise.resolve(true);
        },
        (error) => {
          console.error(error);
        }
      );
  };

  sortProgressDate(a: RoadmapProgressModel, b: RoadmapProgressModel) {
    //descending
    const dateA = new Date(a.dateOfProgress).getTime();
    const dateB = new Date(b.dateOfProgress).getTime();

    if (dateA < dateB) {
      return 1;
    } else if (dateA > dateB) {
      return -1;
    } else {
      return 0;
    }
  }

  delete(e: any, roadmapProgress: RoadmapProgressModel, index: number) {
    e.preventDefault();
    const doneWarning: string = (this.isDone && roadmapProgress.status === 'Done' && index === 0)
    ? '\n\nThe done date on the roadmap will be removed.': '';

    this.confirmationDialogService.confirm('Delete', 'Do you want to delete this progress?\n\n' +
    'State: ' + roadmapProgress.status + '\n' +
    '% Completed: ' + roadmapProgress.percentageOfPlannedWorkCompleted + '\n' +
    'Confidence Level: ' + roadmapProgress.confidenceLevel + doneWarning, 'Yes', 'No','')
      .then(async (confirmed) => {
        if (confirmed) { //selected Yes to delete
          this.roadmapProgressService.delete(roadmapProgress).subscribe(
            async response => {
              this.removePreviousValue(roadmapProgress);
              if (doneWarning) {
                this.roadmap.doneTime = null;
                this.roadmapService.update(this.roadmap).toPromise();
              }
              this.ngOnInit();
            },
            error => {
              console.error(error);
            });
        } else { //Selected no to delete
        }
      }
      );
  }

  passValidation(roadmapProgress: RoadmapProgressModel) {
    if(roadmapProgress.percentageOfPlannedWorkCompleted.trim() === '' &&
    (roadmapProgress.status !== 'Backlog' && roadmapProgress.status !== 'Discovering')) {
      return false;
    }

    if (roadmapProgress.currentValueDelivered.trim() === '') {
      return false;
    } else if (roadmapProgress.confidenceLevel === '' &&
              roadmapProgress.status !== 'Backlog' && roadmapProgress.status !== 'Discovering') {
      return false;
    } else {
      return true;
    }
  }

  async saveRoadmapProgress(e: any, roadmapProgress: RoadmapProgressModel) {
    if (e !== null) {e.preventDefault();}

    if (this.passValidation(roadmapProgress)) {
      roadmapProgress.editMode = false;
      this.roadmapProgressService.update(roadmapProgress).subscribe(
        async response => {
          await this.removePreviousValue(roadmapProgress);
        },
        error => {
          console.error(error);
        });
      if (e !== null) { //if event, then we are performing a single save not save all, re-sort immediately
        await this.removePreviousValue(roadmapProgress);
        this.roadmapProgressEntries.sort(this.sortProgressDate);
      }
    }
  }

  async saveAll(saveAllBtn?: HTMLButtonElement, e?: any, addNew: boolean = false) {
    let isCancel = false;
    const allPassValidation = true;

    if (e !== null) { e.preventDefault(); }
    if (saveAllBtn !== null) {
      saveAllBtn.disabled = true;
    } else {
      if (addNew === false) {
        isCancel = true; //if button isn't null, then we are calling from open confirmation dialog
      }
    }

    this.roadmapProgressEntries.forEach(async (element,index)=>{
      if (element.editMode) {
        await this.saveRoadmapProgress(null, element); //validation performed here
      }
    });
    if (addNew) { //if Add Progress is clicked
      if (this.checkEditMode()) {
        this.closeModal('progress');
      }
    } else {
      this.roadmapProgressEntries.sort(this.sortProgressDate);
      if (isCancel) {
        this.closeModal('close');
      }
    }
    if (saveAllBtn !== null) { saveAllBtn.disabled = false; }
  };

  checkEditMode(): boolean {
    //if after saving, there are any rows still in edit mode, then validation failed
    let noEditFound = true;
    this.roadmapProgressEntries.forEach((element,index)=>{
      if (element.editMode) {
        noEditFound = false;
      }
    });
    return noEditFound;
  }

  editRoadmapProgress(e: any, roadmapProgress: RoadmapProgressModel) {
    e.preventDefault();
    if (roadmapProgress.editMode === false) {
      roadmapProgress.editMode = true;
      //copy values to a new array to restore in case of cancel
      const tempRoadmapProgress = {...roadmapProgress};
      this.restoreRoadmapProgressEntries.push(tempRoadmapProgress);
    }
  }

  cancelEdit(e: any, roadmapProgress: RoadmapProgressModel) {
    e.preventDefault();
    roadmapProgress.editMode = false;
    this.restoreRoadmapProgressEntries.forEach((element,index)=>{
      if(element.id===roadmapProgress.id) {
          roadmapProgress.status = element.status;
          roadmapProgress.percentageOfPlannedWorkCompleted = element.percentageOfPlannedWorkCompleted;
          roadmapProgress.confidenceLevel = element.confidenceLevel;
          roadmapProgress.currentValueDelivered = element.currentValueDelivered;
          this.removePreviousValue(roadmapProgress);
        }
    });
  }

  async removePreviousValue(roadmapProgress: RoadmapProgressModel) {
    this.restoreRoadmapProgressEntries.forEach((element,index)=>{
      if(element.id===roadmapProgress.id) {
        this.restoreRoadmapProgressEntries.splice(index, 1); //element doesn't work with splice?
      }
    });
  }

  setDefaultEditMode = (): void => {
    this.roadmapProgressEntries.forEach((element,index)=>{
      element.editMode = false;
    });
  };

  openConfirmationDialog(sendData: string) {
    if (this.changesDetected()) {
      this.confirmationDialogService.confirm('Save', 'Do you want to save your changes?')
      .then(async (confirmed) => {
        if (confirmed) { //selected Yes, need to save all plus open a new progress or close the modal
          await this.saveAll(null, null, sendData === 'progress' ? true: false);
        } else { //selected No to save
           this.closeModal(sendData);
        }
      })
      .catch(() => console.error());
    } else { //no changes detected, continue to add progress

       if (sendData === 'close') {
        this.dismissModal();
       } else { //add progress
        this.closeModal(sendData);
       }
    }
  }

  dismissModal(): void {
    this.activeModal.dismiss('View Roadmap Progress cancelled');
  }

  closeModal = (sendData: string): void => {
    if (sendData === 'close') {
      this.activeModal.close(sendData);
      let usePriorityTab = false;
      let useObjectiveId = null;
      let useTabId = null;

      if (this.fromPage === 'roadmapPriority') {
        usePriorityTab = true;
      } else {
        useObjectiveId = this.fromObjectiveId;
        useTabId = '2';
      }
      const tabs = {tabId: useTabId, tabObjectiveId: useObjectiveId, priorityTab: usePriorityTab};
      this.eventEmitterService.retrieveObjectives(false, tabs);
    } else if (sendData === 'progress') { //click add progress button
      this.activeModal.close(sendData);
      this.openAddRoadmapProgressModal();
    }
  };

  changesDetected(): boolean {
    let foundChange = false;
    if (this.restoreRoadmapProgressEntries.length > 0) {
      foundChange = true;
    }
    return foundChange;
  }

  openAddRoadmapProgressModal = (): void => {
    if (this.modalService.hasOpenModals) {
      this.modalService.dismissAll('Open-AddRMProgress');
    }
    const modalRef = this.modalService.open(RoadmapProgressAddComponent,
      {
        windowClass: 'roadmap-modal',
        centered: true,
        keyboard: false,
        backdrop: 'static'
      });

    modalRef.componentInstance.fromModal = 'RMProgressView';
    modalRef.componentInstance.roadmap = this.roadmap;
    modalRef.componentInstance.roadmapType = this.roadmapType;
    modalRef.componentInstance.fromPage = this.fromPage;
    modalRef.componentInstance.fromObjectiveId = this.fromObjectiveId;

    modalRef.result.then((result) => {
      if (result === 'close') {
        let usePriorityTab = false;
        let useObjectiveId = null;
        let useTabId = null;

        if (this.fromPage === 'roadmapPriority') {
          usePriorityTab = true;
        } else {
          useObjectiveId = this.fromObjectiveId;
          useTabId = '2';
        }

        const tabs = {tabId: useTabId, tabObjectiveId: useObjectiveId, priorityTab: usePriorityTab};
        this.eventEmitterService.retrieveObjectives(false, tabs);
      }
    }, (reason) => {
    });
  };

  onKeyDown(inputField: HTMLInputElement, $e: KeyboardEvent): void {
    this.utilsService.onKeyDownNumbers(inputField, $e);
  }

  onPaste(inputField: HTMLInputElement, $e: ClipboardEvent): void {
    this.utilsService.onPasteNumbers(inputField, $e);
  }

  onDrop(inputField: HTMLInputElement, $e: DragEvent): void {
    this.utilsService.onDropNumbers(inputField, $e);
  }

}
