<div class="modal-content"> 
    <div class="modal-header">
        <h2 class="modal-title">{{ roadmapObjectives.length }} OKR(s) for {{ (objectiveType === 'PORTFOLIO') ? 'Portfolio Initiative': 'Roadmap'}}</h2>
        <button type="button" class="btn-close" (click)="dismissModal()"></button>
    </div>
    <div class="modal-body">
        <div class="row pt-2 pb-4">
            <div class="col-12 roadmap">
                {{ roadmapItem.description }}
            </div>
        </div>
        <div *ngFor="let objective of roadmapObjectives; let i = index">
            <ngb-accordion #acc="ngbAccordion" [activeIds]="activeIds" (panelChange)="toggleAccordion($event)">
                <ngb-panel [id]="'heading' + i">
                    <ng-template ngbPanelHeader>
                        <div class="d-flex">
                            <div>
                                <button ngbPanelToggle class="btn btn-link container-fluid pl-0 pt-2 pb-2">
                                    <i class="bi" [ngClass]="acc.isExpanded('heading' + i)? 'bi-caret-down-fill':'bi-caret-right-fill'"></i>
                                </button>
                                </div>
                                <div class="flex-grow-1">
                                <button ngbPanelToggle class="btn btn-link container-fluid pl-0 pt-2 pb-2 text-start ps-0 ">
                                    <span>{{ objective.description }} <span *ngIf="objective.draft"> (Draft)</span></span>
                                </button>
                                </div>
                        </div>
                    </ng-template>
                    <ng-template ngbPanelContent>
                        <div class="row mx-1 mb-1 p-2 flex-nowrap" style="background-color: #d0dae0;"
                        *ngIf="objective.keyResults.length > 0">
                        <div class="col-12">Key Results</div>
                        </div>
                        <div class="row mx-1 mb-1 flex-nowrap border-bottom p-2"
                            *ngFor="let keyResult of objective.keyResults; let kri = index">
                            <div class="col-12">
                                {{ keyResult.name }}
                            </div>
                        </div>
                        </ng-template>
                </ngb-panel>
            </ngb-accordion>
        </div>
    </div>
    <div class="modal-footer">
        <button (click)="dismissModal()" type="button" class="btn btn-secondary">Close</button>
    </div>
</div>
