import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { KeyResultModel } from 'src/app/models/key-result.model';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { KeyResultTrackingService } from 'src/app/services/key-results-tracking.service';
import { NgForm } from '@angular/forms';
import { KeyResultTrackingModel } from 'src/app/models/key-result-tracking.model';
import { KeyResultTrackingComponent } from '../key-result-tracking/key-result-tracking.component';
import { ConfirmationDialogService } from 'src/app/services/confirmation-dialog.service';
import { EventEmitterService } from 'src/app/services/event-emitter.service';
import { UtilsService } from 'src/app/services/utils.service';
import { KeyResultService } from 'src/app/services/key-results.service';

@Component({
  selector: 'app-key-result-tracking-modal',
  templateUrl: './key-result-tracking-modal.component.html',
  styleUrls: ['./key-result-tracking-modal.component.scss']
})
export class KeyResultTrackingModalComponent implements OnInit {
  static componentInstance: any;

  @Input() tempKeyResult: KeyResultModel[];
  @Input() fromModal: string;
  @ViewChild('submitBtn') private buttonSubmit: ElementRef;

  selectedKeyResult = [];
  currentDate: Date = new Date();
  currentYear: number;
  years = [];

  keyResultTracking: KeyResultTrackingModel = {
    quarter: '',
    month: '',
    year: '',
    results: '',
    keyResultId: '',
    confidenceLevel: '',
    comments: '',
    form: null,
    editMode: false
  };

  constructor(private keyResultTrackingService: KeyResultTrackingService,
              public activeModal: NgbActiveModal,
              private modalService: NgbModal,
              private confirmationDialogService: ConfirmationDialogService,
              private eventEmitterService: EventEmitterService,
              private keyresultService: KeyResultService,
              private utilsService: UtilsService) { }

  ngOnInit(): void {
   //when new, build year values with current year and previous year (if we are in January)
    this.currentYear = this.currentDate.getFullYear();
    if (this.currentDate.getMonth() <= 1) { //0 = january
      this.years.push(this.currentYear-1) ;
    }
    this.keyResultTracking.year = String(this.currentYear);
    this.years.push(this.currentYear);
    this.selectedKeyResult.push(this.tempKeyResult); // push into an array
  }

  onKeyDown(inputField: HTMLInputElement, $e: KeyboardEvent): void {
    this.utilsService.onKeyDownNumbers(inputField, $e);
  }

  onPaste(inputField: HTMLInputElement, $e: ClipboardEvent): void {
    this.utilsService.onPasteNumbers(inputField, $e);
  }

  onDrop(inputField: HTMLInputElement, $e: DragEvent): void {
    this.utilsService.onDropNumbers(inputField, $e);
  }

  onSubmit(keyResultTrackingForm: NgForm, submitBtn: HTMLButtonElement): void {
    submitBtn.disabled = true;
    this.keyResultTracking.keyResultId = this.selectedKeyResult[0].id;
    if (this.selectedKeyResult[0].status === 'Not Started') {
      this.selectedKeyResult[0].status = 'Measuring';
      this.keyresultService.update(this.selectedKeyResult[0])
      .subscribe(
        response => {
        },
        error => {
          console.error(error);
        });
    }

    this.keyResultTrackingService.create(this.keyResultTracking).subscribe(
      response => {
        keyResultTrackingForm.resetForm();
        this.activeModal.close('onSubmit-Create');
        if (this.fromModal === 'KRT') {
          this.openKeyResultTracking(this.selectedKeyResult[0]);
        }
      },
      error => {
        console.error(error);
      });
  }

  changesDetected(): boolean {
    let foundChange = false;
    //if any fields are populated, then ask to save.
    //validation will run on simulated submit click
    //don't check year, since it has a default value
    if (this.keyResultTracking.quarter !== '' ||
        this.keyResultTracking.month !== '' ||
        this.keyResultTracking.results.trim() !== '' ||
        this.keyResultTracking.confidenceLevel !== '' ||
        this.keyResultTracking.comments.trim() !== '') {
            foundChange = true;
        }

    return foundChange;
  }

  openConfirmationDialog = (sendData: string, keyResultTrackingForm: NgForm) => {
    if (this.changesDetected()) {
      this.confirmationDialogService.confirm('Save', 'Do you want to save your changes?')
      .then((confirmed) => {
        if (confirmed) { //selected Yes
          this.buttonSubmit.nativeElement.click();
          if (keyResultTrackingForm.form.valid) { // and passes validation
            this.closeModal(sendData, keyResultTrackingForm);
          }
        } else { //selected No
          this.closeModal(sendData, keyResultTrackingForm);
        }
      })
      .catch(() => console.error());
    } else { //no changes detected
      this.closeModal(sendData, keyResultTrackingForm);
    }
  };

  closeModal = (sendData: string, keyResultTrackingForm: NgForm): void => {
    if (sendData === 'cancel') {
      keyResultTrackingForm.resetForm();
      this.activeModal.close(sendData);
    } else if (sendData === 'progress') {
      //viewing all progress
      keyResultTrackingForm.resetForm();
      this.activeModal.close(sendData);
      this.openKeyResultTracking(this.selectedKeyResult[0]);
    }
  };

  openKeyResultTracking = (thisKeyResult: KeyResultModel): void => {
    if (this.modalService.hasOpenModals) {
      this.modalService.dismissAll('Open-KRT');
    }
    const modalRef = this.modalService.open(KeyResultTrackingComponent,
      {
        windowClass: 'customModalClass',
        centered: true,
        keyboard: false,
        backdrop: 'static'
      });

    modalRef.componentInstance.tempKeyResult = thisKeyResult;

    modalRef.result.then((result) => {
    }, (reason) => {
    });
  };

}
