<div class="container">
    <div class="row">
        <div class="col-sm box text-center counter-section">
            <span class="fs-4">{{ numObjectives }}</span>
            <p class="fw-bold">Objectives</p>
        </div>
        <div class="col-sm box text-center counter-section">
            <span class="fs-4">{{ numKeyResults }}</span>
            <p class="fw-bold">Key Results</p>
        </div>
        <div class="col-sm box text-center counter-section">
            <span class="fs-4">{{ numNotStarted }}</span>
            <p class="fw-bold">KRs Not Started</p>
        </div>
        <div class="col-sm box text-center counter-section">
            <span class="fs-4">{{ numMeasuring }}</span>
            <p class="fw-bold">KRs Measuring</p>
        </div>
        <div class="col-sm box text-center counter-section">
            <span class="fs-4">{{ numDone }}</span>
            <p class="fw-bold">KRs Done</p>
        </div>
    </div>
</div>
