import { LowerCasePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ObjectiveModel } from 'src/app/models/objective.model';
import { ProductModel } from 'src/app/models/product.model';
import { ObjectiveService } from 'src/app/services/objective.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { PingAuthenticationService } from '@techops-ui/ping-authentication';
import { GraphqlQueriesService, UserSearchResult } from 'src/app/services/graphql-queries.service';
import { ProductGroupModel } from '../../models/product-group.model';
import { ProductService } from 'src/app/services/product.service';
import { ProductGroupService } from 'src/app/services/productGroup.service';



@Component({
  selector: 'app-objectivemodal',
  templateUrl: './objectivemodal.component.html',
  styleUrls: ['./objectivemodal.component.scss']
})

export class ObjectivemodalComponent implements OnInit {
  static componentInstance: any;

  @Input() objectiveType: string;
  @Input() selectedProduct: ProductModel;
  @Input() selectedProductGroup: ProductGroupModel;
  @Input() editObjective: ObjectiveModel[];
  @Input() planningPortfolioId: number;
  @Input() planningPortfolioName: string;

  objective: ObjectiveModel = {
    objectiveType: '',
    portfolioGroupId: '',
    productPortfolioId: '',
    productGroupId: '',
    productId: '',
    ownerId: '',
    userId: '',
    fundingUnit: '',
    portfolioObjectiveId: '',
    enterpriseObjectiveId: '',
    description: '',
    draft: true,
    form: null,
    productType: '',
    planningPortfolioId: null,
    status: ''
  };

  selectedOwnerId: string;
  selectedOwnerName: string;
  lowerObjectiveType: string;
  lowercasePipe = new LowerCasePipe();
  saveType: string;
  userSearchResults: UserSearchResult[];
  keyword = 'name';
  loading = false;
  portfolioObjectives: ObjectiveModel[] = [];
  enterpriseObjectives: ObjectiveModel[] = [];
  products: ProductModel[] = [];
  productGroups: ProductGroupModel[] = [];

  statusList = [
    'Not Started',
    'Measuring',
    'Done',
    'Deprioritized',
    'Archive'
  ];

  constructor(
    private productService: ProductService,
    private productGroupService: ProductGroupService,

    private objectiveService: ObjectiveService,
    private authService: PingAuthenticationService,
    private graphqlQueriesService: GraphqlQueriesService,
    public activeModal: NgbActiveModal) { }

  ngOnInit(): void {


    if (this.objectiveType !== 'ENTERPRISE') {
      this.authService.profile$.subscribe(p => {
        this.objective.ownerId = p.BadgeNo.substring(2);
        this.loadData();
      });
    }
    this.lowerObjectiveType = this.lowercasePipe.transform(this.objectiveType);
    this.initObjective();
  }

  async loadData() {
    this.products = await this.productService.getAllProducts().toPromise();
    this.productGroups = await this.productGroupService.getAllProductGroups().toPromise();
  }

  initObjective = (): void => {
    if (this.editObjective) {
      // edit objective
      const currentObjective = [];
      currentObjective.push(this.editObjective); // push into an array otherwise have to use currentObjective['productId']
      if (currentObjective[0].draft) {  // button label
        this.saveType = 'Submit';
      } else {
        this.saveType = 'Save';
      }
      this.objective.id = currentObjective[0].id;
      this.objective.description = currentObjective[0].description;
      this.objective.productId = currentObjective[0].productId;
      this.objective.portfolioGroupId = currentObjective[0].portfolioGroupId;
      this.objective.productPortfolioId = currentObjective[0].productPortfolioId;
      this.objective.productGroupId = currentObjective[0].productGroupId;
      this.objective.fundingUnit = currentObjective[0].fundingUnit;
      this.objective.objectiveType = currentObjective[0].objectiveType;
      this.objective.ownerId = currentObjective[0].ownerId;
      this.objective.description = currentObjective[0].description;
      this.objective.enterpriseObjectiveId = currentObjective[0].enterpriseObjectiveId;
      this.objective.draft = currentObjective[0].draft;
      this.objective.portfolioObjectiveId = currentObjective[0].portfolioObjectiveId;
      this.objective.productType = currentObjective[0].productType;
      this.objective.planningPortfolioId = currentObjective[0].planningPortfolioId;
      this.objective.status = currentObjective[0].status;
    } else {
      // new objective
      this.objective.status = 'Not Started';
      this.saveType = 'Submit';
      this.objective.objectiveType = this.objectiveType;
      if (this.objectiveType === 'PRODUCT') {
        this.objective.productId = this.selectedProduct.id;
        this.objective.portfolioGroupId = this.selectedProduct.productGroup.productPortfolio.portfolioGroup.id;
        this.objective.productPortfolioId = this.selectedProduct.productGroup.productPortfolio.id;
        this.objective.productGroupId = this.selectedProduct.productGroup.id;
        this.objective.fundingUnit = this.selectedProduct.productGroup.fundingUnit;
      }
      if (this.objectiveType === 'PRODUCT_GROUP') {
        this.objective.portfolioGroupId = this.selectedProductGroup.productPortfolio.portfolioGroup.id;
        this.objective.productPortfolioId = this.selectedProductGroup.productPortfolio.id;
        this.objective.productGroupId = this.selectedProductGroup.id;
        this.objective.fundingUnit = this.selectedProductGroup.fundingUnit;
      }
    }
    if (this.objectiveType === 'PRODUCT' || this.objectiveType === 'PRODUCT_GROUP') { this.retrieveObjectives('PORTFOLIO'); }
    if (this.objectiveType === 'PORTFOLIO') { this.retrieveObjectives('ENTERPRISE'); }
    this.searchUser(this.objective.ownerId);
  };

  retrieveObjectives = (objType: string): void => {
    if (objType === 'PORTFOLIO') {
      this.objectiveService.getAllPortfolioByPlanningPortfolio(this.planningPortfolioId).subscribe(
        (data) => {
          this.portfolioObjectives = data;
        }
      );
    } else if (objType === 'ENTERPRISE') {
      this.objectiveService.getAll(objType, 'false').subscribe(
        (data) => {
          this.enterpriseObjectives = data;
        },
        (error) => {
          console.error(error);
        }
      );
    }
  };

  onSubmit(objectiveForm: NgForm, submitBtn: HTMLButtonElement): void {
    if (submitBtn !== null) { submitBtn.disabled = true; }

    this.authService.profile$.subscribe(p => {
      this.objective.userId = p.BadgeNo.substring(2);
    });

    if (!this.editObjective) {
      this.objective.objectiveType = this.objectiveType;
    }

    this.objective.draft = false;
    if (this.selectedOwnerId) {
      this.objective.ownerId = this.selectedOwnerId;
    }

    if (this.objectiveType === 'PORTFOLIO') {
      this.objective.planningPortfolioId = this.planningPortfolioId;
    }

    if (this.editObjective) {
      this.objectiveService.update(this.objective).subscribe(
        response => {
          objectiveForm.resetForm();
          this.activeModal.close('onSubmit-Update');
        },
        error => {
          console.error(error);
        });
    } else {
      this.objectiveService.create(this.objective).subscribe(
        response => {
          objectiveForm.resetForm();
          this.activeModal.close('onSubmit-Create');
        },
        error => {
          console.error(error);
        });
    }
  }

  saveAsDraft(objectiveForm: NgForm): void {
    this.objective.objectiveType = this.objectiveType;
    this.objective.draft = true;
    if (this.objectiveType === 'PORTFOLIO') {
      this.objective.planningPortfolioId = this.planningPortfolioId;
    }
    if (this.selectedOwnerId) {
      this.objective.ownerId = this.selectedOwnerId;
    }
    if (this.editObjective) {
      this.objectiveService.update(this.objective).subscribe(
        response => {
          objectiveForm.resetForm();
          this.activeModal.close('saveAsDraft-Update');
        },
        error => {
          console.error(error);
        });
    } else {
      this.objectiveService.create(this.objective).subscribe(
        response => {
          objectiveForm.resetForm();
          this.activeModal.close('saveAsDraft-Create');
        },
        error => {
          console.error(error);
        });
    }
  }

  searchUser(search: string) {
    if (search?.length >= 3) {
      this.graphqlQueriesService.getOwnerName(search).subscribe((result: any) => {
        this.loading = result?.loading;
        this.userSearchResults = result?.data?.userSearch?.users
          .map(user => ({
            name: `${user.displayName} [${user.employeeNumber}]`,
            value: `${user.employeeNumber}`
          }));
        if (this.userSearchResults.length === 1) {
          this.selectedOwnerName = this.userSearchResults[0].name;
        }
      });
    }
  }

  selectUser(event: any) {
    this.selectedOwnerId = event.value;
    this.selectedOwnerName = event.name;
  }

  closeModal = (sendData: string, objectiveForm: NgForm, draftBtn: HTMLButtonElement): void => {
    if (sendData === 'cancel') {
      objectiveForm.resetForm();
      this.activeModal.close(sendData);
    } else if (sendData === 'saveAsDraft') {
      draftBtn.disabled = true;
      this.saveAsDraft(objectiveForm);
    }
  };

  onTypeChange() {
    if (this.objective.objectiveType === 'PRODUCT_GROUP') {
      this.objective.productId = null;
    } else {
      this.objective.productGroupId = this.products.find(p => p.id === this.objective.productId).productGroup.id;
    }
  }

}
