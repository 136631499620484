<div class="position-relative" *ngIf="pageLoaded | async">
  <div *ngIf="this.objectiveType ==='ENTERPRISE'">
    <div class="row pb-2">
      <div class="col-md-7">
        <h1>Enterprise Objectives </h1>
      </div>
      <div class="col-md-5">
        <div *ngIf="this.objectives" class="col-sm">
          <app-insights numObjectives="{{ numObjectives }}" numKeyResults="{{ numKeyResults }}"
            numNotStarted="{{ numNotStarted }}" numMeasuring="{{ numMeasuring }}" numDone="{{ numDone }}">
          </app-insights>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="this.objectiveType ==='PORTFOLIO'">
    <div class="row pb-2">
      <div class="col-md-7">
        <h1>Planning Portfolio: {{ planningPortfolioName }}</h1>
        <span class="spanJump">
          <select id="jumpPGL" class="jump jump-product-group d-inline-block" name="jumpPGL" #jumpPGL (change)="routeTo('/product-group-objectives/'+jumpPGL.value)">
            <option value="" disabled selected>Jump to Product Groups</option>
            <ng-container *ngFor="let pgl of productGroupJumpToList">
              <option value={{pgl.id}}>{{pgl.name}}</option>
            </ng-container>
          </select>
        </span>
        <span class="spanJump">
          <select id="jumpPP" class="jump d-inline-block" name="jumpPP" #jumpPP (change)="routeTo('/portfolio-objectives/'+jumpPP.value)">
            <option value="" disabled selected>See all Planning Portfolios</option>
            <ng-container *ngFor="let pp of planningPortfolios">
              <option *ngIf="this.planningPortfolioName !== pp.name" value={{pp.id}}>{{pp.name}}</option>
            </ng-container>
          </select>
        </span>
      </div>
      <div class="col-md-5">
        <div *ngIf="this.objectives" class="col-sm">
          <app-insights numObjectives="{{ numObjectives }}" numKeyResults="{{ numKeyResults }}"
            numNotStarted="{{ numNotStarted }}" numMeasuring="{{ numMeasuring }}" numDone="{{ numDone }}">
          </app-insights>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="this.objectiveType === 'PRODUCT'">
    <div class="row pb-2">
      <div class="col-md-7">
        <h1>Product: {{ this.selectedProduct.name }}</h1>
        <div class="col-sm text-start pt-2">
          <h4><i class="bi-heart-fill me-3"></i>{{
            this.selectedProduct.productGroup.productPortfolio.portfolioGroup.name }}
            > <a [href]="'/product-group-objectives/' + this.selectedProduct.productGroup.id">{{
              this.selectedProduct.productGroup.name }}</a></h4>
        </div>
        <span class="spanJump">
          <select id="jumpP" class="jump jump-product d-inline-block" name="jumpP" #jumpP (change)="routeTo('/product-objectives/'+jumpP.value)">
            <option value="" disabled selected>See all Products</option>
            <ng-container *ngFor="let product of products">
              <option *ngIf="this.selectedProduct.id !== product.id" value={{product.id}}>{{product.name}}</option>
            </ng-container>
          </select>
        </span>
      </div>
      <div class="col-md-5">
        <div *ngIf="this.objectives">
          <app-insights numObjectives="{{ numObjectives }}" numKeyResults="{{ numKeyResults }}"
            numNotStarted="{{ numNotStarted }}" numMeasuring="{{ numMeasuring }}" numDone="{{ numDone }}">
          </app-insights>
          <app-refresh-signal [employeeNumber]="this.empNumber" [taxonomyId]="selectedProduct.id" taxonomyType="PRODUCT"></app-refresh-signal>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="this.objectiveType === 'PRODUCT_GROUP'">
    <div class="row pb-2">
      <div class="col-md-7">
        <h1>Product Group: {{ this.selectedProductGroup.name }}</h1>
        <div class="col-sm text-start pt-2">
          <h4><i class="bi-heart-fill me-3"></i>
          <a [href]="'/portfolio-objectives/' + this.planningPortfolioId">{{this.selectedProductGroup.productPortfolio.portfolioGroup.name}}</a>
          </h4>
        </div>
        <span class="spanJump">
          <select id="jumpPGP" class="jump jump-product p-1" name="jumpPGP" #jumpPGP (change)="routeTo('/product-objectives/'+jumpPGP.value)">
            <option value="" disabled selected>Jump to Products</option>
            <ng-container *ngFor="let product of products">
              <option *ngIf="product.productGroup.id === selectedProductGroup.id" value={{product.id}}>{{product.name}}</option>
            </ng-container>
          </select>
        </span>
        <span class="spanJump">
          <select id="jumpPG" class="jump jump-product-group p-1" name="jumpPG" #jumpPG (change)="routeTo('/product-group-objectives/'+jumpPG.value)">
            <option value="" disabled selected>See all Product Groups</option>
            <ng-container *ngFor="let productGroup of productGroups">
              <option *ngIf="this.selectedProductGroup.id !== productGroup.id" value={{productGroup.id}}>{{productGroup.name}}</option>
            </ng-container>
          </select>
        </span>
      </div>
      <div class="col-md-5">
        <div *ngIf="this.objectives">
          <app-insights numObjectives="{{ numObjectives }}" numKeyResults="{{ numKeyResults }}"
            numNotStarted="{{ numNotStarted }}" numMeasuring="{{ numMeasuring }}" numDone="{{ numDone }}">
          </app-insights>
          <app-refresh-signal [employeeNumber]="this.empNumber" [taxonomyId]="selectedProductGroup.id" taxonomyType="PRODUCT_GROUP"></app-refresh-signal>
        </div>
      </div>
    </div>
  </div>
  <mat-tab-group [selectedIndex]="selectedTabIndex" mat-stretch-tabs="false" mat-align-tabs="start">
    <mat-tab>
      <ng-template mat-tab-label>
        <span class="mt-2">
          <h3><i class="bi bi-compass me-2"></i></h3>
        </span>
        OKR & Roadmap
      </ng-template>
      <div *ngIf="this.objectives" class="padObjectives">
        <div class="row mt-0 pb-2 flex-nowrap align-items-top">
          <div class="col-2 text-start">
            <button type="button" class="btn btn-primary btn-sm mt-3" (click)="openObjectiveModal()">
              <i class="bi-plus"></i>Add Objective
            </button>
          </div>
          <div class="col-7 text-end mt-3">
            <div class="input-group">
              <span class="input-group-prepend mt-1 mx-2">
                <i title="filter by status" class="bi bi-funnel-fill fs-5"></i>
              </span>
              <ng-select
                class="filterSelect"
                [items]="statusList"
                bindLabel="name"
                bindValue="name"
                placeholder="Filter Objective Status"
                appendTo="body"
                [multiple]="true"
                [(ngModel)]="filterStatus"
                (change)="filterObjectives()">
              </ng-select>
              </div>
          </div>
          <div class="col-3 text-end mt-4">
              <button
              (click)="expandAll($event)"
              class="link-button">Expand All</button>/<button
              (click)="activeIds=[]"
              class="link-button">Collapse All</button>
          </div>
        </div>
        <div *ngIf="!this.objectives || this.objectives.length === 0">
          <hr>
          <h4>No objectives found</h4>
        </div>
        <div *ngIf="this.objectiveType === 'ENTERPRISE' || this.objectiveType === 'PORTFOLIO'">
          <div *ngFor="let objective of objectives; let i = index">
            <app-objective-item [objectiveType]="objectiveType" [objective]="objective" [index]="i"
              [onEditObjective]="editObjective" [onEditKeyResult]="editKeyResult"
              [onOpenKeyResultModal]="openKeyResultModal" [onOpenKeyResultTracking]="openKeyResultTracking" [onCopyKeyResultObjective]="copyKeyResultObjective"
              [onDuplicateKeyResult]="duplicateKeyResult" [onOpenObjectivesLog]="openObjectivesLog" [onOpenRoadmapsLog]="openRoadmapsLog" [activeIds]="activeIds" [onToggleAccordion]="toggleAccordion"
              [activeTabId]="(objective.id === activeTabObjectiveId) ? activeTabId: 1"
              [onReload]="retrieveObjectives" [selectedProduct]="selectedProduct" [selectedProductGroup]="selectedProductGroup"
              [planningPortfolioId]="planningPortfolioId" [planningPortfolioName]="planningPortfolioName"
              [readOnly]="objectiveType === 'PRODUCT' && objective.objectiveType === 'PRODUCT_GROUP'"
              *ngIf="canShowObjective(objective)"
              [onDeleteObjective]="deleteObjective">
            </app-objective-item>
          </div>
        </div>
        <div *ngIf="this.objectiveType === 'PRODUCT_GROUP'">
          <div *ngFor="let objective of allProductGroupObjectives; let i = index">
            <app-objective-item [objectiveType]="objectiveType" [objective]="objective" [index]="'PRODUCT_GROUP'+i"
              [onEditObjective]="editObjective" [onEditKeyResult]="editKeyResult"
              [onOpenKeyResultModal]="openKeyResultModal" [onOpenKeyResultTracking]="openKeyResultTracking" [onCopyKeyResultObjective]="copyKeyResultObjective"
              [onDuplicateKeyResult]="duplicateKeyResult" [onOpenObjectivesLog]="openObjectivesLog" [onOpenRoadmapsLog]="openRoadmapsLog" [activeIds]="activeIds" [onToggleAccordion]="toggleAccordion"
              [activeTabId]="(objective.id === activeTabObjectiveId) ? activeTabId: 1"
              [onReload]="retrieveObjectives" [selectedProduct]="selectedProduct" [selectedProductGroup]="selectedProductGroup"
              [planningPortfolioId]="planningPortfolioId" [planningPortfolioName]="planningPortfolioName"
              [readOnly]="objectiveType === 'PRODUCT' && objective.objectiveType === 'PRODUCT_GROUP'"
              *ngIf="canShowObjective(objective)"
              [onDeleteObjective]="deleteObjective">
            </app-objective-item>
          </div>
          <div *ngIf="mixedContentWarning" class="pt-2 mixed-content-alert">
            <div class="mixed-content-alert__icon-box">
              <i class="bi bi-exclamation-triangle mixed-content-alert__icon"></i>
            </div>
            <div class="mixed-content-alert__content">
              <p class="mixed-content-alert__heading">You have a mix of product and product group OKRs</p>
              <p *ngIf="this.objectiveType === 'PRODUCT_GROUP'">When planning at the product group level, all products within that product group will share the same OKRs. To
                edit any product OKRs, please go to that product’s page.</p>
              <p *ngIf="this.objectiveType === 'PRODUCT'">When planning at the product level, there should only be product OKRs. To edit any product group OKRs, please
                go to your product group page.</p>
            </div>
          </div>
          <div *ngFor="let objective of allProductObjectives; let i = index">
            <app-objective-item [objectiveType]="objectiveType" [objective]="objective" [index]="'PRODUCT'+i"
              [onEditObjective]="editObjective" [onEditKeyResult]="editKeyResult"
              [onOpenKeyResultModal]="openKeyResultModal" [onOpenKeyResultTracking]="openKeyResultTracking" [onCopyKeyResultObjective]="copyKeyResultObjective"
              [onDuplicateKeyResult]="duplicateKeyResult" [onOpenObjectivesLog]="openObjectivesLog" [onOpenRoadmapsLog]="openRoadmapsLog" [activeIds]="activeIds" [onToggleAccordion]="toggleAccordion"
              [activeTabId]="(objective.id === activeTabObjectiveId) ? activeTabId: 1"
              [onReload]="retrieveObjectives" [selectedProduct]="selectedProduct" [selectedProductGroup]="selectedProductGroup"
              [planningPortfolioId]="planningPortfolioId" [planningPortfolioName]="planningPortfolioName"
              [readOnly]="objectiveType === 'PRODUCT' && objective.objectiveType === 'PRODUCT_GROUP'"
              *ngIf="canShowObjective(objective)"
              [onDeleteObjective]="deleteObjective">
            </app-objective-item>
          </div>
        </div>
        <div *ngIf="this.objectiveType === 'PRODUCT'">
          <div *ngFor="let objective of allProductObjectives; let i = index">
            <app-objective-item [objectiveType]="objectiveType" [objective]="objective" [index]="'PRODUCT'+i"
              [onEditObjective]="editObjective" [onEditKeyResult]="editKeyResult"
              [onOpenKeyResultModal]="openKeyResultModal" [onOpenKeyResultTracking]="openKeyResultTracking" [onCopyKeyResultObjective]="copyKeyResultObjective"
              [onDuplicateKeyResult]="duplicateKeyResult" [onOpenObjectivesLog]="openObjectivesLog" [onOpenRoadmapsLog]="openRoadmapsLog" [activeIds]="activeIds" [onToggleAccordion]="toggleAccordion"
              [activeTabId]="(objective.id === activeTabObjectiveId) ? activeTabId: 1"
              [onReload]="retrieveObjectives" [selectedProduct]="selectedProduct" [selectedProductGroup]="selectedProductGroup"
              [planningPortfolioId]="planningPortfolioId" [planningPortfolioName]="planningPortfolioName"
              [readOnly]="objectiveType === 'PRODUCT' && objective.objectiveType === 'PRODUCT_GROUP'"
              *ngIf="canShowObjective(objective)"
              [onDeleteObjective]="deleteObjective">
            </app-objective-item>
          </div>
          <div *ngIf="mixedContentWarning" class="pt-2 mixed-content-alert">
            <div class="mixed-content-alert__icon-box">
              <i class="bi bi-exclamation-triangle mixed-content-alert__icon"></i>
            </div>
            <div class="mixed-content-alert__content">
              <p class="mixed-content-alert__heading">You have a mix of product and product group OKRs</p>
              <p *ngIf="this.objectiveType === 'PRODUCT_GROUP'">When planning at the product group level, all products within that product group will share the same OKRs. To
                edit any product OKRs, please go to that product’s page.</p>
              <p *ngIf="this.objectiveType === 'PRODUCT'">When planning at the product level, there should only be product OKRs. To edit any product group OKRs, please
                go to your product group page.</p>
            </div>
          </div>
          <div *ngFor="let objective of allProductGroupObjectives; let i = index">
            <app-objective-item [objectiveType]="objectiveType" [objective]="objective" [index]="'PRODUCT_GROUP'+i"
              [onEditObjective]="editObjective" [onEditKeyResult]="editKeyResult"
              [onOpenKeyResultModal]="openKeyResultModal" [onOpenKeyResultTracking]="openKeyResultTracking" [onCopyKeyResultObjective]="copyKeyResultObjective"
              [onDuplicateKeyResult]="duplicateKeyResult" [onOpenObjectivesLog]="openObjectivesLog" [onOpenRoadmapsLog]="openRoadmapsLog" [activeIds]="activeIds" [onToggleAccordion]="toggleAccordion"
              [activeTabId]="(objective.id === activeTabObjectiveId) ? activeTabId: 1"
              [onReload]="retrieveObjectives" [selectedProduct]="selectedProduct" [selectedProductGroup]="selectedProductGroup"
              [planningPortfolioId]="planningPortfolioId" [planningPortfolioName]="planningPortfolioName"
              [readOnly]="objectiveType === 'PRODUCT' && objective.objectiveType === 'PRODUCT_GROUP'"
              *ngIf="canShowObjective(objective)"
              [onDeleteObjective]="deleteObjective">
            </app-objective-item>
          </div>
        </div>
      </div>
    </mat-tab>
    <mat-tab *ngIf="objectiveType!=='ENTERPRISE'">
      <ng-template mat-tab-label>
        <span class="mt-2">
          <h3><i class="bi bi-map me-2"></i></h3>
        </span>
        {{ (objectiveType === 'PORTFOLIO') ? 'Portfolio Initiative Prioritization':'Roadmap Prioritization'}}
      </ng-template>
      <app-roadmap-priority [objectiveType]="objectiveType" [allPortfolioObjectives]="objectives" [allProductObjectives]="allProductObjectives"
        [allProductGroupObjectives]="allProductGroupObjectives" [productId]="productId" [planningPortfolioId]="planningPortfolioId"
        [productGroupId]="[(objectiveType==='PORTFOLIO') ? '' : (objectiveType === 'PRODUCT') ? selectedProduct.productGroup.id : selectedProductGroup.id]"
        [allProducts]="products" [allProductGroups]="productGroups" [roadmapType]="roadmapType" [onReload]="retrieveObjectives">
      </app-roadmap-priority>
    </mat-tab>
    <mat-tab *ngIf="objectiveType!=='PORTFOLIO'">
      <ng-template mat-tab-label>
        <span class="mt-2">
          <h3><i class="bi bi-binoculars me-2"></i></h3>
        </span>
        Vision
      </ng-template>
      <app-vision-statement objectiveType={{objectiveType}} [selectedProduct]="selectedProduct"
        [selectedProductGroup]="selectedProductGroup" [planningPortfolioId]="planningPortfolioId"></app-vision-statement>
    </mat-tab>
  </mat-tab-group>
</div>