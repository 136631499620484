<div *ngIf="pageLoaded | async">

    <div class="d-flex flex-column align-items-center align-items-sm-start px-3 pt-2 text-white min-vh-100">
        <span><a href="/"><img src="assets/okraa-logo-full.svg" alt="OKRs & Roadmaps Logo" class="d-flex align-items-center pb-3 mb-md-0 me-md-auto d-none d-sm-block" /></a></span>
        <span><a href="/"><img src="assets/okraa-logo-mobile.svg" alt="OKRs & Roadmaps Logo" class="d-flex align-items-center pb-3 mb-md-0 me-md-auto d-block d-sm-none" /></a></span>
        <div class="dropdown pb-4 mt-3">
            <a href="#" class="d-flex align-items-center text-white text-decoration-none dropdown-toggle" id="dropdownUser1" data-bs-toggle="dropdown" aria-expanded="false">
                <i class="bi-person-circle h3"></i>
                <span class="d-none d-sm-inline mx-3">{{userName}}</span>
            </a>
            <ul class="dropdown-menu dropdown-menu-light text-small shadow" aria-labelledby="dropdownUser1">
                <li><a class="dropdown-item" href="/user-profile">Profile</a></li>
                <li>
                    <hr class="dropdown-divider">
                </li>
                <li><a class="dropdown-item" href="/logout">Sign out</a></li>
            </ul>
        </div>
        <div style="width: 100%;">
            <ul class="nav nav-pills flex-column mb-sm-auto mb-0 align-items-center align-items-sm-start" id="menu">
                <li class="nav-item">
                    <a href="/enterprise-objectives" class="nav-link align-middle px-0 text-white">
                        <i class="fs-4 bi-building text-white"></i> <span class="ms-2 d-none d-sm-inline" title="Enterprise">Enterprise</span>
                    </a>
                </li>
                <li class="nav-item">
                    <a [href]="portfolioLink" class="nav-link align-middle px-0 text-white">
                        <i class="fs-4 bi-card-text text-white"></i> <span class="ms-2 d-none d-sm-inline" title="Portfolio">Portfolio</span>
                    </a>
                </li>
                <li class="nav-item">
                    <a [href]="productGroupLink" class="nav-link align-middle px-0 text-white">
                        <i class="fs-4 bi-boxes text-white"></i> <span class="ms-2 d-none d-sm-inline" title="Product Group">Product Group</span>
                    </a>
                </li>
                <li class="nav-item">
                    <a [href]="productLink" class="nav-link align-middle px-0 text-white">
                        <i class="fs-4 bi-box text-white"></i> <span class="ms-2 d-none d-sm-inline" title="Product">Product</span>
                    </a>
                </li>
                <div style="width: 100%; background-color: #ffffff; line-height: 1px; margin-top: 2px; margin-bottom: 2px;">&nbsp;</div>
                <li class="nav-item">
                    <a href="https://wiki.aa.com/bin/view/Delivery%20Transformation%20Journey/DT%20Journey%20:%20Objectives%20and%20Key%20Results%20(OKRs)/A%20simpler%20way%20to%20manage%20OKRs/" target="_blank" rel="noreferrer noopener" class="nav-link align-middle px-0 text-white" title="What's New">
                        <i class="fs-4 bi-star" style="color:yellow;"></i> <span class="ms-2 me-2 d-none d-sm-inline">What's New?</span><i class="fs-sm bi-box-arrow-up-right text-white d-none d-sm-inline"></i>
                    </a>
                </li>
                <li class="nav-item">
                    <a href="https://wiki.aa.com/bin/view/Igniters%20Team%20Homepage/Agile%20Work%20Management%20Model%20/" target="_blank" rel="noreferrer noopener" title='Agile Work Management Model & Tools' class="nav-link align-middle px-0 text-white">
                        <i class="fs-4 bi-map text-white"></i> <span class="ms-2 me-2 d-none d-sm-inline">Agile Work Management Model</span><i class="fs-sm bi-box-arrow-up-right text-white d-none d-sm-inline"></i>
                    </a>
                </li>
                <li class="nav-item">
                    <a href="https://wiki.aa.com/bin/view/Coaching%20Toolbox/OKR%20Details/" target="_blank" rel="noreferrer noopener" title='"U" Model Steps' class="nav-link align-middle px-0 text-white">
                        <i class="fs-4 bi-bucket text-white"></i> <span class="ms-2 me-2 d-none d-sm-inline">"U" Model Steps</span><i class="fs-sm bi-box-arrow-up-right text-white d-none d-sm-inline"></i>
                    </a>
                </li>
                <li class="nav-item">
                    <a href="https://wiki.aa.com/bin/view/Product%20Management%20Practices/OKR%20Fundamentals/" target="_blank" rel="noreferrer noopener" title="OKR Fundamentals" class="nav-link align-middle px-0 text-white">
                        <i class="fs-4 bi-info-circle text-white"></i> <span class="ms-2 me-2 d-none d-sm-inline">OKR Fundamentals</span><i class="fs-sm bi-box-arrow-up-right text-white d-none d-sm-inline"></i>
                    </a>
                </li>
                <li class="nav-item">
                    <a href="https://wiki.aa.com/bin/view/ProductTaxonomy/" target="_blank" rel="noreferrer noopener" title="Taxonomy" class="nav-link align-middle px-0 text-white">
                        <i class="fs-4 bi-pin-map text-white"></i> <span class="ms-2 me-2 d-none d-sm-inline">Taxonomy</span><i class="fs-sm bi-box-arrow-up-right text-white d-none d-sm-inline"></i>
                    </a>
                </li>
                <div style="width: 100%; background-color: #ffffff; line-height: 1px; margin-top: 2px; margin-bottom: 2px;">&nbsp;</div>
              
                <li class="nav-item">
                    <a  id = "lnk-feedback" onmouseover="this.style.cursor='pointer'"  rel="noreferrer noopener" class="nav-link align-middle px-0 text-white" title="Contact Us">
                        <i class="fs-4 bi-mic text-white"></i> <span class="ms-2 me-2 d-none d-sm-inline">Contact Us</span><i class="fs-sm bi-box-arrow-up-right text-white d-none d-sm-inline"></i>
                    </a>
                </li>
            </ul>
        </div>
    </div>
</div>