import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-enterprise-objectives',
  templateUrl: './enterprise-objectives.component.html',
  styleUrls: ['./enterprise-objectives.component.scss']
})
export class EnterpriseObjectivesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
