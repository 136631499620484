import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { BacklogToProductModel } from '../models/backlog-to-product.model';

@Injectable({
  providedIn: 'root',
})
export class BacklogToProductsService {
  backlogToProducts: Observable<BacklogToProductModel[]>;

  constructor(@Inject(HttpClient) private http: HttpClient) {}
  getAllbacklogToProducts(
    productId: string
  ): Observable<BacklogToProductModel[]> {
    this.backlogToProducts = this.http.get<BacklogToProductModel[]>(
      `${environment.baseURL}/backlogtoproducts/${productId}`
    );
    return this.backlogToProducts;
  }


  getAllbacklogToProductGroups(
    productgroupId: string
  ): Observable<BacklogToProductModel[]> {
    this.backlogToProducts = this.http.get<BacklogToProductModel[]>(
      `${environment.baseURL}/backlogtoproducts/${productgroupId}`
    );
    return this.backlogToProducts;
  }



}
