import { Component, Input, OnInit } from '@angular/core';
import { RefreshSignalModel } from 'src/app/models/refresh-signal.model';
import { RefreshSignalService } from 'src/app/services/refresh-signal.service';
import { SiteSettingsService } from 'src/app/services/site-settings.service';

@Component({
  selector: 'app-refresh-signal',
  templateUrl: './refresh-signal.component.html',
  styleUrls: ['./refresh-signal.component.scss']
})
export class RefreshSignalComponent implements OnInit {

  @Input() taxonomyId: string;
  @Input() taxonomyType: string;
  @Input() employeeNumber: string;

  refreshMessageFalse = 'Have you refreshed your OKRs? (click when complete)';
  refreshMessageTrue = 'OKRs Refreshed!';
  refreshMessage: string;
  refreshStatus = false;
  refreshSignal: RefreshSignalModel;
  loaded = Promise.resolve(false);

  constructor(private refreshSignalService: RefreshSignalService,
            private siteSettingsService: SiteSettingsService) { }

  ngOnInit(): void {
    this.loadData();
  }

  async loadData(){
    if (await this.siteSettingsService.checkRefreshDates().toPromise()) { //if today is within start/end dates for refreshing
      if (this.taxonomyId) {
        this.refreshSignal = await this.refreshSignalService.getRefreshSignalByTaxonomyId(String(this.taxonomyId)).toPromise();
        if (this.refreshSignal) {
          if (this.refreshSignal.status === 'Refreshed') {
            this.refreshMessage = this.refreshMessageTrue;
            this.refreshStatus = true;
          } else {
            this.refreshMessage = this.refreshMessageFalse;
          }
        } else { //doesn't exist, create it
          this.refreshSignal = {
            taxonomyId: String(this.taxonomyId),
            taxonomyType: this.taxonomyType,
            status: 'Not Refreshed'
          };
          await this.refreshSignalService.create(this.refreshSignal).toPromise();
          this.refreshMessage = this.refreshMessageFalse;
        }
      }
      this.loaded = Promise.resolve(true);
    }
  }

  async toggleRefreshSignal($e: MouseEvent) {
    this.refreshStatus = !this.refreshStatus;
    this.refreshMessage = (this.refreshStatus) ? this.refreshMessageTrue: this.refreshMessageFalse;
    //update refresh signal for this taxonomyId
    this.refreshSignal = {
      taxonomyId: String(this.taxonomyId),
      taxonomyType: this.taxonomyType,
      employeeNumber: this.employeeNumber,
      status: (this.refreshStatus) ? 'Refreshed': 'Not Refreshed'
    };
    await this.refreshSignalService.update(this.refreshSignal).toPromise();
  }

}
