import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { KeyResultModel } from 'src/app/models/key-result.model';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgForm } from '@angular/forms';
import { ConfirmationDialogService } from 'src/app/services/confirmation-dialog.service';
import { ObjectiveService } from 'src/app/services/objective.service';
import { EventEmitterService } from 'src/app/services/event-emitter.service';
import { UtilsService } from 'src/app/services/utils.service';
import { KeyResultService } from 'src/app/services/key-results.service';
import { ObjectiveModel } from 'src/app/models/objective.model';
import { ProductModel } from 'src/app/models/product.model';
import { ProductGroupModel } from '../../models/product-group.model';
import { ProductService } from 'src/app/services/product.service';
import { ProductGroupService } from 'src/app/services/productGroup.service';


@Component({
  selector: 'app-copy-keyresult-objective-modal',
  templateUrl: './copy-keyresult-objective-modal.component.html',
  styleUrls: ['./copy-keyresult-objective-modal.component.scss']
})
export class CopyKeyresultObjectiveModalComponent implements OnInit {

  static componentInstance: any;

  @Input() objectiveType: string;
  @Input() selectedProduct: ProductModel;
  @Input() selectedProductGroup: ProductGroupModel;
  @Input() editObjective: ObjectiveModel[];
  @Input() planningPortfolioId: number;
  @Input() planningPortfolioName: string;
  @Input() numKeyResults: number;

  @Input() keyResult: KeyResultModel;
  @Input() fromModal: string;
  @ViewChild('submitBtn') private buttonSubmit: ElementRef;

  selectedKeyResult = [];
  selectedObjectiveId = null;
  selectedProductId = null;
  selectedProductGroupId = null;
  selectedDuplicateOption = 'sameobjective';

  productObjectives: ObjectiveModel[] = [];
  productGroupObjectives: ObjectiveModel[] = [];
  portfolioObjectives: ObjectiveModel[] = [];
  enterpriseObjectives: ObjectiveModel[] = [];
  products: ProductModel[] = [];
  productGroups: ProductGroupModel[] = [];
  newKeyResult: KeyResultModel[] = [];

  constructor(public activeModal: NgbActiveModal,
    private objectiveService: ObjectiveService,
    private keyresultService: KeyResultService,
    private productService: ProductService,
    private productGroupService: ProductGroupService,) {}

  ngOnInit(): void {
    this.retrieveObjectives(this.objectiveType);
    this.retrieveProductProductGroups();
  }
  changesDetected(): boolean {
    const foundChange = false;
    return foundChange;
  }
  openConfirmationDialog = (sendData: string, copykeyResultObjectiveForm: NgForm) => {
    this.closeModal(sendData, copykeyResultObjectiveForm);
  };
  closeModal = (sendData: string, copykeyResultObjectiveForm: NgForm): void => {
    if (sendData === 'cancel') {
      copykeyResultObjectiveForm.resetForm();
      this.activeModal.close(sendData);
    }
  };
  retrieveObjectives = (objType: string): void => {
    if (objType === 'PRODUCT') {
      this.objectiveService.getAllByProductAndGroup(objType, this.selectedProduct.id , this.selectedProduct.productGroup.id, '').subscribe(
        (data) => {
          this.productObjectives = data;
          this.sortObjectives(this.productObjectives);
        }
      );
    } else if (objType === 'PRODUCT_GROUP') {
      this.objectiveService.getAllByProductGroup(objType, this.selectedProductGroup.id, '').subscribe(
        (data) => {
          this.productGroupObjectives = data;
          this.sortObjectives(this.productGroupObjectives);
        }
      );
    } else if (objType === 'PORTFOLIO') {
      this.objectiveService.getAllPortfolioByPlanningPortfolio(this.planningPortfolioId).subscribe(
        (data) => {
          this.portfolioObjectives = data;
          this.sortObjectives(this.portfolioObjectives);
        }
      );
    } else if (objType === 'ENTERPRISE') {
      this.objectiveService.getAll(objType, 'false').subscribe(
        (data) => {
          this.enterpriseObjectives = data;
          this.sortObjectives(this.enterpriseObjectives);
        },
        (error) => {
          console.error(error);
        }
      );
    }
  };
  getObjectiveByProductId(productId: string) {
    this.objectiveService. getAllByProduct('PRODUCT', productId ,'false').subscribe(
      (data) => {
        this.productObjectives = data;
        this.sortObjectives(this.productObjectives);
      }
    );
  }
  getObjectiveByProductGroupId(productGroupId: string) {
    this.objectiveService. getAllByProductGroup('PRODUCT_GROUP', productGroupId ,'false').subscribe(
      (data) => {
        this.productGroupObjectives = data;
        this.sortObjectives(this.productGroupObjectives);
      }
    );
  }
  sortObjectives(tempObjectives) {
    tempObjectives.sort((a, b) => a.description.localeCompare(b.description));
  }
  async retrieveProductProductGroups() {
    this.products = await this.productService.getAllProducts().toPromise();
    this.productGroups = await this.productGroupService.getAllProductGroups().toPromise();
  }
  showObjective(objective: ObjectiveModel): boolean {
    if (this.keyResult.objectiveId === objective.id) {
      return false;
    }
    else {
      return true;
    }
  }
  showPGObjective(objective: ObjectiveModel): boolean {
    if (this.keyResult.objectiveId === objective.id) {
        return false;
    }
    else if (objective.objectiveType === 'PRODUCT') {
      return false;
    }
    else {
      return true;
    }
  }
  onSubmit(objectiveForm: NgForm, submitBtn: HTMLButtonElement): void {
    if (this.selectedDuplicateOption === 'sameobjective') {
      this.keyResult.sort = 99;
      this.keyresultService.duplicate(this.keyResult).subscribe(
        (response) => {
          this.activeModal.close('onSubmit-Create');
        },
        (error) => {
          console.error(error);
        }
      );
    }
    else
    {
      this.keyResult.sort = 99;
      this.keyResult.objectiveId = String(this.selectedObjectiveId);
      this.keyresultService.duplicate(this.keyResult).subscribe(
        (response) => {
          this.activeModal.close('onSubmit-Create');
        },
        (error) => {
          console.error(error);
        }
      );
    }
  }
}

