import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SiteSettingsService {

  constructor(@Inject(HttpClient) private http: HttpClient) { }

  checkRefreshDates(): Observable<boolean> {
    return this.http.get<boolean>(`${environment.baseURL}/site-settings/refreshdates`);
  }

}
