<form class="form-horizontal" (ngSubmit)="roadmapProgressForm.form.valid && onSubmitForm()" #roadmapProgressForm="ngForm" novalidate>
    <div class="modal-content">
      <div class="modal-header">
        <h2 class="modal-title">Add Progress for {{ this.roadmapType }}</h2>
        <button type="button" class="btn-close" (click)="dismissModal()"></button>
      </div>
      <div class="modal-body">
        <div class="row pt-1">
            <div class="col-md-12">
                <label for="roadmapInitiative" class="form-label fw-bold">Initiative Name/Title</label>
                <div id="roadmapInitiative" class="bg-light"> {{ roadmap.description }} </div>
            </div>
        </div>
        <div class="col-md-3">
          <label for="dateOfProgress" class="form-label" style = "padding-top: 8pt;">Date of Progress:</label>
          <input
            type="date"
            id="dateOfProgress"
            name="dateOfProgress"
            class="form-control"
            #dateOfProgress = "ngModel"
            [(ngModel)]="roadmapProgress.dateOfProgress"
            [ngClass]="{'is-invalid':roadmapProgressForm.submitted && dateOfProgress.invalid}"
            [required]="errorTrigger === 'null' ? true: false">
            <div *ngIf="errorTrigger === 'future' ? true: false" [ngStyle]="errorTrigger && {'color': 'red'}">Future date Invalid</div>
                    <div class="invalid-feedback">
                        <div> Required</div>
                    </div>
                
          </div>
      </div>
        <hr>
        <div class="row pt-1 pb-3">
            <div class="col-md-4 card text-center">
                <div class="card-body text-center">
                  <input
                  type="radio"
                  id="status1"
                  name="status"
                  class="btn-check"
                  value="Backlog"
                  #status = "ngModel"
                  [(ngModel)]="roadmapProgress.status"
                  [ngClass]="{'is-invalid':roadmapProgressForm.submitted && status.invalid}"
                  required>
                  <label class="btn btn-sm btn-outline-secondary m-1" for="status1">Backlog</label>
                  <input
                      type="radio"
                      id="status2"
                      name="status"
                      class="btn-check"
                      value="Discovering"
                      #status = "ngModel"
                      [(ngModel)]="roadmapProgress.status"
                      [ngClass]="{'is-invalid':roadmapProgressForm.submitted && status.invalid}"
                      required>
                  <label class="btn btn-sm btn-outline-secondary" for="status2">Discovering</label>
                  <input
                      type="radio"
                      id="status3"
                      name="status"
                      class="btn-check"
                      value="Developing"
                      #status = "ngModel"
                      [(ngModel)]="roadmapProgress.status"
                      [ngClass]="{'is-invalid':roadmapProgressForm.submitted && status.invalid}"
                      required>
                  <label class="btn btn-sm btn-outline-secondary m-1" for="status3">Developing</label>
                  <input
                    type="radio"
                    id="status4"
                    name="status"
                    class="btn-check"
                    value="Measuring"
                    #status = "ngModel"
                    [(ngModel)]="roadmapProgress.status"
                    [ngClass]="{'is-invalid':roadmapProgressForm.submitted && status.invalid}"
                    required>
                  <label class="btn btn-sm btn-outline-secondary m-1" for="status4">Measuring</label>
                  <input
                    type="radio"
                    id="status5"
                    name="status"
                    class="btn-check"
                    value="Done"
                    #status = "ngModel"
                    [(ngModel)]="roadmapProgress.status"
                    [ngClass]="{'is-invalid':roadmapProgressForm.submitted && status.invalid}"
                    required>
                  <label class="btn btn-sm btn-outline-secondary m-1" for="status5">Done</label> 
                  <div class="invalid-feedback">
                    <div> Required</div>
                </div>
                </div>
                <div class="card-footer">
                    <label for="baseline">State</label>
                </div>
            </div>
            <div class="col-md-4 card text-center">
                <div class="card-body text-center">
                    <input type="text"
                        id="percentageOfPlannedWorkCompleted"
                        class="form-control text-center fs-4"
                        name="percentageOfPlannedWorkCompleted"
                        #percentageOfPlannedWorkCompleted = "ngModel"
                        inputmode="numeric"
                        placeholder="Numerical value"
                        pattern="[0-9.-]*"
                        (paste)="onPaste(percentageOfPlannedWorkCompleted, $event)"
                        (drop)="onDrop(percentageOfPlannedWorkCompleted, $event)"
                        (keydown)="onKeyDown(percentageOfPlannedWorkCompleted, $event)"
                        [(ngModel)]="roadmapProgress.percentageOfPlannedWorkCompleted"
                        [ngClass]="{'is-invalid':roadmapProgressForm.submitted && percentageOfPlannedWorkCompleted.value === '' && (roadmapProgress.status !== 'Backlog' && roadmapProgress.status !== 'Discovering')}"
                        [required]="(roadmapProgress.status === 'Backlog' || roadmapProgress.status === 'Discovering') ? false: true">
                        <div *ngIf="roadmapProgress.status === 'Backlog' || roadmapProgress.status === 'Discovering' ">optional</div>
                    <div class="invalid-feedback">
                        <div> Required</div>
                    </div>
                </div>
                
                <div class="card-footer text-center">
                    <label for="current">% of Planned Work Completed<br>(from Rally/ADO)</label>
                </div>
            </div>
            <div class="col-md-4 card text-center">
                <div class="card-body text-center">
                  <input
                  type="radio"
                  id="confidenceLevel1"
                  name="confidenceLevel"
                  class="btn-check"
                  value="On Track"
                  #confidenceLevel = "ngModel"
                  [(ngModel)]="roadmapProgress.confidenceLevel"
                  [ngClass]="{'is-invalid':roadmapProgressForm.submitted && roadmapProgress.confidenceLevel === '' && (roadmapProgress.status !== 'Backlog' && roadmapProgress.status !== 'Discovering')}"
                  [required]="(roadmapProgress.status === 'Backlog' || roadmapProgress.status === 'Discovering') ? false: true">
              <label class="btn btn-sm btn-outline-secondary m-1" for="confidenceLevel1">On Track</label>
              <input
                  type="radio"
                  id="confidenceLevel2"
                  name="confidenceLevel"
                  class="btn-check"
                  value="Off Track"
                  #confidenceLevel = "ngModel"
                  [(ngModel)]="roadmapProgress.confidenceLevel"
                  [ngClass]="{'is-invalid':roadmapProgressForm.submitted && roadmapProgress.confidenceLevel === '' && (roadmapProgress.status !== 'Backlog' && roadmapProgress.status !== 'Discovering')}"
                  [required]="(roadmapProgress.status === 'Backlog' || roadmapProgress.status === 'Discovering') ? false: true">
              <label class="btn btn-sm btn-outline-secondary" for="confidenceLevel2">Off Track</label>
              <input
                  type="radio"
                  id="confidenceLevel3"
                  name="confidenceLevel"
                  class="btn-check"
                  value="At Risk"
                  #confidenceLevel = "ngModel"
                  [(ngModel)]="roadmapProgress.confidenceLevel"
                  [ngClass]="{'is-invalid':roadmapProgressForm.submitted && roadmapProgress.confidenceLevel === '' && (roadmapProgress.status !== 'Backlog' && roadmapProgress.status !== 'Discovering')}"
                  [required]="(roadmapProgress.status === 'Backlog' || roadmapProgress.status === 'Discovering') ? false: true">
              <label class="btn btn-sm btn-outline-secondary m-1" for="confidenceLevel3">At Risk</label>
              <div class="invalid-feedback"> Required</div>
              <div *ngIf="roadmapProgress.status === 'Backlog' || roadmapProgress.status === 'Discovering' ">optional</div>
              </div>
              <div class="card-footer text-center">
                  <label for="goal">Confidence Level</label>
              </div>
            </div>
      </div>
      <div class="row pt-0">
          <div class="col-md-12">
            <label for="currentValueDelivered" class="col-form-label">Current Value Delivered:</label>
            <textarea
              type="text"
              id="currentValueDelivered"
              name="currentValueDelivered"
              class="form-control" 
              placeholder="Status or summary of"
              rows="2"
              #currentValueDelivered = "ngModel"
              [(ngModel)]="roadmapProgress.currentValueDelivered"
              [ngClass]="{'is-invalid':roadmapProgressForm.submitted && currentValueDelivered.invalid}"
              required>
            </textarea>
            <div class="invalid-feedback">
              <div> Required</div>
            </div>
          </div>
          <div class="col-md-12">
            <label for="nextBestIdeas" class="col-form-label">Next best ideas to deliver:</label>
            <textarea
              type="text"
              id="nextBestIdeas"
              name="nextBestIdeas"
              class="form-control" 
              rows="2"
              placeholder="Brief description"
              #nextBestIdeas = "ngModel"
              [(ngModel)]="roadmapProgress.nextBestIdeas">
            </textarea>


        </div>
      </div>
    </div>
    <div class="modal-footer">
       <button type="button" class="btn btn-primary me-auto" (click)="openConfirmationDialog('progress', roadmapProgressForm)">
            View Progress
        </button>
        <button (click)="dismissModal()" type="button" class="btn btn-secondary">Cancel</button>
        <button type="submit" class="btn btn-primary" #submitBtn>Save</button>
    </div>
