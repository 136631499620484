import { Component,  Input, OnInit,  } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgForm } from '@angular/forms';
import { ObjectiveModel } from 'src/app/models/objective.model';
import { ObjectivesLogService } from 'src/app/services/objectives-log.service';
import { ObjectivesLogModel } from 'src/app/models/objectives-log.model';
import { GraphqlQueriesService, UserSearchResult } from 'src/app/services/graphql-queries.service';



@Component({
  selector: 'app-objectives-log',
  templateUrl: './objectives-log.component.html',
  styleUrls: ['./objectives-log.component.scss']
})
export class ObjectivesLogComponent implements OnInit {

  static componentInstance: any;

  @Input() objectiveType: string;
  @Input() id: string;
  @Input() fromModal: string;
  @Input() objective: ObjectiveModel;

  noObjectiveLogs: boolean;
  objectivesLogEntries: ObjectivesLogModel[];
  pageLoaded: Promise<boolean>;
  loading = false;
  selectedOwnerName: string;

  productObjectives: ObjectiveModel[] = [];
  productGroupObjectives: ObjectiveModel[] = [];
  portfolioObjectives: ObjectiveModel[] = [];
  enterpriseObjectives: ObjectiveModel[] = [];
  userSearchResults: UserSearchResult[];

  constructor(public activeModal: NgbActiveModal,
    private objectivesLogService: ObjectivesLogService,
    private graphqlQueriesService: GraphqlQueriesService,

    ) {}

  ngOnInit(): void {
    this.pageLoaded = Promise.resolve(false);
    this.retrieveObjectivesLog(this.objective.id);
  }

  openConfirmationDialog = (sendData: string, objectiveslogForm: NgForm) => {
    this.closeModal(sendData, objectiveslogForm);
  };
  closeModal = (sendData: string, objectiveslogForm: NgForm): void => {
    if (sendData === 'cancel') {
      objectiveslogForm.resetForm();
      this.activeModal.close(sendData);
    }
  };

 retrieveObjectivesLog = (objId: string): void => {
    this.objectivesLogService.getAllByObjId(objId).subscribe(
      async (data) => {
        this.noObjectiveLogs = data.length === 0 ? true : false;
        this.objectivesLogEntries = data;
        let logLength = this.objectivesLogEntries.length;
        this.objectivesLogEntries.forEach((objectivesLogEntry) => {
          if (objectivesLogEntry.userId) {
            this.graphqlQueriesService
                    .getOwnerName(objectivesLogEntry.userId)
                    .subscribe((result: any) => {
                      objectivesLogEntry.userName = result.data.userSearch.users[0]?.displayName || '';
                  });
          }
          objectivesLogEntry.sort = logLength--;
        });
        this.pageLoaded = Promise.resolve(true);
      }
    );
};
}

