<div *ngIf="pageLoaded" class="modal-lg">
    <div class="modal-content">
        <div class="modal-header">
            <h2 class="modal-title">Progress for {{ this.roadmapType }}</h2>
            <button type="button" class="btn-close" aria-label="Close" (click)="openConfirmationDialog('close')"></button>
        </div>
        <div class="modal-body text-start">
            <div class="row pb-2">
                <div class="col-12">
                    <label for="roadmapInitiative" class="form-label fw-bold">Initiative Name/Title</label>
                    <div id="roadmapInitiative" class=" bg-light">{{ roadmap.description }}</div>
                </div>
            </div>
        <div class="row pt-2 gridHeader">
            <div class="col-2">
                Date of Progress
            </div>
            <div class="col">
                State
            </div>
            <div class="percentComplete">
                % Completed
            </div>
            <div class="col">
                Confidence Level
            </div>
            <div class="col-3">
                Current Value Delivered
            </div>
            <div class="col-2">
                Next Best Ideas To Deliver
            </div>
            <div class="col">
                Action
            </div>
        </div>
        <div *ngIf="noRoadmapProgress">
            <hr>
            <div class="row pt-2">
                <div class="col-12">
                No Progress Found
                </div>
            </div>
        </div>
        <div class="row grid-striped" *ngFor="let progress of roadmapProgressEntries; let i = index">
            <div class="col-2 pt-1" (click)="editRoadmapProgress($event, progress)" role="button">
                {{ progress.dateOfProgress | date: 'M/d/Y' }}
            </div>
            <div class="col pt-1" (click)="editRoadmapProgress($event, progress)" role="button">
                <span *ngIf="!progress.editMode || progress.status === 'Done'">
                    {{progress.status}}
                </span>
                <div *ngIf="progress.editMode && progress.status !== 'Done'">
                <select
                    class="form-control editSelect"
                    id="status"
                    name="status"
                    #status="ngModel"
                    [(ngModel)]="progress.status"
                    [ngClass]="{'is-invalid': status.value === ''}">
                    <option value='Backlog'>Backlog</option>
                    <option value='Discovering'>Discovering</option>
                    <option value='Developing'>Developing</option>
                    <option value='Measuring'>Measuring</option>
                </select>
                <div class="invalid-feedback">
                    <div> Required</div>
                </div>
                </div>
            </div>
            <div class="percentComplete pt-1" (click)="editRoadmapProgress($event, progress)" role="button">
                    <span *ngIf="!progress.editMode">
                        {{ progress.percentageOfPlannedWorkCompleted}}
                    </span>
                    <span *ngIf="progress.percentageOfPlannedWorkCompleted && !progress.editMode">%</span>
                    <span *ngIf="progress.editMode">
                        <input
                        id="percentageOfPlannedWorkCompleted"
                        type="text"
                        name="percentageOfPlannedWorkCompleted"
                        #percentageOfPlannedWorkCompleted = "ngModel"
                        class="form-control editInput"
                        inputmode="numeric"
                        pattern="[0-9.-]*"
                        (paste)="onPaste(percentageOfPlannedWorkCompleted, $event)"
                        (drop)="onDrop(percentageOfPlannedWorkCompleted, $event)"
                        (keydown)="onKeyDown(percentageOfPlannedWorkCompleted, $event)"
                        [ngClass]="{'is-invalid': percentageOfPlannedWorkCompleted.value === '' && (progress.status !== 'Backlog' && progress.status !== 'Discovering')}"
                        [(ngModel)]="progress.percentageOfPlannedWorkCompleted"
                        [required]="(progress.status === 'Backlog' || progress.status === 'Discovering') ? false: true">
                    <div class="invalid-feedback">
                        <div> Required</div>
                    </div>
                    <div *ngIf="(progress.status === 'Backlog' || progress.status === 'Discovering')">optional</div>
                    </span>
                    
                </div>
                <div class="col pt-1" (click)="editRoadmapProgress($event, progress)" role="button">
                    <span *ngIf="!progress.editMode">
                        {{ progress.confidenceLevel}}
                    </span>
                    <select *ngIf="progress.editMode"
                    class="form-control editSelect"
                    id="confidenceLevel"
                    name="confidenceLevel"
                    #confidenceLevel = "ngModel"
                    [(ngModel)]="progress.confidenceLevel"
                    [ngClass]="{'is-invalid':progress.confidenceLevel === '' && (progress.status !== 'Backlog' && progress.status !== 'Discovering')}"
                    [required]="(progress.status === 'Backlog' || progress.status === 'Discovering') ? false: true">
                        <option value =""></option>
                        <option value="On Track">On Track</option>
                        <option value="Off Track">Off Track</option>
                        <option value="At Risk">At Risk</option>
                    </select>
                    <div *ngIf="progress.editMode && (progress.status === 'Backlog' || progress.status === 'Discovering')">optional</div>
                    <div class="invalid-feedback">
                        <div> Required</div>
                    </div>
                </div>
                <div class="col-3 pt-1 pb-1" (click)="editRoadmapProgress($event, progress)" role="button">
                    <span *ngIf="!progress.editMode">{{ progress.currentValueDelivered }}</span>
                    <textarea
                        *ngIf="progress.editMode"
                        type="text"
                        id="currentValueDelivered"
                        name="currentValueDelivered"
                        class="form-control editTextArea"
                        placeholder="Status or summary of"
                        [(ngModel)]="progress.currentValueDelivered"
                        [ngClass]="{'is-invalid':progress.currentValueDelivered === ''}"
                        required
                        rows="4">
                    </textarea>
                    <div class="invalid-feedback">
                        <div> Required</div>
                    </div>
                </div>
                <div class="col-2 pt-1 pb-1" (click)="editRoadmapProgress($event, progress)" role="button">
                    <span *ngIf="!progress.editMode">{{ progress.nextBestIdeas }}</span>
                    <textarea
                        *ngIf="progress.editMode"
                        type="text"
                        id="nextBestIdeas"
                        name="nextBestIdeas"
                        class="form-control editTextArea" 
                        rows="4"
                        placeholder="Brief description"
                        [(ngModel)]="progress.nextBestIdeas">
                    </textarea>
                </div>
                <div class="col">
                    <div *ngIf="!progress.editMode">
                        <a href="#" (click)="editRoadmapProgress($event, progress)"><span title="Edit" class="bi-pencil-square fs-5 p-0"></span></a>
                        <a href="#" (click)="delete($event, progress, i)"><span title="Delete" class="bi bi-trash fs-5 px-1"></span></a>
                    </div>
                    <div class="editButtons" *ngIf="progress.editMode">
                        <a href="#" (click)="saveRoadmapProgress($event, progress)"><span title="Save" class="bi-check-square-fill fs-5 p-1"></span></a>
                        <a href="#" (click)="cancelEdit($event, progress)"><span title="Cancel" class="bi-x-square fs-5 p-0"></span></a>
                        <a href="#" (click)="delete($event, progress, i)"><span title="Delete" class="bi bi-trash fs-5 px-1"></span></a>
                    </div>
                </div>
            </div>
            <hr>
            <div class="row mt-3 mb-1 flex-nowrap">
                <div class="col-12 text-end">
                    <button *ngIf="restoreRoadmapProgressEntries.length > 1" type="button" class="btn btn-primary btn-sm" #saveAllBtn (click)="saveAll(saveAllBtn, $event)">
                        Save All
                    </button>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <div class="me-auto">
                <button type="button" class="btn btn-primary" (click)="openConfirmationDialog('progress')">
                    <i class="bi-plus"></i>Add Progress
                </button>
            </div>
            <button (click)="closeModal('close')" type="button" class="btn btn-secondary">Close</button>
        </div>
    </div>
</div>
