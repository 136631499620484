import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { EmpidTaxonomyModel } from '../models/empid-taxonomy.model';

@Injectable({
  providedIn: 'root'
})
export class EmpidTaxonomyservice {

  constructor(@Inject(HttpClient) private http: HttpClient) { }

  gettaxonomyByEmpNum(empNumber: string): Observable<EmpidTaxonomyModel> {
    return this.http.get<EmpidTaxonomyModel>(`${environment.baseURL}/empid-taxonomy?empNumber=${empNumber}`);
  }

  create(data: any): Observable<any> {
    return this.http.post(`${environment.baseURL}/empid-taxonomy`, data);
  }

  update(data: any): Observable<any> {
    return this.http.put(`${environment.baseURL}/empid-taxonomy`, data, {
      params: {
        empId: data.empNumber
      }
    });
  }

}
