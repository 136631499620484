import { Component , OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Title } from '@angular/platform-browser';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { filter, map } from 'rxjs/operators';
import { PingAuthenticationService } from '@techops-ui/ping-authentication';
import { TitleCasePipe } from '@angular/common';
import { environment } from 'src/environments/environment';

import $ from 'jquery';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit  {
  userName: string;
  employeeNumber: string;
  userEmail: string;
  titlecasePipe = new TitleCasePipe();
  constructor(private authService: PingAuthenticationService,
              private router: Router,
              private activatedRoute: ActivatedRoute,
              private titleService: Title) {
  }
  ngOnInit() {
    this.authService.profile$
     .pipe(filter((profile) => profile != null))
     .subscribe((profile) => {
      if (environment.production) {
       (jQuery(document) as any).surveysPlugin({
        /* eslint-disable */
        archer_id: environment.archerId,
         expanded: false,
         onBeforeSave: (data) => {
           return Promise.resolve({
             ...data,
             ...{ user_id: profile.sub, user_role: profile.role },
           });
         },
       });
       const feedback = (jQuery(document) as any).feedback({
        archer_id: environment.archerId,
        participant_name: function () {
           return Promise.resolve(
             `${profile.given_name} ${profile.family_name}`
           );
        },
        participant_email: function () {
         return Promise.resolve(``);
        },
        participant_contact_no: function () {
          return Promise.resolve(``);
        },
       });
       jQuery(document).on('click touchstart', '#lnk-feedback', () => {
         feedback.show();
         return false;
       });
      }
     });
     /* eslint-enable */
        this.router.events.pipe(
        filter(event => event instanceof NavigationEnd),
      ).subscribe(() => {
        const rt = this.getChild(this.activatedRoute);
        rt.data.subscribe(data => {
          this.titleService.setTitle(data.title);});
      });
  }
  getChild(activatedRoute: ActivatedRoute) {
    if (activatedRoute.firstChild) {
      return this.getChild(activatedRoute.firstChild);
    } else {
      return activatedRoute;
    }

  }

}


