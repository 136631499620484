import {NgModule} from '@angular/core';
import {HttpClientModule} from '@angular/common/http';
import {ApolloModule, APOLLO_OPTIONS} from 'apollo-angular';
import {ApolloLink, ApolloClientOptions, InMemoryCache} from '@apollo/client/core';
import {HttpLink} from 'apollo-angular/http';
import {environment} from 'src/environments/environment';
import {HttpHeaders} from '@angular/common/http';
import extractFiles from 'extract-files/extractFiles.mjs';

//const uri = 'https://graph-np.aa.com'; // <-- add the URL of the GraphQL server here

@NgModule({

  imports: [
    HttpClientModule,
    ApolloModule
  ],

  providers: [

    {
      provide: APOLLO_OPTIONS,
      useFactory: (httpLink: HttpLink) => {
        const http = httpLink.create({ uri: environment.graphQLGatewayUrl });
         const headers = new ApolloLink((operation, forward) => {
          operation.setContext(() => ({
          headers: new HttpHeaders()
            .set('apollographql-client-name', `${environment.okrtrackerClientName}`)
            .set('apollographql-client-version', `${environment.okrtrackerClientVersion}`)
          }));
          return forward(operation);
        });

        return {
          cache: new InMemoryCache(),
          link: headers.concat(http),
          extractFiles
        };
      },
      deps: [HttpLink],
    }
  ],

})

export class GraphQLModule {}
