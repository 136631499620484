import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { RefreshSignalModel } from '../models/refresh-signal.model';

@Injectable({
  providedIn: 'root'
})
export class RefreshSignalService {

  constructor(@Inject(HttpClient) private http: HttpClient) { }

  getRefreshSignalByTaxonomyId(taxonomyId: string): Observable<RefreshSignalModel> {
    return this.http.get<RefreshSignalModel>(`${environment.baseURL}/refresh-signal/${taxonomyId}`);
  }

  create(data: any): Observable<any> {
    return this.http.post(`${environment.baseURL}/refresh-signal`, data);
  }

  update(data: any): Observable<any> {
    return this.http.put(`${environment.baseURL}/refresh-signal`, data);
  }

}
