import {
  Component,
  ElementRef,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { NgForm } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { KeyResultModel } from 'src/app/models/key-result.model';
import { ObjectiveModel } from 'src/app/models/objective.model';
import { ProductGroupModel } from 'src/app/models/product-group.model';
import { ProductModel } from 'src/app/models/product.model';
import { RoadmapModel } from 'src/app/models/roadmap.model';
import { ObjectiveService } from 'src/app/services/objective.service';
import { RoadmapSortService } from 'src/app/services/roadmap-sort.service';
import { RoadmapSortModel } from 'src/app/models/roadmap-sort.model';
import { RoadmapPriorityService } from 'src/app/services/roadmap-priority.service';
import { UtilsService } from 'src/app/services/utils.service';
import { RoadmapOtherLevelModel } from 'src/app/models/roadmap-other-level.model';
import { RoadmapOtherLevelService } from 'src/app/services/roadmap-other-level.service';
import { ConfirmationDialogService } from 'src/app/services/confirmation-dialog.service';
import { RoadmapProgressAddComponent } from '../roadmap-progress-add/roadmap-progress-add.component';
import { RoadmapProgressViewComponent } from '../roadmap-progress-view/roadmap-progress-view.component';
import { RoadmapProgressModel } from 'src/app/models/roadmap-progress.model';
import {
  GraphqlQueriesService,
  UserSearchResult,
} from 'src/app/services/graphql-queries.service';
import { PingAuthenticationService } from '@techops-ui/ping-authentication';
import { EventEmitterService } from 'src/app/services/event-emitter.service';
import { ExternalLinkService } from 'src/app/services/external-links.service';
import { RallyAdoInitiativesService } from 'src/app/services/rally-ado-initiatives.service';
import { ExternalLinkModel } from 'src/app/models/external-link.model';
import { BacklogToProductModel } from 'src/app/models/backlog-to-product.model';
import { AllinitiativesModel } from 'src/app/models/allinitiatives.model';
import { BacklogToProductsService } from 'src/app/services/backlog-to-products.service';

import { isNull } from 'lodash';
import { ProductService } from 'src/app/services/product.service';
import { ProductGroupService } from 'src/app/services/productGroup.service';

@Component({
  selector: 'app-new-roadmap-item',
  templateUrl: './new-roadmap-item.component.html',
  styleUrls: ['./new-roadmap-item.component.scss'],
})
export class NewRoadmapItemComponent implements OnInit {
  @Input() existingRoadmapItem = false;
  @Input() planningPortfolioId: number;
  @Input() roadmapProgress: RoadmapProgressModel[];
  @Input() objectiveType: string;
  @Input() productGroupId: string;
  @Input() productId: string;
  @Input() objectiveId: string;
  @Input() fromModal: string;
  @ViewChild('submitBtn') private buttonSubmit: ElementRef;

  roadmapType: string;
  showKrs = false;
  selectedObjectives: string[] = [];
  selectedInitiative: string[] = [];
  objectives: ObjectiveModel[] = [];
  selectedProducts: ProductModel[] = [];
  products: ProductModel[] = [];
  selectedProductGroups: number[] = [];
  productGroups: ProductGroupModel[] = [];
  roadmap: RoadmapModel = {
    description: '',
    keyResults: [],
    valueTimeframe: '',
    quarter: '0',
    year: '0',
    month: '0',
    userId: '',
    impactEstimate: '',
    adoInitiativeId: '',
    rallyInitiativeId: '',
    ownerId: '',
    refreshCompFlag: false,
    hardwareEst: '0',
    softwareEst: '0',
    thirdPartyEst: '0',
    totalEst: 0,
    requiresAttachment: false,
  };

  uniqueObjectives: any = [];
  allOtherLevelRoadmaps: RoadmapModel[] = [];
  selectedRoadmaps: any[] = [];
  roadmapOtherLevelLabel: string;
  roadmapOtherLevelValidation: string;
  portfolioObjectives: ObjectiveModel[] = [];
  enterpriseObjectives: ObjectiveModel[] = [];
  allADOinitiatives: AllinitiativesModel[] = [];
  allRallyinitiatives: AllinitiativesModel[] = [];
  backlogsToProducts: BacklogToProductModel[] = [];

  allinitiatives: AllinitiativesModel[] = [];

  progressConfidenceLevel: string;
  progressPercentComplete: string;
  currentDate: Date = new Date();
  currentYear: number;
  years = [];
  currentRoadmap: RoadmapModel;
  hasProgress = false;
  userSearchResults: UserSearchResult[];
  selectedOwnerId: string;
  selectedOwnerName: string;
  loading = false;
  keyword = 'name';
  showProgressButtons = false;
  hardwareEst: string;
  softwareEst: string;
  thirdPartyEst: string;
  backlogSource: string;
  totalEst: number;
  hasLinks: boolean;
  linked: string;

  constructor(
    private readonly objectiveService: ObjectiveService,
    private readonly activeModal: NgbActiveModal,
    private readonly productService: ProductService,
    private readonly productGroupService: ProductGroupService,
    private readonly roadmapSortService: RoadmapSortService,
    private readonly roadmapPriorityService: RoadmapPriorityService,
    private utilsService: UtilsService,
    private roadmapOtherLevelService: RoadmapOtherLevelService,
    private backlogToProductsService: BacklogToProductsService,
    private confirmationDialogService: ConfirmationDialogService,
    private modalService: NgbModal,
    private graphqlQueriesService: GraphqlQueriesService,
    private authService: PingAuthenticationService,
    private eventEmitterService: EventEmitterService,
    private externalLinkService: ExternalLinkService,

    private rallyAdoInitiativesService: RallyAdoInitiativesService
  ) // private externalLinkModel: ExternalLinkModel
  {}

  ngOnInit(): void {
    this.selectedObjectives.push(this.objectiveId);
    if (this.objectiveType === 'ENTERPRISE') {
      this.roadmapType = 'Theme';
    } else if (this.objectiveType === 'PORTFOLIO') {
      this.roadmapType = 'Portfolio Initiative';
    } else {
      this.roadmapType = 'Roadmap Item';
    }

    this.getTotalEst();

    this.loadData();
    if (this.roadmap.ownerId) {
      this.selectedOwnerId = this.roadmap.ownerId;
      this.searchUser(this.roadmap.ownerId);
    } else {
      this.authService.profile$.subscribe((p) => {
        this.roadmap.ownerId = p.BadgeNo.substring(2);
        this.selectedOwnerId = this.roadmap.ownerId;
        this.searchUser(this.roadmap.ownerId);
      });
    }
  }

  async loadData() {

    if (this.objectiveType === 'PRODUCT') {
      this.retrieveADOInitiatives(this.productId);
      this.retrieveRallyInitiatives(this.productId);
      this.retrieveBacklogsToProducts(this.productId);
    } else if (this.objectiveType === 'PRODUCT_GROUP') {
      this.retrieveADOInitiativesPG(this.productGroupId);
      this.retrieveRallyInitiativesPG(this.productGroupId);
      this.retrieveBacklogsToProducts(this.productGroupId);
    }


    // this.retrieveInitiatives(this.productId);
    this.currentYear = this.currentDate.getFullYear();
    if (this.currentDate.getMonth() <= 1) {
      //0 = january, if feb or earlier, show previous year
      this.years.push(this.currentYear - 1);
    }
    this.years.push(this.currentYear);
    this.years.push(this.currentYear + 1);
    if (this.roadmap.year === '0' || this.roadmap.year === '') {
      this.roadmap.year = String(this.currentYear);
    }
    //if there is an existing roadmap and valueTimeframe isn't populated, convert existing year/month to string
    if (this.existingRoadmapItem && !this.roadmap.valueTimeframe) {
      this.roadmap.year = String(this.roadmap.year);
      this.roadmap.month = String(this.roadmap.month);
    } else {
      //new roadmap or we are editing an existing roadmap that has still has valueTimeFrame
      //default year to current year
      this.roadmap.year = String(this.currentYear);
    }
    if (
      this.objectiveType === 'PRODUCT' ||
      this.objectiveType === 'PRODUCT_GROUP'
    ) {
      const objectives = await this.objectiveService
        .getAllByProductGroup('PRODUCT_GROUP', this.productGroupId, '')
        .toPromise();
      this.objectives = [...this.objectives, ...objectives];
      this.products = await this.productService.getAllProducts().toPromise();
      this.productGroups = await this.productGroupService.getAllProductGroups().toPromise();
      this.selectedProductGroups.push(+this.productGroupId);
      //load portfolio initiative (roadmaps) from planning portfolio level
      this.portfolioObjectives = await this.objectiveService
        .getAllPortfolioByPlanningPortfolio(this.planningPortfolioId)
        .toPromise();
      this.roadmapOtherLevelLabel =
        'Choose the Portfolio Initiative(s) your Roadmap Item impacts';
      this.roadmapOtherLevelValidation = 'Portfolio Initiative';
      //exclude roadmaps on draft objectives?
      //this.portfolioObjectives = this.portfolioObjectives.filter((item => item.draft === false));
      //populate list of roadmaps at planning portfolio level
      //all states for now
      this.portfolioObjectives.forEach((objElement) => {
        objElement.keyResults.forEach((krElement) => {
          krElement.roadmaps.forEach((rmElement) => {
            const findRoadmap = this.allOtherLevelRoadmaps.find(
              (x) => x.id === rmElement.id
            );
            if (!findRoadmap) {
              //each roadmap may exist multiple times, once per KR, only add unique roadmaps
              this.allOtherLevelRoadmaps.push(rmElement);
            }
          });
        });
      });
      if (this.existingRoadmapItem) {
        //load existing
        this.selectedRoadmaps = await this.roadmapOtherLevelService
          .getAllByRoadmapId(this.roadmap.id)
          .toPromise();
        this.selectedRoadmaps = this.selectedRoadmaps.map(
          (x) => x.otherRoadmapId
        ); //only return the otherRoadmapId
      }
      if (this.allOtherLevelRoadmaps) {
        this.allOtherLevelRoadmaps.sort((a, b) =>
          a.description.localeCompare(b.description)
        );
      }
    } else if (this.objectiveType === 'PORTFOLIO') {
      //load themes (roadmaps) from enterprise level
      this.roadmapOtherLevelLabel =
        'Choose the Theme(s) your Portfolio Initiative impacts';
      this.roadmapOtherLevelValidation = 'Theme';
      //pull Enterprise objectives
      this.enterpriseObjectives = await this.objectiveService
        .getAllByEnterprise()
        .toPromise();

      //populate list of roadmaps at planning portfolio level
      //all states for now
      this.enterpriseObjectives.forEach((objElement) => {
        objElement.keyResults.forEach((krElement) => {
          krElement.roadmaps.forEach((rmElement) => {
            const findRoadmap = this.allOtherLevelRoadmaps.find(
              (x) => x.id === rmElement.id
            );
            if (!findRoadmap) {
              //each roadmap may exist multiple times, once per KR, only add unique roadmaps
              this.allOtherLevelRoadmaps.push(rmElement);
            }
          });
        });
      });
      if (this.existingRoadmapItem) {
        //load existing
        this.selectedRoadmaps = await this.roadmapOtherLevelService
          .getAllByRoadmapId(this.roadmap.id)
          .toPromise();
        this.selectedRoadmaps = this.selectedRoadmaps.map(
          (x) => x.otherRoadmapId
        ); //only return the otherRoadmapId
      }
      if (this.allOtherLevelRoadmaps) {
        this.allOtherLevelRoadmaps.sort((a, b) =>
          a.description.localeCompare(b.description)
        );
      }
      const objectives = await this.objectiveService
        .getAllPortfolioByPlanningPortfolio(this.planningPortfolioId)
        .toPromise();
      this.objectives = [...this.objectives, ...objectives];
    } else if (this.objectiveType === 'ENTERPRISE') {
      const objectives = await this.objectiveService
        .getAllByEnterprise()
        .toPromise();
      this.objectives = [...this.objectives, ...objectives];
    }
    //unique copy of this.selectedObjectives ids to compare what has been removed to handle sorting
    this.uniqueObjectives = [
      ...new Set(this.selectedObjectives.map((item) => item)),
    ];
    if (this.existingRoadmapItem) {
      //save current values to confirm changes and ask to save before clicking Add or View Progress buttons
      this.getLatestProgress();
      const tempRoadmap = { ...this.roadmap };
      this.currentRoadmap = tempRoadmap;
    } else {
      this.roadmap.progressState = 'Backlog';
    }
    this.showProgressButtons = true;
    this.backlogSource = this.backlogsToProducts[0].source;

  }

  getLatestProgress() {
    //determine latest progress to display
    if (this.roadmapProgress && this.roadmapProgress.length > 0) {
      this.hasProgress = true;
      this.roadmapProgress.sort(this.sortCreatedAt);
      this.roadmap.progressCreatedAt = this.roadmapProgress[0].createdAt;
      this.roadmap.progressConfidenceLevel =
        this.roadmapProgress[0].confidenceLevel;
      this.roadmap.progressState = this.roadmapProgress[0].status;
      this.roadmap.progressPercentPlannedWorkCompleted =
        this.roadmapProgress[0].percentageOfPlannedWorkCompleted;
      this.roadmap.progressCurrentValueDelivered =
        this.roadmapProgress[0].currentValueDelivered;
      this.roadmap.progressNextBestIdeas =
        this.roadmapProgress[0].nextBestIdeas;
    } else {
      this.roadmap.progressState = 'Backlog';
    }
  }

  getTotalEst() {
    if (this.roadmap.hardwareEst === '' || this.roadmap.hardwareEst === null) {
      this.roadmap.hardwareEst = '0';
    }
    if (this.roadmap.softwareEst === '' || this.roadmap.softwareEst === null) {
      this.roadmap.softwareEst = '0';
    }
    if (
      this.roadmap.thirdPartyEst === '' ||
      this.roadmap.thirdPartyEst === null
    ) {
      this.roadmap.thirdPartyEst = '0';
    }

    this.totalEst =
      parseInt(this.roadmap.hardwareEst, 10) +
      parseInt(this.roadmap.softwareEst, 10) +
      parseInt(this.roadmap.thirdPartyEst, 10);
  }

  sortCreatedAt(a: RoadmapProgressModel, b: RoadmapProgressModel) {
    //descending
    const dateA = new Date(a.createdAt).getTime();
    const dateB = new Date(b.createdAt).getTime();

    if (dateA < dateB) {
      return 1;
    } else if (dateA > dateB) {
      return -1;
    } else {
      return 0;
    }
  }

  dismissModal(): void {
    this.activeModal.dismiss('Roadmap cancelled');
  }

  changesDetected(): boolean {
    if (
      Object.entries(this.roadmap)?.toString() ===
      Object.entries(this.currentRoadmap)?.toString()
    ) {
      return false; //no roadmap changes
    } else {
      return true; //changes found, ask to save
    }
  }

  openConfirmationDialog = (sendData: string, roadmapForm: NgForm) => {
    if (this.changesDetected()) {
      this.confirmationDialogService
        .confirm('Save', 'Do you want to save your changes?')
        .then((confirmed) => {
          if (confirmed) {
            //selected Yes
            this.buttonSubmit.nativeElement.click();
            if (roadmapForm.form.valid) {
              // and passes validation
              this.closeModal(sendData, roadmapForm);
            }
          } else {
            //selected No
            this.closeModal(sendData, roadmapForm);
          }
        })
        .catch(() => console.error());
    } else {
      //no changes detected
      this.closeModal(sendData, roadmapForm);
    }
  };

  retrieveInitiatives = (productId: string): void => {
    this.rallyAdoInitiativesService
      .getAllByProductId(productId)
      .subscribe(async (data) => {
        this.allinitiatives = data;
      });
  };

  retrieveADOInitiatives = (productId: string): void => {
    this.rallyAdoInitiativesService
      .getAllADOByProductId(productId)
      .subscribe(async (data) => {
        this.allADOinitiatives = data;
      });
  };

  retrieveRallyInitiatives = (productId: string): void => {
    this.rallyAdoInitiativesService
      .getAllRallyByProductId(productId)
      .subscribe(async (data) => {
        this.allRallyinitiatives = data;
      });
  };


  retrieveADOInitiativesPG = (productGroupId: string): void => {
    this.rallyAdoInitiativesService
      .getAllADOByProductGroupId(productGroupId)
      .subscribe(async (data) => {
        this.allADOinitiatives = data;
      });
  };

  retrieveRallyInitiativesPG = (productGroupId: string): void => {
    this.rallyAdoInitiativesService
      .getAllRallyByProductrGroupId(productGroupId)
      .subscribe(async (data) => {
        this.allRallyinitiatives = data;
      });
  };


  retrieveBacklogsToProducts = (productId: string): void => {
    this.backlogToProductsService
      .getAllbacklogToProducts(productId)
      .subscribe(async (data) => {
        this.backlogsToProducts = data;
      });
  };

  closeModal = (sendData: string, roadmapForm: NgForm): void => {
    if (sendData === 'cancel') {
      roadmapForm.resetForm();
      this.activeModal.close(sendData);
    } else if (sendData === 'add') {
      //add new progress
      this.activeModal.close(sendData);
      this.openRoadmapProgress(true);
    } else if (sendData === 'progress') {
      //viewing all progress
      this.activeModal.close(sendData);
      this.openRoadmapProgress(false);
    }
  };

  openRoadmapProgress = (isNew: boolean): void => {
    let useModal: any;
    if (isNew) {
      useModal = RoadmapProgressAddComponent;
    } else {
      useModal = RoadmapProgressViewComponent;
    }

    if (this.modalService.hasOpenModals) {
      this.modalService.dismissAll('Open-RoadmapProgress');
    }
    const modalRef = this.modalService.open(useModal, {
      windowClass: 'roadmap-modal',
      centered: true,
      keyboard: false,
      backdrop: 'static',
    });
    modalRef.componentInstance.roadmap = this.roadmap;
    modalRef.componentInstance.roadmapType = this.roadmapType;
    modalRef.result.then(
      (result) => {
        let usePriorityTab = false;
        if (this.fromModal === 'editRoadmapPriority') {
          usePriorityTab = true;
        }
        const tabs = {
          tabId: 2,
          tabObjectiveId: this.objectiveId,
          priorityTab: usePriorityTab,
        };
        this.eventEmitterService.retrieveObjectives(false, tabs);
      },
      (reason) => {}
    );
  };

  async onSubmitForm(): Promise<void> {
    if (!this.validateMilestone()) {
      return;
    }

    //populate roadmap other level for modal close event for roadmap service to create/update
    this.roadmap.roadmapOtherLevel = [];
    this.selectedRoadmaps.forEach((rmElement) => {
      this.roadmap.roadmapOtherLevel.push({
        roadmapId: this.roadmap.id,
        otherRoadmapId: rmElement,
      });
    });

    this.authService.profile$.subscribe((p) => {
      this.roadmap.userId = p.BadgeNo.substring(2);
    });
    this.roadmap.totalEst = this.totalEst;

    //compare the unique copy of this.selectedObjectives with the current this.selectedObjectives
    //if an id no longer exists, call delete with roadmap id and objective id
    const removedObjectives = this.uniqueObjectives.filter(
      (item) => this.selectedObjectives.indexOf(item) < 0
    );
    if (removedObjectives.length > 0) {
      removedObjectives.forEach((objective) => {
        const deleteSortItem = {
          roadmapId: this.roadmap.id,
          objectiveId: objective,
        };
        this.roadmapSortService.delete(deleteSortItem).toPromise();
        const findObjective = this.objectives.find((x) => x.id === objective);
        if (findObjective) {
          if (
            findObjective.objectiveType === 'PRODUCT' ||
            findObjective.objectiveType === 'PRODUCT_GROUP'
          ) {
            const deletePriorityItem = {
              roadmapId: this.roadmap.id,
              taxonomyId:
                findObjective.objectiveType === 'PRODUCT'
                  ? findObjective.productId
                  : findObjective.productGroupId,
            };
            this.roadmapPriorityService.delete(deletePriorityItem).toPromise();
          }
        }
      });
    }

    if (this.selectedOwnerId) {
      this.roadmap.ownerId = this.selectedOwnerId;
    } else if (
      this.selectedOwnerId === undefined ||
      this.selectedOwnerId === null
    ) {
      this.roadmap.ownerId = null;
    }

    this.roadmap.keyResults = [];
    this.objectives.forEach((objective) => {
      if (this.selectedObjectives.includes(objective.id)) {
        const krs = objective.keyResults.map((kr) => ({ id: kr.id }));
        this.roadmap.keyResults.push(...krs);
      }
    });
    if (this.roadmap.valueTimeframe) {
      if (this.roadmap.quarter && this.roadmap.year && this.roadmap.month) {
        this.roadmap.valueTimeframe = '';
      }
    }

    if (this.existingRoadmapItem) {
      await this.checkAttachedDoc();
    }

    this.activeModal.close(this.roadmap);
  }

  validateMilestone() {
    if (this.roadmap.progressState === 'Discovering') {
      //dates are optional
      if (this.roadmap.quarter === '0' || this.roadmap.year === '0') {
        return false;
      }
    } else if (this.roadmap.progressState !== 'Backlog') {
      if (
        this.roadmap.quarter === '0' ||
        this.roadmap.month === '0' ||
        this.roadmap.year === '0'
      ) {
        return false;
      }
    }
    return true;
  }

  resetDate() {
    this.roadmap.quarter = '0';
    this.roadmap.month = '0';
    this.roadmap.year = '0';
  }
  resetFinanceData($event) {
    if ($event.target.checked === false) {
      this.confirmationDialogService
        .confirm(

          'Head\'s up!',
          'Unchecking this box will reset the funding values below. Are you sure?',
          'Yes',
          'No',
          ''
        )
        .then((confirmed) => {
          if (confirmed) {
            //selected Yes
            this.roadmap.refreshCompFlag = false;
            this.roadmap.hardwareEst = '0';
            this.roadmap.softwareEst = '0';
            this.roadmap.thirdPartyEst = '0';
            this.roadmap.totalEst = 0;
            this.totalEst = 0;
          } else {
            this.roadmap.requiresAttachment = true;
          }
        });
    }
  }

  resetMonth() {
    this.roadmap.month = '0';
  }

  showObjective(objective: ObjectiveModel): boolean {
    //Dont display draft objectives
    if (objective.draft) {
      return false;
    }

    if (
      this.objectiveType === 'PRODUCT' ||
      this.objectiveType === 'PRODUCT_GROUP'
    ) {
      return (
        (this.selectedProducts.some(
          (product: ProductModel) =>
            product.productGroup.id === objective.productGroupId
        ) ||
          this.selectedProductGroups.includes(+objective.productGroupId)) &&
        objective.keyResults.length > 0
      );
    } else if (this.objectiveType === 'PORTFOLIO') {
      if (objective.keyResults.length > 0) {
        return true;
      }
    } else if (this.objectiveType === 'ENTERPRISE') {
      if (objective.keyResults.length > 0) {
        return true;
      }
    }
  }

  onKeyDown(inputField: HTMLInputElement, $e: KeyboardEvent): void {
    this.utilsService.onKeyDownNumbers(inputField, $e);
  }

  onPaste(inputField: HTMLInputElement, $e: ClipboardEvent): void {
    this.utilsService.onPasteNumbers(inputField, $e);
  }

  onDrop(inputField: HTMLInputElement, $e: DragEvent): void {
    this.utilsService.onDropNumbers(inputField, $e);
  }

  searchUser(search: string) {
    if (search?.length >= 3) {
      this.graphqlQueriesService
        .getOwnerName(search)
        .subscribe((result: any) => {
          this.loading = result?.loading;
          this.userSearchResults = result?.data?.userSearch?.users.map(
            (user) => ({
              name: `${user.displayName} [${user.employeeNumber}]`,
              value: `${user.employeeNumber}`,
            })
          );
          if (this.userSearchResults.length === 1) {
            this.selectedOwnerName = this.userSearchResults[0].name;
          }
        });
    }
  }

  selectUser(event: any) {
    this.selectedOwnerId = event.value;
    this.selectedOwnerName = event.name;
  }

  clearUser(event: any) {
    this.selectedOwnerId = null;
  }

  async checkAttachedDoc() {
    const res: ExternalLinkModel[] = await this.externalLinkService
      .getByParentId(this.roadmap.id)
      .toPromise();
    if (res) {
      this.roadmap.hasAttachedDocument = res.length > 0 ? 1 : 0;
    }
  }

  showRallyAlert() {
    if (
      this.backlogSource === 'RALLY' &&
      this.allRallyinitiatives.length === 0
    ) {
      return true;
    } else {
      return false;
    }
  }
  showADOAlert() {
    if (
      this.backlogSource === 'ADO CLOUD' &&
      this.allADOinitiatives.length === 0
    ) {
      return true;
    } else {
      return false;
    }
  }
}
