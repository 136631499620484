import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {VisionStatementModel} from 'src/app/models/vision-statement.model';
import {NgForm} from '@angular/forms';
import { ProductModel } from 'src/app/models/product.model';
import { ProductGroupModel } from 'src/app/models/product-group.model';
import { VisionStatementService } from 'src/app/services/vision-statement.service';
import { PlanningPortfolioModel } from 'src/app/models/planning-portfolio.model';
import { isNumber } from '@ng-bootstrap/ng-bootstrap/util/util';

@Component({
  selector: 'app-vision-statement',
  templateUrl: './vision-statement.component.html',
  styleUrls: ['./vision-statement.component.scss']
})
export class VisionStatementComponent implements OnInit {
  static componentInstance: any;

  @Input() objectiveType: string;
  @Input() selectedProduct: ProductModel;
  @Input() selectedProductGroup: ProductGroupModel;
  @Input() planningPortfolioId: number;

  existingVisionStatement = false;
  parentId: string;
  editMode = false;
  prevStatement: string;
  visionMsg: string;

  vision: VisionStatementModel = {
    parentId: '',
    visionStatement: ''
  };

  constructor(private visionStatementService: VisionStatementService) { }

  ngOnInit(): void {
    this.visionMsg = 'Vision Statement';
    if (this.objectiveType === 'PRODUCT' && this.selectedProduct) {
      this.parentId = this.selectedProduct.id;
    } else if (this.objectiveType === 'PRODUCT_GROUP' && this.selectedProductGroup) {
      this.parentId = this.selectedProductGroup.id;
    } else if (this.objectiveType === 'PORTFOLIO' && this.planningPortfolioId) {
      this.visionMsg = 'Primary Goal';
      this.parentId = String(this.planningPortfolioId);
    } else if (this.objectiveType === 'ENTERPRISE') {
      this.parentId = 'ENTERPRISE';
    }

    this.retrieveVisionStatement(this.parentId);
  }

  retrieveVisionStatement(parentId: string): void {
    this.visionStatementService.getVisionStatementById(parentId).subscribe(
      response => {
        if (response === null) {
          this.vision.parentId = this.parentId;
        } else {
          this.vision = response;
          this.prevStatement = response.visionStatement;
          this.existingVisionStatement = true;
        }
      },
      error => {
        console.error(error);
      });
  }

  saveVisionStatement(visionStatementForm?: NgForm): void {
    if (!this.existingVisionStatement) {
      this.visionStatementService.create(this.vision).subscribe(
        response => {
          this.retrieveVisionStatement(this.parentId);
          this.editMode = false;
        },
        error => {
          console.error(error);
        });
      } else {
        this.visionStatementService.update(this.vision).subscribe(
          response => {
          this.retrieveVisionStatement(this.parentId);
          this.editMode = false;
        },
        error => {
          console.error(error);
        });
      }
  }
}
