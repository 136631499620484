<form name="form" class="form-horizontal" #objectiveslogForm="ngForm" novalidate>
    <div class="modal-content">
        <div class="modal-header">
            <h2 class="modal-title">View History</h2>
            <button type="button" class="btn-close" aria-label="Close" (click)="closeModal('cancel', objectiveslogForm)"></button>
        </div>
        <div class="modal-body text-start" *ngIf="pageLoaded | async">
            <div class="row pb-2">
                <div class="col-12">
                    <label for="outcome" class="form-label fw-bold">Objective:</label>  
                    <div id="outcome" class=" bg-light">{{ this.objective.description }}</div>
                </div>
            </div>

            <div *ngIf="noObjectiveLogs">
                <hr>
                <div class="row">
                    <div class="col-12">
                    No History Found
                    </div>
                </div>
            </div>
            <div *ngIf="!noObjectiveLogs">
                <hr>                
                <div *ngIf="pageLoaded" class="px-2">
                    <div class="row grid-striped" *ngFor="let objectivesLog of objectivesLogEntries;">
                        <div class="row">
                            <div class="col-12">
                                <span *ngIf="objectivesLog.operation === 'I'">
                                {{ objectivesLog.sort }}). {{ objectivesLog.updatedAt | date: 'MMM d, y, h:mm a' }}: {{ objectivesLog.userName }} - created.
                                </span>
                                <span *ngIf="objectivesLog.operation === 'U' && objectivesLog.oldStatus !== objectivesLog.newStatus">
                                    {{ objectivesLog.sort }}). {{ objectivesLog.updatedAt | date: 'MMM d, y, h:mm a' }}: {{ objectivesLog.userName }} - Status updated from
                                    {{ objectivesLog.oldStatus }} to {{ objectivesLog.newStatus }}.
                                </span>
                                <span *ngIf="objectivesLog.operation === 'U' && objectivesLog.oldStatus === objectivesLog.newStatus">
                                    {{ objectivesLog.sort }}). {{ objectivesLog.updatedAt | date: 'MMM d, y, h:mm a' }}: {{ objectivesLog.userName }} - updated.                                    
                                </span>  
                            </div>
                        </div>                  
                    </div>
                </div>
            </div>          
            <hr>
        </div>   
  
  <div class="modal-footer">
    <button (click)="closeModal('cancel', objectiveslogForm)" type="button" class="btn btn-secondary">Cancel</button>    
  </div>
</div>
</form>