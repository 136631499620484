import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { PlanningPortfolioModel } from '../models/planning-portfolio.model';
import { OKRProductPortfolioGroupModel } from '../models/okr-product-portfolio.model';

@Injectable({
  providedIn: 'root'
})
export class PlanningPortfoliosService {

  constructor(@Inject(HttpClient) private http: HttpClient) { }

  getAll(): Observable<PlanningPortfolioModel[]> {
    return this.http.get<PlanningPortfolioModel[]>(`${environment.baseURL}/planning-portfolios`);
  }

  getByProductPortfolioId(planPortId: number): Observable<OKRProductPortfolioGroupModel[]> {
    //returns the planning portfolios by the product portfolio group ID
    let params = new HttpParams();
    params = params.set('productportfolioid', planPortId);
    return this.http.get<OKRProductPortfolioGroupModel[]>(`${environment.baseURL}/planning-portfolios`, { params });
  }

  getPlanningPortfolioByEmpNum(empNumber: string): Observable<any> {
    let params = new HttpParams();
    params = params.set('empnumber', empNumber);
    return this.http.get<any>(`${environment.baseURL}/planning-portfolios`, { params });
  }

  getByPlanningPortfolioId(planPortId: number): Observable<OKRProductPortfolioGroupModel[]> {
    //returns the product portfolio groups by planning portfolio ID
    let params = new HttpParams();
    params = params.set('planningportfolioid', planPortId);
    return this.http.get<OKRProductPortfolioGroupModel[]>(`${environment.baseURL}/planning-portfolios`, { params });
  }

}
