<div *ngIf="pageLoaded" class="px-2">
    <h4>{{title}}</h4>
    <hr>
    <div *ngIf="!allLinks || allLinks.length === 0" class="row px-2 py-1">
        No {{title}} found.
    </div>
    <div *ngIf="allLinks">
        <ng-container *ngFor="let link of allLinks">
            <div class="row grid-striped py-1">
                <div *ngIf="!link.editMode" class="col-11">
                    <a href='{{link.url}}' target='_blank' title='{{link.url}}'>{{link.displayName}}</a>
                </div>
                <div *ngIf="link.editMode" class="col-3">
                    <input
                    id="displayNameEdit"
                    type="text"
                    name="displayNameEdit"
                    #displayNameEdit="ngModel"
                    class="form-control editInput"
                    [(ngModel)]="link.displayName"
                    [ngClass]="{'is-invalid': link.editMode && displayNameEdit.value === ''}"
                    placeholder="display name"
                    (keydown.enter)="$event.preventDefault()"
                    (keydown.shift.enter)="$event.preventDefault()"
                    maxlength="255"
                    required>
                    <div class="invalid-feedback">
                        <div> Required</div>
                    </div>
                </div>
                <div *ngIf="link.editMode" class="col-7">
                    <input
                    id="urlEdit"
                    type="text"
                    name="urlEdit"
                    #urlEdit="ngModel"
                    class="form-control editInput"
                    [(ngModel)]="link.url"
                    [ngClass]="{'is-invalid': link.editMode && urlEdit.value === ''}"
                    placeholder="URL"
                    (keydown.enter)="$event.preventDefault()"
                    (keydown.shift.enter)="$event.preventDefault()"
                    required>
                    <div class="invalid-feedback">
                        <div> Required</div>
                    </div>
                </div>
                <div class="col editButtons">
                    <div *ngIf="!link.editMode">
                        <a href="#" (click)="editExternalLink($event, link)"><span title="Edit" class="bi-pencil-square fs-5 p-0"></span></a>
                        <a href="#" (click)="delete($event, link)"><span title="Delete" class="bi bi-trash fs-5 px-1"></span></a>
                    </div>
                    <div *ngIf="link.editMode">
                        <a href="#" (click)="updateExternalLink($event, link)"><span title="Save" class="bi-check-square-fill fs-5 p-1"></span></a>
                        <a href="#" (click)="cancelEdit($event, link)"><span title="Cancel" class="bi-x-square fs-5 p-0"></span></a>
                        <a href="#" (click)="delete($event, link)"><span title="Delete" class="bi bi-trash fs-5 px-1"></span></a>
                    </div>
                </div>
            </div>
        </ng-container>
    </div>
    <div *ngIf="!isAdding" class="py-2">
        <button type="button" class="btn btn-primary btn-sm" (click)="toggleAddExternal()">
            <i class="bi-plus"></i>Add
        </button>
    </div>
    <div *ngIf="isAdding" class="row py-2">
        <div class="col-3">
            <input
            id="displayNameAdd"
            type="text"
            name="displayNameAdd"
            class="form-control editInput"
            #displayNameAdd="ngModel"
            [(ngModel)]="addLink.displayName"
            [ngClass]="{'is-invalid': isSaving && displayNameAdd.invalid}"
            placeholder="display name"
            (keydown.enter)="$event.preventDefault()"
            (keydown.shift.enter)="$event.preventDefault()"
            maxlength="255"
            required>
            <div class="invalid-feedback">
                <div> Required</div>
            </div>
        </div>
        <div class="col-7">
            <input
            id="urlAdd"
            type="text"
            name="urlAdd"
            #urlAdd = "ngModel"
            class="form-control editInput"
            [(ngModel)]="addLink.url"
            [ngClass]="{'is-invalid': isSaving && urlAdd.invalid}"
            placeholder="URL"
            (keydown.enter)="$event.preventDefault()"
            (keydown.shift.enter)="$event.preventDefault()"
            required>
            <div class="invalid-feedback">
                <div> Required</div>
            </div>
        </div>
        <div class="col btn-group">
            <button type="button" class="btn btn-primary btn-sm" (click)="saveNewExternalLink($event, link)">Save</button>
            <button type="button" class="btn btn-secondary btn-sm" (click)="toggleAddExternal()">Cancel</button>
        </div>
    </div>
</div>