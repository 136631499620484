<div class="mt-3 mx-5 padOuter">
    <app-roadmap-filter [milestoneYears]="years" (selectState)="filterRoadmapByState($event)" (selectQuarter)="filterRoadmapByQuarter($event)"
        (selectYear)="filterRoadmapByYear($event)" (selectMonth)="filterRoadmapByMonth($event)"></app-roadmap-filter>
    <div class="intro p-2" *ngIf="this.objectiveType === 'PRODUCT' || this.objectiveType === 'PRODUCT_GROUP'">
        Prioritize all your roadmap items in one place. Consider de-prioritizing items that don't make your top 10.
    </div>
    <div class="intro p-2" *ngIf="this.objectiveType === 'PORTFOLIO'">
        Prioritize all your portfolio initiatives in one place. Consider de-prioritizing items that don't make your top 10.
    </div>
    <div *ngIf="this.objectiveType === 'PORTFOLIO'">
        <div class="row header p-2">
            <div class="col-md-1 text-center">
                Priority
            </div>
            <div class="col-md-2">
                Initiative
            </div>
            <div class="col-md-1">
                Owner
            </div>
            <div class="col-md-1">
                Delivery Milestone
            </div>
            <div class="col-md-1">
                State
            </div>
            <div class="col-md-1">
                % Complete
            </div>
            <div class="col-md-1">
                Confidence Level
            </div>
            <div class="col-md-2">
                Current Value Delivered
            </div>
            <div class="col-md-1">
                Next Idea
            </div>
            <div class="col-md-1"></div>
        </div>
        <div *ngIf="allPortfolioRoadmaps.length === 0" class="pb-5"> 
            No Portfolio Initiatives Found
        </div>
        <div *ngIf="filterYears.length===0 && filterMonths.length===0 && filterQuarters.length===0 && ((filterStates.length===4 && !filterStates.includes('Done')) || (filterStates.length===5 || filterStates.length===0))">
            <div *ngFor="let roadmap of allPortfolioRoadmaps; let i = index">
                <div class="row p-2"
                    [class.draggable]="(roadmap.progressState === 'Done') ? false: true"
                    [draggable]="(roadmap.progressState === 'Done') ? false: true"
                    [class.active]="draggedOverIndex === i && dragType==='PORTFOLIO'"
                    (dragover)="allowDrop($event, i, 'PORTFOLIO', roadmap.progressState)"
                    (dragstart)="onDragStart(i, 'PORTFOLIO')"
                    (dragleave)="dragLeave($event)"
                    (drop)="onDrop($event, i, 'PORTFOLIO')">
                    <div class="col-md-1 text-center priority">
                    {{ roadmap.priority }}
                    </div>
                    <div class="col-md-2">
                        {{ roadmap.description }}&nbsp;&nbsp;&nbsp;
                        <span class="bi bi-info-circle desc-hover-icon" style="font-size: 16px" title="Description &#13;{{ roadmap.impactEstimate | shorten: 256 }}" *ngIf="roadmap.impactEstimate;else noDescription"></span>
                        <ng-template #noDescription><span class="bi bi-info-circle desc-hover-icon" style="font-size: 16px" title="Description not yet updated"></span></ng-template>
                        <span class="bi bi-star-fill fundstar" style="font-size: 16px" title="{{ ((roadmap.totalEst && roadmap.totalEst >0) ? roadmap.totalEst: 0) | currency : 'USD' : 'symbol' : '1.0-0' }}; Additional funding documents attached." *ngIf="roadmap.requiresAttachment && roadmap.hasAttachedDocument"></span>
                        <span class="bi bi-star fundstar" style="font-size: 16px" title="{{ ((roadmap.totalEst && roadmap.totalEst >0) ? roadmap.totalEst: 0) | currency : 'USD' : 'symbol' : '1.0-0' }}; No documents attached." *ngIf="roadmap.requiresAttachment && !roadmap.hasAttachedDocument "></span>
                    </div>
                    <div class="col-md-1">
                        <span *ngIf="roadmap.ownerId">{{ roadmap.ownerName }} [{{ roadmap.ownerId }}]</span>
                    </div>
                    <div class="col-md-1">
                        {{ setMilestone(roadmap) }}
                    </div>
                    <div class="col-md-1">
                        {{ roadmap.progressState }}<br> {{ roadmap.doneTime | date: 'MMM d, y, h:mm a' }}
                    </div>
                    <div class="col-md-1">
                        {{ roadmap.progressPercentPlannedWorkCompleted }}<span *ngIf="roadmap.progressPercentPlannedWorkCompleted">%</span>
                    </div>
                    <div class="col-md-1">
                        {{ roadmap.progressConfidenceLevel }}
                    </div>
                    <div class="col-md-2">
                        {{ roadmap.progressCurrentValueDelivered }}
                    </div>
                    <div class="col-md-1">
                        <span class="bi bi-lightbulb-fill desc-hover-icon" style="font-size: 16px" title="{{ roadmap.progressNextBestIdeas | shorten: 256 }}" *ngIf="roadmap.progressNextBestIdeas;else noIdeas"></span>
                        <ng-template #noIdeas>
                            <span></span> <!--If no ideas, display no icon!-->
                        </ng-template>
                    </div>
                    <div class="col text-end">
                        <a class="bi bi-three-dots-vertical fs-5" role="button" id="dropdownMoreInfoRoadmap"
                            data-bs-toggle="dropdown" aria-expanded="false" *ngIf="!readOnly"></a>
                        <ul class="dropdown-menu dropdown-menu-light text-small shadow"
                            aria-labelledby="dropdownMoreInfoRoadmap">
                            <li><a class="dropdown-item" role="button" data-toggle="modal" data-target="#editRoadmap"
                                (click)="onEditRoadmap(roadmap.id)"><i class="pe-2 bi-pencil-square"></i>
                                Edit
                                </a>
                            </li>
                            <li><a class="dropdown-item" role="button" data-toggle="modal"
                                data-target="#newKRT" (click)="onRoadmapProgress(true, roadmap)"><i
                                class="pe-2 bi-calendar-plus"></i>Add Progress</a>
                            </li>
                            <li><a class="dropdown-item" role="button" data-toggle="modal"
                                data-target="#newKRT" (click)="onRoadmapProgress(false, roadmap)"><i
                                class="pe-2 bi-calendar-check"></i>View Progress</a>
                            </li>
                            <li>
                                <a class="dropdown-item" role="button" data-toggle="modal" data-target="#viewObjectives"
                                (click)="viewObjectives(roadmap, '', '', planningPortfolioId)"><i class="pe-2 bi-card-text"></i>
                                View OKRs
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="!(filterYears.length===0 && filterMonths.length===0 && filterQuarters.length===0 && ((filterStates.length===4 && !filterStates.includes('Done')) || (filterStates.length===5 || filterStates.length===0)))">
            <div *ngFor="let roadmap of allPortfolioRoadmaps; let i = index">
                <div class="row p-2">
                    <div class="col-md-1 text-center priority">
                    {{ roadmap.priority }}
                    </div>
                    <div class="col-md-2">
                        {{ roadmap.description }}&nbsp;&nbsp;&nbsp;
                        <span class="bi bi-info-circle desc-hover-icon" style="font-size: 16px" title="Description &#13;{{ roadmap.impactEstimate | shorten: 256 }}" *ngIf="roadmap.impactEstimate;else noDescription"></span>
                        <ng-template #noDescription><span class="bi bi-info-circle desc-hover-icon" style="font-size: 16px" title="Description not yet updated"></span></ng-template>
                        <span class="bi bi-star-fill fundstar" style="font-size: 16px" title="{{ ((roadmap.totalEst && roadmap.totalEst >0) ? roadmap.totalEst: 0) | currency : 'USD' : 'symbol' : '1.0-0' }}; Additional funding documents attached." *ngIf="roadmap.requiresAttachment && roadmap.hasAttachedDocument"></span>
                        <span class="bi bi-star fundstar" style="font-size: 16px" title="{{ ((roadmap.totalEst && roadmap.totalEst >0) ? roadmap.totalEst: 0) | currency : 'USD' : 'symbol' : '1.0-0' }}; No documents attached." *ngIf="roadmap.requiresAttachment && !roadmap.hasAttachedDocument "></span>
                    </div>
                    <div class="col-md-1">
                        <span *ngIf="roadmap.ownerId">{{ roadmap.ownerName }} [{{ roadmap.ownerId }}]</span>
                    </div>
                    <div class="col-md-1">
                        {{ setMilestone(roadmap) }}
                    </div>
                    <div class="col-md-1">
                        {{ roadmap.progressState }}<br> {{ roadmap.doneTime | date: 'MMM d, y, h:mm a' }}
                    </div>
                    <div class="col-md-1">
                        {{ roadmap.progressPercentPlannedWorkCompleted }}<span *ngIf="roadmap.progressPercentPlannedWorkCompleted">%</span>
                    </div>
                    <div class="col-md-1">
                        {{ roadmap.progressConfidenceLevel }}
                    </div>
                    <div class="col-md-2">
                        {{ roadmap.progressCurrentValueDelivered }}
                    </div>
                    <div class="col-md-1">
                        <span class="bi bi-lightbulb-fill desc-hover-icon" style="font-size: 16px" title="{{ roadmap.progressNextBestIdeas | shorten: 256 }}" *ngIf="roadmap.progressNextBestIdeas;else noIdeas"></span>
                        <ng-template #noIdeas>
                            <span></span> <!--If no ideas, display no icon!-->
                        </ng-template>
                    </div>
                    <div class="col text-end">
                        <a class="bi bi-three-dots-vertical fs-5" role="button" id="dropdownMoreInfoRoadmap"
                            data-bs-toggle="dropdown" aria-expanded="false" *ngIf="!readOnly"></a>
                        <ul class="dropdown-menu dropdown-menu-light text-small shadow"
                            aria-labelledby="dropdownMoreInfoRoadmap">
                            <li><a class="dropdown-item" role="button" data-toggle="modal" data-target="#editRoadmap"
                                (click)="onEditRoadmap(roadmap.id)"><i class="pe-2 bi-pencil-square"></i>
                                Edit
                                </a>
                            </li>
                            <li><a class="dropdown-item" role="button" data-toggle="modal"
                                data-target="#newKRT" (click)="onRoadmapProgress(true, roadmap)"><i
                                class="pe-2 bi-calendar-plus"></i>Add Progress</a>
                            </li>
                            <li><a class="dropdown-item" role="button" data-toggle="modal"
                                data-target="#newKRT" (click)="onRoadmapProgress(false, roadmap)"><i
                                class="pe-2 bi-calendar-check"></i>View Progress</a>
                            </li>
                            <li>
                                <a class="dropdown-item" role="button" data-toggle="modal" data-target="#viewObjectives"
                                (click)="viewObjectives(roadmap, '', '', planningPortfolioId)"><i class="pe-2 bi-card-text"></i>
                                View OKRs
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div class="text-end pt-2"></div>
    </div>
    <div *ngIf="this.objectiveType === 'PRODUCT_GROUP'">
        <div class="row header p-2">
            <div class="col-md-1 text-center">
                Priority
            </div>
            <div class="col-md-2">
                Initiative
            </div>
            <div class="col-md-1">
                Owner
            </div>
            <div class="col-md-1">
                Delivery Milestone
            </div>
            <div class="col-md-1">
                State
            </div>
            <div class="col-md-1">
                % Complete
            </div>
            <div class="col-md-1">
                Confidence Level
            </div>
            <div class="col-md-1">
                Current Value Delivered
            </div>
            <div class="col-md-1">
                Next Idea
            </div>
            <div class="col-md-1">
                Funding
            </div>
            
        </div>
        <div *ngIf="allProductGroupRoadmaps.length === 0" class="pb-5"> 
            No Product Group Roadmaps Found
        </div>
        <div *ngIf="filterYears.length===0 && filterMonths.length===0 && filterQuarters.length===0 && ((filterStates.length===4 && !filterStates.includes('Done')) || (filterStates.length===5 || filterStates.length===0))">
            <div *ngFor="let roadmap of allProductGroupRoadmaps; let i = index">
                <div class="row p-2"
                    [class.draggable]="(roadmap.progressState === 'Done') ? false: true"
                    [draggable]="(roadmap.progressState === 'Done') ? false: true"
                    [class.active]="draggedOverIndex === i"
                    (dragover)="allowDrop($event, i, 'PRODUCT_GROUP', roadmap.progressState)"
                    (dragstart)="onDragStart(i, 'PRODUCT_GROUP')"
                    (dragleave)="dragLeave($event)"
                    (drop)="onDrop($event, i, 'PRODUCT_GROUP')">
                    <div class="col-md-1 text-center priority">
                    {{ roadmap.priority }}
                    </div>
                    <div class="col-md-2">
                        {{ roadmap.description }}&nbsp;&nbsp;&nbsp;
                        <span class="bi bi-info-circle desc-hover-icon" style="font-size: 16px" title="Description &#13;{{ roadmap.impactEstimate | shorten: 256 }}" *ngIf="roadmap.impactEstimate;else noDescription"></span>
                        <ng-template #noDescription><span class="bi bi-info-circle desc-hover-icon" style="font-size: 16px" title="Description not yet updated"></span></ng-template>
                        <span class="bi bi-star-fill fundstar" style="font-size: 16px" title="{{ ((roadmap.totalEst && roadmap.totalEst >0) ? roadmap.totalEst: 0) | currency : 'USD' : 'symbol' : '1.0-0' }}; Additional funding documents attached." *ngIf="roadmap.requiresAttachment && roadmap.hasAttachedDocument"></span>
                        <span class="bi bi-star fundstar" style="font-size: 16px" title="{{ ((roadmap.totalEst && roadmap.totalEst >0) ? roadmap.totalEst: 0) | currency : 'USD' : 'symbol' : '1.0-0' }}; No documents attached." *ngIf="roadmap.requiresAttachment && !roadmap.hasAttachedDocument "></span>
                    </div>
                    <div class="col-md-1">
                        <span *ngIf="roadmap.ownerId">{{ roadmap.ownerName }} [{{ roadmap.ownerId }}]</span>
                    </div>
                    <div class="col-md-1">
                        {{ setMilestone(roadmap) }}
                    </div>
                    <div class="col-md-1">
                        {{ roadmap.progressState }}<br> {{ roadmap.doneTime | date: 'MMM d, y, h:mm a' }}
                    </div>
                    <div class="col-md-1">
                        {{ roadmap.progressPercentPlannedWorkCompleted }}<span *ngIf="roadmap.progressPercentPlannedWorkCompleted">%</span>
                    </div>
                    <div class="col-md-1">
                        {{ roadmap.progressConfidenceLevel }}
                    </div>
                    <div class="col-md-1">
                        {{ roadmap.progressCurrentValueDelivered }}
                    </div>
                    <div class="col-md-1">
                        <span class="bi bi-lightbulb-fill desc-hover-icon" style="font-size: 16px" title="{{ roadmap.progressNextBestIdeas | shorten: 256 }}" *ngIf="roadmap.progressNextBestIdeas;else noIdeas"></span>
                        <ng-template #noIdeas>
                            <span></span> <!--If no ideas, display no icon!-->
                        </ng-template>
                    </div>
                    <div class="col-md-1">
                        <span class="bi bi-currency-dollar desc-hover-icon" style="font-size: 16px" title="{{ roadmap.requiresAttachment | shorten: 256 }}" *ngIf="roadmap.requiresAttachment;else notFunded" ></span>
                        <ng-template #notFunded>
                            <span></span> <!--If not funded, display no icon!-->
                        </ng-template>
                    </div>
                    <div class="col-md-1 text-end">
                        <a class="bi bi-three-dots-vertical fs-5" role="button" id="dropdownMoreInfoRoadmap"
                            data-bs-toggle="dropdown" aria-expanded="false" *ngIf="!readOnly"></a>
                        <ul class="dropdown-menu dropdown-menu-light text-small shadow"
                            aria-labelledby="dropdownMoreInfoRoadmap">
                            <li><a class="dropdown-item" role="button" data-toggle="modal" data-target="#editRoadmap"
                                (click)="onEditRoadmap(roadmap.id)"><i class="pe-2 bi-pencil-square"></i>
                                Edit
                                </a>
                            </li>
                            <li><a class="dropdown-item" role="button" data-toggle="modal"
                                data-target="#newKRT" (click)="onRoadmapProgress(true, roadmap)"><i
                                class="pe-2 bi-calendar-plus"></i>Add Progress</a>
                            </li>
                            <li><a class="dropdown-item" role="button" data-toggle="modal"
                                data-target="#newKRT" (click)="onRoadmapProgress(false, roadmap)"><i
                                class="pe-2 bi-calendar-check"></i>View Progress</a>
                            </li>
                            <li>
                                <a class="dropdown-item" role="button" data-toggle="modal" data-target="#viewObjectives"
                                (click)="viewObjectives(roadmap, roadmap.productId, roadmap.productGroupId)"><i class="pe-2 bi-card-text"></i>
                                View OKRs
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="!(filterYears.length===0 && filterMonths.length===0 && filterQuarters.length===0 && ((filterStates.length===4 && !filterStates.includes('Done')) || (filterStates.length===5 || filterStates.length===0)))">
            <div *ngFor="let roadmap of allProductGroupRoadmaps; let i = index">
                <div class="row p-2">
                    <div class="col-md-1 text-center priority">
                    {{ roadmap.priority }}
                    </div>
                    <div class="col-md-2">
                        {{ roadmap.description }}&nbsp;&nbsp;&nbsp;
                        <span class="bi bi-info-circle desc-hover-icon" style="font-size: 16px" title="Description &#13;{{ roadmap.impactEstimate | shorten: 256 }}" *ngIf="roadmap.impactEstimate;else noDescription"></span>
                        <ng-template #noDescription><span class="bi bi-info-circle desc-hover-icon" style="font-size: 16px" title="Description not yet updated"></span></ng-template>
                        <span class="bi bi-star-fill fundstar" style="font-size: 16px" title="{{ ((roadmap.totalEst && roadmap.totalEst >0) ? roadmap.totalEst: 0) | currency : 'USD' : 'symbol' : '1.0-0' }}; Additional funding documents attached." *ngIf="roadmap.requiresAttachment && roadmap.hasAttachedDocument"></span>
                        <span class="bi bi-star fundstar" style="font-size: 16px" title="{{ ((roadmap.totalEst && roadmap.totalEst >0) ? roadmap.totalEst: 0) | currency : 'USD' : 'symbol' : '1.0-0' }}; No documents attached." *ngIf="roadmap.requiresAttachment && !roadmap.hasAttachedDocument "></span>
                    </div>
                    <div class="col-md-1">
                        <span *ngIf="roadmap.ownerId">{{ roadmap.ownerName }} [{{ roadmap.ownerId }}]</span>
                    </div>
                    <div class="col-md-1">
                        {{ setMilestone(roadmap) }}
                    </div>
                    <div class="col-md-1">
                        {{ roadmap.progressState }}<br> {{ roadmap.doneTime | date: 'MMM d, y, h:mm a' }}
                    </div>
                    <div class="col-md-1">
                        {{ roadmap.progressPercentPlannedWorkCompleted }}<span *ngIf="roadmap.progressPercentPlannedWorkCompleted">%</span>
                    </div>
                    <div class="col-md-1">
                        {{ roadmap.progressConfidenceLevel }}
                    </div>
                    <div class="col-md-2">
                        {{ roadmap.progressCurrentValueDelivered }}
                    </div>
                    <div class="col-md-1">
                        <span class="bi bi-lightbulb-fill desc-hover-icon" style="font-size: 16px" title="{{ roadmap.progressNextBestIdeas | shorten: 256 }}" *ngIf="roadmap.progressNextBestIdeas;else noIdeas"></span>
                        <ng-template #noIdeas>
                            <span></span> <!--If no ideas, display no icon!-->
                        </ng-template>
                    </div>
                    <div class="col text-end">
                        <a class="bi bi-three-dots-vertical fs-5" role="button" id="dropdownMoreInfoRoadmap"
                            data-bs-toggle="dropdown" aria-expanded="false" *ngIf="!readOnly"></a>
                        <ul class="dropdown-menu dropdown-menu-light text-small shadow"
                            aria-labelledby="dropdownMoreInfoRoadmap">
                            <li><a class="dropdown-item" role="button" data-toggle="modal" data-target="#editRoadmap"
                                (click)="onEditRoadmap(roadmap.id)"><i class="pe-2 bi-pencil-square"></i>
                                Edit
                                </a>
                            </li>
                            <li><a class="dropdown-item" role="button" data-toggle="modal"
                                data-target="#newKRT" (click)="onRoadmapProgress(true, roadmap)"><i
                                class="pe-2 bi-calendar-plus"></i>Add Progress</a>
                            </li>
                            <li><a class="dropdown-item" role="button" data-toggle="modal"
                                data-target="#newKRT" (click)="onRoadmapProgress(false, roadmap)"><i
                                class="pe-2 bi-calendar-check"></i>View Progress</a>
                            </li>
                            <li>
                                <a class="dropdown-item" role="button" data-toggle="modal" data-target="#viewObjectives"
                                (click)="viewObjectives(roadmap, roadmap.productId, roadmap.productGroupId)"><i class="pe-2 bi-card-text"></i>
                                View OKRs
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>

        <div class="text-end pt-2"></div>
        <div *ngIf="mixedContentWarning" class="pt-2 mixed-content-alert">
            <div class="mixed-content-alert__icon-box">
              <i class="bi bi-exclamation-triangle mixed-content-alert__icon"></i>
            </div>
            <div class="mixed-content-alert__content">
              <p class="mixed-content-alert__heading">You have a mix of product and product group OKRs</p>
              <p *ngIf="this.objectiveType === 'PRODUCT_GROUP'">When planning at the product group level, all products within that product group will share the same OKRs. To
                edit any product OKRs, please go to that product’s page.</p>
              <p *ngIf="this.objectiveType === 'PRODUCT'">When planning at the product level, there should only be product OKRs. To edit any product group OKRs, please
                go to your product group page.</p>
            </div>
        </div>
        <div *ngIf="!mixedContentWarning" class="pb-5"></div>
        <div class="row p-2 header">
            <div class="col-md-1 text-center">
                Priority
            </div>
            <div class="col-md-1">
                Initiative
            </div>
            <div class="col-md-1">
                Owner
            </div>
            <div class="col-md-1">
                Delivery Milestone
            </div>
            <div class="col-md-1">
                State
            </div>
            <div class="col-md-1">
                % Complete
            </div>
            <div class="col-md-1">
                Confidence Level
            </div>
            <div class="col-md-1">
                Current Value Delivered
            </div>
            <div class="col-md-1">
                Next Idea
            </div>
            <div class="col-md-1">
                Funding
            </div>
            <div class="col-md-1">
                Product
            </div>
            <div class="col-md-1"></div>
        </div>
        <div *ngIf="allProductRoadmaps.length === 0" class="pb-5">
            No Product Roadmaps Found
        </div>
        <div *ngIf="filterYears.length===0 && filterMonths.length===0 && filterQuarters.length===0 && ((filterStates.length===4 && !filterStates.includes('Done')) || (filterStates.length===5 || filterStates.length===0))">
            <div *ngFor="let roadmap of allProductRoadmaps | unique; let i = index">
                <div class="row p-2"
                    [class.draggable]="(roadmap.progressState === 'Done') ? false: true"
                    [draggable]="(roadmap.progressState === 'Done') ? false: true"
                    [class.active]="draggedOverIndex === i && dragType==='PRODUCT'"
                    (dragover)="allowDrop($event, i, 'PRODUCT', roadmap.progressState)"
                    (dragstart)="onDragStart(i, 'PRODUCT')"
                    (dragleave)="dragLeave($event)"
                    (drop)="onDrop($event, i, 'PRODUCT')">
                    <div class="col-md-1 text-center priority">
                        {{ roadmap.priority }}
                    </div>
                    <div class="col-md-1">
                        {{ roadmap.description }}&nbsp;&nbsp;&nbsp;
                        <span class="bi bi-info-circle desc-hover-icon" style="font-size: 16px" title="Description &#13;{{ roadmap.impactEstimate | shorten: 256 }}" *ngIf="roadmap.impactEstimate;else noDescription"></span>
                        <ng-template #noDescription><span class="bi bi-info-circle desc-hover-icon" style="font-size: 16px" title="Description not yet updated"></span></ng-template>
                        <span class="bi bi-star-fill fundstar" style="font-size: 16px" title="{{ ((roadmap.totalEst && roadmap.totalEst >0) ? roadmap.totalEst: 0) | currency : 'USD' : 'symbol' : '1.0-0' }}; Additional funding documents attached." *ngIf="roadmap.requiresAttachment && roadmap.hasAttachedDocument"></span>
                        <span class="bi bi-star fundstar" style="font-size: 16px" title="{{ ((roadmap.totalEst && roadmap.totalEst >0) ? roadmap.totalEst: 0) | currency : 'USD' : 'symbol' : '1.0-0' }}; No documents attached." *ngIf="roadmap.requiresAttachment && !roadmap.hasAttachedDocument "></span>
                    </div>
                    <div class="col-md-1">
                        <span *ngIf="roadmap.ownerId">{{ roadmap.ownerName }} [{{ roadmap.ownerId }}]</span>
                    </div>
                    <div class="col-md-1">
                        {{ setMilestone(roadmap) }}
                    </div>
                    <div class="col-md-1">
                        {{ roadmap.progressState }}<br> {{ roadmap.doneTime | date: 'MMM d, y, h:mm a' }}
                    </div>
                    <div class="col-md-1">
                        {{ roadmap.progressPercentPlannedWorkCompleted }}<span *ngIf="roadmap.progressPercentPlannedWorkCompleted">%</span>
                    </div>
                    <div class="col-md-1">
                        {{ roadmap.progressConfidenceLevel }}
                    </div>
                    <div class="col-md-1">
                        {{ roadmap.progressCurrentValueDelivered }}
                    </div>
                    <div class="col-md-1">
                        <span class="bi bi-lightbulb-fill desc-hover-icon" style="font-size: 16px" title="{{ roadmap.progressNextBestIdeas | shorten: 256 }}" *ngIf="roadmap.progressNextBestIdeas;else noIdeas"></span>
                        <ng-template #noIdeas>
                            <span></span> <!--If no ideas, display no icon!-->
                        </ng-template>
                    </div>
                    <div class="col-md-1">
                        <span class="bi bi-currency-dollar desc-hover-icon" style="font-size: 16px" title="{{ roadmap.requiresAttachment | shorten: 256 }}" *ngIf="roadmap.requiresAttachment;else notFunded" ></span>
                        <ng-template #notFunded>
                            <span></span> <!--If not funded, display no icon!-->
                        </ng-template>
                    </div>
                    <div class="col-md-1 productNewLine">{{roadmap.productName}}</div>
                    <div class="col text-end">
                        <a class="bi bi-three-dots-vertical fs-5" role="button" id="dropdownMoreInfoRoadmap"
                            data-bs-toggle="dropdown" aria-expanded="false" *ngIf="!readOnly"></a>
                        <ul class="dropdown-menu dropdown-menu-light text-small shadow"
                            aria-labelledby="dropdownMoreInfoRoadmap">
                            <li><a class="dropdown-item" role="button" data-toggle="modal" data-target="#editRoadmap"
                                (click)="onEditRoadmap(roadmap.id)"><i class="pe-2 bi-pencil-square"></i>
                                Edit
                                </a>
                            </li>
                            <li><a class="dropdown-item" role="button" data-toggle="modal"
                                data-target="#newKRT" (click)="onRoadmapProgress(true, roadmap)"><i
                                class="pe-2 bi-calendar-plus"></i>Add Progress</a>
                            </li>
                            <li><a class="dropdown-item" role="button" data-toggle="modal"
                                data-target="#newKRT" (click)="onRoadmapProgress(false, roadmap)"><i
                                class="pe-2 bi-calendar-check"></i>View Progress</a>
                            </li>
                            <li>
                                <a class="dropdown-item" role="button" data-toggle="modal" data-target="#viewObjectives"
                                (click)="viewObjectives(roadmap, roadmap.productId, roadmap.productGroupId)"><i class="pe-2 bi-card-text"></i>
                                View OKRs
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="!(filterYears.length===0 && filterMonths.length===0 && filterQuarters.length===0 && ((filterStates.length===4 && !filterStates.includes('Done')) || (filterStates.length===5 || filterStates.length===0)))">
            <div *ngFor="let roadmap of allProductRoadmaps | unique; let i = index">
                <div class="row p-2">
                    <div class="col-md-1 text-center priority">
                        {{ roadmap.priority }}
                    </div>
                    <div class="col-md-2">
                        {{ roadmap.description }}&nbsp;&nbsp;&nbsp;
                        <span class="bi bi-info-circle desc-hover-icon" style="font-size: 16px" title="Description &#13;{{ roadmap.impactEstimate | shorten: 256 }}" *ngIf="roadmap.impactEstimate;else noDescription"></span>
                        <ng-template #noDescription><span class="bi bi-info-circle desc-hover-icon" style="font-size: 16px" title="Description not yet updated"></span></ng-template>
                        <span class="bi bi-star-fill fundstar" style="font-size: 16px" title="{{ ((roadmap.totalEst && roadmap.totalEst >0) ? roadmap.totalEst: 0) | currency : 'USD' : 'symbol' : '1.0-0' }}; Additional funding documents attached." *ngIf="roadmap.requiresAttachment && roadmap.hasAttachedDocument"></span>
                        <span class="bi bi-star fundstar" style="font-size: 16px" title="{{ ((roadmap.totalEst && roadmap.totalEst >0) ? roadmap.totalEst: 0) | currency : 'USD' : 'symbol' : '1.0-0' }}; No documents attached." *ngIf="roadmap.requiresAttachment && !roadmap.hasAttachedDocument "></span>
                    </div>
                    <div class="col-md-1">
                        <span *ngIf="roadmap.ownerId">{{ roadmap.ownerName }} [{{ roadmap.ownerId }}]</span>
                    </div>
                    <div class="col-md-1">
                        {{ setMilestone(roadmap) }}
                    </div>
                    <div class="col-md-1">
                        {{ roadmap.progressState }}<br> {{ roadmap.doneTime | date: 'MMM d, y, h:mm a' }}
                    </div>
                    <div class="col-md-1">
                        {{ roadmap.progressPercentPlannedWorkCompleted }}<span *ngIf="roadmap.progressPercentPlannedWorkCompleted">%</span>
                    </div>
                    <div class="col-md-1">
                        {{ roadmap.progressConfidenceLevel }}
                    </div>
                    <div class="col-md-1">
                        {{ roadmap.progressCurrentValueDelivered }}
                    </div>
                    <div class="col-md-1">
                        <span class="bi bi-lightbulb-fill desc-hover-icon" style="font-size: 16px" title="{{ roadmap.progressNextBestIdeas | shorten: 256 }}" *ngIf="roadmap.progressNextBestIdeas;else noIdeas"></span>
                        <ng-template #noIdeas>
                            <span></span> <!--If no ideas, display no icon!-->
                        </ng-template>
                    </div>
                    <div class="col-md-1">
                        <span class="bi bi-currency-dollar desc-hover-icon" style="font-size: 16px" title="{{ roadmap.requiresAttachment | shorten: 256 }}" *ngIf="roadmap.requiresAttachment;else notFunded" ></span>
                        <ng-template #notFunded>
                            <span></span> <!--If not funded, display no icon!-->
                        </ng-template>
                    </div>
                    <div class="col-md-1 productNewLine">{{roadmap.productName}}</div>
                    <div class="col text-end">
                        <a class="bi bi-three-dots-vertical fs-5" role="button" id="dropdownMoreInfoRoadmap"
                            data-bs-toggle="dropdown" aria-expanded="false" *ngIf="!readOnly"></a>
                        <ul class="dropdown-menu dropdown-menu-light text-small shadow"
                            aria-labelledby="dropdownMoreInfoRoadmap">
                            <li><a class="dropdown-item" role="button" data-toggle="modal" data-target="#editRoadmap"
                                (click)="onEditRoadmap(roadmap.id)"><i class="pe-2 bi-pencil-square"></i>
                                Edit
                                </a>
                            </li>
                            <li><a class="dropdown-item" role="button" data-toggle="modal"
                                data-target="#newKRT" (click)="onRoadmapProgress(true, roadmap)"><i
                                class="pe-2 bi-calendar-plus"></i>Add Progress</a>
                            </li>
                            <li><a class="dropdown-item" role="button" data-toggle="modal"
                                data-target="#newKRT" (click)="onRoadmapProgress(false, roadmap)"><i
                                class="pe-2 bi-calendar-check"></i>View Progress</a>
                            </li>
                            <li>
                                <a class="dropdown-item" role="button" data-toggle="modal" data-target="#viewObjectives"
                                (click)="viewObjectives(roadmap, roadmap.productId, roadmap.productGroupId)"><i class="pe-2 bi-card-text"></i>
                                View OKRs
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div class="text-end pt-2"></div>
    </div>
    <div *ngIf="this.objectiveType === 'PRODUCT'">
        <div class="row header p-2">
            <div class="col-md-1 text-center">
                Priority
            </div>
            <div class="col-md-2">
                Initiative
            </div>
            <div class="col-md-1">
                Owner
            </div>
            <div class="col-md-1">
                Delivery Milestone
            </div>
            <div class="col-md-1">
                State
            </div>
            <div class="col-md-1">
                % Complete
            </div>
            <div class="col-md-1">
                Confidence Level
            </div>
            <div class="col-md-1">
                Current Value Delivered
            </div>
            <div class="col-md-1">
                Next Idea
            </div>
            <div class="col-md-1">
                Funding
            </div>
            <div class="col-md-1">

            </div>
        </div>
        <div *ngIf="allProductRoadmaps.length === 0" class="pb-5"> 
            No Product Roadmaps Found
        </div>
        <div *ngIf="filterYears.length===0 && filterMonths.length===0 && filterQuarters.length===0 && ((filterStates.length===4 && !filterStates.includes('Done')) || (filterStates.length===5 || filterStates.length===0))">
            <div *ngFor="let roadmap of allProductRoadmaps; let i = index">
                <div class="row p-2" 
                    [class.draggable]="(roadmap.progressState === 'Done') ? false: true"
                    [draggable]="(roadmap.progressState === 'Done') ? false: true"
                    [class.active]="draggedOverIndex === i && dragType==='PRODUCT'"
                    (dragover)="allowDrop($event, i, 'PRODUCT', roadmap.progressState)"
                    (dragstart)="onDragStart(i, 'PRODUCT')"
                    (dragleave)="dragLeave($event)"
                    (drop)="onDrop($event, i, 'PRODUCT')">
                    <div class="col-md-1 text-center priority">
                        {{ roadmap.priority }}
                    </div>
                    <div class="col-md-2">
                        {{ roadmap.description }}&nbsp;&nbsp;&nbsp;
                        <span class="bi bi-info-circle desc-hover-icon" style="font-size: 16px" title="Description &#13;{{ roadmap.impactEstimate | shorten: 256 }}" *ngIf="roadmap.impactEstimate;else noDescription"></span>
                        <ng-template #noDescription><span class="bi bi-info-circle desc-hover-icon" style="font-size: 16px" title="Description not yet updated"></span></ng-template>
                        <span class="bi bi-star-fill fundstar" style="font-size: 16px" title="{{ ((roadmap.totalEst && roadmap.totalEst >0) ? roadmap.totalEst: 0) | currency : 'USD' : 'symbol' : '1.0-0' }}; Additional funding documents attached." *ngIf="roadmap.requiresAttachment && roadmap.hasAttachedDocument"></span>
                        <span class="bi bi-star fundstar" style="font-size: 16px" title="{{ ((roadmap.totalEst && roadmap.totalEst >0) ? roadmap.totalEst: 0) | currency : 'USD' : 'symbol' : '1.0-0' }}; No documents attached." *ngIf="roadmap.requiresAttachment && !roadmap.hasAttachedDocument "></span>
                    </div>
                    <div class="col-md-1">
                        <span *ngIf="roadmap.ownerId">{{ roadmap.ownerName }} [{{ roadmap.ownerId }}]</span>
                    </div>
                    <div class="col-md-1">
                        {{ setMilestone(roadmap) }}
                    </div>
                    <div class="col-md-1">
                        {{ roadmap.progressState }}<br> {{ roadmap.doneTime | date: 'MMM d, y, h:mm a' }}
                    </div>
                    <div class="col-md-1">
                        {{ roadmap.progressPercentPlannedWorkCompleted }}<span *ngIf="roadmap.progressPercentPlannedWorkCompleted">%</span>
                    </div>
                    <div class="col-md-1">
                        {{ roadmap.progressConfidenceLevel }}
                    </div>
                    <div class="col-md-1">
                        {{ roadmap.progressCurrentValueDelivered }}
                    </div>
                    <div class="col-md-1">
                        <span class="bi bi-lightbulb-fill desc-hover-icon" style="font-size: 16px" title="{{ roadmap.progressNextBestIdeas | shorten: 256 }}" *ngIf="roadmap.progressNextBestIdeas;else noIdeas"></span>
                        <ng-template #noIdeas>
                            <span></span> <!--If no ideas, display no icon!-->
                        </ng-template>
                    </div>
                    <div class="col-md-1">
                        <span class="bi bi-currency-dollar desc-hover-icon" style="font-size: 16px" title="{{ roadmap.requiresAttachment | shorten: 256 }}" *ngIf="roadmap.requiresAttachment;else notFunded" ></span>
                        <ng-template #notFunded>
                            <span></span> <!--If not funded, display no icon!-->
                        </ng-template>
                    </div>
                    <div class="col text-end">
                        <a class="bi bi-three-dots-vertical fs-5" role="button" id="dropdownMoreInfoRoadmap"
                            data-bs-toggle="dropdown" aria-expanded="false" *ngIf="!readOnly"></a>
                        <ul class="dropdown-menu dropdown-menu-light text-small shadow"
                            aria-labelledby="dropdownMoreInfoRoadmap">
                            <li><a class="dropdown-item" role="button" data-toggle="modal" data-target="#editRoadmap"
                                (click)="onEditRoadmap(roadmap.id)"><i class="pe-2 bi-pencil-square"></i>
                                Edit
                                </a>
                            </li>
                            <li><a class="dropdown-item" role="button" data-toggle="modal"
                                data-target="#newKRT" (click)="onRoadmapProgress(true, roadmap)"><i
                                class="pe-2 bi-calendar-plus"></i>Add Progress</a>
                            </li>
                            <li><a class="dropdown-item" role="button" data-toggle="modal"
                                data-target="#newKRT" (click)="onRoadmapProgress(false, roadmap)"><i
                                class="pe-2 bi-calendar-check"></i>View Progress</a>
                            </li>
                            <li>
                                <a class="dropdown-item" role="button" data-toggle="modal" data-target="#viewObjectives"
                                (click)="viewObjectives(roadmap, roadmap.productId, roadmap.productGroupId)"><i class="pe-2 bi-card-text"></i>
                                View OKRs
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="!(filterYears.length===0 && filterMonths.length===0 && filterQuarters.length===0 && ((filterStates.length===4 && !filterStates.includes('Done')) || (filterStates.length===5 || filterStates.length===0)))">
            <div *ngFor="let roadmap of allProductRoadmaps; let i = index">
                <div class="row p-2">
                    <div class="col-md-1 text-center priority">
                        {{ roadmap.priority }}
                    </div>
                    <div class="col-md-2">
                        {{ roadmap.description }}&nbsp;&nbsp;&nbsp;
                        <span class="bi bi-info-circle desc-hover-icon" style="font-size: 16px" title="Description &#13;{{ roadmap.impactEstimate | shorten: 256 }}" *ngIf="roadmap.impactEstimate;else noDescription"></span>
                        <ng-template #noDescription><span class="bi bi-info-circle desc-hover-icon" style="font-size: 16px" title="Description not yet updated"></span></ng-template>
                        <span class="bi bi-star-fill fundstar" style="font-size: 16px" title="{{ ((roadmap.totalEst && roadmap.totalEst >0) ? roadmap.totalEst: 0) | currency : 'USD' : 'symbol' : '1.0-0' }}; Additional funding documents attached." *ngIf="roadmap.requiresAttachment && roadmap.hasAttachedDocument"></span>
                        <span class="bi bi-star fundstar" style="font-size: 16px" title="{{ ((roadmap.totalEst && roadmap.totalEst >0) ? roadmap.totalEst: 0) | currency : 'USD' : 'symbol' : '1.0-0' }}; No documents attached." *ngIf="roadmap.requiresAttachment && !roadmap.hasAttachedDocument "></span>
                    </div>
                    <div class="col-md-1">
                        <span *ngIf="roadmap.ownerId">{{ roadmap.ownerName }} [{{ roadmap.ownerId }}]</span>
                    </div>
                    <div class="col-md-1">
                        {{ setMilestone(roadmap) }}
                    </div>
                    <div class="col-md-1">
                        {{ roadmap.progressState }}<br> {{ roadmap.doneTime | date: 'MMM d, y, h:mm a' }}
                    </div>
                    <div class="col-md-1">
                        {{ roadmap.progressPercentPlannedWorkCompleted }}<span *ngIf="roadmap.progressPercentPlannedWorkCompleted">%</span>
                    </div>
                    <div class="col-md-1">
                        {{ roadmap.progressConfidenceLevel }}
                    </div>
                    <div class="col-md-1">
                        {{ roadmap.progressCurrentValueDelivered }}
                    </div>
                    <div class="col-md-1">
                        <span class="bi bi-lightbulb-fill desc-hover-icon" style="font-size: 16px" title="{{ roadmap.progressNextBestIdeas | shorten: 256 }}" *ngIf="roadmap.progressNextBestIdeas;else noIdeas"></span>
                        <ng-template #noIdeas>
                            <span></span> <!--If no ideas, display no icon!-->
                        </ng-template>
                    </div>
                    <div class="col text-end">
                        <a class="bi bi-three-dots-vertical fs-5" role="button" id="dropdownMoreInfoRoadmap"
                            data-bs-toggle="dropdown" aria-expanded="false" *ngIf="!readOnly"></a>
                        <ul class="dropdown-menu dropdown-menu-light text-small shadow"
                            aria-labelledby="dropdownMoreInfoRoadmap">
                            <li><a class="dropdown-item" role="button" data-toggle="modal" data-target="#editRoadmap"
                                (click)="onEditRoadmap(roadmap.id)"><i class="pe-2 bi-pencil-square"></i>
                                Edit
                                </a>
                            </li>
                            <li><a class="dropdown-item" role="button" data-toggle="modal"
                                data-target="#newKRT" (click)="onRoadmapProgress(true, roadmap)"><i
                                class="pe-2 bi-calendar-plus"></i>Add Progress</a>
                            </li>
                            <li><a class="dropdown-item" role="button" data-toggle="modal"
                                data-target="#newKRT" (click)="onRoadmapProgress(false, roadmap)"><i
                                class="pe-2 bi-calendar-check"></i>View Progress</a>
                            </li>
                            <li>
                                <a class="dropdown-item" role="button" data-toggle="modal" data-target="#viewObjectives"
                                (click)="viewObjectives(roadmap, roadmap.productId, roadmap.productGroupId)"><i class="pe-2 bi-card-text"></i>
                                View OKRs
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>

        <div class="text-end pt-2"></div>
        <div *ngIf="mixedContentWarning" class="pt-2 mixed-content-alert">
            <div class="mixed-content-alert__icon-box">
              <i class="bi bi-exclamation-triangle mixed-content-alert__icon"></i>
            </div>
            <div class="mixed-content-alert__content">
              <p class="mixed-content-alert__heading">You have a mix of product and product group OKRs</p>
              <p *ngIf="this.objectiveType === 'PRODUCT_GROUP'">When planning at the product group level, all products within that product group will share the same OKRs. To
                edit any product OKRs, please go to that product’s page.</p>
              <p *ngIf="this.objectiveType === 'PRODUCT'">When planning at the product level, there should only be product OKRs. To edit any product group OKRs, please
                go to your product group page.</p>
            </div>
        </div>
        <div *ngIf="!mixedContentWarning" class="pb-5"></div>
        <div class="row p-2 header">
            <div class="col-md-1 text-center">
                Priority
            </div>
            <div class="col-md-1">
                Initiative
            </div>
            <div class="col-md-1">
                Owner
            </div>
            <div class="col-md-1">
                Delivery Milestone
            </div>
            <div class="col-md-1">
                State
            </div>
            <div class="col-md-1">
                % Complete
            </div>
            <div class="col-md-1">
                Confidence Level
            </div>
            <div class="col-md-1">
                Current Value Delivered
            </div>
            <div class="col-md-1">
                Next Idea
            </div>
            <div class="col-md-1">
                Funding
            </div>
            <div class="col-md-1">
                Product Group
            </div>
            <div class="col-md-1"></div>
        </div>
        <div *ngIf="allProductGroupRoadmaps.length === 0" class="pb-5"> 
            No Product Group Roadmaps Found
        </div>
        <div *ngFor="let roadmap of allProductGroupRoadmaps; let i = index" class="pb-2">
            <div class="row p-2">
                <div class="col-md-1 text-center priority">
                    {{ roadmap.priority }}
                 </div>
                 <div class="col-md-1">
                    {{ roadmap.description }}&nbsp;&nbsp;&nbsp;
                    <span class="bi bi-info-circle desc-hover-icon" style="font-size: 16px" title="Description &#13;{{ roadmap.impactEstimate | shorten: 256 }}" *ngIf="roadmap.impactEstimate;else noDescription"></span>
                    <ng-template #noDescription><span class="bi bi-info-circle desc-hover-icon" style="font-size: 16px" title="Description not yet updated"></span></ng-template>
                    <span class="bi bi-star-fill fundstar" style="font-size: 16px" title="{{ ((roadmap.totalEst && roadmap.totalEst >0) ? roadmap.totalEst: 0) | currency : 'USD' : 'symbol' : '1.0-0' }}; Additional funding documents attached." *ngIf="roadmap.requiresAttachment && roadmap.hasAttachedDocument"></span>
                    <span class="bi bi-star fundstar" style="font-size: 16px" title="{{ ((roadmap.totalEst && roadmap.totalEst >0) ? roadmap.totalEst: 0) | currency : 'USD' : 'symbol' : '1.0-0' }}; No documents attached." *ngIf="roadmap.requiresAttachment && !roadmap.hasAttachedDocument "></span>
                </div>
                  <div class="col-md-1">
                    <span *ngIf="roadmap.ownerId">{{ roadmap.ownerName }} [{{ roadmap.ownerId }}]</span>
                 </div>
                 <div class="col-md-1">
                    {{ setMilestone(roadmap) }}
                 </div>
                 <div class="col-md-1">
                     {{ roadmap.progressState }}<br> {{ roadmap.doneTime | date: 'MMM d, y, h:mm a' }}
                 </div>
                 <div class="col-md-1">
                    {{ roadmap.progressPercentPlannedWorkCompleted }}<span *ngIf="roadmap.progressPercentPlannedWorkCompleted">%</span>
                </div>
                 <div class="col-md-1">
                     {{ roadmap.progressConfidenceLevel }} 
                 </div>
                 <div class="col-md-1">
                     {{ roadmap.progressCurrentValueDelivered }}
                 </div>
                 <div class="col-md-1">
                    <span class="bi bi-lightbulb-fill desc-hover-icon" style="font-size: 16px" title="{{ roadmap.progressNextBestIdeas | shorten: 256 }}" *ngIf="roadmap.progressNextBestIdeas;else noIdeas"></span>
                    <ng-template #noIdeas>
                        <span></span> <!--If no ideas, display no icon!-->
                    </ng-template>
                </div>
                <div class="col-md-1">
                    <span class="bi bi-currency-dollar desc-hover-icon" style="font-size: 16px" title="{{ roadmap.requiresAttachment | shorten: 256 }}" *ngIf="roadmap.requiresAttachment;else notFunded" ></span>
                    <ng-template #notFunded>
                        <span></span> <!--If no ideas, display no icon!-->
                    </ng-template>
                </div>
                <div class="col-md-1">
                    {{ roadmap.productGroupName }}
                </div>
                <div class="col text-end">
                    <a class="bi bi-three-dots-vertical fs-5" role="button" id="dropdownMoreInfoRoadmap"
                        data-bs-toggle="dropdown" aria-expanded="false" *ngIf="!readOnly"></a>
                    <ul class="dropdown-menu dropdown-menu-light text-small shadow"
                        aria-labelledby="dropdownMoreInfoRoadmap">
                        <li><a class="dropdown-item" role="button" data-toggle="modal" data-target="#editRoadmap"
                            (click)="onEditRoadmap(roadmap.id)"><i class="pe-2 bi-pencil-square"></i>
                            Edit
                            </a>
                        </li>
                        <li><a class="dropdown-item" role="button" data-toggle="modal"
                            data-target="#newKRT" (click)="onRoadmapProgress(true, roadmap)"><i
                            class="pe-2 bi-calendar-plus"></i>Add Progress</a>
                        </li>
                        <li><a class="dropdown-item" role="button" data-toggle="modal"
                            data-target="#newKRT" (click)="onRoadmapProgress(false, roadmap)"><i
                            class="pe-2 bi-calendar-check"></i>View Progress</a>
                        </li>
                        <li>
                            <a class="dropdown-item" role="button" data-toggle="modal" data-target="#viewObjectives"
                            (click)="viewObjectives(roadmap, roadmap.productId, roadmap.productGroupId)"><i class="pe-2 bi-card-text"></i>
                            View OKRs
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="text-end pt-2"></div>
    </div>
</div>
