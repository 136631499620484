import { EventEmitter, Injectable } from '@angular/core';
import { Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EventEmitterService {
  invokeRetrieveObjectives = new EventEmitter();
  subsVar: Subscription;

  constructor() { }

  retrieveObjectives(tmpShowLatest?: boolean, tmpTabs?: any) {
    const passValues = {showLatest:tmpShowLatest, tabs:tmpTabs};
    this.invokeRetrieveObjectives.emit(passValues);
  }

}
