import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ExternalLinkModel } from '../models/external-link.model';

@Injectable({
  providedIn: 'root'
})
export class ExternalLinkService {

  constructor(@Inject(HttpClient) private http: HttpClient) { }

  getByParentId(parentId: string): Observable<ExternalLinkModel[]> {
    return this.http.get<ExternalLinkModel[]>(`${environment.baseURL}/external-links/${parentId}`);
  }

  create(data: any): Observable<any> {
    return this.http.post(`${environment.baseURL}/external-links`, data);
  }

  update(data: any): Observable<any> {
    return this.http.put(`${environment.baseURL}/external-links`, data);
  }

  delete(data: any): Observable<any> {
    return this.http.delete(`${environment.baseURL}/external-links/${data.id}`);
  }



}
