import { Component, OnInit } from '@angular/core';
import { TitleCasePipe } from '@angular/common';
import { PingAuthenticationService } from '@techops-ui/ping-authentication';
// import { UserProfilesService } from 'src/app/services/user-profiles.service';
// import { PlanningPortfoliosService } from 'src/app/services/planning-portfolios.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})

export class SidebarComponent implements OnInit {
  userName: string;
  employeeNumber: string;
  titlecasePipe = new TitleCasePipe();
  pageLoaded = Promise.resolve(false);
  productLink = '/product-objectives';
  productGroupLink = '/product-group-objectives';
  portfolioLink = '/portfolio-objectives';
  planningPortfolio: any;

  constructor(private authService: PingAuthenticationService,
              // private userProfilesService: UserProfilesService,
              // private planningPortfolioService: PlanningPortfoliosService
              ) { }

  ngOnInit(): void {
    if (this.authService.loggedIn$) {
      this.authService.profile$.subscribe(p => {
        this.userName= this.titlecasePipe.transform(p.given_name) + ' ' + this.titlecasePipe.transform(p.family_name);
        this.employeeNumber = p.BadgeNo.substring(2);
        //this.loadUserData(); //disabled for now, defaults will load in objective-items if missing in router/url
      });
      this.pageLoaded = Promise.resolve(true);
    }
  }

}
