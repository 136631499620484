import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UtilsService } from 'src/app/services/utils.service';
import { RoadmapProgressModel } from 'src/app/models/roadmap-progress.model';
import { RoadmapModel } from 'src/app/models/roadmap.model';
import { RoadmapProgressService } from 'src/app/services/roadmap-progress.service';
import { RoadmapProgressViewComponent } from '../roadmap-progress-view/roadmap-progress-view.component';
import { ConfirmationDialogService } from 'src/app/services/confirmation-dialog.service';
import { NgForm } from '@angular/forms';
import { RoadmapService } from 'src/app/services/roadmap.service';
import { EventEmitterService } from 'src/app/services/event-emitter.service';
import { RoadmapPriorityService } from 'src/app/services/roadmap-priority.service';
import { RoadmapPriorityModel } from 'src/app/models/roadmap-priority.model';
import { getLocaleDateFormat } from '@angular/common';
import { now } from 'lodash';

@Component({
  selector: 'app-roadmap-progress-add',
  templateUrl: './roadmap-progress-add.component.html',
  styleUrls: ['./roadmap-progress-add.component.scss']
})
export class RoadmapProgressAddComponent implements OnInit {
  @Input() roadmap: RoadmapModel;
  @Input() fromModal: string;
  @Input() roadmapType: string;
  @Input() fromPage: string;
  @Input() fromObjectiveId: string;


  @ViewChild('submitBtn') private buttonSubmit: ElementRef;

  roadmapProgress: RoadmapProgressModel = {
    status: '',
    roadmapId: '',
    percentageOfPlannedWorkCompleted:'',
    confidenceLevel:'',
    currentValueDelivered:'',
    dateOfProgress: null,
    currentDate: new Date()
  };

  errorTrigger = '';

  constructor(private readonly activeModal: NgbActiveModal,
              private utilsService: UtilsService,
              private roadmapProgressService: RoadmapProgressService,
              private modalService: NgbModal,
              private confirmationDialogService: ConfirmationDialogService,
              private roadmapService: RoadmapService,
              private eventEmitterService: EventEmitterService,
              private roadmapPriorityService: RoadmapPriorityService) { }

  ngOnInit(): void {
    this.loadData();
  }

  async loadData() {
    this.roadmapProgress.roadmapId = this.roadmap.id;
  }

  async onSubmitForm(): Promise<void> {
    if (this.passValidation()) {
      await this.roadmapProgressService.create(this.roadmapProgress).toPromise();
      //if the new roadmap progress was flagged as done, and doneTime hasn't been set, update the doneTime on the roadmap
      if (this.roadmapProgress.status === 'Done') {
        this.roadmap.doneTime = new Date();
        await this.roadmapService.update(this.roadmap).toPromise();
        const highestPriorityNumber: RoadmapPriorityModel =
        await this.roadmapPriorityService.getHighestPriorityByTaxIdAndTaxType(this.roadmap.taxonomyId,
          this.roadmap.taxonomyType).toPromise();
        //update priority entry so it is last in the priority list
        const updatePriorityItem: RoadmapPriorityModel = {
          roadmapId: this.roadmap.id,
          taxonomyId: this.roadmap.taxonomyId,
          taxonomyType: this.roadmap.taxonomyType,
          priority: ++highestPriorityNumber.priority
        };
        this.roadmapPriorityService.update(updatePriorityItem).subscribe();
      } else if (this.roadmap.doneTime && this.roadmapProgress.status !== 'Done') {
        this.roadmap.doneTime = null;
        await this.roadmapService.update(this.roadmap).toPromise();
      }
      if (this.fromModal === 'RMProgressView') {
        this.openViewRoadmapProgress();
      }
      this.activeModal.close('OnSubmit-Create');
    }
  }

  openViewRoadmapProgress = (): void => {
    if (this.modalService.hasOpenModals) {
      this.modalService.dismissAll('Open-RMProgressView');
    }
    const modalRef = this.modalService.open(RoadmapProgressViewComponent,
      {
        windowClass: 'roadmap-modal',
        centered: true,
        keyboard: false,
        backdrop: 'static'
      });

    modalRef.componentInstance.fromModal = 'RMProgressAdd';
    modalRef.componentInstance.roadmap = this.roadmap;
    modalRef.componentInstance.roadmapType = this.roadmapType;
    modalRef.componentInstance.fromPage = this.fromPage;
    modalRef.componentInstance.fromObjectiveId = this.fromObjectiveId;

    modalRef.result.then((result) => {
      if (result === 'close') {
        let usePriorityTab = false;
        let useObjectiveId = null;
        let useTabId = null;

        if (this.fromPage === 'roadmapPriority') {
          usePriorityTab = true;
        } else {
          useObjectiveId = this.fromObjectiveId;
          useTabId = 2;
        }

        const tabs = {tabId: useTabId, tabObjectiveId: useObjectiveId, priorityTab: usePriorityTab};
        this.eventEmitterService.retrieveObjectives(false, tabs);
      }
    }, (reason) => {
    });
  };

  passValidation() {
    if (this.roadmapProgress.percentageOfPlannedWorkCompleted.trim() === '' &&
     (this.roadmapProgress.status !== 'Backlog' &&
      this.roadmapProgress.status !== 'Discovering')) {
      return false;
    }
    if (this.roadmapProgress.confidenceLevel === '' &&
     (this.roadmapProgress.status !== 'Backlog' &&
     this.roadmapProgress.status !== 'Discovering')) {
      return false;
    }
    if (this.roadmapProgress.status === '') {
      return false;
    }
    if (!this.roadmapProgress.dateOfProgress) {
        this.errorTrigger = 'null';
      return false;
    }
      if (Date.parse(this.roadmapProgress.dateOfProgress) > this.roadmapProgress.currentDate.getTime()){
        this.errorTrigger = 'future';
      return false;
      }

    return true;
  }


  changesDetected(): boolean {
    let foundChange = false;
    //if any fields are populated, then ask to save.
    //validation will run on simulated submit click
    //don't check year, since it has a default value
    if (
        this.roadmapProgress.status !== '' ||
        this.roadmapProgress.percentageOfPlannedWorkCompleted.trim() !== '' ||
        this.roadmapProgress.confidenceLevel !== '' ||
        this.roadmapProgress.currentValueDelivered.trim() !== '') {
            foundChange = true;
        }

    return foundChange;
  }

  closeModal = (sendData: string, roadmapProgressForm: NgForm): void => {
    if (sendData === 'cancel') {
      roadmapProgressForm.resetForm();
      this.activeModal.close(sendData);
    } else if (sendData === 'progress') {
      //viewing all progress
      roadmapProgressForm.resetForm();
      this.activeModal.close(sendData);
      this.openViewRoadmapProgress();
    }
  };

  openConfirmationDialog = (sendData: string, roadmapProgressForm: NgForm) => {
    if (this.changesDetected()) {
      this.confirmationDialogService.confirm('Save', 'Do you want to save your changes?')
      .then((confirmed) => {
        if (confirmed) { //selected Yes
            this.buttonSubmit.nativeElement.click();
            //form valid will work for most fields, but passValidation
            if (roadmapProgressForm.form.valid && this.passValidation()) { // and passes validation
              this.closeModal(sendData, roadmapProgressForm);
            }
        } else { //selected No
          this.closeModal(sendData, roadmapProgressForm);
        }
      })
      .catch(() => console.error());
    } else { //no changes detected
      this.closeModal(sendData, roadmapProgressForm);
    }
  };

  dismissModal(): void {
    this.activeModal.dismiss('Roadmap Progress cancelled');
  }

  onKeyDown(inputField: HTMLInputElement, $e: KeyboardEvent): void {
    this.utilsService.onKeyDownNumbers(inputField, $e);
  }

  onPaste(inputField: HTMLInputElement, $e: ClipboardEvent): void {
    this.utilsService.onPasteNumbers(inputField, $e);
  }

  onDrop(inputField: HTMLInputElement, $e: DragEvent): void {
    this.utilsService.onDropNumbers(inputField, $e);
  }

}
