<form name="form" class="form-horizontal" (ngSubmit)="copykeyResultObjectiveForm.form.valid && onSubmit(copykeyResultObjectiveForm, submitBtn)"
  #copykeyResultObjectiveForm="ngForm" novalidate>
  <div class="modal-content">
    <div class="modal-header">
      <h2 class="modal-title">Duplicate Key Result</h2>
      <button type="button" class="btn-close" aria-label="Close"
        (click)="closeModal('cancel', copykeyResultObjectiveForm)"></button>
    </div>
    <div class="modal-body pt-2">       
      <div class="mb-3">
      <label for="enterpriseobjective" class="form-label">Key Result:</label>
      <div class="bg-light"> {{ keyResult.name }} </div>      
    </div>
    <div class="mb-3">
      <div class="row pt-1">
        <div class="col-md-12">
          <div class="form-label">Duplicate Key Result to?</div>
          <input
            type="radio"
            id="duplicateoption1"
            name="timeframe"
            class="btn-check"
            value="sameobjective"
            #timeframe = "ngModel"
            [(ngModel)]="selectedDuplicateOption"
            [ngClass]="{'is-invalid':copykeyResultObjectiveForm.submitted}"
            required>
            <label class="btn btn-sm btn-outline-secondary ps-2 m-1" for="duplicateoption1">Same Objective</label>
          <input
            type="radio"
            id="duplicateoption2"
            name="timeframe"
            class="btn-check"
            value="anotherobjective"
            #timeframe = "ngModel"
            [(ngModel)]= "selectedDuplicateOption"
            [ngClass]="{'is-invalid':copykeyResultObjectiveForm.submitted }"
            required>
            <label class="btn btn-sm btn-outline-secondary ps-2 m-1" for="duplicateoption2">Another Objective</label>        
          <span *ngIf="this.objectiveType === 'PRODUCT_GROUP' || this.objectiveType === 'PRODUCT'">
            <input
              type="radio"
              id="duplicateoption3"
              name="timeframe"
              class="btn-check"
              value="anotherproduct"
              #timeframe = "ngModel"
              [(ngModel)]="selectedDuplicateOption"
              [ngClass]="{'is-invalid':copykeyResultObjectiveForm.submitted }"
              required>
              <label class="btn btn-sm btn-outline-secondary ps-2 m-1" for="duplicateoption3">Another Product</label>            
            <input
              type="radio"
              id="duplicateoption4"
              name="timeframe"
              class="btn-check"
              value="anotherproductgroup"
              #timeframe = "ngModel"
              [(ngModel)]="selectedDuplicateOption"
              [ngClass]="{'is-invalid':copykeyResultObjectiveForm.submitted }"
              required>
              <label class="btn btn-sm btn-outline-secondary ps-2 m-1" for="duplicateoption4">Another Product Group</label>      
          </span> 
        </div>         
      </div>      
    </div>

    <span *ngIf="selectedDuplicateOption === 'anotherobjective'">

    <div *ngIf="this.objectiveType === 'PRODUCT'"class="mb-3">
      <label for="enterpriseobjective" class="form-label">Objective:</label>
      <select id="objective" class="form-control" name="objective" #objective="ngModel" [(ngModel)]="selectedObjectiveId"
        [ngClass]="{'is-invalid':copykeyResultObjectiveForm.submitted && objective.invalid }"
        class="form-select" required>
        <option value=""></option>
        <ng-container *ngFor="let productObjective of productObjectives">
          <ng-container *ngIf="showObjective(productObjective)">
          <option [value]="productObjective.id">{{ productObjective.description | shorten: 90 }}</option>
        </ng-container>
        </ng-container>
      </select>
      <div class="invalid-feedback">
        <div>Objective is required</div>
      </div>
    </div>
    <div *ngIf="this.objectiveType === 'PRODUCT_GROUP'" class="mb-3">
      <label for="enterpriseobjective" class="form-label">Objective:</label>
      <select id="objective" class="form-control" name="objective" #objective="ngModel" [(ngModel)]="selectedObjectiveId"
        [ngClass]="{'is-invalid':copykeyResultObjectiveForm.submitted && objective.invalid }"
        class="form-select" required>
        <option value=""></option>
        <ng-container *ngFor="let productGroupObjective of productGroupObjectives">
          <ng-container *ngIf="showObjective(productGroupObjective)">
          <option [value]="productGroupObjective.id">{{ productGroupObjective.description | shorten: 90 }}</option>
        </ng-container>
      </ng-container>
      </select>
      <div class="invalid-feedback">
        <div>Objective is required</div>
      </div>
    </div>
    <div *ngIf="this.objectiveType === 'PORTFOLIO'" class="mb-3">
      <label for="enterpriseobjective" class="form-label">Objective:</label>
      <select id="objective" class="form-control" name="objective" #objective="ngModel" [(ngModel)]="selectedObjectiveId"
        [ngClass]="{'is-invalid':copykeyResultObjectiveForm.submitted && objective.invalid }"
        class="form-select" required>
        <option value=""></option>
        <ng-container *ngFor="let portfolioObjective of portfolioObjectives">
          <ng-container *ngIf="showObjective(portfolioObjective)">
          <option [value]="portfolioObjective.id">{{ portfolioObjective.description | shorten: 90 }}</option>
        </ng-container>
        </ng-container>
      </select>
      <div class="invalid-feedback">
        <div>Objective is required</div>
      </div>
    </div>
    <div *ngIf="this.objectiveType === 'ENTERPRISE'"class="mb-3">
      <label for="enterpriseobjective" class="form-label">Objective:</label>
      <select id="objective" class="form-control" name="objective" #objective="ngModel" [(ngModel)]="selectedObjectiveId"
        [ngClass]="{'is-invalid':copykeyResultObjectiveForm.submitted && objective.invalid }"
        class="form-select" required>
        <option value=""></option>
        <ng-container *ngFor="let enterpriseObjective of enterpriseObjectives">
          <ng-container *ngIf="showObjective(enterpriseObjective)">
          <option [value]="enterpriseObjective.id">{{ enterpriseObjective.description | shorten: 90 }}</option>
        </ng-container>
        </ng-container>
      </select>
      <div class="invalid-feedback">
        <div>Objective is required</div>
      </div>
    </div>
  </span>
  
  <span *ngIf="selectedDuplicateOption === 'anotherproduct'">
 
    <div *ngIf="this.objectiveType === 'PRODUCT_GROUP'"class="mb-3">  
      <label for="allproducts" class="form-label">Products:</label>
      <select id="product" class="form-control" name="product" #product="ngModel" [(ngModel)]="selectedProductId"
      [ngClass]="{'is-invalid':copykeyResultObjectiveForm.submitted && product.invalid }" (change)="getObjectiveByProductId(selectedProductId)"
      class="form-select" required>
      <option value=""></option>
      <ng-container *ngFor="let product of products">       
        <option value={{product.id}}>{{product.name}}</option>       
      </ng-container>
      </select>
      <div class="invalid-feedback">
        <div>Product is required</div>
      </div>
    </div>
    <div *ngIf="this.objectiveType === 'PRODUCT'"class="mb-3">  
      <label for="allproducts" class="form-label">Products:</label>
      <select id="product" class="form-control" name="product" #product="ngModel" [(ngModel)]="selectedProductId"
      [ngClass]="{'is-invalid':copykeyResultObjectiveForm.submitted && product.invalid }" (change)="getObjectiveByProductId(selectedProductId)"
      class="form-select" required>
      <option value=""></option>
      <ng-container *ngFor="let product of products">       
        <option *ngIf="this.selectedProduct.id !== product.id" value={{product.id}}>{{product.name}}</option>       
      </ng-container>
      </select>
      <div class="invalid-feedback">
        <div>Product is required</div>
      </div>
    </div>
    <div *ngIf="!this.productObjectives || this.productObjectives.length === 0">
      <hr>
      <h4>No objectives found</h4>
    </div>    
    <div class="mb-3" *ngIf="!this.productObjectives || this.productObjectives.length > 0">
      <label for="enterpriseobjective" class="form-label">Objective:</label>
      <select id="objective" class="form-control" name="objective" #objective="ngModel" [(ngModel)]="selectedObjectiveId"
        [ngClass]="{'is-invalid':copykeyResultObjectiveForm.submitted && objective.invalid }"
        class="form-select" required>
        <option value=""></option>
        <ng-container *ngFor="let productObjective of productObjectives">
          <ng-container *ngIf="showObjective(productObjective)">
          <option [value]="productObjective.id">{{ productObjective.description | shorten: 90 }}</option>
        </ng-container>
        </ng-container>
      </select>
      <div class="invalid-feedback">
        <div>Objective is required</div>
      </div>
    </div>
  </span>
  <span *ngIf="selectedDuplicateOption === 'anotherproductgroup'">
 
    <div *ngIf="this.objectiveType === 'PRODUCT'"class="mb-3">  
      <label for="allproductGroupss" class="form-label">Product Groups:</label>
      <select id="productGroup" class="form-control" name="productGroup" #productGroup="ngModel" [(ngModel)]="selectedProductGroupId"
      [ngClass]="{'is-invalid':copykeyResultObjectiveForm.submitted && productGroup.invalid }" (change)="getObjectiveByProductGroupId(selectedProductGroupId)"
      class="form-select" required>
      <option value=""></option>
      <ng-container *ngFor="let productGroup of productGroups">
        <option value={{productGroup.id}}>{{productGroup.name}}</option>
      </ng-container>
      </select>
      <div class="invalid-feedback">
       <div>Product is required</div>
      </div>
    </div>
    <div *ngIf="this.objectiveType === 'PRODUCT_GROUP'"class="mb-3">  
      <label for="allproductGroupss" class="form-label">Product Groups:</label>
      <select id="productGroup" class="form-control" name="productGroup" #productGroup="ngModel" [(ngModel)]="selectedProductGroupId"
      [ngClass]="{'is-invalid':copykeyResultObjectiveForm.submitted && productGroup.invalid }" (change)="getObjectiveByProductGroupId(selectedProductGroupId)"
      class="form-select" required>
      <option value=""></option>
      <ng-container *ngFor="let productGroup of productGroups">
        <option *ngIf="this.selectedProductGroup.id !== productGroup.id" value={{productGroup.id}}>{{productGroup.name}}</option>
      </ng-container>
      </select>
      <div class="invalid-feedback">
       <div>Product is required</div>
      </div>
    </div>
    <div *ngIf="!this.productGroupObjectives || this.productGroupObjectives.length === 0">
      <hr>
      <h4>No objectives found</h4>
    </div>    
    <div class="mb-3" *ngIf="!this.productGroupObjectives || this.productGroupObjectives.length > 0">
      <label for="enterpriseobjective" class="form-label">Objective:</label>
      <select id="objective" class="form-control" name="objective" #objective="ngModel" [(ngModel)]="selectedObjectiveId"
        [ngClass]="{'is-invalid':copykeyResultObjectiveForm.submitted && objective.invalid }"
        class="form-select" required>
        <option value=""></option>
        <ng-container *ngFor="let productGroupObjective of productGroupObjectives">
          <ng-container *ngIf="showPGObjective(productGroupObjective)">
          <option [value]="productGroupObjective.id">{{ productGroupObjective.description | shorten: 90 }}</option>
        </ng-container>
        </ng-container>
      </select>
      <div class="invalid-feedback">
        <div>Objective is required</div>
      </div>
    </div>
  </span>


  </div>
  
  <div class="modal-footer">
    <button (click)="closeModal('cancel', copykeyResultObjectiveForm)" type="button" class="btn btn-secondary">Cancel</button>
    <button class="btn btn-primary" type="submit" #submitBtn>Save</button>
  </div>
  </div>
</form>