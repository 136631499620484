import {TitleCasePipe} from '@angular/common';
import {Component, Input, OnInit, ViewChild, ElementRef, Output, EventEmitter } from '@angular/core';
import {ObjectiveModel} from 'src/app/models/objective.model';
import {ObjectiveService} from 'src/app/services/objective.service';
import {KeyResultModel} from 'src/app/models/key-result.model';
import {ProductModel} from 'src/app/models/product.model';
import {ObjectivemodalComponent} from '../objectivemodal/objectivemodal.component';
import {ObjectivesLogComponent } from '../objectives-log/objectives-log.component';
import {KeyResultModalComponent} from '../key-result-modal/key-result-modal.component';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {KeyResultTrackingModalComponent} from '../key-result-tracking-modal/key-result-tracking-modal.component';
import {KeyResultTrackingComponent} from '../key-result-tracking/key-result-tracking.component';
import {EventEmitterService} from 'src/app/services/event-emitter.service';
import {KeyResultService} from 'src/app/services/key-results.service';
import {ConfirmationDialogService} from 'src/app/services/confirmation-dialog.service';
import {PingAuthenticationService} from '@techops-ui/ping-authentication';
import {UserProfilesService} from 'src/app/services/user-profiles.service';
import {ActivatedRoute, Router} from '@angular/router';
import {ProductGroupModel} from '../../models/product-group.model';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { RoadmapModel } from 'src/app/models/roadmap.model';
import { RoadmapService } from 'src/app/services/roadmap.service';
import { PlanningPortfoliosService } from 'src/app/services/planning-portfolios.service';
import { PlanningPortfolioModel } from 'src/app/models/planning-portfolio.model';
import { CopyKeyresultObjectiveModalComponent } from '../copy-keyresult-objective-modal/copy-keyresult-objective-modal.component';
import { RoadmapsLogComponent } from '../roadmaps-log/roadmaps-log.component';
import {Status} from '@enums';
import { ProductService } from 'src/app/services/product.service';
import { ProductGroupService } from 'src/app/services/productGroup.service';

@Component({
  selector: 'app-objective-items',
  templateUrl: './objective-items.component.html',
  styleUrls: ['./objective-items.component.scss'],
})
export class ObjectiveItemsComponent implements OnInit {
  @Input() objectiveType: string;
  @Input() productId: string;
  @Input() productGroupId: string;
  @Input() planningPortfolioId: number;
  @Input() ownerId: string;
  @ViewChild('selectedProduct') productSelectField: ElementRef;
  @Output() selectStatus: EventEmitter<Status[] | null> = new EventEmitter();

  activeTabId: number;
  activeTabObjectiveId: string;
  selectedTabIndex: number;

  showChangeProduct = false;
  searchOwner = [];
  activeIds: string[] = [];
  accordionIndex = 0;
  titleCasePipe = new TitleCasePipe();
  properObjectiveType: string;
  defaultProductId = '000000';
  defaultProductGroupId = '000000';
  defaultPlanningPortfolioId = 0;
  parentObjectiveHeader: string;
  numKeyResults = 0;
  numNotStarted = 0;
  numDone = 0;
  numMeasuring = 0;
  numObjectives = 0;
  mixedContentWarning = false;
  roadmapType: string;

  pageLoaded = Promise.resolve(false);
  objectives: ObjectiveModel[];
  selectedProduct: ProductModel;
  selectedProductGroup: ProductGroupModel;
  selectedOwner: any;
  products: ProductModel[] = [];
  productGroups: ProductGroupModel[] = [];
  empNumber: string;
  loading = false;
  planningPortfolios: PlanningPortfolioModel[] = [];
  planningPortfolioName: string;
  productGroupJumpToList = [];
  tempProductGroupJumpToList = [];
  allProductObjectives: ObjectiveModel[];
  allProductGroupObjectives: ObjectiveModel[];
  filterStatus = [];

  statusList = [
    {id:0, name:'Not Started'},
    {id:1, name:'Measuring'},
    {id:2, name:'Done'},
    {id:3, name:'Deprioritized'},
    {id:4, name:'Archive'}
  ];

  constructor(
    private route: ActivatedRoute,
    private objectiveService: ObjectiveService,
    private productService: ProductService,
    private productGroupService: ProductGroupService,
    private modalService: NgbModal,
    private eventEmitterService: EventEmitterService,
    private keyResultService: KeyResultService,
    private roadmapService: RoadmapService,
    private confirmationDialogService: ConfirmationDialogService,
    private userProfileService: UserProfilesService,
    private authService: PingAuthenticationService,
    private router: Router,
    private planningPortfolioService: PlanningPortfoliosService,

  ) {
  }

  ngOnInit(): void {
    if (this.authService.loggedIn$) {
      this.authService.profile$.subscribe(p => {
        this.empNumber = p.BadgeNo.substring(2);
        this.route.params.subscribe(params => {
          this.productId = params.productId;
          this.productGroupId = params.productGroupId;
          this.planningPortfolioId = params.planningPortfolioId;
          this.filterStatus.push(Status.notstarted);
          this.filterStatus.push(Status.statusmeasuring);
          this.filterStatus.push(Status.statusdone);
          this.filterStatus.push(Status.deprioritized);
          this.loadData();
        });
        if (this.eventEmitterService.subsVar === undefined) {
          this.eventEmitterService.subsVar =
            this.eventEmitterService.invokeRetrieveObjectives.subscribe(
              (tmpValues: any) => {
                this.retrieveObjectives(tmpValues.showLatest, tmpValues.tabs);
              }
            );
        }
      });
    }
  }

  loadData() {
    this.properObjectiveType = this.titleCasePipe.transform(this.objectiveType);
    if (this.objectiveType === 'PRODUCT' || this.objectiveType === 'PRODUCT_GROUP') {
      this.retrieveUserDefaults();
    } else if(this.objectiveType === 'PORTFOLIO') {
      this.retrieveUserDefaults();
      this.retrieveObjectives();
      this.pageLoaded = Promise.resolve(true);
    } else {
      this.retrieveObjectives();
      this.pageLoaded = Promise.resolve(true);
    }

    if (this.objectiveType === 'ENTERPRISE') {
      this.roadmapType = 'Theme';
    } else if (this.objectiveType === 'PORTFOLIO') {
      this.roadmapType = 'Portfolio Initiative';
    } else {
      this.roadmapType = 'Roadmap Item';
    }
  }

  async retrievePlanningPortfolios() {
    this.tempProductGroupJumpToList = [];
    this.productGroupJumpToList = [];
    const data = await this.planningPortfolioService.getAll().toPromise();
    if (data) {
      this.planningPortfolios = data;
      if (this.objectiveType === 'PORTFOLIO') {
        this.planningPortfolioId = !this.planningPortfolioId
        ? this.defaultPlanningPortfolioId
        : this.planningPortfolioId;

        if (this.planningPortfolioId !== undefined) {
          const allProductPortfolios: any =
          await this.planningPortfolioService.getByPlanningPortfolioId(this.planningPortfolioId).toPromise();
          if (allProductPortfolios) {
            this.productGroups = await this.productGroupService.getAllProductGroups().toPromise();

            if (this.productGroups) {

              allProductPortfolios.forEach(element =>
                this.tempProductGroupJumpToList.push(
                  this.productGroups.filter((obj) => (obj.productPortfolio.id).toString() === (element.id).toString())
                  .map((obj) => ({
                      id: obj.id,
                      name: obj.name
                    }))
                )
              );}
          }
        }
        //extract and sort array of arrays
        this.tempProductGroupJumpToList.forEach(element1 =>
          element1.forEach(element2 =>
            this.productGroupJumpToList.push({id: element2.id, name: element2.name})
          )
        );
        this.productGroupJumpToList.sort((a, b) => a.name.localeCompare(b.name));

      } else if (this.objectiveType === 'PRODUCT' || this.objectiveType === 'PRODUCT_GROUP') {

        const prodPortId = (this.objectiveType === 'PRODUCT') ?
        this.selectedProduct.productGroup.productPortfolio.id:
        this.selectedProductGroup.productPortfolio.id;
        const prodPortData: any =
        await this.planningPortfolioService.getByProductPortfolioId(Number(prodPortId)).toPromise();
        if (prodPortData) {
          this.planningPortfolioId = prodPortData.planningPortfolio.id;
        }
      }
      if (this.planningPortfolioId) {
        this.planningPortfolioName = data.find(x => x.id === Number(this.planningPortfolioId)).name;
      }
    }

  }

  toggleAccordion(e: any) {
    //tracks accordion headers open/closed so they may be retained when modals close
    if (e.nextState === false) {
      const getIndex: number = this.activeIds.indexOf(e.panelId);
      if (getIndex > -1) {
        this.activeIds.splice(getIndex, 1);
      }
    } else {
      this.activeIds.push(e.panelId);
    }
  }

  duplicateKeyResult = (keyResult: KeyResultModel, numKeyResults: number): void => {
    this.confirmationDialogService
      .confirm(
        'Confirm',
        'Do you want to duplicate the Key Result titled: ' +
        keyResult.name +
        '?',
        'Yes',
        'No',
        ''
      )
      .then((confirmed) => {
        if (confirmed) {
          //selected Yes
          keyResult.sort = numKeyResults;
          this.keyResultService.duplicate(keyResult).subscribe(
            (response) => {
              this.retrieveObjectives();
            },
            (error) => {
              console.error(error);
            }
          );
        }
      })
      .catch(() => console.error());
  };

  editObjective = (objective: ObjectiveModel): void => {
    this.openObjectiveModal(objective);
  };

  deleteObjective(objective: ObjectiveModel) {
    const modalRef: NgbModalRef = this.modalService.open(ConfirmationDialogComponent,
      {
        windowClass: 'customModalClass',
        centered: true,
        keyboard: false,
        backdrop: 'static'
      }
      );
    modalRef.componentInstance.title = 'Delete Objective';
    modalRef.componentInstance.message = `By deleting this objective you are deleting the associated KRs and ${this.roadmapType}s. \
    Are you sure you you want to continue deleting \n\n ${objective.description}?`;
    modalRef.componentInstance.btnYesText = 'Delete';
    modalRef.componentInstance.btnNoText = 'Cancel';

    modalRef.result.then((result: boolean)=> {
      if (result) {
        this.objectiveService.delete(objective).subscribe(() => window.location.reload());
      }
    });
  }

  expandAll(e: any) {
    this.activeIds = [];
    if (this.objectiveType==='ENTERPRISE' || this.objectiveType==='PORTFOLIO') {
      for (let i = 0; i < this.objectives.length; i++) {
        this.activeIds.push('heading' + i);
      }
    } else if (this.objectiveType === 'PRODUCT' || this.objectiveType === 'PRODUCT_GROUP') {
      for (let i = 0; i < this.allProductGroupObjectives.length; i++) {
        this.activeIds.push('headingPRODUCT_GROUP' + i);
      }
      for (let i = 0; i < this.allProductObjectives.length; i++) {
        this.activeIds.push('headingPRODUCT' + i);
      }
    }
  }

  calculateInsights() {
    this.numKeyResults = 0;
    this.numNotStarted = 0;
    this.numDone = 0;
    this.numMeasuring = 0;
    this.numObjectives = 0;
    this.mixedContentWarning = false;
    let productObjectivesPresent = false;
    let productGroupObjectivesPresent = false;
    this.objectives.forEach(objective => {
      const visible = this.canShowObjective(objective);
      if (objective.objectiveType === 'PRODUCT' && this.objectiveType === 'PRODUCT_GROUP') {
        productObjectivesPresent = true;
      } else if (
        objective.objectiveType === 'PRODUCT' && visible) {
          productObjectivesPresent = true;
      } else if (objective.objectiveType === 'PRODUCT_GROUP') {
        productGroupObjectivesPresent = true;
      }
      if (!objective.draft && visible) {
        this.numObjectives++;
        objective.keyResults.forEach((krElement, krIndex) => {
          if (!krElement.draft) {
            this.numKeyResults++;
            switch (krElement.status) {
              case 'Not Started':
                this.numNotStarted++;
                break;
              case 'Measuring':
                this.numMeasuring++;
                break;
              case 'Done':
                this.numDone++;
                break;
            }
          }
        });
      }
    });
    if (productObjectivesPresent && productGroupObjectivesPresent) {
      this.mixedContentWarning = true;
    }
  }

  retrieveObjectives = async (showLatest?: boolean, tabs?: any): Promise<void> => {
    if (tabs !== undefined) {
      if (tabs?.tabId && tabs?.tabObjectiveId) { //roadmap tab will remain open for this one objective
        this.selectedTabIndex = 0; //okr & roadmap tab is index 0
        this.activeTabId = tabs.tabId;
        this.activeTabObjectiveId = tabs.tabObjectiveId;
      } else if (tabs?.priorityTab) { //if true, will stay open on prioritization tab
        this.selectedTabIndex = 1; //prioritization tab is index 1
      } else {
        this.selectedTabIndex = 0; //okr & roadmap tab is index 0
        this.activeTabId = 1;
      }
    } else {
      this.selectedTabIndex = 0; //okr & roadmap tab is index 0
      this.activeTabId = 1;
    }

    let request;
    if (this.objectiveType === 'PRODUCT') {
      this.productId = !this.productId
        ? this.defaultProductId
        : this.productId;
      this.productGroupId = this.selectedProduct.productGroup.id;
      request = this.objectiveService.getAllByProductAndGroup(this.objectiveType, this.productId, this.productGroupId, '');
    } else if (this.objectiveType === 'PRODUCT_GROUP') {
      this.productGroupId = !this.productId
        ? this.defaultProductGroupId
        : this.productGroupId;
      request = this.objectiveService.getAllByProductGroup(this.objectiveType, this.productGroupId, '');
    } else if (this.objectiveType === 'PORTFOLIO') {
      if (this.planningPortfolioId !== undefined) {
        request = this.objectiveService.getAllPortfolioByPlanningPortfolio(this.planningPortfolioId);
      } else {
        return;
      }
    } else {
      request = this.objectiveService.getAll(this.objectiveType, '');
    }

    await request.subscribe(
      (data) => {
        if (this.filterStatus.length > 0) {
          //if we have at least one status filter
           this.objectives = data.filter((obj) => this.filterStatus.includes(obj.status));
        } else {
          //no filter
          this.objectives = data;
        }

        this.calculateInsights();

        //count and sort KR ascending
        this.objectives.forEach(objective => {
          if ((this.objectiveType === 'PRODUCT' || this.objectiveType === 'PRODUCT_GROUP') &&
            objective.productId !== this.productId) {
            this.productService.getProductById(objective.productId).subscribe(
              (productData) => {
                objective.productName = productData[0].name;
              },
              (error) => {
                console.error(error);
              }
            );
          };
        });

        if (this.objectiveType === 'PRODUCT' || this.objectiveType === 'PRODUCT_GROUP') {
          this.allProductObjectives = this.objectives.filter((obj) => obj.objectiveType === 'PRODUCT');
          this.allProductGroupObjectives = this.objectives.filter((obj) => obj.objectiveType === 'PRODUCT_GROUP');
        }

        this.pageLoaded = Promise.resolve(true);
        this.showChangeProduct = false;

        if (showLatest) {
          //expand last accordion header for new objective
          let lastHeading: string;
          if (this.objectiveType === 'PRODUCT') {
            lastHeading = 'headingPRODUCT' + (this.allProductObjectives.length - 1);
          } else if (this.objectiveType === 'PRODUCT_GROUP') {
            lastHeading = 'headingPRODUCT_GROUP' + (this.allProductGroupObjectives.length - 1);
          } else {
            lastHeading = 'heading' + (data.length - 1);
          }
          this.activeIds.push(lastHeading);
        }
      },
      (error) => {
        console.error(error);
      }
    );
  };

  retrieveProduct = (): void => {
    if (this.productId) {
      this.defaultProductId = this.productId;
    }
    this.selectedProduct = this.products.find(product => String(product.id) === this.defaultProductId);
  };

  retrieveProductGroup = (): void => {
    if (this.productGroupId) {
      this.defaultProductGroupId = this.productGroupId;
    }
    this.selectedProductGroup = this.productGroups.find(productGroup => String(productGroup.id) === this.defaultProductGroupId);
  };

  async retrieveUserDefaults() {
    const userProfile = await this.userProfileService.getUserProfileByEmpNum(this.empNumber).toPromise();
    if (!userProfile) {
      location.href = 'user-profile';
      return;
    }
    if (this.objectiveType === 'PORTFOLIO') {
      if (!this.planningPortfolioId && userProfile?.defaultPlanningPortfolioId) {
        location.href = 'portfolio-objectives/' + userProfile.defaultPlanningPortfolioId;
        return;
      }
      if (userProfile?.defaultPlanningPortfolioId === null || userProfile?.defaultPlanningPortfolioId === 0 || !userProfile) {
        location.href = 'user-profile';
        return;
      }
      this.retrievePlanningPortfolios();
    }
    if (this.objectiveType === 'PRODUCT') {
      if (userProfile?.defaultProductId === '000000' || !userProfile) {
        location.href = 'user-profile';
        return;
      }
      this.defaultProductId = userProfile?.defaultProductId;
      if (!this.productId) { //no product ID found in router/url
        location.href = 'product-objectives/' + this.defaultProductId;
      }
      this.products = await this.productService.getAllProducts().toPromise();
      this.products.sort((a, b) => a.name.localeCompare(b.name));

      this.retrieveProduct();
      this.retrieveObjectives();
      if (!this.productId) {
        this.productId = this.defaultProductId;
      }
      this.retrievePlanningPortfolios();
    }
    if (this.objectiveType === 'PRODUCT_GROUP') {
      if (userProfile?.defaultProductGroupId === '000000' || !userProfile) {
        location.href = 'user-profile';
        return;
      }
      this.defaultProductGroupId = userProfile?.defaultProductGroupId;
      if (!this.productGroupId) { //no product group ID found in router/url
        location.href = 'product-group-objectives/' + this.defaultProductGroupId;
      }
      this.products = await this.productService.getAllProducts().toPromise();
      this.products.sort((a, b) => a.name.localeCompare(b.name));
      this.productGroups = await this.productGroupService.getAllProductGroups().toPromise();
      this.productGroups.sort((a, b) => a.name.localeCompare(b.name));

      this.retrieveProductGroup();
      this.retrieveObjectives();
      if (!this.productGroupId) {
        this.productGroupId = this.defaultProductGroupId;
      }
      this.retrievePlanningPortfolios();
    }
  }

  routeTo(value: string) {
     location.href = value;
  }

  filterObjectives() {
    this.retrieveObjectives();
  }

  openObjectiveModal = (thisObjective?: ObjectiveModel): void => {
    const modalRef = this.modalService.open(ObjectivemodalComponent, {
      windowClass: 'customModalClass',
      centered: true,
      keyboard: false,
      backdrop: 'static',
    });

    modalRef.componentInstance.selectedProduct = this.selectedProduct;
    modalRef.componentInstance.selectedProductGroup = this.selectedProductGroup;
    modalRef.componentInstance.objectiveType = this.objectiveType;
    modalRef.componentInstance.planningPortfolioId = this.planningPortfolioId;
    modalRef.componentInstance.planningPortfolioName = this.planningPortfolioName;

    if (thisObjective) {
      modalRef.componentInstance.editObjective = thisObjective;
    }

    modalRef.result.then(
      (result) => {
        // true will expand last entry on accordion
        const showLatest =
          result === 'onSubmit-Create' || result === 'saveAsDraft-Create';
        if (result !== 'cancel') {
          this.retrieveObjectives(showLatest);
        }
      }
    );
  };

  editKeyResult = (keyResult: KeyResultModel, objectiveType: string): void => {
    this.openKeyResultModal(keyResult, objectiveType);
  };

   openKeyResultTracking = (
    isNew: boolean,
    keyResult: KeyResultModel
  ): void => {
    //if new, show modal to create new tracking, otherwise display all tracking for selected key result
    let useModal: any;
    if (isNew) {
      useModal = KeyResultTrackingModalComponent;
    } else {
      useModal = KeyResultTrackingComponent;
    }

    const modalRef = this.modalService.open(useModal, {
      windowClass: 'customModalClass',
      centered: true,
      keyboard: false,
      backdrop: 'static',
    });

    modalRef.componentInstance.tempKeyResult = keyResult;

    modalRef.result.then(
      (result) => {
        if (result !== 'cancel') {
          this.retrieveObjectives(false);
        }
      }
    );
  };

  copyKeyResultObjective = (keyResult: KeyResultModel, thisObjective?: ObjectiveModel): void => {
    const modalRef = this.modalService.open(CopyKeyresultObjectiveModalComponent, {
      windowClass: 'customModalClass',
      centered: true,
      keyboard: false,
      backdrop: 'static',
    });
    modalRef.componentInstance.keyResult = keyResult;
    modalRef.componentInstance.selectedProduct = this.selectedProduct;
    modalRef.componentInstance.selectedProductGroup = this.selectedProductGroup;
    modalRef.componentInstance.objectiveType = this.objectiveType;
    modalRef.componentInstance.planningPortfolioId = this.planningPortfolioId;

    modalRef.result.then(
      (result) => {

        if (result !== 'cancel') {
          this.retrieveObjectives(false);
        }
      }
    );
  };

  openObjectivesLog = (objective: ObjectiveModel): void => {
    const modalRef = this.modalService.open(ObjectivesLogComponent, {
      windowClass: 'customModalClass',
      centered: true,
      keyboard: false,
      backdrop: 'static',
    });
    modalRef.componentInstance.objective = objective;

    modalRef.result.then(
      (result) => {

        if (result !== 'cancel') {
       //   this.retrieveObjectives(false);
        }
      }
    );
  };

  openRoadmapsLog = (roadmap: RoadmapModel): void => {
    const modalRef = this.modalService.open(RoadmapsLogComponent, {
      windowClass: 'customModalClass',
      centered: true,
      keyboard: false,
      backdrop: 'static',
    });
    modalRef.componentInstance.roadmap = roadmap;

    modalRef.result.then(
      (result) => {

        if (result !== 'cancel') {
       //   this.retrieveObjectives(false);
        }
      }
    );
  };


  openKeyResultModal = (
    keyResult?: KeyResultModel,
    objectiveType?: string,
    objectiveId?: string,
    numKeyResults?: number
  ): void => {
    const modalRef = this.modalService.open(KeyResultModalComponent, {
      windowClass: 'customModalClass',
      centered: true,
      keyboard: false,
      backdrop: 'static',
    });

    modalRef.componentInstance.objectiveId = objectiveId;
    modalRef.componentInstance.objectiveType = objectiveType;

    if (keyResult) {
      modalRef.componentInstance.editKeyResult = keyResult;
    }

    if (numKeyResults) {
      modalRef.componentInstance.numKeyResults = numKeyResults;
    }

    modalRef.result.then(
      (result) => {
        if (result !== 'cancel') {
          this.retrieveObjectives(false);
        }
      }
    );
  };

  canShowObjective(o: ObjectiveModel): boolean {
    if(this.objectiveType !== 'PRODUCT' || o.objectiveType === 'PRODUCT_GROUP') {
      return true;
    }

    return o.productId === String(this.productId);

    return true;
  }
}
