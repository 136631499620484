<form name="form" class="form-horizontal" (ngSubmit)="userProfileForm.form.valid && saveUserProfile(userProfileForm)"
      #userProfileForm="ngForm" novalidate>
  <div class="position-relative" *ngIf="pageLoaded | async">
    <div class="row">
      <div class="col-12">
        <h1>
          {{ this.firstName }} {{ this.lastName }} ({{ this.empNumber }})
        </h1>
      </div>
    </div>
    <div class="alert alert-primary col-md-6" role="alert" *ngIf="this.displayDefaultPrompt === true">
      <strong>Welcome to okr.aa!</strong> Since this is your first visit, please verify that the below Product and Product Group we found for you in Squad 360 is correct. These selections will become the default landing page each time you visit. This can be updated at any time in this Profile page, which can be reached by clicking on your name in the top left corner, and selecting Profile.
    </div>
    <div class="alert alert-warning col-md-6" role="alert" *ngIf="this.inSq360 === false">
      <strong>Welcome to okr.aa!</strong> We were not able to find your name in Squad 360 to set a default landing page. Since this is your first visit, please select your preferred Product and Product Group to set your default. This can be updated at any time in this Profile page, which can be reached by clicking on your name in the top left corner, and selecting Profile.
      <br>
      <br>Jump over to <a href="https://squad360.aa.com" target="_blank">Squad 360</a> and add yourself to one of your teams to enable customized views in other apps as well!
    </div>
    <div class="row">
      <div class="col-md-6">
        <label for="defaultProduct" class="form-label">Select default product</label>

        <select
          id="defaultProduct"
          class="form-control"
          name="defaultProduct"
          (change)="selectProduct()"
          #defaultProduct="ngModel"
          [(ngModel)]="this.defaultProductId"
          [ngClass]="{'is-invalid':userProfileForm.submitted && this.defaultProductId === '000000'}"
          required>
          <option *ngFor="let product of products" [value]="product.id">{{ product.name }}</option>
        </select>
        <div class="invalid-feedback">
          <div> Product is required</div>
        </div>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-md-6 text-start">
        <div *ngIf='this.selectedProduct'>
          <h6>
            {{ this.selectedProduct.productGroup.productPortfolio.portfolioGroup.name }} >
            {{ this.selectedProduct.productGroup.productPortfolio.name }} >
            {{ this.selectedProduct.productGroup.name }} >
            {{ this.selectedProduct.name }}
          </h6>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6 text-end">
        <hr/>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <label for="defaultProductGroup" class="form-label">Select default product group</label>
        <select
          id="defaultProductGroup"
          class="form-control"
          name="defaultProductGroup"
          (change)="selectProductGroup()"
          #defaultProductGroup="ngModel"
          [(ngModel)]="this.defaultProductGroupId"
          [ngClass]="{'is-invalid':userProfileForm.submitted && this.defaultProductGroupId === '000000'}"
          required>
          <option *ngFor="let productGroup of productGroups" [value]="productGroup.id">{{ productGroup.name }}</option>
        </select>
        <div class="invalid-feedback">
          <div> Product Group is required</div>
        </div>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-md-6 text-start">
        <div *ngIf='this.selectedProductGroup'>
          <h6>
            {{ this.selectedProductGroup.productPortfolio.portfolioGroup.name }} >
            {{ this.selectedProductGroup.productPortfolio.name }} >
            {{ this.selectedProductGroup.name }}
          </h6>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <hr>
      </div>
    </div>
    <div class="row">
      <div class="col-md-3">
        Default planning portfolio:
      </div>
      <div class="col-md-3" *ngIf="visability">
        {{ this.selectedProduct.productGroup.productPortfolio.portfolioGroup.name }} 
      </div>
    </div>
    <div class="row">
      <div class="col-md-6 text-end">
        <hr>
        <button class="btn btn-primary" type="submit" #submitBtn>Save</button>
      </div>
    </div>
  </div>
</form>
