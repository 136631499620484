<div class="container">
    <div class="row">
            <div class="input-group">
                    <i title="filter by status" class="bi bi-funnel-fill fs-5 px-2 pt-1"></i>
                <ng-select
                    id="stateFilter"
                    class="filterSelect"
                    [items]="states"
                    placeholder="State"
                    (change)="onStateChange($event)"
                    [multiple]="true"
                    [closeOnSelect]="false"
                    [(ngModel)]="selectedState"
                    [searchable]="false">
                </ng-select>
                <ng-select
                    id="yearFilter"
                    class="filterSelect"
                    [items]="milestoneYears"
                    (change)="onYearChange($event)"
                    placeholder="Milestone Year"
                    [multiple]="true"
                    [(ngModel)]="selectedYear"
                    [searchable]="false">
                </ng-select>
                <ng-select
                    id="quarterFilter"
                    class="filterSelect"
                    [items]="milestoneQuarters"
                    (change)="onQuarterChange($event)"
                    placeholder="Milestone Quarter"
                    [multiple]="true"
                    [(ngModel)]="selectedQuarter"
                    [searchable]="false">
                </ng-select>
                <ng-select
                id="monthFilter"
                class="filterSelect"
                [items]="milestoneMonths"
                (change)="onMonthChange($event)"
                placeholder="Milestone Month"
                [multiple]="true"
                [(ngModel)]="selectedMonth"
                [searchable]="false">
            </ng-select>
        </div>
    </div>
</div>
