import { LowerCasePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ObjectiveModel } from 'src/app/models/objective.model';
import { ProductModel } from 'src/app/models/product.model';
import { ObjectiveService } from 'src/app/services/objective.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { PingAuthenticationService } from '@techops-ui/ping-authentication';
import { GraphqlQueriesService, UserSearchResult } from 'src/app/services/graphql-queries.service';
import { ProductGroupModel } from '../../models/product-group.model';
import { ProductService } from 'src/app/services/product.service';
import { ProductGroupService } from 'src/app/services/productGroup.service';



@Component({
  selector: 'app-objective-realign-modal',
  templateUrl: './objective-realign-modal.component.html',
  styleUrls: ['./objective-realign-modal.component.scss']
})

export class ObjectiveRealignModalComponent implements OnInit {
  static componentInstance: any;
  objective: ObjectiveModel = {
    objectiveType: '',
    portfolioGroupId: '',
    productPortfolioId: '',
    productGroupId: '',
    productId: '',
    ownerId: '',
    userId: '',
    fundingUnit: '',
    portfolioObjectiveId: '',
    enterpriseObjectiveId: '',
    description: '',
    draft: true,
    form: null,
    productType: ''
  };
  lowercasePipe = new LowerCasePipe();
  selectedType = '';
  selectedProductId = null;
  selectedProductGroupId = null;
  lowerObjectiveType = '';
  products: ProductModel[] = [];
  productGroups: ProductGroupModel[] = [];

  constructor(
    private productService: ProductService,
    private productGroupService: ProductGroupService,

    private objectiveService: ObjectiveService,
    private authService: PingAuthenticationService,
    private graphqlQueriesService: GraphqlQueriesService,
    public activeModal: NgbActiveModal) { }

  ngOnInit(): void {
    this.lowerObjectiveType = this.lowercasePipe.transform(this.objective.objectiveType);
    this.selectedType = this.objective.objectiveType;
    this.selectedProductGroupId = Number(this.objective.productGroupId);
    this.selectedProductId = Number(this.objective.productId);
    this.loadData();
  }

  async loadData() {
    this.products = await this.productService.getAllProducts().toPromise();
    this.products = this.products.filter(p => String(p.productGroup.id) === this.objective.productGroupId);
    this.productGroups = await this.productGroupService.getAllProductGroups().toPromise();

  }

  onSubmit(objectiveForm: NgForm, submitBtn: HTMLButtonElement): void {
    this.objective.productGroupId = String(this.selectedProductGroupId);
    this.objective.productId = String(this.selectedProductId);
    this.objective.objectiveType = this.selectedType;

    this.objectiveService.update(this.objective).subscribe(
      response => {
        this.activeModal.close('onSubmit-Update');
      },
      error => {
        console.error(error);
      });
  }

  closeModal = (sendData: string, objectiveForm: NgForm, draftBtn: HTMLButtonElement): void => {
    objectiveForm.resetForm();
    this.activeModal.close(sendData);
  };

  onTypeChange() {
    if (this.objective.objectiveType === 'PRODUCT_GROUP') {
      this.selectedProductId = null;
    }
  }

}
