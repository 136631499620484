<div class="modal-header">
    <h4 class="modal-title">{{ title }}</h4>    
    <button type="button" class="btn-close" aria-label="Close" (click)="dismiss()"></button>
</div>
<div class="modal-body">
    <div style="white-space: pre-line">{{ message }}</div>
</div>
<div class="modal-footer">
    <button *ngIf="btnCancelText" type="button" class="btn btn-secondary" (click)="dismiss()">{{ btnCancelText }}</button>
    <button type="button" class="btn btn-primary" (click)="accept(false)">{{ btnNoText }}</button>
    <button type="button" class="btn btn-primary" (click)="accept(true)">{{ btnYesText }}</button>
</div>
