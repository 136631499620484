 <div class="container-fluid">
    <div class="row flex-nowrap">
        <div class="col-auto col-sm-4 col-md-3 col-lg-2 col-xl-2 col-xxl-2 px-sm-2 px-0" style="background-image: linear-gradient(#00467f, #0061ab);">
            <app-sidebar></app-sidebar>
        </div>
        <div class="col py-3" style="padding: 0 1.5rem;">
            <div class="row">
                <router-outlet></router-outlet>
            </div>
        </div>
    </div>
</div>

