import {Component, ElementRef, OnInit} from '@angular/core';
import {UserProfilesService} from 'src/app/services/user-profiles.service';
import {UserProfileModel} from 'src/app/models/user-profile.model';
import {PingAuthenticationService} from '@techops-ui/ping-authentication';
import { ProductService } from 'src/app/services/product.service';
import {EmpidTaxonomyservice} from 'src/app/services/empid-taxonomy.service';
import {ProductModel} from 'src/app/models/product.model';
import {NgForm} from '@angular/forms';
import {Location} from '@angular/common';
import {ProductGroupModel} from '../../models/product-group.model';
import { PlanningPortfolioModel } from 'src/app/models/planning-portfolio.model';
import { PlanningPortfoliosService } from 'src/app/services/planning-portfolios.service';
import { OKRProductPortfolioGroupModel } from 'src/app/models/okr-product-portfolio.model';
import { ProductOKRModel } from 'src/app/models/productokr.model';
import { ProductGroupService } from 'src/app/services/productGroup.service';
import { ProductGroupOKRModel } from 'src/app/models/productGroupokr.model';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss']
})
export class UserProfileComponent implements OnInit {

  userProfile = new UserProfileModel();
  pageLoaded: Promise<boolean>;
  products: ProductModel[];
  productOKR: ProductOKRModel[];
  productGroupsOKR: ProductGroupOKRModel[];
  productGroups: ProductGroupModel[];
  defaultProductId: string;
  defaultProductGroupId: string;
  defaultPlanningPortfolioId: number;
  defaultPlanningPortfolioName: string;
  existingProfile = false;
  inSq360: boolean;
  displayDefaultPrompt = false;
  selectedProduct: ProductModel;
  selectedProductGroup: ProductGroupModel;
  selectedPlanningPortfolio: OKRProductPortfolioGroupModel[] = [];
  empNumber: string;
  firstName: string;
  lastName: string;
  noOfProducts: number;
  visability: boolean;

  constructor(private userProfilesService: UserProfilesService,
              private authService: PingAuthenticationService,
              private eRef: ElementRef,
              private empidTaxonomyservice: EmpidTaxonomyservice,
              private productService: ProductService,
              private productGroupService: ProductGroupService,
              private location: Location,
              private planningPortfolioService: PlanningPortfoliosService) {
  }

  ngOnInit(): void {
    if (this.authService.loggedIn$) {
      this.authService.profile$.subscribe(p => {
        this.firstName = p.given_name;
        this.lastName = p.family_name;
        this.empNumber = p.BadgeNo.substring(2);
        this.visability = false;
        this.pageLoaded = this.loadData();
      });
    }
  }

  async selectProduct() {
    this.selectedProduct = this.products.find(product => String(product.id) === this.defaultProductId);
    if(this.selectedProduct) {
      this.defaultProductId = this.selectedProduct.id;
      this.defaultProductGroupId = this.selectedProduct.productGroup.id;
      this.visability = true;
      const prodPortId = this.selectedProduct.productGroup.productPortfolio.id;
      const params = {prodPortId: Number(prodPortId)};
      await this.retrievePlanningPortfolio(params);
    }
  }

  selectProductGroup(): void {
    this.selectedProductGroup = this.productGroups.find(productGroup => String(productGroup.id) === this.defaultProductGroupId);
    if (this.selectedProductGroup) {
      this.defaultProductGroupId = this.selectedProductGroup.id;
    }
  }

  async loadData(): Promise<boolean> {
    try {
      await this.retrieveProducts();
      await this.retrieveProductGroups();
      await this.retrieveUserProfile();
    } catch (e) {
      console.error(e);
    }
    return Promise.resolve(true);
  }

  async retrieveProducts() {
   const productData = await this.productService.getAllProducts().toPromise();

    if (productData) {
      this.products = productData;
      this.noOfProducts = this.products.length;
    }
  }

  async retrieveProductGroups() {
    const productGroupData = await this.productGroupService.getAllProductGroups().toPromise();
    this.productGroups = productGroupData;
  }

  async retrievePlanningPortfolio(params: any) {
    let data: any;
    if (params.empNumber) {
      data = await this.planningPortfolioService.getPlanningPortfolioByEmpNum(params.empNumber).toPromise();
    }else if (params.prodPortId) {
      data = await this.planningPortfolioService.getByProductPortfolioId(params.prodPortId).toPromise();
    }
    if (data) {
      this.defaultPlanningPortfolioId = data.planningPortfolio.id;
      this.defaultPlanningPortfolioName = data.planningPortfolio.name;
    }
  }

  async retrieveUserProfile() {
    const userProfile = await this.userProfilesService.getUserProfileByEmpNum(this.empNumber).toPromise();
    if (userProfile) {
      this.existingProfile = true;
      this.userProfile = userProfile;
      this.defaultProductId = this.userProfile.defaultProductId;
      this.defaultProductGroupId = this.userProfile.defaultProductGroupId;
      if (!this.userProfile.defaultPlanningPortfolioId) {
        const params = {empNumber: this.empNumber};
        await this.retrievePlanningPortfolio(params);
      } else {
        this.defaultPlanningPortfolioId = this.userProfile.defaultPlanningPortfolioId;
      }
      this.selectProduct();
      this.selectProductGroup();
    } else {
      this.existingProfile = false;
      this.displayDefaultPrompt = true;
      await this.retrieveS360DefaultProduct();
    }
    if (this.userProfile.defaultProductId === '000000' || this.userProfile.defaultProductGroupId === '000000') {
      this.inSq360 = false;
    }
  }

  saveUserProfile(userProfileForm?: NgForm): void {
    if (userProfileForm) { //we are saving from button
      if (this.defaultProductId === '000000' || this.defaultProductGroupId === '000000') {
        return;
      }
    }

    if (this.existingProfile) { //update an existing profile
      //new defaults
      this.userProfile.defaultProductId = this.defaultProductId;
      this.userProfile.defaultProductGroupId = this.defaultProductGroupId;
      this.userProfile.defaultPlanningPortfolioId = this.defaultPlanningPortfolioId;
      this.userProfilesService.update(this.userProfile).subscribe(
        response => {
          this.location.back();
        },
        error => {
          console.error(error);
        });
    } else { //creating a new profile
      this.userProfilesService.create(this.userProfile).subscribe(
        response => {
          this.retrieveUserProfile();
        },
        error => {
          console.error(error);
        });
    }
  }

  async retrieveS360DefaultProduct() {
     const s360Data = await this.empidTaxonomyservice.gettaxonomyByEmpNum(this.empNumber).toPromise();
    if (s360Data) {
      this.defaultProductId = s360Data.productId || '000000';
      const product = this.products.find(p => p.id === this.defaultProductId);
      this.defaultProductGroupId = s360Data.productGroupId || '000000';
    }
    await this.createNewUserProfile();
  }

  async createNewUserProfile() {
    //create new profile with S360 default
    this.userProfile.empNumber = this.empNumber;
    if (this.defaultProductId && this.defaultProductGroupId) {
      this.userProfile.defaultProductId = this.defaultProductId;
      this.userProfile.defaultProductGroupId = this.defaultProductGroupId;
      const params = {empNumber: this.empNumber};
      await this.retrievePlanningPortfolio(params);
      this.userProfile.defaultPlanningPortfolioId = this.defaultPlanningPortfolioId;
      await this.selectProduct();
      await this.selectProductGroup();
    } else { //user doesn't have a default, set to all zero and require change before saving
      this.userProfile.defaultProductId = '000000';
      this.userProfile.defaultProductGroupId = '000000';
      this.userProfile.defaultPlanningPortfolioId = 0;
    }
    this.pageLoaded = Promise.resolve(true);
    this.saveUserProfile();
  }

}

