<form
  class="form-horizontal"
  (ngSubmit)="roadmapForm.form.valid && onSubmitForm()"
  #roadmapForm="ngForm"
  novalidate
>
  <div class="modal-content new-kr-dialog">
    <div class="modal-header">
      <h2 class="modal-title">
        {{ existingRoadmapItem ? "Edit" : "Add a new" }} {{ this.roadmapType
        }}{{
          this.roadmap.progressState && !hasProgress
            ? " (" + this.roadmap.progressState + ")"
            : ""
        }}
      </h2>
      <button type="button" class="btn-close" (click)="dismissModal()"></button>
    </div>
    <div class="modal-body">
      <!-- Roadmap initiative name/title -->
      <div class="row mb-3">
        <div class="col-md-12">
          <label for="description" class="form-label"
            >Initiative Name/Title</label
          >
          <textarea
            placeholder="Describe what will be done"
            class="form-control"
            id="description"
            rows="2"
            #description="ngModel"
            name="description"
            [(ngModel)]="roadmap.description"
            [ngClass]="{
              'is-invalid': roadmapForm.submitted && description.invalid
            }"
            required
          >
          </textarea>
          <div class="invalid-feedback">
            Please enter an initiative name/title.
          </div>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-md-12">
          <label for="impactEstimate" class="form-label">Description</label>
          <textarea
            placeholder="Estimate of measurable impact or value expected during delivery"
            class="form-control"
            id="impactEstimate"
            rows="2"
            #impactEstimate="ngModel"
            name="impactEstimate"
            [(ngModel)]="roadmap.impactEstimate"
          >
          </textarea>
        </div>
      </div>
      <div *ngIf="existingRoadmapItem">
        <hr />
        <label class="form-label">Current Progress</label>
        <div class="row gridHeader">
          <div class="col">State</div>
          <div class="col text-center">% Completed</div>
          <div class="col">Confidence Level</div>
          <div class="col">Current Value Delivered</div>
          <div class="col text-center">Next Idea</div>
          <div class="col text-center">Action</div>
        </div>
        <div class="row">
          <div class="col pt-1">
            {{ roadmap.progressState
            }}<ng-container *ngIf="this.roadmap.doneTime"
              >:
              {{
                this.roadmap.doneTime | date : "MMM d, y, h:mm a"
              }}</ng-container
            >
          </div>
          <div class="col pt-1 text-center">
            {{ roadmap.progressPercentPlannedWorkCompleted }}%
          </div>
          <div class="col pt-1">
            {{ roadmap.progressConfidenceLevel }}
          </div>
          <div class="col pt-1">
            {{ roadmap.progressCurrentValueDelivered }}
          </div>
          <div class="col pt-1 text-center">
            <span
              class="bi bi-lightbulb-fill desc-hover-icon"
              style="font-size: 16px"
              title="{{ roadmap.progressNextBestIdeas | shorten : 256 }}"
              *ngIf="roadmap.progressNextBestIdeas; else noIdeas"
            ></span>
            <ng-template #noIdeas>
              <span></span>
              <!--If no ideas, display no icon!-->
            </ng-template>
          </div>
          <div *ngIf="showProgressButtons" class="col text-center">
            <button
              type="button"
              class="btn btn-primary btn-sm"
              (click)="openConfirmationDialog('add', roadmapForm)"
            >
              <i class="bi-plus"></i>Add
            </button>
            <span>&nbsp;</span>
            <button
              type="button"
              class="btn btn-primary btn-sm"
              (click)="openConfirmationDialog('progress', roadmapForm)"
            >
              View
            </button>
          </div>
        </div>
        <hr />
      </div>
      <div class="row">
        <div class="col-2"></div>
        <div class="col-3">Quarter</div>
        <div class="col-4">Month</div>
        <div class="col-3">Year</div>
      </div>
      <div class="row mb-3">
        <div class="col-2 align-bottom pt-2">
          Delivery Milestone
          <a [routerLink]="[]" (click)="resetDate()">Reset</a>
        </div>
        <div class="col-3">
          <input
            type="radio"
            id="quarter1"
            name="quarter"
            class="btn-check"
            value="Q1"
            #quarter="ngModel"
            (change)="resetMonth()"
            [(ngModel)]="roadmap.quarter"
            [ngClass]="{
              'is-invalid':
                roadmapForm.submitted &&
                quarter.value === '0' &&
                roadmap.progressState !== 'Backlog'
            }"
            required
          />
          <label class="btn btn-sm btn-outline-secondary m-1" for="quarter1"
            >Q1</label
          >
          <input
            type="radio"
            id="quarter2"
            name="quarter"
            class="btn-check"
            value="Q2"
            #quarter="ngModel"
            (change)="resetMonth()"
            [(ngModel)]="roadmap.quarter"
            [ngClass]="{
              'is-invalid':
                roadmapForm.submitted &&
                quarter.value === '0' &&
                roadmap.progressState !== 'Backlog'
            }"
            required
          />
          <label class="btn btn-sm btn-outline-secondary m-1" for="quarter2"
            >Q2</label
          >
          <input
            type="radio"
            id="quarter3"
            name="quarter"
            class="btn-check"
            value="Q3"
            #quarter="ngModel"
            (change)="resetMonth()"
            [(ngModel)]="roadmap.quarter"
            [ngClass]="{
              'is-invalid':
                roadmapForm.submitted &&
                quarter.value === '0' &&
                roadmap.progressState !== 'Backlog'
            }"
            required
          />
          <label class="btn btn-sm btn-outline-secondary m-1" for="quarter3"
            >Q3</label
          >
          <input
            type="radio"
            id="quarter4"
            name="quarter"
            class="btn-check"
            value="Q4"
            #quarter="ngModel"
            (change)="resetMonth()"
            [(ngModel)]="roadmap.quarter"
            [ngClass]="{
              'is-invalid':
                roadmapForm.submitted &&
                quarter.value === '0' &&
                roadmap.progressState !== 'Backlog'
            }"
            required
          />
          <label class="btn btn-sm btn-outline-secondary m-1" for="quarter4"
            >Q4</label
          >
          <div class="invalid-feedback pb-0">Quarter is required</div>
          <div *ngIf="roadmap.progressState === 'Backlog'">optional</div>
        </div>
        <div class="col-4">
          <div class="text-muted pt-md-3" *ngIf="roadmap.quarter === '0'">
            Select Quarter
          </div>
          <div [ngSwitch]="roadmap.quarter">
            <ng-container *ngSwitchCase="'Q1'">
              <input
                type="radio"
                id="month1"
                name="month"
                class="btn-check"
                value="1"
                #month="ngModel"
                [(ngModel)]="roadmap.month"
                [ngClass]="{
                  'is-invalid':
                    roadmapForm.submitted &&
                    month.value === '0' &&
                    roadmap.progressState !== 'Backlog' &&
                    roadmap.progressState !== 'Discovering'
                }"
                required
              />
              <label class="btn btn-sm btn-outline-secondary m-1" for="month1"
                >January</label
              >
              <input
                type="radio"
                id="month2"
                name="month"
                class="btn-check"
                value="2"
                #month="ngModel"
                [(ngModel)]="roadmap.month"
                [ngClass]="{
                  'is-invalid':
                    roadmapForm.submitted &&
                    month.value === '0' &&
                    roadmap.progressState !== 'Backlog' &&
                    roadmap.progressState !== 'Discovering'
                }"
                required
              />
              <label class="btn btn-sm btn-outline-secondary m-1" for="month2"
                >February</label
              >
              <input
                type="radio"
                id="month3"
                name="month"
                class="btn-check"
                value="3"
                #month="ngModel"
                [(ngModel)]="roadmap.month"
                [ngClass]="{
                  'is-invalid':
                    roadmapForm.submitted &&
                    month.value === '0' &&
                    roadmap.progressState !== 'Backlog' &&
                    roadmap.progressState !== 'Discovering'
                }"
                required
              />
              <label class="btn btn-sm btn-outline-secondary m-1" for="month3"
                >March</label
              >
            </ng-container>
            <ng-container *ngSwitchCase="'Q2'">
              <input
                type="radio"
                id="month4"
                name="month"
                class="btn-check"
                value="4"
                #month="ngModel"
                [(ngModel)]="roadmap.month"
                [ngClass]="{
                  'is-invalid':
                    roadmapForm.submitted &&
                    month.value === '0' &&
                    roadmap.progressState !== 'Backlog' &&
                    roadmap.progressState !== 'Discovering'
                }"
                required
              />
              <label class="btn btn-sm btn-outline-secondary m-1" for="month4"
                >April</label
              >
              <input
                type="radio"
                id="month5"
                name="month"
                class="btn-check"
                value="5"
                #month="ngModel"
                [(ngModel)]="roadmap.month"
                [ngClass]="{
                  'is-invalid':
                    roadmapForm.submitted &&
                    month.value === '0' &&
                    roadmap.progressState !== 'Backlog' &&
                    roadmap.progressState !== 'Discovering'
                }"
                required
              />
              <label class="btn btn-sm btn-outline-secondary m-1" for="month5"
                >May</label
              >
              <input
                type="radio"
                id="month6"
                name="month"
                class="btn-check"
                value="6"
                #month="ngModel"
                [(ngModel)]="roadmap.month"
                [ngClass]="{
                  'is-invalid':
                    roadmapForm.submitted &&
                    month.value === '0' &&
                    roadmap.progressState !== 'Backlog' &&
                    roadmap.progressState !== 'Discovering'
                }"
                required
              />
              <label class="btn btn-sm btn-outline-secondary m-1" for="month6"
                >June</label
              >
            </ng-container>
            <ng-container *ngSwitchCase="'Q3'">
              <input
                type="radio"
                id="month7"
                name="month"
                class="btn-check"
                value="7"
                #month="ngModel"
                [(ngModel)]="roadmap.month"
                [ngClass]="{
                  'is-invalid':
                    roadmapForm.submitted &&
                    month.value === '0' &&
                    roadmap.progressState !== 'Backlog' &&
                    roadmap.progressState !== 'Discovering'
                }"
                required
              />
              <label class="btn btn-sm btn-outline-secondary m-1" for="month7"
                >July</label
              >
              <input
                type="radio"
                id="month8"
                name="month"
                class="btn-check"
                value="8"
                #month="ngModel"
                [(ngModel)]="roadmap.month"
                [ngClass]="{
                  'is-invalid':
                    roadmapForm.submitted &&
                    month.value === '0' &&
                    roadmap.progressState !== 'Backlog' &&
                    roadmap.progressState !== 'Discovering'
                }"
                required
              />
              <label class="btn btn-sm btn-outline-secondary m-1" for="month8"
                >August</label
              >
              <input
                type="radio"
                id="month9"
                name="month"
                class="btn-check"
                value="9"
                #month="ngModel"
                [(ngModel)]="roadmap.month"
                [ngClass]="{
                  'is-invalid':
                    roadmapForm.submitted &&
                    month.value === '0' &&
                    roadmap.progressState !== 'Backlog' &&
                    roadmap.progressState !== 'Discovering'
                }"
                required
              />
              <label class="btn btn-sm btn-outline-secondary m-1" for="month9"
                >September</label
              >
            </ng-container>
            <ng-container *ngSwitchCase="'Q4'">
              <input
                type="radio"
                id="month10"
                name="month"
                class="btn-check"
                value="10"
                #month="ngModel"
                [(ngModel)]="roadmap.month"
                [ngClass]="{
                  'is-invalid':
                    roadmapForm.submitted &&
                    month.value === '0' &&
                    roadmap.progressState !== 'Backlog' &&
                    roadmap.progressState !== 'Discovering'
                }"
                required
              />
              <label class="btn btn-sm btn-outline-secondary m-1" for="month10"
                >October</label
              >
              <input
                type="radio"
                id="month11"
                name="month"
                class="btn-check"
                value="11"
                #month="ngModel"
                [(ngModel)]="roadmap.month"
                [ngClass]="{
                  'is-invalid':
                    roadmapForm.submitted &&
                    month.value === '0' &&
                    roadmap.progressState !== 'Backlog' &&
                    roadmap.progressState !== 'Discovering'
                }"
                required
              />
              <label class="btn btn-sm btn-outline-secondary m-1" for="month11"
                >November</label
              >
              <input
                type="radio"
                id="month12"
                name="month"
                class="btn-check"
                value="12"
                #month="ngModel"
                [(ngModel)]="roadmap.month"
                [ngClass]="{
                  'is-invalid':
                    roadmapForm.submitted &&
                    month.value === '0' &&
                    roadmap.progressState !== 'Backlog' &&
                    roadmap.progressState !== 'Discovering'
                }"
                required
              />
              <label class="btn btn-sm btn-outline-secondary m-1" for="month12"
                >December</label
              >
            </ng-container>
            <div class="invalid-feedback">Month is required</div>
            <div
              *ngIf="
                roadmap.quarter !== '0' &&
                (roadmap.progressState === 'Backlog' ||
                  roadmap.progressState === 'Discovering')
              "
            >
              optional
            </div>
          </div>
        </div>
        <div class="col-3">
          <ng-container
            style="float: left"
            *ngFor="let year of years; let i = index"
          >
            <input
              type="radio"
              [id]="'year' + i"
              name="yearRadio"
              class="btn-check"
              value="{{ year }}"
              #yearRadio="ngModel"
              [(ngModel)]="roadmap.year"
              [ngClass]="{
                'is-invalid':
                  roadmapForm.submitted &&
                  yearRadio.value === '0' &&
                  roadmap.progressState !== 'Backlog'
              }"
              required
            />
            <label
              class="btn btn-sm btn-outline-secondary m-1"
              [attr.for]="'year' + i"
              >{{ year }}</label
            >
          </ng-container>
          <div class="invalid-feedback">Year is required</div>
          <div *ngIf="roadmap.progressState === 'Backlog'">optional</div>
        </div>
      </div>
      <hr />
      <div class="row mb-3" *ngIf="roadmap.valueTimeframe">
        <div class="col-12" style="font-style: italic">
          Value Timeframe: {{ roadmap.valueTimeframe }}
        </div>
      </div>
      <div class="row">
        <div class="col-3 mb-3">
          <label for="owner" class="form-label">Owner</label>
          <span class="ng-autocomplete">
            <ng-autocomplete
              #owner
              id="owner"
              name="owner"
              class="form-control"
              [data]="userSearchResults"
              [searchKeyword]="keyword"
              (selected)="selectUser($event)"
              (inputChanged)="searchUser($event)"
              placeholder="owner search..."
              [itemTemplate]="itemTemplate"
              (inputCleared)="clearUser($event)"
              [(ngModel)]="selectedOwnerName"
              minQueryLength="3"
            >
            </ng-autocomplete>
            <div class="invalid-feedback">
              <div>Owner is required</div>
            </div>
            <ng-template #itemTemplate let-item>
              <a [innerHTML]="item.name"></a>
            </ng-template>
          </span>
        </div>
      </div>
      <div class="mb-3">
        <label for="otherObj"
          >Choose the OKRs your {{ this.roadmapType }} impacts</label
        >
        <select
          [(ngModel)]="selectedObjectives"
          name="otherObj"
          id="otherObj"
          #selectedObjective="ngModel"
          class="form-select objective-select-box"
          [ngClass]="{
            'is-invalid':
              roadmapForm.submitted && selectedObjectives.length === 0
          }"
          required
          multiple
        >
          <ng-container *ngFor="let objective of this.objectives">
            <ng-container *ngIf="showObjective(objective)">
              <option
                [selected]="this.selectedObjectives.includes(objective.id)"
                [ngValue]="objective.id"
              >
                {{ objective.description | shorten : 90 }}
              </option>
            </ng-container>
          </ng-container>
        </select>
        <span class="form-text">Hold Ctrl to select multiple objectives</span>
        <div class="invalid-feedback">
          Please select at least one objective.
        </div>
        <div style="display: flex; justify-content: flex-end">
          <button
            class="link-button"
            type="button"
            (click)="showKrs = !showKrs"
          >
            {{ showKrs ? "Hide" : "Show" }} Key Results
          </button>
        </div>
        <ng-container *ngIf="showKrs">
          <label class="form-label">Impacted Key Results</label>
          <table class="table">
            <ng-container *ngFor="let objective of this.objectives">
              <ng-container
                *ngIf="this.selectedObjectives.includes(objective.id)"
              >
                <tr>
                  <th colspan="2">{{ objective.description }}</th>
                </tr>
                <ng-container *ngFor="let keyResult of objective.keyResults">
                  <tr>
                    <td>&nbsp;</td>
                    <td>{{ keyResult.name }}</td>
                  </tr>
                </ng-container>
              </ng-container>
            </ng-container>
          </table>
        </ng-container>
      </div>
      <div class="mb-3" *ngIf="objectiveType !== 'ENTERPRISE'">
        <label for="otherLevel">{{ this.roadmapOtherLevelLabel }}</label>
        <select
          [(ngModel)]="selectedRoadmaps"
          name="otherLevel"
          id="otherLevel"
          #otherLevel="ngModel"
          class="form-select roadmap-select-box"
          multiple
        >
          <ng-container *ngFor="let otherRoadmap of this.allOtherLevelRoadmaps">
            <option
              [selected]="selectedRoadmaps.includes(otherRoadmap.id)"
              [ngValue]="otherRoadmap.id"
            >
              {{ otherRoadmap.description | shorten : 90 }}
            </option>
          </ng-container>
        </select>
        <span class="form-text"
          >Hold Ctrl to select multiple {{ roadmapOtherLevelValidation }}s</span
        >
      </div>
      <h4>Collaboration & Communication</h4>
      <hr />

      <div *ngIf="showADOAlert()">
        <div class="mixed-content-alert__content">
          <p class="mixed-content-alert__heading">
            None of the ADO Backlogs linked to your product have initiatives defined, Please create
            initiatives in ADO and map one to this roadmap.
          </p>
        </div>
      </div>

      <div
        class="row"
        *ngIf="!this.allADOinitiatives || this.allADOinitiatives.length > 0"
      >
        <div class="mb-3">
          <label for="adoInitiativeId" class="form-label"
            >ADO Initiative:</label
          >
          <select
            id="adoInitiativeId"
            class="form-control"
            name="adoInitiativeId"
            #adoInitiativeId="ngModel"
            [(ngModel)]="roadmap.adoInitiativeId"
            [ngClass]="{
              'is-invalid': roadmapForm.submitted && adoInitiativeId.invalid
            }"
            class="form-select"
          >
            <option value=""></option>
            <option
              *ngFor="let initiative of allADOinitiatives"
              [value]="initiative.initiativeId"
            >
              [{{ initiative.initiativeId }}] -
              {{ initiative.initiativeTitle | shorten : 90 }}
            </option>
          </select>
        </div>
      </div>

        <div *ngIf="showRallyAlert()">
        <div class="mixed-content-alert__content">
          <p class="mixed-content-alert__heading">
            None of the Rally Backlogs linked to your product have initiatives defined, Please create
            initiatives in Rally and map one to this roadmap.
          </p>
        </div>
      </div>

      <div
        class="row"
        *ngIf="!this.allRallyinitiatives || this.allRallyinitiatives.length > 0"
      >
        <div class="mb-3">
          <label for="rallyInitiativeId" class="form-label"
            >Rally Initiative:</label
          >
          <select
            id="rallyInitiativeId"
            class="form-control"
            name="rallyInitiativeId"
            #rallyInitiativeId="ngModel"
            [(ngModel)]="roadmap.rallyInitiativeId"
            [ngClass]="{
              'is-invalid': roadmapForm.submitted && rallyInitiativeId.invalid
            }"
            class="form-select"
          >
            <option value=""></option>
            <option
              *ngFor="let initiative of allRallyinitiatives"
              [value]="initiative.initiativeId"
            >
              [{{ initiative.initiativeId }}] -
              {{ initiative.initiativeTitle | shorten : 90 }}
            </option>
          </select>
        </div>
      </div>

      <br />
      <div class="form-check mb-3">
        <input
          name="dependency"
          class="form-check-input"
          type="checkbox"
          value="0"
          id="dependency"
          #dependency="ngModel"
          [(ngModel)]="roadmap.dependency"
        />
        <label class="form-check-label" for="dependency">
          Dependency Flag (Requires alignment from other products to continue)
        </label>
      </div>
      <div class="form-check mb-3">
        <input
          name="requiresAttachment"
          class="form-check-input"
          type="checkbox"
          value="0"
          id="requiresAttachment"
          (change)="resetFinanceData($event)"
          #requiresAttachment="ngModel"
          [(ngModel)]="roadmap.requiresAttachment"
        />
        <label class="form-check-label" for="requiresAttachment">
          Funding Flag (Requires external funds to continue)
          <span *ngIf="roadmap.requiresAttachment" class="extfundstext"
            >Please add a link to your business case in Associated
            Documents</span
          >
        </label>
      </div>

      <span *ngIf="roadmap.requiresAttachment">
        <h4>Product Investment Data</h4>
        <hr />
        <span class="form-label"
          >Some efforts require investment above and beyond the existing
          allocation of funds for hardware, software and third-party staffing.
          Use this section to keep notes on funding estimates to calculate the
          return on investment of OKR-driving efforts.</span
        >
        <br /><br />
        <div class="row">
          <div class="form-check mb-3">
            <input
              name="refreshCompFlag"
              class="form-check-input"
              type="checkbox"
              value="0"
              id="refreshCompFlag"
              #dependency="ngModel"
              [(ngModel)]="roadmap.refreshCompFlag"
            />
            <label class="form-check-label" for="refreshCompFlag">
              Refresh and Compliance Flag
            </label>
          </div>
        </div>
        <div class="row">
          <table style="width: 50%" class="table table-striped">
            <thead>
              <tr>
                <th scope="col">Investment Category</th>
                <th scope="col">Investment ($)</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Hardware Estimate</td>
                <td>
                  <input
                    type="text"
                    id="hardwareEst"
                    name="hardwareEst"
                    class="form-control-number"
                    placeholder="Numerical value"
                    inputmode="numeric"
                    pattern="[0-9.-]*"
                    #hardwareEst="ngModel"
                    (focusout)="getTotalEst()"
                    (paste)="onPaste(hardwareEst, $event)"
                    (drop)="onDrop(hardwareEst, $event)"
                    (keydown)="onKeyDown(hardwareEst, $event)"
                    [(ngModel)]="roadmap.hardwareEst"
                    [ngClass]="{
                      'is-invalid': roadmap.submitted && hardwareEst.invalid
                    }"
                    required
                  />
                  <div class="invalid-feedback">
                    <div>
                      Hardware Estimate is required (numerical value only)
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>Software Estimate</td>
                <td>
                  <input
                    type="text"
                    id="softwareEst"
                    name="softwareEst"
                    class="form-control-number"
                    placeholder="Numerical value"
                    inputmode="numeric"
                    pattern="[0-9.-]*"
                    #softwareEst="ngModel"
                    (focusout)="getTotalEst()"
                    (paste)="onPaste(softwareEst, $event)"
                    (drop)="onDrop(softwareEst, $event)"
                    (keydown)="onKeyDown(softwareEst, $event)"
                    [(ngModel)]="roadmap.softwareEst"
                    [ngClass]="{
                      'is-invalid': roadmap.submitted && softwareEst.invalid
                    }"
                    required
                  />
                  <div class="invalid-feedback">
                    <div>
                      Software Estimate is required (numerical value only)
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>Third-Party Estimate</td>
                <td>
                  <input
                    type="text"
                    id="thirdPartyEst"
                    name="thirdPartyEst"
                    class="form-control-number"
                    placeholder="Numerical value"
                    inputmode="numeric"
                    pattern="[0-9.-]*"
                    #thirdPartyEst="ngModel"
                    (focusout)="getTotalEst()"
                    (paste)="onPaste(thirdPartyEst, $event)"
                    (drop)="onDrop(thirdPartyEst, $event)"
                    (keydown)="onKeyDown(thirdPartyEst, $event)"
                    [(ngModel)]="roadmap.thirdPartyEst"
                    [ngClass]="{
                      'is-invalid': roadmap.submitted && thirdPartyEst.invalid
                    }"
                    required
                  />
                  <div class="invalid-feedback">
                    <div>
                      Third-Party Estimate is required (numerical value only)
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td><B>Total</B></td>
                <td>
                  <span class="spam-right">{{
                    totalEst | currency : "USD" : "symbol" : "1.0-0"
                  }}</span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </span>
      <br />
      <app-external-links
        *ngIf="roadmap.id"
        title="Associated Documents"
        [parentId]="roadmap.id"
      ></app-external-links>
    </div>
    <div class="modal-footer">
      <button type="submit" class="btn btn-primary" #submitBtn>
        {{ existingRoadmapItem ? "Save" : "Submit" }}
      </button>
    </div>
  </div>
</form>
