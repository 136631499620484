import {Month, Quarter, State} from '@enums';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import _ from 'lodash';

@Component({
  selector: 'app-roadmap-filter',
  templateUrl: './roadmap-filter.component.html',
  styleUrls: ['./roadmap-filter.component.scss']
})
export class RoadmapFilterComponent implements OnInit {

  @Output() selectState: EventEmitter<State[] | null> = new EventEmitter();

  @Output() selectQuarter: EventEmitter<Quarter[] | null> = new EventEmitter();

  @Output() selectYear: EventEmitter<number[] | null> = new EventEmitter();

  @Output() selectMonth: EventEmitter<Month[] | null> = new EventEmitter();

  @Input() milestoneYears: number[];

  selectedState: string[] = [];

  selectedQuarter: string[];

  selectedMonth: string[];

  selectedYear: number[];

  states = Object.values(State);

  milestoneQuarters: string[] = Object.values(Quarter);

  milestoneMonths: string[] = Object.values(Month);

  constructor() { }

  ngOnInit(): void {
    //this will only show the filters selected by default, the actual filtering
    //happens in the roadmap priority ngoninit event before loaddata is called
    //the first time
    this.selectedState.push(State.backlog);
    this.selectedState.push(State.developing);
    this.selectedState.push(State.discovering);
    this.selectedState.push(State.measuring);
  }

  onMonthChange($event) {
    const m: Month[] = _(this.selectedMonth)
      .map((value: string) => value as Month)
      .value();
    this.selectMonth.emit(m);
  }

  onQuarterChange($event) {
    const q: Quarter[] = _(this.selectedQuarter)
      .map((value: string) => value as Quarter)
      .value();
    this.selectQuarter.emit(q);
  }

  onStateChange($event?) {
  const s: State[] = _(this.selectedState)
      .map((value: string) => value as State)
      .value();

    this.selectState.emit(s);
  }


  onYearChange($event) {
    this.selectYear.emit(this.selectedYear);
  }
}
