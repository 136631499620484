import { ObjectiveRealignModalComponent } from '../objective-realign-modal/objective-realign-modal.component';
import { ProductGroupModel } from 'src/app/models/product-group.model';
import { Component, Input, NgModuleRef, OnInit } from '@angular/core';
import { ObjectiveModel } from '../../models/objective.model';
import { KeyResultModel } from '../../models/key-result.model';
import { GraphqlQueriesService } from '../../services/graphql-queries.service';
import { ProductModel } from 'src/app/models/product.model';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { NewRoadmapItemComponent } from '../new-roadmap-item/new-roadmap-item.component';
import { RoadmapModel } from 'src/app/models/roadmap.model';
import { RoadmapService } from 'src/app/services/roadmap.service';
import { KeyResultService } from 'src/app/services/key-results.service';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { Drag } from '../../utils/drag';
import { RoadmapSortService } from 'src/app/services/roadmap-sort.service';
import { RoadmapSortModel } from 'src/app/models/roadmap-sort.model';
import { ObjectiveService } from 'src/app/services/objective.service';
import { RoadmapPriorityService } from 'src/app/services/roadmap-priority.service';
import { KeyResultTrackingModel } from 'src/app/models/key-result-tracking.model';
import { RoadmapProgressAddComponent } from '../roadmap-progress-add/roadmap-progress-add.component';
import { RoadmapProgressModel } from 'src/app/models/roadmap-progress.model';
import { RoadmapProgressService } from 'src/app/services/roadmap-progress.service';
import { RoadmapProgressViewComponent } from '../roadmap-progress-view/roadmap-progress-view.component';
import { DatePipe } from '@angular/common';
import {EventEmitterService} from 'src/app/services/event-emitter.service';

@Component({
  selector: 'app-objective-item',
  templateUrl: './objective-item.component.html',
  styleUrls: ['./objective-item.component.scss']
})
export class ObjectiveItemComponent implements OnInit {
  @Input() objectiveType: string;
  @Input() objective: ObjectiveModel;
  @Input() index: number;
  @Input() onEditObjective: (objective: ObjectiveModel) => void;
  @Input() onDeleteObjective: (objective: ObjectiveModel) => void;
  @Input() onOpenObjectivesLog: (objective: ObjectiveModel) => void;
  @Input() onOpenRoadmapsLog: (roadmap: RoadmapModel) => void;
  @Input() onEditKeyResult: (keyResult: KeyResultModel, objectiveType?: string) => void;
  @Input() onOpenKeyResultModal: (keyResult?: KeyResultModel, objectiveType?: string, objectiveId?: string, numKeyResults?: number) => void;
  @Input() onOpenKeyResultTracking: (isNew: boolean, keyResult: KeyResultModel) => void;
  @Input() onCopyKeyResultObjective: (keyResult: KeyResultModel) => void;
  @Input() onDuplicateKeyResult: (keyResult: KeyResultModel, numKeyResults: number) => void;
  @Input() onToggleAccordion: (e: any) => void;
  @Input() onReload: (showLatest?: boolean, tabs?: any) => void;
  @Input() activeIds: string[];
  @Input() activeTabId: number;
  @Input() selectedProduct: ProductModel;
  @Input() selectedProductGroup: ProductGroupModel;
  @Input() readOnly = true;
  @Input() planningPortfolioId: number;

  roadmapItems: RoadmapModel[] = [];
  roadmapType: string;
  objectkeys = Object.keys;
  draggedIndex = -1;
  draggedOverIndex = -1;
  roadmapSortItems: RoadmapSortModel[] = [];
  uniqueRoadmaps: any = [];
  dragType: string;
  currentDate: Date = new Date();
  currentYear: number;

  constructor(
    private graphqlQueriesService: GraphqlQueriesService,
    private readonly modalService: NgbModal,
    private readonly roadmapService: RoadmapService,
    private readonly keyResultService: KeyResultService,
    private drag: Drag,
    private roadmapSortService: RoadmapSortService,
    private objectiveService: ObjectiveService,
    private roadmapPriorityService: RoadmapPriorityService,
    private roadmapProgressService: RoadmapProgressService,
    private datePipe: DatePipe,
    private eventEmitterService: EventEmitterService
  ) {
  }

  ngOnInit(): void {
    this.loadData();
  }

  async loadData() {
    this.currentYear = this.currentDate.getFullYear();
    if (this.objectiveType !== 'ENTERPRISE') {
      this.graphqlQueriesService
        .getOwnerName(this.objective.ownerId)
        .subscribe((result: any) => {
          this.objective.ownerName = result.data.userSearch.users[0]?.displayName || '';
        });
    }

    if (this.objective.keyResults.length > 0) {
      //for key result sorts
      let totalNoKRSort = 0;
      let lastSortKRValue = null;
      let noSortKRIndex = null;

      this.objective.keyResults.forEach((kr, krindex) => {
        if (kr.sort === null) {
          noSortKRIndex = krindex;
          totalNoKRSort++;
        } else {
          lastSortKRValue = kr.sort;
        }
        this.roadmapItems.push(...kr.roadmaps);
      });

      if (totalNoKRSort === this.objective.keyResults.length) {
        //no sorts found for all key results, sort by date ascending first, then add sort number
        this.objective.keyResults.sort(this.sortCreatedAtASC);
        this.updateKeyResultSort();
      } else if (totalNoKRSort === 1) {
        //one sort value doesn't exist, add it to the bottom
        //this happens when adding a new key result
        this.objective.keyResults[noSortKRIndex].sort = lastSortKRValue + 1;
        this.keyResultService.update(this.objective.keyResults[noSortKRIndex]).toPromise();
      }
      //now that we have all sort values, now sort them
      this.objective.keyResults.sort(this.sortKeyResult);
      this.updateKeyResultSort();//once sorted, now renumber in case deleted

      //this.roadmapItems = roadmaps will be duplicated across each key result for this objective
      //uniqueRoadmaps will return a unique list of roadmap ids for this objective
      this.uniqueRoadmaps = [...new Map(this.roadmapItems.map(item => [item.id, item])).values()];

      this.roadmapItems.forEach((roadmap) => {
        if (roadmap.ownerId) {
          this.graphqlQueriesService
                  .getOwnerName(roadmap.ownerId)
                  .subscribe((result: any) => {
                    roadmap.ownerName = result.data.userSearch.users[0]?.displayName || '';
                });
        }
      });

      //using objective id as key, retrieve data from roadmap sort table using service
      await this.retrieveRoadMapSorts();
      await this.setRoadmapProgress();
    }

  this.objective.keyResults.forEach(async (krElement, krIndex) => {
    const getLength = krElement.keyResultsTracking.length; //KRT is sorted descending, use index 0
    if (getLength > 0) {

      krElement.keyResultsTracking.sort(this.sortKeyResultTracking);

      this.objective.keyResults[krIndex].confidenceLevel = krElement.keyResultsTracking[0].confidenceLevel;
      this.objective.keyResults[krIndex].progress = krElement.keyResultsTracking[0].results;
    }
  });

  if (this.objectiveType === 'ENTERPRISE') {
    this.roadmapType = 'Theme';
  } else if (this.objectiveType === 'PORTFOLIO') {
    this.roadmapType = 'Portfolio Initiative';
  } else {
    this.roadmapType = 'Roadmap Item';
  }
}

deleteKeyResults(keyResult: KeyResultModel) {
  const modalRef: NgbModalRef = this.modalService.open(ConfirmationDialogComponent,
    {
      windowClass: 'customModalClass',
      centered: true,
      keyboard: false,
      backdrop: 'static'
    }
  );
  modalRef.componentInstance.title = 'Delete Key Result';
  modalRef.componentInstance.message = `Are you sure you you want to delete \n \n ${keyResult.name}?`;
  modalRef.componentInstance.btnYesText = 'Delete';
  modalRef.componentInstance.btnNoText = 'Cancel';

  modalRef.result.then((result: boolean)=> {
    if (result) {
      //TODO refactor and create retrieveKeyResult func
      this.keyResultService.delete(keyResult).subscribe(()=> {
        const tabs = {tabId: 1, tabObjectiveId: this.objective.id, priorityTab: false};
        this.eventEmitterService.retrieveObjectives(false, tabs);
      });
    }
  });
}

async setRoadmapProgress() {
  await this.uniqueRoadmaps.forEach(async (rm: RoadmapModel, rmindex) => {
    const rmProgress: RoadmapProgressModel[] = rm['roadmap-progress'];
    if (rmProgress && rmProgress.length > 0) {
      rmProgress.sort(this.sortCreatedAtDSC);
      rm.progressConfidenceLevel = rmProgress[0].confidenceLevel;
      rm.progressState = rmProgress[0].status;
      rm.progressPercentPlannedWorkCompleted = rmProgress[0].percentageOfPlannedWorkCom;
      rm.progressCurrentValueDelivered = rmProgress[0].currentValueDelivered;
      rm.progressNextBestIdeas = rmProgress[0].nextBestIdeas;
    }
  });
}

  setMilestone(roadmap: RoadmapModel): string {
    let thisMonth: string;
    let thisQuarter: string;
    let thisYear: string;

    if (roadmap.month !== '0') {
      thisMonth = roadmap.month;
    }
    if (roadmap.quarter !== '0') {
      thisQuarter = roadmap.quarter;
    }
    if (roadmap.year !== '0') {
      thisYear = roadmap.year;
    }

    if (thisMonth && thisQuarter && thisYear) {
      return thisYear + ' ' + thisQuarter + ' ' + this.datePipe.transform(thisMonth + '-01-' + thisYear, 'MMMM');
    } else if (!thisMonth && thisQuarter && thisYear) {
      return thisYear + ' ' + thisQuarter;
    } else if (thisQuarter && !thisMonth && !thisYear) {
      return thisQuarter;
    } else if (!thisQuarter && !thisMonth && thisYear) {
      return thisYear;
    } else if (thisQuarter && thisMonth && !thisYear) {
      return thisQuarter + ' ' + this.datePipe.transform(thisMonth + '-01-' + thisYear, 'MMMM');
    } else {
      return '';
    }

  }

  sortKeyResultTracking(a: KeyResultTrackingModel, b: KeyResultTrackingModel) {
    //year + zero-filled month
    const sortA = a.year + '_' + ('0'+ a.month).slice(-2);
    const sortB = b.year + '_' + ('0'+ b.month).slice(-2);

    if (sortA < sortB) {
      return 1;
    } else if (sortA > sortB) {
      return -1;
    } else {
      return 0;
    }
  }

  async retrieveRoadMapSorts() {
    const data = await this.roadmapSortService.getById(this.objective.id).toPromise();
    this.roadmapSortItems = data;
    if (this.roadmapSortItems.length > 0) {
      if (this.roadmapSortItems.length < this.uniqueRoadmaps.length) {
        this.uniqueRoadmaps.forEach((rm: RoadmapModel, rmindex) => {
          const thisSort = this.uniqueRoadmaps.length-1;
          const findRoadmapSort = this.roadmapSortItems.find(
            x => x.roadmapId === rm.id &&
            x.objectiveId === this.objective.id);
          if (!findRoadmapSort) {
            //if number of sorts is < number of roadmaps,
            //create new roadmap sort entry and assign latest number
            //this will happen when a new roadmap is added
            const newSortItem = {
              roadmapId: rm.id,
              objectiveId: this.objective.id,
              sort: thisSort
            };
            rm.sort = thisSort;
            this.roadmapSortService.create(newSortItem).toPromise();
          } else {
            //sort found for roadmap and objective, assign sort to roadmap entry
            rm.sort = findRoadmapSort.sort;
          }
        });
      } else {
        //assign sort to uniqueRoadmaps
        this.uniqueRoadmaps.forEach((rm: RoadmapModel) => {
          const findRoadmapSort = this.roadmapSortItems.find(
            x => x.roadmapId === rm.id &&
            x.objectiveId === this.objective.id &&
            x.sort !== null);
          if (findRoadmapSort) {
            rm.sort = findRoadmapSort.sort;
          }
        });
      }
    } else {
      //if no results, create new roadmap sort mapping for each unique roadmap on this objective
       let newSort = 0;
       this.uniqueRoadmaps.forEach((rm: RoadmapModel) => {
        const thisSort = newSort++;
        const newSortItem = {
          roadmapId: rm.id,
          objectiveId: this.objective.id,
          sort: thisSort
        };
        rm.sort = thisSort;
        this.roadmapSortService.create(newSortItem).toPromise();
      });
    }
    await this.uniqueRoadmaps.sort(this.sortRoadmap);
    this.updateRoadmapSort(); //after sorting, renumber in case of deletions/gaps
  }

  async createRoadMapSort(roadMapSort) {
    this.roadmapSortService.create(roadMapSort).subscribe(
      (_: any) => {

      },
      (err: Error) => {
        console.error(err);
      }
    );
  }

  onDragStart(index, dragType: string): void {
    //capture objective to compare when dropped to global
    this.drag.setTargetObjectiveId(this.objective.id);
    this.dragType = dragType;
    //the row we are dragging
    this.draggedIndex = index;
  }

  dragLeave($event) {
    this.draggedOverIndex = -1;
    $event.preventDefault();
  }

  allowDrop($event, index, dropType: string): void {
    //will only show blue line within objective we started the drag
    if (this.dragType === undefined || dropType === undefined) {
      this.resetDrag();
      return;
    } else if (this.draggedIndex !== -1 && dropType === this.dragType) {
    //the row we are hovering over, where to place blue line to drop
      this.draggedOverIndex = index;
      $event.preventDefault();
    }
  }

  resetDrag() {
    this.dragType = '';
    this.draggedIndex = -1;
    this.draggedOverIndex = -1;
    this.drag.setTargetObjectiveId('');
  }

  onDrop($event, index, dropType: string): void {
    if (this.dragType !== dropType) {
      this.resetDrag();
      return;
    };
    const globalObjectiveId = this.drag.getTargetObjectiveId();
    if (globalObjectiveId !== this.objective.id) {
      this.resetDrag();
      return;
    }

    //prevent drag-dropping on another objective
    if (this.draggedIndex === undefined ||
      this.draggedOverIndex === undefined ||
      this.draggedOverIndex === -1 ||
      this.draggedIndex === -1 ||
      (dropType === 'kr' && this.objective.keyResults[this.draggedIndex] === undefined)) {
      this.resetDrag();
      return;
    }
    //drop the row and update array and backend
    $event.preventDefault();

    if (this.draggedIndex !== index) {
      if (dropType === 'kr') {
        const item = this.objective.keyResults[this.draggedIndex];
        this.objective.keyResults.splice(this.draggedIndex, 1);
        this.objective.keyResults.splice(index, 0, item);
        this.updateKeyResultSort();
      } else if (dropType === 'roadmap') {
        const item = this.uniqueRoadmaps[this.draggedIndex];
        this.uniqueRoadmaps.splice(this.draggedIndex, 1);
        this.uniqueRoadmaps.splice(index, 0, item);
        this.updateRoadmapSort();
      }
      this.resetDrag();
    } else {
      //was dropped in the same position - just reset
      this.resetDrag();
    }
  }

  updateRoadmapSort(): void {
    let newSort = 0;
    this.uniqueRoadmaps.forEach((rmElement) => {
      //changed newSort++ because it wasn't renumbering properly like it does for KR below
      newSort = newSort + 1;
      rmElement.sort = newSort;
      const updateSortItem = {
        roadmapId: rmElement.id,
        objectiveId: this.objective.id,
        sort: rmElement.sort
      };
      this.roadmapSortService.update(updateSortItem).toPromise();
    });
  }

  updateKeyResultSort(): void {
    //called from drag/drop and from ngoninit
    let newSort = 0;
    this.objective.keyResults.forEach((krElement) => {
        krElement.sort = newSort++;
        this.keyResultService.update(krElement).toPromise();
    });
  }

  getColor(confidenceLevelColor?: string) {
    switch (confidenceLevelColor) {
      case 'On Track':
        return 'green';
      case 'Off Track':
        return 'yellow';
      case 'At Risk':
        return 'red';
      default:
        return 'gray';
    }
  }

  sortRoadmap(a: RoadmapModel, b: RoadmapModel) {
    const sortA = a.sort;
    const sortB = b.sort;

    if (sortA > sortB) {
      return 1;
    } else if (sortA < sortB) {
      return -1;
    } else {
      return 0;
    }
  }

  sortKeyResult(a: KeyResultModel, b: KeyResultModel) {
    const sortA = a.sort;
    const sortB = b.sort;

    if (sortA > sortB) {
      return 1;
    } else if (sortA < sortB) {
      return -1;
    } else {
      return 0;
    }
  }

  sortCreatedAtASC(a: any, b: any) {
    const dateA = new Date(a.createdAt).getTime();
    const dateB = new Date(b.createdAt).getTime();

    if (dateA > dateB) {
      return 1;
    } else if (dateA < dateB) {
      return -1;
    } else {
      return 0;
    }
  }

  sortCreatedAtDSC(a: any, b: any) {
    const dateA = new Date(a.createdAt).getTime();
    const dateB = new Date(b.createdAt).getTime();

    if (dateA < dateB) {
      return 1;
    } else if (dateA > dateB) {
      return -1;
    } else {
      return 0;
    }
  }

  onAddNewRoadmapItem(): void {
    const modalRef: NgbModalRef = this.modalService.open(NewRoadmapItemComponent, {
      windowClass: 'roadmap-modal',
      centered: true,
      keyboard: false,
      backdrop: 'static',
    });

    modalRef.componentInstance.planningPortfolioId = this.planningPortfolioId;
    modalRef.componentInstance.objectiveType = this.objective.objectiveType;
    modalRef.componentInstance.objectiveId = this.objective.id;
    modalRef.componentInstance.productGroupId = this.objective.productGroupId;
    modalRef.componentInstance.productId = this.objective.productId;


    //needed here for adding progress from roadmap modal
    modalRef.componentInstance.onReload = this.onReload;

    modalRef.result
      .then((roadmap: RoadmapModel) => {
        this.roadmapService.create(roadmap).subscribe(
          (_: any) => {
            const tabs = {tabId: 2, tabObjectiveId: this.objective.id, priorityTab: false};
            this.onReload(false, tabs);
          },
          (err: Error) => {
            console.error(err);
          }
        );
      })
      .catch((err: any) => console.error(`${err}`));
  }

  onRealignObjective(): void {
    const modalRef: NgbModalRef = this.modalService.open(ObjectiveRealignModalComponent, {
      centered: true,
      keyboard: false,
      backdrop: 'static',
    });

    modalRef.componentInstance.objective = this.objective;

    modalRef.result
      .then((e: any) => {
        this.onReload();
      })
      .catch((err: any) => {
        console.error(err);
      });
  }

  buttonColor(): string {
    return this.canAddRoadmapItem() ? 'btn-dark' : 'btn-primary';
  }

  canAddRoadmapItem(): boolean {
    return this.readOnly || (this.objective.keyResults?.length < 1);
  }

  onRoadmapProgress = (
    isNew: boolean,
    roadmap: RoadmapModel
  ): void => {
     //if new, show modal to create new roadmap progress, otherwise display all progress for selected roadmap
     let useModal: any;
     if (isNew) {
       useModal = RoadmapProgressAddComponent;
     } else {
       useModal = RoadmapProgressViewComponent;
     }

     const modalRef = this.modalService.open(useModal, {
       windowClass: 'roadmap-modal',
       centered: true,
       keyboard: false,
       backdrop: 'static',
     });

     modalRef.componentInstance.roadmap = roadmap;
     modalRef.componentInstance.roadmapType = this.roadmapType;
     modalRef.componentInstance.fromModal = 'editRoadmapOKR';
     modalRef.componentInstance.fromObjectiveId = this.objective.id;

     modalRef.result
      .then((e: any) => {
        const tabs = {tabId: 2, tabObjectiveId: this.objective.id, priorityTab: false};
        this.onReload(false, tabs);
      })
      .catch((err: any) => {
        console.error(err);
      });
  };

  async onEditRoadmap(roadmapItemId: string) {
    const roadmapItem = await this.roadmapService.getById(roadmapItemId).toPromise();
    const modalRef: NgbModalRef = this.modalService.open(NewRoadmapItemComponent, {
      windowClass: 'roadmap-modal',
      centered: true,
      keyboard: false,
      backdrop: 'static',
    });

    modalRef.componentInstance.objectiveType = this.objectiveType;
    if (this.objectiveType === 'PRODUCT_GROUP') {
      modalRef.componentInstance.productGroupId = this.selectedProductGroup.id;
    } else if (this.objectiveType === 'PRODUCT') {
      modalRef.componentInstance.productGroupId = this.selectedProduct.productGroup.id;
      modalRef.componentInstance.productId = this.selectedProduct.id;

    }
    modalRef.componentInstance.existingRoadmapItem = true;
    modalRef.componentInstance.planningPortfolioId = this.planningPortfolioId;
    modalRef.componentInstance.objectiveId = this.objective.id;
    modalRef.componentInstance.roadmap = roadmapItem;

    const roadmapProgressItems = await this.roadmapProgressService.getAll(roadmapItem.id).toPromise();
    modalRef.componentInstance.roadmapProgress = roadmapProgressItems;

    roadmapItem.keyResults.forEach(kr => {
      modalRef.componentInstance.selectedObjectives.push(kr.objectiveId);
    });

    modalRef.result
    .then((roadmap: RoadmapModel) => {
      this.roadmapService.update(roadmap).subscribe(
        (_: any) => {
          const tabs = {tabId: 2, tabObjectiveId: this.objective.id, priorityTab: false};
          this.onReload(false, tabs);
        },
        (err: Error) => {
          console.error(err);
        }
      );
    })
    .catch((err: any) => console.error(`${err}`));
  }

  onDeleteRoadmap(roadmap: RoadmapModel) {
    const modalRef: NgbModalRef = this.modalService.open(ConfirmationDialogComponent,
      {
        windowClass: 'customModalClass',
        centered: true,
        keyboard: false,
        backdrop: 'static'
      }

    );
    modalRef.componentInstance.title = `Delete ${this.roadmapType}`;
    modalRef.componentInstance.message = `Are you sure you you want to delete \n \n ${roadmap.description}?`;
    modalRef.componentInstance.btnYesText = 'Delete';
    modalRef.componentInstance.btnNoText = 'Cancel';

    modalRef.result.then((result: boolean) => {
      if (result) {
        //delete all sort and priority items for this roadmap (across multiple objectives)
        const deleteItem = {
          roadmapId: roadmap.id
        };
        this.roadmapPriorityService.delete(deleteItem).toPromise();
        this.roadmapSortService.delete(deleteItem).toPromise();
        const tabs = {tabId: 2, tabObjectiveId: this.objective.id, priorityTab: false};
        this.roadmapService.delete(roadmap).subscribe(() => this.onReload(false, tabs));
      }
    });
  }

  isNum(x): boolean {
    return !isNaN(x);
  }
}
