<div *ngIf="this.refreshSignal && loaded | async" class="container">
    <div class="row align-items-center">
        <div class="col-9 text-end pt-2">
            {{ refreshMessage }}
        </div>
        <div class="col-3 pt-2 text-start">
            <label class="switch">
                <input type="checkbox"
                id="refreshSignalInput"
                name="refreshSignalInput"
                [checked]="this.refreshSignal.status === 'Refreshed' ? true: false"
                (click)="toggleRefreshSignal($event)"
                >
                <span class="slider round"></span>
            </label>
         </div>
    </div>
</div>