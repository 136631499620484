import { Component, Input, OnInit } from '@angular/core';
import { KeyResultTrackingModel } from 'src/app/models/key-result-tracking.model';
import { KeyResultTrackingService } from 'src/app/services/key-results-tracking.service';
import { KeyResultModel } from 'src/app/models/key-result.model';
import { KeyResultTrackingModalComponent } from '../key-result-tracking-modal/key-result-tracking-modal.component';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmationDialogService } from 'src/app/services/confirmation-dialog.service';
import { EventEmitterService } from 'src/app/services/event-emitter.service';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-key-result-tracking',
  templateUrl: './key-result-tracking.component.html',
  styleUrls: ['./key-result-tracking.component.scss']
})

export class KeyResultTrackingComponent implements OnInit {
  @Input() tempKeyResult: KeyResultModel[];

  noKeyResultTracking: boolean;
  keyResultTrackingEntries: KeyResultTrackingModel[];
  restoreKeyResultTrackingEntries = [];
  selectedKeyResult = [];
  pageLoaded: Promise<boolean>;

  constructor(private keyResultTrackingService: KeyResultTrackingService,
              private modalService: NgbModal,
              public activeModal: NgbActiveModal,
              private confirmationDialogService: ConfirmationDialogService,
              private eventEmitterService: EventEmitterService,
              private utilsService: UtilsService) { }

  ngOnInit(): void {
    this.pageLoaded = Promise.resolve(false);
    if (this.tempKeyResult) {
      this.selectedKeyResult.push(this.tempKeyResult);  // push into an array to reference model
      this.retrieveKeyResultTracking();
    }
  }

  onKeyDown(inputField: HTMLInputElement, $e: KeyboardEvent): void {
    this.utilsService.onKeyDownNumbers(inputField, $e);
  }

  onPaste(inputField: HTMLInputElement, $e: ClipboardEvent): void {
    this.utilsService.onPasteNumbers(inputField, $e);
  }

  onDrop(inputField: HTMLInputElement, $e: DragEvent): void {
    this.utilsService.onDropNumbers(inputField, $e);
  }

  openKeyResultTrackingModal = (): void => {
    if (this.modalService.hasOpenModals) {
      this.modalService.dismissAll('Open-KRT');
    }
    const modalRef = this.modalService.open(KeyResultTrackingModalComponent,
      {
        windowClass: 'customModalClass',
        centered: true,
        keyboard: false,
        backdrop: 'static'
      });

    modalRef.componentInstance.fromModal = 'KRT';
    modalRef.componentInstance.tempKeyResult = this.tempKeyResult;
    modalRef.result.then((result) => {
      this.retrieveKeyResultTracking();
    }, (reason) => {
    });
  };

  changesDetected(): boolean {
    let foundChange = false;
    if (this.restoreKeyResultTrackingEntries.length > 0) {
      foundChange = true;
    }
    return foundChange;
  }

  validateFields(): boolean {
    //if after saving, there are any rows still in edit mode, then validation failed
    let passFields = true;
    this.keyResultTrackingEntries.forEach((element,index)=>{
      if (element.editMode) {
        passFields = false;
      }
    });
    return passFields;
  }

  openConfirmationDialog(sendData: string) {
    if (this.changesDetected()) {
      this.confirmationDialogService.confirm('Save', 'Do you want to save your changes?')
      .then(async (confirmed) => {
        if (confirmed) { //selected Yes, need to save all plus open a new progress or close the modal
          await this.saveAll(null, null, sendData === 'progress' ? true: false);
        } else { //selected No to save
            this.closeModal(sendData);
        }
      })
      .catch(() => console.error());
    } else { //no changes detected, continue to add progress
        this.closeModal(sendData);
    }
  }

  retrieveKeyResultTracking = (): void => {
    this.keyResultTrackingService.getAll(this.selectedKeyResult[0].id).subscribe(
      (data) => {
        this.noKeyResultTracking = data.length === 0 ? true : false;
        this.keyResultTrackingEntries = data;
        this.setDefaultEditMode();
        this.pageLoaded = Promise.resolve(true);
      },
      (error) => {
        console.error(error);
      }
    );
  };

  setDefaultEditMode = (): void => {
    this.keyResultTrackingEntries.forEach((element,index)=>{
      element.editMode = false;
    });
  };

  async saveAll(saveAllBtn?: HTMLButtonElement, e?: any, addNew: boolean = false) {
    let isCancel = false;
    let allPassValidation = true;

    if (e !== null) { e.preventDefault(); }
    if (saveAllBtn !== null) {
      saveAllBtn.disabled = true;
    } else {
      if (addNew === false) {
        isCancel = true; //if button isn't null, then we are calling from open confirmation dialog
      }
    }

    this.keyResultTrackingEntries.forEach(async (element,index)=>{
      if (element.editMode && element.results.trim() !== '') {
        await this.saveKeyResultTracking(null, element);
      } else if (element.editMode && element.results.trim() === '') {
        allPassValidation = false;
      }
    });

    if (addNew) {
      if (this.validateFields()) {
        this.closeModal('progress');
      }
    } else {
      if (allPassValidation) {
        this.keyResultTrackingEntries.sort(this.sortKRT);
        if (isCancel) {
          this.closeModal('close');
        }
      }
    }
    if (saveAllBtn !== null) { saveAllBtn.disabled = false; }
  };

  sortKRT(a: KeyResultTrackingModel,b: KeyResultTrackingModel) {
    const dateA = new Date(String(a.month + '/01/' + a.year)).getTime();
    const dateB = new Date(String(b.month + '/01/' + b.year)).getTime();

    //descending
    if (dateA > dateB) {
      return -1;
    } else if (dateA < dateB) {
      return 1;
    } else {
      return 0;
    }
  };

  async saveKeyResultTracking(e: any, keyResultTracking: KeyResultTrackingModel) {
    if (e !== null) {e.preventDefault();}
    if (keyResultTracking.results.trim() !== '' && keyResultTracking.confidenceLevel !== '') {
      keyResultTracking.editMode = false;
      this.keyResultTrackingService.update(keyResultTracking).subscribe(
        async response => {
          await this.removePreviousValue(keyResultTracking);
        },
        error => {
          console.error(error);
        });
    }
    if (e !== null) { //if event, then we are performing a single save not save all, re-sort immediately
      this.keyResultTrackingEntries.sort(this.sortKRT);
    }
  }

  editKeyResultTracking(e: any, keyResultTracking: KeyResultTrackingModel) {
    e.preventDefault();
    if (keyResultTracking.editMode === false) {
      keyResultTracking.editMode = true;
      //copy values to a new array to restore in case of cancel
      const tempKeyResultTracking = {...keyResultTracking};
      this.restoreKeyResultTrackingEntries.push(tempKeyResultTracking);
    }
  }

  cancelEdit(e: any, keyResultTracking: KeyResultTrackingModel) {
    e.preventDefault();
    keyResultTracking.editMode = false;
    this.restoreKeyResultTrackingEntries.forEach((element,index)=>{
      if(element.id===keyResultTracking.id) {
          keyResultTracking.confidenceLevel = element.confidenceLevel;
          keyResultTracking.results = element.results;
          keyResultTracking.comments = element.comments;
          keyResultTracking.month = element.month;
          this.removePreviousValue(keyResultTracking);
        }
    });
  }

  delete(e: any, keyResultTracking: KeyResultTrackingModel) {
    e.preventDefault();
    this.confirmationDialogService.confirm('Delete', 'Do you want to delete ' +
    keyResultTracking.year  + ' ' + keyResultTracking.quarter + ' with a progress of ' +
    keyResultTracking.results + '?', 'Yes', 'No','')
      .then(async (confirmed) => {
        if (confirmed) { //selected Yes to delete
          this.keyResultTrackingService.delete(keyResultTracking).subscribe(
            async response => {
              await this.removePreviousValue(keyResultTracking);
              this.ngOnInit();
            },
            error => {
              console.error(error);
            });
        } else { //Selected no to delete
        }
      }
      );
  }

  async removePreviousValue(keyResultTracking: KeyResultTrackingModel) {
    this.restoreKeyResultTrackingEntries.forEach((element,index)=>{
      if(element.id===keyResultTracking.id) {
        this.restoreKeyResultTrackingEntries.splice(index, 1); //element doesn't work with splice?
      }
    });
  }

  closeModal = (sendData: string): void => {
    if (sendData === 'close') {
      this.activeModal.close(sendData);
      this.eventEmitterService.retrieveObjectives();
    } else if (sendData === 'progress') { //click add progress button
      this.activeModal.close(sendData);
      this.openKeyResultTrackingModal();
    }
  };

}
