export enum Quarter {
  q1='Q1',
  q2='Q2',
  q3='Q3',
  q4='Q4'
}

export enum Month {
  january='January',
  february='February',
  march='March',
  april='April',
  may='May',
  june='June',
  july='July',
  august='August',
  september='September',
  october='October',
  november='November',
  december='December'
}

export enum State {
  backlog='Backlog',
  discovering='Discovering',
  developing='Developing',
  measuring='Measuring',
  done='Done'
}

export enum Status {
  notstarted = 'Not Started',
  statusmeasuring = 'Measuring',
  statusdone = 'Done',
  deprioritized = 'Deprioritized',
  archive = 'Archive'
}
