import { Component, Input, OnInit} from '@angular/core';
import { ObjectiveModel } from 'src/app/models/objective.model';
import { RoadmapModel } from 'src/app/models/roadmap.model';
import { RoadmapPriorityService } from 'src/app/services/roadmap-priority.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ViewObjectivesComponent } from '../view-objectives/view-objectives.component';
import { ProductModel } from 'src/app/models/product.model';
import { ProductGroupModel } from 'src/app/models/product-group.model';
import { DatePipe } from '@angular/common';
import { RoadmapService } from 'src/app/services/roadmap.service';
import { NewRoadmapItemComponent } from '../new-roadmap-item/new-roadmap-item.component';
import { RoadmapProgressService } from 'src/app/services/roadmap-progress.service';
import { RoadmapProgressAddComponent } from '../roadmap-progress-add/roadmap-progress-add.component';
import { RoadmapProgressViewComponent } from '../roadmap-progress-view/roadmap-progress-view.component';
import { Month, Quarter, State } from '@enums';
import { ObjectiveService } from 'src/app/services/objective.service';

@Component({
  selector: 'app-roadmap-priority',
  templateUrl: './roadmap-priority.component.html',
  styleUrls: ['./roadmap-priority.component.scss']
})
export class RoadmapPriorityComponent implements OnInit {
  @Input() allProductObjectives: ObjectiveModel[];
  @Input() allProductGroupObjectives: ObjectiveModel[];
  @Input() objectiveType: string;
  @Input() productId: string; //product id of the page we are viewing
  @Input() productGroupId: string; //will always be the same within group of products
  @Input() allProducts: ProductModel[] = [];
  @Input() allProductGroups: ProductGroupModel[] = [];
  @Input() allPortfolioObjectives: ObjectiveModel[] = [];
  @Input() planningPortfolioId: string;
  @Input() roadmapType: string;
  @Input() onReload: (showLatest?: boolean, tabs?: any) => void;

  years: number[] = [];
  allProductRoadmaps: RoadmapModel[] = [];
  tempAllProductRoadmaps: RoadmapModel[] = [];
  allProductGroupRoadmaps: RoadmapModel[] = [];
  allPortfolioRoadmaps: RoadmapModel[] = [];
  mixedContentWarning = false;
  draggedIndex: number;
  draggedOverIndex: number;
  dragType: string;
  uniqueRoadmaps: RoadmapModel[] = [];
  currentDate: Date = new Date();
  currentYear: number;

  filterStates: State[] = [];
  filterQuarters: Quarter[] = [];
  filterYears: number[] = [];
  filterMonths: Month[] = [];

  readonly portfolioTaxType: string = 'PORTFOLIO';
  readonly productTaxType: string = 'PRODUCT';
  readonly productGroupTaxType: string = 'PRODUCT_GROUP';
  readonly productOnProductGroupTaxType: string = 'PRODUCT_ON_PRODUCT_GROUP';

  constructor(private readonly roadmapPriorityService: RoadmapPriorityService,
              private readonly modalService: NgbModal,
              private datePipe: DatePipe,
              private roadmapService: RoadmapService,
              private roadmapProgressService: RoadmapProgressService,
              private objectiveService: ObjectiveService
              ) {
  }

  ngOnInit(): void {
    //this performs the filter before loaddata is called the first time
    //the visible UI selection of the default values in the filter is in
    //the ngoninit of the roadmap filter component
    this.filterStates.push(State.backlog);
    this.filterStates.push(State.developing);
    this.filterStates.push(State.discovering);
    this.filterStates.push(State.measuring);
    this.loadData();
  }

  filterRoadmapByMonth(months: Month[]) {
    this.filterMonths = months;
    this.loadData();
  }

  filterRoadmapByQuarter(quarters: Quarter[]) {
    this.filterQuarters = quarters;
    this.loadData();
  }

  filterRoadmapByState(states: State[]) {
    this.filterStates = states;
    this.loadData();
  }

  filterRoadmapByYear(years: number[]) {
    this.filterYears = years;
    this.loadData();
  }

  setMilestone(roadmap: RoadmapModel): string {
    return this.roadmapService.formatMilestone(roadmap);
  }

  filterRoadmapsByRoadmapFilters(roadmaps: RoadmapModel[]) {
      let filteredRoadmaps: RoadmapModel[] = this.roadmapService.filterRoadmapsByStates(roadmaps, this.filterStates);
      filteredRoadmaps = this.roadmapService.filterRoadmapsByYears(filteredRoadmaps, this.filterYears);
      filteredRoadmaps = this.roadmapService.filterRoadmapsByQuarters(filteredRoadmaps, this.filterQuarters);
      filteredRoadmaps = this.roadmapService.filterRoadmapsByMonths(filteredRoadmaps, this.filterMonths);

      return filteredRoadmaps;
  }

  async loadData() {
    this.currentYear = this.currentDate.getFullYear();
    if (this.objectiveType === this.portfolioTaxType) {
      this.allPortfolioRoadmaps = await this.roadmapService.findAllRoadmapsByTaxTypeAndTaxIdSortedByPriority(this.allPortfolioObjectives,
        'PORTFOLIO', this.planningPortfolioId);
      this.years = this.roadmapService.collectRoadmapDeliveryMilestoneYears(this.allPortfolioRoadmaps);

      this.allPortfolioRoadmaps = this.filterRoadmapsByRoadmapFilters(this.allPortfolioRoadmaps);

    } else if (this.objectiveType === this.productGroupTaxType) {
      this.allProductGroupRoadmaps = await this.roadmapService
      .findAllRoadmapsByTaxTypeAndTaxIdSortedByPriority(this.allProductGroupObjectives,
        'PRODUCT_GROUP', this.productGroupId);

      this.allProductRoadmaps = await this.roadmapService.findAllRoadmapsByTaxTypeAndTaxIdSortedByPriority(this.allProductObjectives,
        'PRODUCT_ON_PRODUCT_GROUP', this.productGroupId);

      this.years = this.roadmapService.collectRoadmapDeliveryMilestoneYears([]
        .concat(this.allProductGroupRoadmaps).concat(this.allProductRoadmaps));

      this.allProductGroupRoadmaps = this.filterRoadmapsByRoadmapFilters(this.allProductGroupRoadmaps);
      this.allProductRoadmaps = this.filterRoadmapsByRoadmapFilters(this.allProductRoadmaps);
    } else if (this.objectiveType === this.productTaxType) {
      this.allProductGroupRoadmaps = await this.roadmapService
      .findAllRoadmapsByTaxTypeAndTaxIdSortedByPriority(this.allProductGroupObjectives,
        'PRODUCT_GROUP', this.productGroupId);

      this.allProductRoadmaps = await this.roadmapService
      .findAllRoadmapsByTaxTypeAndTaxIdSortedByPriority(this.allProductObjectives,
        'PRODUCT', this.productId);

      this.years = this.roadmapService.collectRoadmapDeliveryMilestoneYears([]
        .concat(this.allProductGroupRoadmaps).concat(this.allProductRoadmaps));

      this.allProductRoadmaps = this.filterRoadmapsByRoadmapFilters(this.allProductRoadmaps);
      this.allProductGroupRoadmaps = this.filterRoadmapsByRoadmapFilters(this.allProductGroupRoadmaps);
    }

    this.years.forEach((value, index) => {
      if (value === 0) { this.years.splice(index, 1); }
    });

  }

  async viewObjectives(roadmap: RoadmapModel, productId?: string, productGroupId?: string, planningPortfolioId?: number) {
    const modalRef: NgbModalRef = this.modalService.open(ViewObjectivesComponent, {
      windowClass: 'viewObjective-modal',
      centered: true,
      keyboard: false,
      scrollable: true,
      backdrop: 'static',
    });

    if (productId) { modalRef.componentInstance.productId = productId; }
    if (productGroupId) { modalRef.componentInstance.productGroupId = productGroupId; }
    if (planningPortfolioId) { modalRef.componentInstance.planningPortfolioId = planningPortfolioId; }
    modalRef.componentInstance.objectiveType = this.objectiveType;
    modalRef.componentInstance.roadmapItem = roadmap;

  }

  onDragStart(index, dragType: string): void {
    this.dragType = dragType;
    //the row we are dragging
    this.draggedIndex = index;
  }

  dragLeave($event) {
    this.draggedOverIndex = -1;
    $event.preventDefault();
  }

  allowDrop($event, index, dropType: string, state: string): void {
    //will only show blue line within objective we started the drag
    if (state === 'Done' || this.dragType === undefined || dropType === undefined) {
      this.resetDrag();
      return;
    } else if (this.draggedIndex !== -1 && dropType === this.dragType) {
    //the row we are hovering over, where to place blue line to drop
      this.draggedOverIndex = index;
      $event.preventDefault();
    }
  }

  resetDrag() {
    this.dragType = '';
    this.draggedIndex = -1;
    this.draggedOverIndex = -1;
  }

  async onDrop($event, index, dropType: string): Promise<void> {
    if (this.dragType !== dropType) {
      this.resetDrag();
      return;
    };

    //prevent drag-dropping on another objective
    if (this.draggedIndex === undefined ||
      this.draggedOverIndex === undefined ||
      this.draggedOverIndex === -1 ||
      this.draggedIndex === -1) {
      this.resetDrag();
      return;
    }
    //drop the row and update array and backend
    $event.preventDefault();

    if (this.draggedIndex !== index) {
      if (dropType === 'PRODUCT') {
        const item = this.allProductRoadmaps[this.draggedIndex];
        this.allProductRoadmaps.splice(this.draggedIndex, 1);
        this.allProductRoadmaps.splice(index, 0, item);
        this.roadmapPriorityService.renumberPriority(this.allProductRoadmaps);
        //this is required to reload objectives, without @input for objectives will not have latest data for priority settings
        this.allProductObjectives = await this.objectiveService.getAllByProduct('PRODUCT', this.productId, 'false').toPromise();
        this.loadData();
      } else if (dropType === 'PRODUCT_GROUP') {
        const item = this.allProductGroupRoadmaps[this.draggedIndex];
        this.allProductGroupRoadmaps.splice(this.draggedIndex, 1);
        this.allProductGroupRoadmaps.splice(index, 0, item);
        this.roadmapPriorityService.renumberPriority(this.allProductGroupRoadmaps);
        //this is required to reload objectives, without @input for objectives will not have latest data for priority settings
        this.allProductGroupObjectives = await this.objectiveService.getAllByProductGroup('PRODUCT-GROUP',
          this.productGroupId, 'false').toPromise();
        this.loadData();
      } else if (dropType === 'PORTFOLIO') {
        const item = this.allPortfolioRoadmaps[this.draggedIndex];
        this.allPortfolioRoadmaps.splice(this.draggedIndex, 1);
        this.allPortfolioRoadmaps.splice(index, 0, item);
        this.roadmapPriorityService.renumberPriority(this.allPortfolioRoadmaps);
        //this is required to reload objectives, without @input for objectives will not have latest data for priority settings
        this.allPortfolioObjectives = await this.objectiveService.getAllPortfolioByPlanningPortfolio(
          Number(this.planningPortfolioId)).toPromise();
        this.loadData();
      }
      this.resetDrag();
    } else {
      //was dropped in the same position - just reset
      this.resetDrag();
    }
  }

  async onEditRoadmap(roadmapItemId: string) {
    const roadmapItem = await this.roadmapService.getById(roadmapItemId).toPromise();
    const modalRef: NgbModalRef = this.modalService.open(NewRoadmapItemComponent, {
      windowClass: 'roadmap-modal',
      centered: true,
      keyboard: false,
      backdrop: 'static',
    });

    modalRef.componentInstance.objectiveType = this.objectiveType;
    modalRef.componentInstance.productGroupId = this.productGroupId;
    modalRef.componentInstance.productId = this.productId;
    modalRef.componentInstance.existingRoadmapItem = true;
    modalRef.componentInstance.planningPortfolioId = this.planningPortfolioId;
    modalRef.componentInstance.objectiveId = null;//not needed for priority
    modalRef.componentInstance.roadmap = roadmapItem;

    const roadmapProgressItems = await this.roadmapProgressService.getAll(roadmapItem.id).toPromise();
    modalRef.componentInstance.roadmapProgress = roadmapProgressItems;
    modalRef.componentInstance.fromModal = 'editRoadmapPriority';

    roadmapItem.keyResults.forEach(kr => {
      modalRef.componentInstance.selectedObjectives.push(kr.objectiveId);
    });

    modalRef.result
    .then((roadmap: RoadmapModel) => {
      this.roadmapService.update(roadmap).subscribe(
        (_: any) => {
          const tabs = {tabId: null, tabObjectiveId: null, priorityTab: true};
          this.onReload(false, tabs);
        },
        (err: Error) => {
          console.error(err);
        }
      );
    })
    .catch((err: any) => console.error(`${err}`));
  }

  onRoadmapProgress = (
    isNew: boolean,
    roadmap: RoadmapModel
  ): void => {
     //if new, show modal to create new roadmap progress, otherwise display all progress for selected roadmap
     let useModal: any;
     if (isNew) {
       useModal = RoadmapProgressAddComponent;
     } else {
       useModal = RoadmapProgressViewComponent;
     }

     const modalRef = this.modalService.open(useModal, {
       windowClass: 'roadmap-modal',
       centered: true,
       keyboard: false,
       backdrop: 'static',
     });

     modalRef.componentInstance.roadmap = roadmap;
     modalRef.componentInstance.roadmapType = this.roadmapType;
     modalRef.componentInstance.fromPage = 'roadmapPriority';

     modalRef.result
      .then((e: any) => {
        if (e === 'OnSubmit-Create') { //we are closing, not switching between progress modals
          const tabs = {tabId: null, tabObjectiveId: null, priorityTab: true};
          this.onReload(false, tabs);
        }
      })
      .catch((err: any) => {
        console.error(err);
      });
  };

}
