import {HttpClient} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from 'src/environments/environment';
import { ProductOKRModel } from '../models/productokr.model';
import { ProductModel } from '../models/product.model';

@Injectable({
  providedIn: 'root'
})
export class ProductService {

  constructor(@Inject(HttpClient) private http: HttpClient) {
  }



  // getAllProducts(): Observable<ProductOKRModel[]> {
  //   return this.http.get<ProductOKRModel[]>(`${environment.baseURL}/productokr`);
  // }

  getAllProducts(): Observable<ProductModel[]> {
    return this.http.get<ProductModel[]>(`${environment.baseURL}/productokr`);
  }

  getProductById(id: string): Observable<ProductModel[]> {
    return this.http.get<ProductModel[]>(`${environment.baseURL}/productokr?id=${id}`);
  }


}
