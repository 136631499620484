import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {environment} from 'src/environments/environment';
import {RoadmapOtherLevelModel} from '../models/roadmap-other-level.model';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RoadmapOtherLevelService {

  constructor(
    private readonly httpClient: HttpClient
  ) {
  }

  getAllByRoadmapId(id: string): Observable<RoadmapOtherLevelModel[]> {
    return this.httpClient.get<RoadmapOtherLevelModel[]>(`${environment.baseURL}/roadmap-other-level/${id}`);
  }

}
