import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {environment} from 'src/environments/environment';
import {RoadmapSortModel} from '../models/roadmap-sort.model';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RoadmapSortService {

  constructor(
    private readonly httpClient: HttpClient
  ) {
  }

  create(newRoadmapSort: RoadmapSortModel): Observable<any> {
    return this.httpClient.post<RoadmapSortModel>(`${environment.baseURL}/roadmap-sort`, newRoadmapSort);
  }

  update(existingRoadmapSortItem: RoadmapSortModel): Observable<any> {
    return this.httpClient.put<RoadmapSortModel>(`${environment.baseURL}/roadmap-sort`, existingRoadmapSortItem);
  }

  getById(id: string): Observable<any> {
    //this is by objective id
    return this.httpClient.get<RoadmapSortModel>(`${environment.baseURL}/roadmap-sort/${id}`);
  }

  delete(roadmapItem: any): Observable<any> {
    let params = new HttpParams();
    if (roadmapItem.roadmapId) {
      params = params.set('roadmapid', roadmapItem.roadmapId);
    }
    if (roadmapItem.objectiveId) {
      params = params.set('objectiveid', roadmapItem.objectiveId);
    }
    return this.httpClient.delete(`${environment.baseURL}/roadmap-sort`, { params });
  }
}
