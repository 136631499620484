<form name="form" class="form-horizontal" (ngSubmit)="objectiveForm.form.valid && onSubmit(objectiveForm, submitBtn)"
  #objectiveForm="ngForm" novalidate>
  <div class="modal-content">
    <div class="modal-header">
      <h2 *ngIf="objective.draft && objectiveType === 'PORTFOLIO'" class="modal-title" id="newObjectivepLabel">Add new objective to {{
        planningPortfolioName }}</h2>
      <h2 *ngIf="!objective.draft && objectiveType === 'PORTFOLIO'" class="modal-title" id="newObjectivepLabel">Edit objective for {{
          planningPortfolioName }}</h2>
      <h2 *ngIf="objective.draft && objectiveType !== 'PORTFOLIO'" class="modal-title" id="newObjectivepLabel">Add a new {{
        this.lowerObjectiveType.replace('_', ' ') }} objective</h2>
      <h2 *ngIf="!objective.draft && objectiveType !== 'PORTFOLIO'" class="modal-title" id="newObjectivepLabel">Edit {{
        this.lowerObjectiveType.replace('_', ' ') }} objective</h2>
      <button type="button" class="btn-close" aria-label="Close"
        (click)="closeModal('cancel', objectiveForm, null)"></button>
    </div>
    <div class="modal-body pt-2">
      <div class="mb-3">
        <label for="description" class="form-label">Objective:</label>
        <textarea id="description" name="description" class="form-control" #description="ngModel" rows="2"
          placeholder="What business outcomes do you want to accomplish?" [(ngModel)]="objective.description"
          [ngClass]="{'is-invalid':objectiveForm.submitted && description.invalid}" required>
        </textarea>
        <div class="invalid-feedback">
          <div> Objective is required</div>
        </div>
      </div>
      <div *ngIf="this.objective.objectiveType === 'PRODUCT' || this.objective.objectiveType==='PRODUCT_GROUP'"
        class="mb-3">
        <label for="portfolioobjective" class="form-label">Objectives for {{ planningPortfolioName }} Portfolio:</label>
        <select id="portfolioobjective" class="form-control" name="portfolioobjective" #portfolioobjective="ngModel"
          [(ngModel)]="objective.portfolioObjectiveId"
          [ngClass]="{'is-invalid':objectiveForm.submitted && portfolioobjective.invalid && (this.objective.objectiveType ==='PRODUCT' || this.objective.objectiveType === 'PRODUCT_GROUP')}"
          class="form-select" required>
          <option value=""></option>
          <option *ngFor="let portfolioObjective of portfolioObjectives" [value]="portfolioObjective.id">{{
            portfolioObjective.description }}</option>
        </select>
        <div class="invalid-feedback">
          <div> Portfolio Objective is required</div>
        </div>
      </div>
      <div *ngIf="this.objective.objectiveType === 'PORTFOLIO'" class="mb-3">
        <label for="enterpriseobjective" class="form-label">Enterprise objective this aligns with:</label>
        <select id="enterpriseobjective" class="form-control" name="enterpriseoobjective" #enterpriseobjective="ngModel"
          [(ngModel)]="objective.enterpriseObjectiveId"
          [ngClass]="{'is-invalid':objectiveForm.submitted && enterpriseobjective.invalid && this.objective.objectiveType ==='PORTFOLIO'}"
          class="form-select" required>
          <option value=""></option>
          <option *ngFor="let enterpriseObjective of enterpriseObjectives" [value]="enterpriseObjective.id">{{
            enterpriseObjective.description }}</option>
        </select>
        <div class="invalid-feedback">
          <div> Enterprise Objective is required</div>
        </div>
      </div>
      <div *ngIf="this.objective.objectiveType !== 'ENTERPRISE'" class="mb-3">
        
        <label for="owner" class="form-label">Owner:</label>
        <span class="ng-autocomplete" [ngClass]="{'is-invalid':objectiveForm.submitted && !selectedOwnerName}">
          <ng-autocomplete #owner id="owner" name="owner" class="form-control" [data]="userSearchResults"
            [searchKeyword]="keyword" (selected)="selectUser($event)" (inputChanged)="searchUser($event)"
            placeholder="owner search..." [itemTemplate]="itemTemplate"
            [notFoundTemplate]="notFoundTemplate" [(ngModel)]="selectedOwnerName" minQueryLength=3
            [ngClass]="{'is-invalid':objectiveForm.submitted && !selectedOwnerName}"
             required>
          </ng-autocomplete>
          
          <div class="invalid-feedback">
            <div> Owner is required</div>
          </div>

          <ng-template #itemTemplate let-item>
            <a [innerHTML]="item.name"></a>
          </ng-template>
        </span>
      </div>
      <div class="mb-3">
        <label for="status" class="form-label">Status:</label>
        <select
          class="form-control status"
          id="status"
          name="status"
          #status = "ngModel"
          [(ngModel)]="objective.status"
          [ngClass]="{'is-invalid':objectiveForm.submitted && status.invalid}"
          required>
          <option *ngFor="let tmpStatus of statusList">{{tmpStatus}}</option>
        </select>
        <div class="invalid-feedback">
          <div> Status is required</div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button (click)="closeModal('cancel', objectiveForm, null)" type="button"
        class="btn btn-secondary">Cancel</button>
      <button *ngIf="objective.draft" (click)="closeModal('saveAsDraft', objectiveForm, draftBtn)"
        class="btn btn-primary" type="button" #draftBtn>Save as a Draft</button>
      <button class="btn btn-primary" type="submit" #submitBtn>{{ this.saveType }}</button>
    </div>
  </div>
</form>