<form name="form" class="form-horizontal" (ngSubmit)="keyResultForm.form.valid && onSubmit(submitBtn, keyResultForm)" #keyResultForm="ngForm" novalidate>
  <div class="modal-content">
    <div class="modal-header">
      <h2 *ngIf="keyResult.draft" class="modal-title" id="keyResultLabel">Add a new Key Result</h2>
      <h2 *ngIf="!keyResult.draft" class="modal-title" id="keyResultLabel">Edit Key Result</h2>
      <button type="button" class="btn-close" aria-label="Close" (click)="closeModal('cancel', keyResultForm)"></button>
    </div>
    <div class="modal-body text-start">
        <div class="row pt-1">
          <div class="col-md-12">
            <label for="name" class="col-form-label">Desired Outcome:</label>
            <textarea
              type="text"
              id="name"
              name="name"
              class="form-control" 
              placeholder="Describe business value outcomes, not projects, activities or tasks."
              #name = "ngModel"
              rows="2"
              [(ngModel)]="keyResult.name"
              [ngClass]="{'is-invalid':keyResultForm.submitted && name.invalid}"
              required>
            </textarea>
              <div class="invalid-feedback">
                <div> Desired Outcome is required</div>
              </div>
          </div>
        </div>
        <div class="row pt-1">
          <div class="col-md-6">
            <label for="baseline" class="form-label">Baseline (from):</label>
            <input 
              type="text"
              id="baseline"
              name="baseline"
              class="form-control"
              placeholder="Numerical value"
              inputmode="numeric"
              pattern="[0-9.-]*"
              #baseline = "ngModel"
              (paste)="onPaste(baseline, $event)"
              (drop)="onDrop(baseline, $event)"
              (keydown)="onKeyDown(baseline, $event)"
              [(ngModel)]="keyResult.baseline"
              [ngClass]="{'is-invalid':keyResultForm.submitted && baseline.invalid}"
              required>
              <div class="invalid-feedback">
                <div> Baseline is required (numerical value only)</div>
              </div>
          </div>
          <div class="col-md-6">
            <label for="goal" class="form-label">Goal (to):</label>
            <input
              type="text"
              id="goal"
              name="goal"
              class="form-control"
              placeholder="Numerical value"
              inputmode="numeric"
              pattern="[0-9.-]*"
              #goal = "ngModel"
              (paste)="onPaste(goal, $event)"
              (drop)="onDrop(goal, $event)"
              (keydown)="onKeyDown(goal, $event)"
              [(ngModel)]="keyResult.goal"
              [ngClass]="{'is-invalid':keyResultForm.submitted && goal.invalid}"
              required>
              <div class="invalid-feedback">
                <div> Goal is required (numerical value only)</div>
              </div>
          </div>
        </div>
        <div class="row pt-1">
          <div class="col-md-6">
            <label for="measureType" class="form-label">Measurement Type:</label>
            <select 
              id="measureType"
              name="measureType"
              class="form-control"
              #measureType = "ngModel"
              [(ngModel)]="keyResult.measureType"
              (ngModelChange)="changeMeasureType($event)"
              [ngClass]="{'is-invalid':keyResultForm.submitted && (measureType.invalid || measureType.value === '')}"
              required>
              <option value=""></option>
              <option value="Percent">Percent</option>
              <option value="Count">Count</option>
              <option value="Dollar">Dollar</option>
              <option value="Time (Hours)">Time (Hours)</option>
              <option value="Time (Minutes)">Time (Minutes)</option>
              <option value="Time (Seconds)">Time (Seconds)</option>
              <option value="Other">Other</option>
            </select>
            <div class="input-group mt-2" *ngIf="keyResult.measureType==='Other'">
              <input 
                  type="text"
                  id="otherMeasurementType"
                  name="otherMeasurementType"
                  class="form-control"
                  maxlength="{{ maxOtherLength }}"
                  placeholder="enter 'Other' type"
                  #otherMeasurementType = "ngModel"
                  [(ngModel)] = "keyResult.otherMeasurementType"
                  [ngClass]="{'is-invalid':keyResultForm.submitted && keyResult.measureType==='Other' && otherMeasurementType.invalid}"
                  required>
                  <div class="form-help text-muted input-group-append">
                    <span *ngIf="!keyResult.otherMeasurementType" class="input-group-text">0/{{ maxOtherLength }}</span>
                    <span *ngIf="keyResult.otherMeasurementType" class="input-group-text">{{ keyResult.otherMeasurementType.length }}/{{ maxOtherLength }}</span>
                  </div>
                  <div class="invalid-feedback">
                    <div> Other is required (max 15 characters)</div>
                  </div>
            </div>
            <div class="invalid-feedback">
              <div> Measurement Type is required</div>
            </div>
          </div>
          <div class="col-md-6">
            <label for="dueDate" class="form-label">By When:</label>
            <input
              type="date"
              id="dueDate"
              name="dueDate"
              class="form-control"
              #dueDate = "ngModel"
              [(ngModel)]="keyResult.dueDate"
              [ngClass]="{'is-invalid':keyResultForm.submitted && dueDate.invalid}"
              required>
              <div class="invalid-feedback">
                <div> Due Date is required</div>
              </div>
          </div>
        </div>
        <div class="row pt-1">
          <div class="col-md-6">
            <div class="form-label">How often will you measure progress?</div>
            <input
              type="radio"
              id="timeframe1"
              name="timeframe"
              class="btn-check"
              value="monthly"
              #timeframe = "ngModel"
              [(ngModel)]="keyResult.timeframe"
              [ngClass]="{'is-invalid':keyResultForm.submitted && timeframe.invalid}"
              required>
              <label class="btn btn-sm btn-outline-secondary ps-2 m-1" for="timeframe1">Monthly</label>
            <input
              type="radio"
              id="timeframe2"
              name="timeframe"
              class="btn-check"
              value="quarterly"
              #timeframe = "ngModel"
              [(ngModel)]= "keyResult.timeframe"
              [ngClass]="{'is-invalid':keyResultForm.submitted && timeframe.invalid}"
              required>
            <label class="btn btn-sm btn-outline-secondary ps-2" for="timeframe2">Quarterly</label>
            <span *ngIf="objectiveType === 'PORTFOLIO'">
              <input
                type="radio"
                id="timeframe3"
                name="timeframe"
                class="btn-check"
                value="twiceyearly"
                #timeframe = "ngModel"
                [(ngModel)]="keyResult.timeframe"
                [ngClass]="{'is-invalid':keyResultForm.submitted && timeframe.invalid}"
                required>
              <label class="btn btn-sm btn-outline-secondary ps-2 m-1" for="timeframe3">Twice a year</label>
              <input
                type="radio"
                id="timeframe4"
                name="timeframe"
                class="btn-check"
                value="yearly"
                #timeframe = "ngModel"
                [(ngModel)]="keyResult.timeframe"
                [ngClass]="{'is-invalid':keyResultForm.submitted && timeframe.invalid}"
                required>
              <label class="btn btn-sm btn-outline-secondary ps-2" for="timeframe4">Yearly</label>
            </span>
            <div class="invalid-feedback">A timeframe is required</div>
          </div>
          <div class="col-md-6">
            <label for="status" class="form-label">Status:</label>
            <select
              class="form-control"
              id="status"
              name="status"
              #status = "ngModel"
              [(ngModel)]="keyResult.status"
              [ngClass]="{'is-invalid':keyResultForm.submitted && status.invalid || (status.value === this.invalidStatus)}"
              required>
                <option [ngValue]="null"></option>
                <option *ngFor="let status of statusList; let i = index" [ngValue]="status">{{status}}</option>
                <option *ngIf="invalidStatus" value="{{ this.invalidStatus }}">{{ this.invalidStatus }}</option>
            </select>
            <div class="invalid-feedback">
              <div *ngIf="keyResultForm.submitted && status.invalid"> Status is required</div>
              <div *ngIf="status.value === this.invalidStatus"> Invalid status. Please select a valid option.</div>
            </div>
          </div>
      </div>
    </div>
    <div class="modal-footer">
      <div *ngIf="!keyResult.draft" class="me-auto">
        <button type="button" class="btn btn-primary" (click)="openConfirmationDialog(true, keyResultForm)">
          <i class="bi-plus"></i>Add Progress
        </button>
        <span>&nbsp;</span>
        <button type="button" class="btn btn-primary" (click)="openConfirmationDialog(false, keyResultForm)">
          View Progress
        </button>
      </div>
      <button (click)="closeModal('cancel', keyResultForm)" type="button" class="btn btn-secondary">Cancel</button>
      <button *ngIf="keyResult.draft" (click)="closeModal('saveAsDraft', keyResultForm, draftBtn)" class="btn btn-primary" type="button" #draftBtn>Save as a Draft</button>
      <button class="btn btn-primary" type="submit" #submitBtn>{{ this.saveType }}</button>
    </div>
  </div>
</form>
