import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { VisionStatementModel } from '../models/vision-statement.model';

@Injectable({
  providedIn: 'root'
})
export class VisionStatementService {

  constructor(@Inject(HttpClient) private http: HttpClient) { }

  getAll(): Observable<VisionStatementModel> {
    return this.http.get<VisionStatementModel>(`${environment.baseURL}/vision-statements`);
  }

  getVisionStatementById(parentId: string): Observable<VisionStatementModel> {
    let params = new HttpParams();
    params = params.set('parentId', parentId);
    return this.http.get<VisionStatementModel>(`${environment.baseURL}/vision-statements`, { params });
  }

  create(data: any): Observable<any> {
    return this.http.post(`${environment.baseURL}/vision-statements`, data);
  }

  update(data: any): Observable<any> {
    return this.http.put(`${environment.baseURL}/vision-statements`, data, {
      params: {
        id: data.id
      }
    });
  }

}
