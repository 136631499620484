import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { UserProfileModel } from '../models/user-profile.model';

@Injectable({
  providedIn: 'root'
})
export class UserProfilesService {

  constructor(@Inject(HttpClient) private http: HttpClient) { }

  getUserProfileByEmpNum(empNumber: string): Observable<UserProfileModel> {
    return this.http.get<UserProfileModel>(`${environment.baseURL}/user-profiles?empnumber=${empNumber}`);
  }

  create(data: any): Observable<any> {
    return this.http.post(`${environment.baseURL}/user-profiles`, data);
  }

  update(data: any): Observable<any> {
    return this.http.put(`${environment.baseURL}/user-profiles`, data, {
      params: {
        empNumber: data.empNumber
      }
    });
  }

}
